// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyADUBIkqzAu4Z0v65kVuCGy-UbxNSZIHFE",
  authDomain: "yamaha-a9570.firebaseapp.com",
  projectId: "yamaha-a9570",
  storageBucket: "yamaha-a9570.appspot.com",
  messagingSenderId: "775690873678",
  appId: "1:775690873678:web:942ac01e017b09c7338c1f",
  measurementId: "G-QNFKJV2CD8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const screenPages =(event)=> {
  switch(event){
    case "Home" : return "Home"
    case "Shop" : return "Shop"
    case "Available Vouchers" : return "Available Vouchers"
    case "Redeemed Vouchers" : return "Redeemed Vouchers"
    case "Expired Vouchers" : return "Expired Vouchers"
    case "My Redemptions" : return "My Redemptions"
    case "Profile" : return "Profile"
    case "Transactions" : return "Transactions"
    case "Transaction Details" : return "Transaction Details"
    case "Order Details" : return "Order Details"
    case "Membership Cards" : return "Membership Cards"
    case "Membership" : return "Membership"
    case "Events Attended" : return "Events Attended"
    case "Manage User" : return "Manage User"
    case "Manage Address" : return "Manage Address"
    case "Add New Address" : return "Add New Address"
    case "Edit Address" : return "Edit Address"
    case "Notifications" : return "Notifications"
    case "Notification Details" : return "Notification Details"
    case "Settings" : return "Settings"
    case "Edit Profile" : return "Edit Profile"
    case "Edit Username" : return "Edit Username"
    case "Change Password" : return "Change Password"
    case "Campaign Check In" : return "Campaign Check In"
    case "Raise a Ticket" : return "Raise a Ticket"
    case "My Tickets" : return "My Tickets"
    case "Ticket Details" : return "Ticket Details"
    case "Bike Info" : return "Bike Info"
    case 'Added Bike Details' : return "Added Bike Details"
    case "Add New Bike Popup" : return "Add New Bike Popup"
    case "Bike More Details" : return "Bike More Details"
    case "Profile Setup" : return "Profile Setup"
    case "Forgot Password" : return "Forgot Password"
    case "Cart" : return "Cart"
    case "Wishlist" : return "Wishlist"
    case "Product Detail" : return "Product Detail"
    case "Check Out Address" : return "Check Out Address"
    case "Order Placed" : return "Order Placed"
    case "Voucher Details" : return "Voucher Details"
    case "Login" : return "Login"
    case "SignUp" : return "SignUp"
    case "Campaign Details" : return "Campaign Details"
  }
}

const buttonClicked =(button)=>{
  
  switch(button){
    case "E Service Booking" : return "E Sevice Booking"
    case "Terms and Conditions" : return "Terms and Conditions"
    case "Privacy Policy" : return "Privacy Policy"
    case "Extended" : return "Membership Extended"
    case "Reactivated" : return "Membership Reactivated"
    case "Continue as Fan" : return "Continue as Fan"
    case "Continue as Owner" : return "Continue as Owner"
    case "Shop Explore Now" : return "Shop Explore Now"
  }
}

class Firebase {
  constructor() {
    if (!Firebase.instance) { Firebase.instance = this }
    return Firebase.instance
  }






logEventForAnalytics = (event) => {
    
    logEvent(analytics, "screen_view", {
        firebase_screen: screenPages(event)
        
    });

    // console.log("event logged" + event)
}

logButtonClicked = (button) => {

    logEvent(analytics, 'button_clicked', {
    button_title: buttonClicked(button)
  });

  // console.log("buttonClickLogged" + button)
}

}


const firebase = new Firebase();
export default firebase;