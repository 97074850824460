import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import infoImg from "../../assets/img/yamahaIcons/info.png"
import firebase from 'utils/firebase';



export default function ConfirmationDialog1 (props) {
    const handleClose = event => { props.setOpen (false)  };
    const handleCancel = event => { console.log("ref ref"); window.location.replace(window.location.href);};
    useEffect(() => {
        
        firebase.logButtonClicked(props.status);
    }, [])
    return (
        <Dialog open={props.open} onClose = { handleClose }  >
          <img src = {infoImg} style = {{height:"50px", width:"50px", marginLeft: "220px", marginTop:"10px", marginBottom:"7px"}}/>
            <DialogContent>
                <DialogContentText> { props.dialogMessage } </DialogContentText>
                <DialogActions>
                    <Button   style = {{ backgroundColor : '#138DE8', color : 'floralwhite' }}  onClick = { handleCancel }  > Ok </Button>

                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};