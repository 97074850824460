import React, { useState, useEffect } from 'react';
import { Row, Col, Spinner, Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import "../../../layouts/layouts.css"
import Select from 'react-select';
import memberService from "../../service/APIService"
import moment from "moment"
import bikeImage from "assets/img/yamahaIcons/bike2.imageset/Rectangle654@2x.png"
import NotificationModal from 'components/Utilities/NotificationModal';
import MembershipDetail from 'views/ManageUser/MembershipDetail';
import OTPVerification from '../OTPVerification';
import AssignedUserConfirmationPopup from 'views/ManageUser/AssignedUserConfirmationPopup';
import { ToastsStore } from "react-toasts";
import firebase from 'utils/firebase';
import { t } from 'i18next';

const ProvideMoreDetails = (props) => {
    const {
        buttonLabel,
        className
    } = props;

    const [modal, setModal] = useState(false);
    



    const deleteBikeDetails = () => {
            let request = {
                "plateNumber": props.plateNumber.toUpperCase(),
                "icNumber": props.icNumber.toUpperCase(),
                "id": props.bikeDetails.id
            }
            memberService.deleteVehicle(request).then((response) => {
                    console.log(response);
            }).catch(error => {
            }).finally(e => {
                props.closePopup();
            })
    }

    

    return (
        <div>


            <Modal size="sm" isOpen={props.openPopup} className={className}>
                <ModalHeader toggle={deleteBikeDetails}>{t("Details not found")}</ModalHeader>
                <ModalBody>

                    <span>
                    {t("Sorry, we are unable to find information about the motorcycle with the given plate number and IC number which might be due to:")} 
 
                        <br/> 1. {t("Motorcycle is registered with JPJ before 2016.")}
                        <br/> 2. {t("Motorcycle ownership has changed (second owner).")}
                        <br/> 3. {t("Interchanged Plate Number.")}
                        <br/> 4. {t("JPJ data is not updated.")}
                        <br/> {t("Do 'Provide more details' for Genblu Rewards admin verification and approval,")}
                        <br/> {t("thank you.")}
                    </span>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-center">
                    {/* <Button color="primary" onClick={deleteBikeDetails}>Cancel</Button> */}
                    <Button color="primary" onClick={props.parentCallback}>{t("Continue")}</Button>

                </ModalFooter>
            </Modal>
        </div>
    );
}

export default ProvideMoreDetails;