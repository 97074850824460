import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import "../../views/LoginComponents/login.css"
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import closeButton from "../../assets/img/yamahaIcons/close.png"
// import SubscriptionPopup from '/Users/sandeepyadav/ReactFrontend/Yamaha-Customer-Web/src/views/Subscriptions/SubscriptionPopup.js'
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function AlertDialogSlide (props){
  const [open, setOpen] = React.useState(props.isOpen);
  const [showSubscriptionPopup, setShowSubscriptionPopup] = React.useState(true)
  // const [membershipDetails, setMembershipDetails] = React.useState(JSON.parse(sessionStorage.getItem("membershipDetails")))

  const handleClickOpen = () => {
    setOpen(props.isOpen);
  };
  const handleActivation = () => {
    props.handle(false, 0);
    setOpen(false);
  }

  const handleAddBike = () => {
    console.log("Add bike");
    props.handle(false, 1);
    setOpen(false);
  }

  console.log(showSubscriptionPopup, "STATE");

  const handleClose = () => {
    props.handle(false);
    setOpen(false);
  }

  return (
    <div style = {{ height:"1000px", width:"100px"}}>
      {/* { showSubscriptionPopup  &&<SubscriptionPopup openPopup={showSubscriptionPopup}
               membershipDetails={membershipDetails}  closePopup={() => { setShowSubscriptionPopup(false) }}></SubscriptionPopup>}  */}
      <Dialog 
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <img onClick={handleClose} src={closeButton} alt="Logo" className='closeButtonAlterDialogBox'/>
        <DialogContentText id="alert-dialog-slide-description" style={{paddingTop:"10px", paddingLeft:"20px", paddingRight:"20px"}}>
          Your membership has expired, Please activate your subscription plan or add a new Yamaha motorcycle to continue,
Thank you
          </DialogContentText>
        <DialogActions >
          <Button onClick={handleActivation} style={{backgroundColor:"#138de8", color:"white", marginTop:"30px", marginLeft:"10px", padding:"8px"}}>Activate Subscription</Button>
          <Button onClick={handleAddBike} style={{backgroundColor:"#138de8", color:"white", marginTop:"30px", marginLeft:"20px", padding:"8px"}} >Add Bike</Button>
      
        </DialogActions>
      </Dialog>
    </div>
  );
}

