
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Row,Nav,
    Col, TabContent, TabPane, NavItem, NavLink, CardTitle,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import React, { useState, useEffect } from 'react';
import memberService from "../service/APIService";
import ticket from "assets/img/yamahaIcons/ticketBlue.svg"
import {  Link } from "react-router-dom";
import moment from "moment"
import classnames from 'classnames';
import RaiseTicket from './RaiseTicket';
import MyTickets from "./MyTickets";
import { getAnalytics, logEvent } from "firebase/analytics";
import firebase from "utils/firebase.js"
import { useTranslation } from "react-i18next";



const Ticket = () => {
    // const [tickets, setTickets] = useState({})
    const [activeTab, setActiveTab] = useState('1');
    const { t } = useTranslation();

    const toggle = (tab) => {

        // if (activeTab !== tab) 
        setActiveTab(tab);
    }
  
    

   
    return (
        <>
       
            < >
            <Row>
                <Col className="order-xl-1" xl="12">
                    <Card className="bg-white shadow">
                        <CardHeader className="bg-white border-0">
                            <Row>
                                <Col xs="12" className="mt-4">
                                    <Card className="mt-3">
                                        <CardHeader style={{ padding: '0px' }}>
                                            <Nav tabs>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === '1' })}
                                                        onClick={() => { toggle('1'); }}
                                                    >{t("Raise a Ticket")}
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === '2' })}
                                                        onClick={() => { toggle('2'); }} >
                                                        {t("My Tickets")}
                                                    </NavLink>
                                                </NavItem>
                                            
                                            </Nav>
                                        </CardHeader>
                                        <CardBody style={{ overflow: 'auto' }}>
                                            <TabContent activeTab={activeTab}>
                                                <TabPane tabId={activeTab}>
                                                    {console.log("activeTab",activeTab)}
                                                    {activeTab == '1' ? <RaiseTicket/> : <MyTickets/>}
                                                </TabPane>    
                                            </TabContent>

                                        </CardBody>
                                    </Card>
                                </Col>

                            </Row>
                        </CardHeader>
                        <CardBody>

                        </CardBody>
                    </Card>
                </Col>
            </Row>
            
            </>
        </>
    );
};

export default Ticket;
