
import {
    Button,
    Card, CardFooter,
    CardBody,
    Row, Input,
    Col,
    Spinner
} from "reactstrap";
import "../../views/LoginComponents/login.css"
import { Redirect, Link } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import memberService from "../service/APIService"
import classnames from 'classnames';
import yamahaVoucherTitle from "assets/img/yamahaIcons/yamahaVoucherTitle.png"
import relationship from "assets/img/yamahaIcons/relationship.svg"
import countryFlag from "assets/img/yamahaIcons/countryFlag.svg"
import QRCode from "react-qr-code";
import Tooltip from '@material-ui/core/Tooltip';
import { ToastsStore } from "react-toasts";
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

import moment from "moment"
import OTPVerification from "./OTPVerification";
import firebase from "utils/firebase";
import {t} from 'i18next';
const useStyles = makeStyles((theme) => ({
    fab: {
        margin: theme.spacing(2),
    },
    absolute: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(3),
    },
}));

function EditProfile(props) {
    const classes = useStyles();

    const [activeTab, setActiveTab] = useState('1');
    const [userId, setUserId] = useState(sessionStorage.getItem('userId'))
    const [userDetails, setUserDetails] = useState(JSON.parse(sessionStorage.getItem('userDetails')))
    const [userDetails2, setUserDetails2] = useState(JSON.parse(sessionStorage.getItem('membershipDetails')))

    const assetBaseUrl = process.env.REACT_APP_ENV === "PROD" ? process.env.REACT_APP_ASSET_BASE_URL_PROD :  process.env.REACT_APP_ENV === "DEV" ? process.env.REACT_APP_ASSET_BASE_URL :  process.env.REACT_APP_ASSET_BASE_STAGE;

    const [editEmail, setEditEmail] = useState(false)
    const [editMobile, setEditMobile] = useState(false)
    const [data, setData] = useState(JSON.parse(sessionStorage.getItem('userDetails')))
    const [openVerificationPopup, setOpenVerificationPopup] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [fileName, setFileName] = useState()
    const [isUploading, setIsUploading] = useState(false)
    const [error, setError] = useState()
    const [mobileError, setMobileError] = useState("")
    const [otp , setOTP ] = useState(null);

    useEffect(() => {
        setData({
            ...data,
            ["email"]: userDetails.email,
            ["mobileNumber"]: userDetails.mobile && userDetails.mobile.mobileNumber
        })

    }, [])
    const onFileChange = (event) => {
        setIsUploading(true)
        // setFileName("")
        if (event.target.files && event.target.files.length) {
            let fileList = event.target.files;
            let filepush = []
            filepush.push(fileList[0])
            //   setShowProfilePicture(true)
            uploadFile(event.target.files)
        }

    }


    const uploadFile = (files) => {
        let fileName = files[0].name.split(".")
        let actualFileName = sessionStorage.getItem("userId") + fileName[0]
        let extension = fileName[1]
        setFileName(actualFileName)
        //        setUserDetails({...userDetails,
        //     ["profileImageName"]:actualFileName
        // })
        memberService.fetchs3PresignedURlForProfile(actualFileName).then(res => {
            if (res) {
                memberService.uploadToS3(res, files[0]).then(res => {
                    updateMemberProfileDetails(actualFileName)
                })
            }
        })
    }

    const handleEditMobile = () => {
        setEditMobile(true)
        // setEditEmail(false)

    }

    const handleEditEmail = () => {
        // setEditMobile(false)
        setEditEmail(true)
    }

    const handleChange = (event) => {
        let phoneRegex = /^(\+?6?01)[0|1|2|3|4|6|7|8|9]\-*[0-9]{7,9}$/
        if (event.target.name === "mobileNumber") {
          if (!phoneRegex.test("+60" + event.target.value)) {
           setMobileError("Please enter a valid mobile number")
          }
          else {
        
            setMobileError("")
          }
        }
        
    
        setData({
            ...data,
            [event.target.name]: event.target.value
        })
    }

    const cancelEdit = () => {
        setError("")
        setData({
            ...data,
            ["email"]: userDetails && userDetails.email,
            ["mobileNumber"]: userDetails.mobile && userDetails.mobile.mobileNumber
        })
        setEditMobile(false)
        setEditEmail(false)
    }

    const handleUpdate = () => {
        // data.mobile.mobileNumber = data.mobileNumber
        if (data.mobile.mobileNumber !== data.mobileNumber) {
            sendOtp()
        }
        else {
            updateMemberProfileDetails()
        }
        // if(editEmail){
        //     updateMemberProfileDetails()
        // }
    }

    const sendOtp = () => {
        setIsLoading(true)
        let mobileNumber = data.mobileNumber
        memberService.handleSendOtp("+60" + mobileNumber).then(response => {
            // setNextScreen(true)
            ToastsStore.success("OTP sent successfully.");

            setOpenVerificationPopup(true)

        }).catch(error => {
        }).finally(e => {
            setIsLoading(false)
            // setNextScreen(true)
        })
    }

    const updateMemberProfileDetails = (actualFileName) => {
        let request = data
        request.profileImageName = actualFileName ? actualFileName : userDetails.profileImageName
        if (data.mobile.mobileNumber !== data.mobileNumber) {
            data.mobile.mobileNumber = data.mobileNumber
            data.otp = actualFileName
         //   data.bypassCode = "123456"
        }
        // data.profileImageName=fileName!==null ?fileName: data.profileImageName
        memberService.updateMemberProfileDetails(request, userId).then(response => {
            setEditEmail(false)
            setEditMobile(false)
            sessionStorage.setItem("userDetails", JSON.stringify(response.data))
            localStorage.setItem("userDetails",JSON.stringify(response.data))
            setUserDetails(response.data)
            if (userDetails.email != null && data.email != userDetails.email || (data.email!=null && editEmail))
                handleVerifyOTP(actualFileName)
                setOpenVerificationPopup(false);
                ToastsStore.success("Profile Details Updated Successfully")
           
        }).catch(error => {
         //   setError("Invalid OTP")
            ToastsStore.error(t("Invalid OTP"))
        })
    }
    const handleVerifyOTP = (actualFileName) => {
      //  setOTP(otp);
        let request = {
            "emailOrMobile": data.email,
            "verificationType": "EmailAddress",
            "otp": actualFileName
       //     "bypassCode": "123456"
        }
        memberService.getEmailVerificationLink(userId, request).then(response => {
            ToastsStore.success("Email verification link sent successfully")
        })
       
    }
    const handleOTPVerification = (otp) => {
        console.log(otp);
          setOTP(otp);
      //  setOpenVerificationPopup(false)
        updateMemberProfileDetails(otp)
    }

    return (
        <>
            {openVerificationPopup && <OTPVerification type="EditProfile"  mobileNumber={data.mobileNumber} openPopup={openVerificationPopup} closePopup={() => { setOpenVerificationPopup(false) }}
                parentCallback={handleOTPVerification}></OTPVerification>}

            <Row>
                <Col className="order-xl-1" xl="4">
                    <Card className="bg-white shadow">
                        <CardBody>

                            <div >
                                <Row>
                                    <Col xs="12" className="d-flex justify-content-center">
                                        {userDetails.profileImageName != undefined && <img src={assetBaseUrl + userDetails.profileImageName} className="profileImage" style={{ marginLeft: '24px' }} />}
                                        {/* {userDetails.profileImageName==undefined && <img src={assetBaseUrl + fileName} className="profileImage" />} */}

                                        {userDetails.profileImageName == undefined && <Button className="ma_btn mt-3 mb-3 profile_img_btn_new" color="primary" type="button" >
                                            <i style={{ fontSize: '20px', margin: '2px' }} class="fas fa-user-plus"></i>
                                            <input type="file" accept=".jpg,.jpeg,.png" onChange={(event) => onFileChange(event)} />
                                        </Button>}
                                        {/* {userDetails.profileImageName == undefined && <Button className="ma_btn mt-3 mb-3 profile_img_btn_new" color="primary" type="button" >
                                            <img width="35px" height="35px" className="fas" src="/avatar-placeholder.jpeg"></img>
                                            <i style={{ fontSize: '20px', margin: '2px' }} class="fas"></i>
                                            <input type="file" accept=".jpg,.jpeg,.png" onChange={(event) => onFileChange(event)} />
                                        </Button>} */}
                                        {/* {isUploading  && <img src={assetBaseUrl + fileName} className="profileImage" />} */}
                                    </Col>
                                    {userDetails.profileImageName != undefined && <Button className="camera_btn mt--3 " color="primary" type="button" >
                                        <i className="fas fa-camera  " style={{ marginLeft: '-5px', marginTop: '-2px' }}></i>
                                        <input type="file" accept=".jpg,.jpeg,.png" onChange={(event) => onFileChange(event)} />
                                    </Button>}

                                </Row>
                                <Row className="mt-3" style={{ borderBottom: '1px solid #dddd' }}>
                                    <Col xs="12" className="d-flex justify-content-center">
                                        <p className="textDescription">{userDetails.name}</p>
                                    </Col>
                                    <Col xs="12" className="d-flex justify-content-center">
                                        <p className="memberPointsLabel">{userDetails.membershipNumber}</p>
                                    </Col>
                                    
                                </Row>
                                <Row className="mt-3" style={{ borderBottom: '1px solid #dddd' }}>
                                <Col xs="12" className="d-flex justify-content-center" style={{fontSize:'2px'}}>
                                        <p className="textDescription" >{t("Status")}:</p>
                                    </Col>
                                    <Col xs="12" className="d-flex justify-content-center">
                                        <p className="textDescription">{userDetails.membershipStatus}</p>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs="12" className="d-flex justify-content-center">
                                        <img src={relationship} alt="" />
                                    </Col>
                                    <Col xs="12" className="mt-3 d-flex justify-content-center">
                                        <p className="memberPointsLabel" style={{ textTransform: "capitalize" }}>{userDetails2 && userDetails2.tier}</p>

                                    </Col>
                                </Row>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col className="order-xl-1" xl="8">
                    <Card className="bg-white shadow" style={{ height: '480px' }}>
                        <CardBody>
                            <Row style={{ borderBottom: '1px solid #dddd', margin: '10px', marginTop: 0 }}>
                                <Col xs="2" className="d-flex">
                                    <p className="textHeading mt-3" > {t("Full name")}</p>
                                </Col>
                                <Col xs="9" className="d-flex" style={{ justifyContent: 'flex-end' }}>
                                    <p className="productTitle mt-3">{userDetails.name}</p>
                                </Col>
                            </Row>
                            {userDetails.icNumber != null && <Row style={{ borderBottom: '1px solid #dddd', margin: '10px', marginTop: 0 }}>
                                <Col xs="2" className="d-flex">
                                    <p className="textHeading mt-2">{t("IC number")}</p>
                                </Col>
                                <Col xs="9" className="d-flex" style={{ justifyContent: 'flex-end' }}>
                                    <p className="productTitle mt-3">{userDetails.icNumber}</p>

                                </Col>
                            </Row>}
                            <Row style={{ borderBottom: '1px solid #dddd', margin: '10px', marginTop: 0 }}>
                                <Col xs="2" className="d-flex">
                                    <p className="textHeading mt-2">{t("Gender")}</p>

                                </Col>
                                <Col xs="9" className="d-flex" style={{ justifyContent: 'flex-end' }}>
                                    <p className="productTitle mt-3">{userDetails.gender}</p>

                                </Col>
                            </Row>
                            <Row style={{ borderBottom: '1px solid #dddd', margin: '10px', marginTop: 0 }}>
                                <Col xs="3" className="d-flex">
                                    <p className="textHeading mt-2">{t("Date of birth")}</p>

                                </Col>
                                <Col xs="8" className="d-flex" style={{ justifyContent: 'flex-end' }}>
                                    <p className="productTitle mt-3">{userDetails.dateOfBirth}</p>

                                </Col>
                            </Row>
                            <Row style={{ borderBottom: '1px solid #dddd', margin: '10px', marginTop: 0 }}>
                                <Col xs="2" className="d-flex">
                                    <p className="textHeading mt-2">{t("Email ID")}</p>

                                </Col>
                                <Col xs="9" className="d-flex" style={{ justifyContent: 'flex-end' }}>

                                    {userDetails.email && userDetails.emailVerifiedStatus === 'NotVerified' && !editEmail &&

                                        <Tooltip title="Not Verified">
                                            <IconButton aria-label="delete">
                                                <i className="fas fa-exclamation-circle" style={{ marginRight: '10px' }}></i>
                                            </IconButton>
                                        </Tooltip>
                                        // <i className="fas fa-exclamation-circle" style={{ marginRight: '10px', marginTop: '18px' }}></i>

                                    }
                                    {userDetails.email && userDetails.emailVerifiedStatus !== 'NotVerified' && !editEmail &&
                                        <Tooltip title="Verified">
                                            <IconButton aria-label="delete">
                                                <i className="fas fa-check-circle" style={{ color: 'green', marginRight: '10px' }}></i>
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    {!editEmail && <p className="productTitle mt-3">{userDetails.email}</p>}
                                    {editEmail && <Input value={data.email} style={{ paddingLeft: '10px', width: '100%' }} name="email" placeholder="Email" type="text"
                                        autoComplete="new-email" onChange={handleChange} />}
                                </Col>
                                <Col xs="1">
                                    <i className="fas fa-edit d-flex" style={{ color: '#138de8', marginRight: '10px', marginTop: '18px', justifyContent: 'flex-end' }}
                                        onClick={handleEditEmail}></i>
                                </Col>
                            </Row>
                            <Row style={{ borderBottom: '1px solid #dddd', margin: '10px', marginTop: 0 }}>
                                <Col xs="2" className="d-flex">
                                    <p className="textHeading mt-2">{t("Mobile number")}</p>

                                </Col>
                                <Col xs="9" className="d-flex" style={{ justifyContent: 'flex-end' }}>
                                    {/* <img src={countryFlag} alt="" height="30" width="30" style={{ margin: '5px' }} /> */}
                                    <p className="productTitle mt-3" style={{marginRight:'5px'}}>
                                        +60
                                    </p>
                                    {!editMobile && <p className="productTitle mt-3">{userDetails.mobile && userDetails.mobile.mobileNumber}</p>}
                                    {editMobile && <Input value={data.mobileNumber} style={{ paddingLeft: '10px' }} name="mobileNumber" placeholder="mobileNumber" type="number"
                                        autoComplete="new-mobileNumber" onChange={handleChange} />}

                                </Col>
                                <Col xs="1">
                                   {userDetails && userDetails.credentialType!=='MobileNumber' && <i className="fas fa-edit d-flex" style={{ color: '#138de8', marginRight: '10px', marginTop: '18px', justifyContent: 'flex-end' }}
                                        onClick={handleEditMobile}></i>}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12">
                                <small style={{ color: 'red' }}>{ mobileError}</small>
                                </Col>
                               

                            <small style={{ color: 'red'  , margin: 20 }}>{error}</small>
                            </Row>
                        </CardBody>
                        </Card>

                        {(editMobile || editEmail) && <div className="d-flex justify-content-center mt-4" style={{ margin: '5px'  }}>
                            <div>
                            <Button className="mt-10" color="primary" type="button" style={{ marginRight: '15px' , marginBottom:'4px' }} onClick={cancelEdit}>
                                {t("Cancel")}
                            </Button>
                            {!isLoading && <Button className="mt-10" color="primary" type="button" style={{ marginRight: '15px' , marginBottom: '4px' }} 
                            onClick={handleUpdate} disabled={mobileError!=""}>
                                {t("Update")}
                            </Button>
                            }
                            </div>
                            {isLoading && <Spinner style={{ marginTop: '10px' }} color="primary" />}
                        </div>}
                    
                </Col>
            </Row>

        </>
    );
};

export default EditProfile;
