import React, { useState, useEffect } from 'react';
import { Row, Col, Spinner, Label,Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input } from 'reactstrap';
import "../../layouts/layouts.css"
import firebase from 'utils/firebase';

const VoucherDetails = (props) => {
    useEffect(()=>{
        firebase.logEventForAnalytics("Voucher Details")
    },[])
    const {
        buttonLabel,
        className
    } = props;

 
    return (
        <div>
            {/* <Button color="danger" onClick={toggle}>{buttonLabel}</Button> */}
            <Modal size="sm" isOpen={props.openPopup} className={className}>
                <ModalHeader toggle={props.closePopup}>Voucher Description</ModalHeader>
                <ModalBody className="">
                   {props && props.activeVoucherDescription && props.activeVoucherDescription.description}
                </ModalBody>
                <ModalFooter className="d-flex justify-content-center">
               <Button className="mt-3" color="primary" type="button" onClick={props.closePopup}>Back</Button>
                 
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default VoucherDetails;