/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import React, { useState, useEffect } from 'react';
import memberService from "../service/APIService";
import relationship from "assets/img/yamahaIcons/relationship.svg"
import genbluIcon from "assets/img/yamahaIcons/Group192.svg"
import yamahaIcon from "assets/img/yamahaIcons/Group326.png"
import { ToastsStore } from "react-toasts";
import { Link } from "react-router-dom";
import moment from "moment"
import QRCode from "react-qr-code";
import yamahaBackground from "assets/img/yamahaIcons/Group381.png"
import SubscriptionPopup from "views/Subscriptions/SubscriptionPopup";
import firebase from "utils/firebase";
import { t } from 'i18next';

const Membership = () => {
    const [userId, setUserId] = useState(sessionStorage.getItem('userId'))
    const [userDetails, setUserDetails] = useState(JSON.parse(sessionStorage.getItem('userDetails')))
    const [membershipCards, setMembershipCards] = useState(JSON.parse(sessionStorage.getItem('userDetails')).cards)
    const [membershipDetails,setMembershipDetails] = useState({})
    const [showSubscriptionPopup, setSubscriptionPopup] = useState(false)
    const [userDetails2, setUserDetails2] = useState(JSON.parse(sessionStorage.getItem('membershipDetails')))

    // const [memberCa rdNumber]

    useEffect(() => {
        getMembershipDetails()
        firebase.logEventForAnalytics("Membership")
    }, [])

    const getMembershipDetails =()=>{
        memberService.getMembershipDetails(userId).then(response=>{
            setMembershipDetails(response.data)
            console.log("membership details", response.data)

        })
    }


    const extendMembership = () => {
        setSubscriptionPopup(true);
        membershipDetails.membershipExpiryDate ?  new Date(membershipDetails.membershipExpiryDate) < new Date() ? firebase.logButtonClicked("Membership Reactivated") : firebase.logButtonClicked("Membership Extended") : firebase.logButtonClicked("Membership Activated")
       
        // memberService.extendMembership(userId).then(response=> {
        //     console.log("extendmembership called in Membership--")
        //     setSubscriptionPopup(true);
        // })
    }

    const getFormattedCard = (cardNumber)=>{
        let str=cardNumber.toString().match(/\d{1,4}/g)
        let newCardNumber=[];
        str.forEach(aVal=>{
            newCardNumber.push(aVal)
        })
        let card=newCardNumber[0]+" "+newCardNumber[1]+" "+newCardNumber[2]

        return card;

    }


    const getFormattedExpiryDate = (expiryDate) => {
        if(expiryDate === null || expiryDate === undefined)
        {
            return " ";
        }
        let newExpiryDate = moment(expiryDate).format('DD MMM YYYY')
        return newExpiryDate;
    }

    const handleDefaultCard = (membershipCardId) =>{
        memberService.setDefaultMembershipCard(userId,membershipCardId).then(response=>{
            ToastsStore.success("Default card set successfully");
            sessionStorage.setItem("userDetails",JSON.stringify(response.data))
            localStorage.setItem("userDetails",JSON.stringify(response.data))
            setMembershipCards(response.data.cards)
        })
    }

    const getFormattedDuration = (durationInMonths) => {
        if(durationInMonths!=undefined) 
          {  let year = parseInt(durationInMonths/12)
            let month = durationInMonths%12
            if(year === 0 ){return month + " "+ "month(s)"}
            else if(month === 0 ) {return year + " " + "year(s)"}
            else { return year + " "+"year(s) and"+  " "+ month + "month(s)"} }
        else {return 0 + " " + "year"}    
    }

    const getFormattedAmount = (amount) => {
        if(amount!=undefined){
            return parseFloat(amount).toFixed(2)
        }
        else return 0.00
    }

    const handelClosePopup = () =>{
        setSubscriptionPopup();
    } 
    console.log("1",new Date(new Date().setMonth(new Date().getMonth() + 6)));
    console.log("2",new Date(membershipDetails.membershipExpiryDate));
    console.log("3",new Date(new Date().setMonth(new Date().getMonth() + 6))<new Date(membershipDetails.membershipExpiryDate));
    console.log("final",(new Date(new Date().setMonth(new Date().getMonth() + 6))<new Date(membershipDetails.membershipExpiryDate))?true:false);
    return (
        <>
            < >
            {showSubscriptionPopup && <SubscriptionPopup openPopup={showSubscriptionPopup} handelClosePopup = {handelClosePopup}
               membershipDetails={membershipDetails}  closePopup={() => { setSubscriptionPopup(false) }}></SubscriptionPopup>}
                <Row>
                    <Col className="order-xl-1" xl="12">
                        <Card className="bg-white shadow">
                            <CardBody>

                                <Row className="mt-3">
                                    <Col xs="4"  className="mt-4">
                                    <Card style={{height:'320px',maxHeight:'320px'}}>
                                    <Row  className="mt-8" style={{display:'grid',justifyContent:'center'}}>
                                         <Col xs="12"> <img style={{textAlign:'center',marginLeft:'32px'}} src={relationship} alt="" height="50" width="50" />
                                                  <p style={{textAlign:'center',textTransform: "capitalize"}}className="memberPointsLabel">{userDetails2 && userDetails2.tier} {t("Membership")}</p></Col>
                                     </Row>
                                    </Card>
                                    </Col>
                                    <Col xs="8">
                                        <Card className="mt-4">
                                            <Row style={{ borderBottom: '1px solid #dddd', margin: '10px', marginTop: 0 }}>
                                                <Col xs="5" className="d-flex">
                                                    <p className="textHeading mt-3">{t("Current plan duration")}</p>
                                                </Col>
                                                <Col xs="6" className="d-flex" style={{ justifyContent: 'flex-end' }}>
                                                    <p className="productTitle mt-3">{getFormattedDuration(membershipDetails.planDurationInMonths)}</p>
                                                </Col>
                                            </Row>
                                            <Row style={{ borderBottom: '1px solid #dddd', margin: '10px', marginTop: 0 }}>
                                                <Col xs="6" className="d-flex">
                                                    <p className="textHeading mt-2">{t("Amount paid")}</p>

                                                </Col>
                                                <Col xs="5" className="d-flex" style={{ justifyContent: 'flex-end' }}>
                                                    <p className="productTitle mt-3"> RM {getFormattedAmount(membershipDetails.amountPaidForSubscription)}</p>

                                                </Col>
                                            </Row>
                                            <Row style={{ borderBottom: '1px solid #dddd', margin: '10px', marginTop: 0 }}>
                                                <Col xs="6" className="d-flex">
                                                    <p className="textHeading mt-2">{t("Membership expires on")}</p>

                                                </Col>
                                                <Col xs="5" className="d-flex" style={{ justifyContent: 'flex-end' }}>
                                                <p className="productTitle mt-3">{getFormattedExpiryDate(membershipDetails.membershipExpiryDate)}</p>

                                                </Col>
                                            </Row>
                                        </Card>
                                        <Row>
                                            <Col xs="12">
                                            <Card className="mt-4">
                                                <CardBody>
                                                <Row>
                                                    <Col xs="8" className="d-flex">  <p className="membershipExpiryTitle">{ membershipDetails.membershipExpiryDate != null ?  new Date(membershipDetails.membershipExpiryDate) < new Date() ? t("Your membership expired on") : t("Your membership expires on") : "Please Activate You Membership"}</p>
                                                    <p className="membershipExpiryDate">{membershipDetails.membershipExpiryDate ? getFormattedExpiryDate(membershipDetails.membershipExpiryDate):" "}</p></Col>

                                                    {(new Date(new Date().setMonth(new Date().getMonth() + 6))<new Date(membershipDetails.membershipExpiryDate))?false:true && <Col xs="4" classNAme="d-flex" style={{textAlign:'end'}}>  <Button type="button" style={{ marginRight: '10px', height: '40px', border: '1px solid #ff9017'}} onClick={e=>extendMembership()}
                                                    ><span style={{ color: '#ff9017' }}>{ membershipDetails.membershipExpiryDate ?  new Date(membershipDetails.membershipExpiryDate) < new Date() ? "REACTIVATE" : "EXTEND" : "Activate"}</span>  </Button></Col>}
                                                </Row>
                                                   
                                                </CardBody>
                                            </Card>
                                            </Col>
                                     
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col xs="12" className="bg-white shadow">
                        <Card>
                            <CardHeader style={{height:'30px'}}>
                                <p className="productQuantityLabel">{t("Yamaha Loyalty Membership Card")}</p>
                            </CardHeader>
                            <CardBody>
                                <Row>
                            {membershipCards && membershipCards.map(aDefaultCard=>{
                                return(
                                  <Col xs="4">
                                      <Card className="membershipCardLayout"style={{ backgroundImage: aDefaultCard.backgroundImage?`url(${aDefaultCard.backgroundImage})`:`url(${yamahaBackground})` }}>
                                          <CardBody className="membershipCardSize">
                                              <Row style={{marginTop:'7rem'}}>
                                                  <Col xs="8">
                                                      <p className="memberCardNumber mt-4" style={{ color:aDefaultCard.fontColor ,fontFamily:aDefaultCard.fontType}}> {getFormattedCard(aDefaultCard.cardNumber)}</p>
                                                  </Col>
                                                  <Col xs="4"  className="d-flex" style={{ justifyContent: 'flex-end' }}>
                                                  <QRCode value={aDefaultCard.cardNumber} 
                                                   size={60}
                                                   bgColor={"transparent"}
                                                   fgColor={"white"}
                                                //    opacity={"0.7"}
                                                //    level={"L"}
                                                //    includeMargin={false}
                                                   renderAs={"svg"}
                                                  />

                                                  </Col>
                                              </Row>
                                            
                                          </CardBody>
                                      </Card>
                                      {aDefaultCard.defaultStatus==='OtherCard' &&<p className="membershipCardFooter" onClick={()=>handleDefaultCard(aDefaultCard.id)}>{t("Make this as my default card")}</p>}
                                      {aDefaultCard.defaultStatus!=='OtherCard' &&<p className="membershipCardDefFooter" onClick={()=>handleDefaultCard(aDefaultCard.id)}>{t("Default card")}</p>}
                                  </Col>
                                )
                            })}
                            </Row>
                            </CardBody>
                        </Card>
                    </Col> 
                </Row>
            </>
        </>
    );
};

export default Membership;
