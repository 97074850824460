import { useEffect } from "react"
import firebase from "utils/firebase";


const PrivacyPolicy = () => {
    const privacyPolicyUrl =  process.env.REACT_APP_ENV === "PROD" ? process.env.REACT_APP_PRIVACY_URL_PROD :  process.env.REACT_APP_ENV === "DEV" ? process.env.REACT_APP_PRIVACY_URL :  process.env.REACT_APP_PRIVACY_URL_STAGE;
       
    useEffect(()=>{
        firebase.logButtonClicked("Privacy Policy")
        console.log("environment2",process.env.REACT_APP_ENV);
         console.log("privacypolicy",privacyPolicyUrl)
        })
    return (<div>
        <iframe src={privacyPolicyUrl} style={{ height: "1200px", width: '100%', marginTop:"-50px" }} title="Iframe Example"></iframe>
    </div>)
}
export default PrivacyPolicy