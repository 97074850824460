/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Spinner
} from "reactstrap";
import "../../views/LoginComponents/login.css"
import { Link } from "react-router-dom";
import React, { useState } from 'react';
import memberService from "../service/APIService"
import { ToastsStore } from "react-toasts";
import { useEffect } from "react";
import {t} from 'i18next';

const ChangePassword = (props) => {
    const [data, setData] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(false)
    const [mobileNumber, setMobileNumber] = React.useState(JSON.parse(sessionStorage.getItem('userDetails')).mobile.mobileNumber)
    const [passwordError, setPasswordError] = React.useState(false)
    const [error, setError] = React.useState("");
    const [isLogin, setIsLogin] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)

    

    const handleChange = (e) => {
        setPasswordError("")
        if (e.target.name === 'password' || e.target.name === 'confirmPassword') {
            setPasswordError("")
        }
        setData({
            ...data,
            [e.target.name]: e.target.value,
            [e.target.name + "_error"]: null,
        });


    };
    const handleLogout = () => {
        sessionStorage.clear();
        window.location.reload();
    }

    const handleChangePassword = (e) => {
        if (handleError()) {
            setIsLoading(true)
            memberService.handleChangePassword(data, mobileNumber).then((response) => {
                ToastsStore.success(response.data.message);
                handleLogout()
                setData("")
                setIsLogin(true)
            }).catch(error => {
                setError(error && error.response && error.response.data && error.response.data.message)
            }).finally(e => {
                setIsLoading(false)
            })
        }
        else {
            return
        }
    }

    const handleError = () => {
        var passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/;
        if (!passwordRegex.test(data.password)) {
            setPasswordError("Should be 8-32 characters consisting of at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character")
            return false
        }
        if (data.confirmPassword === undefined) {
            setPasswordError("Please enter  password again")
            return false
        }
        if (data.password !== data.confirmPassword) {
            setPasswordError("Passwords do not match")
            return false
        }

        return true
    }
    return (
        <>
            <Row style={{ height: '550px' }}>
                <Col xs="3"></Col>
                <Col xs="6">
                    <Card className="bg-white shadow module-border-wrap">
                        <CardBody className="px-lg-5 py-lg-5">
                            <Form role="form">
                                <FormGroup>
                                    <InputGroup className="input-group-alternative mb-3">
                                        <InputGroupAddon addonType="prepend">
                                            {!showOldPassword && <InputGroupText>
                                                <i className="fas fa-eye-slash" onClick={() => { setShowOldPassword(true) }}></i>
                                            </InputGroupText>}
                                            {showOldPassword && <InputGroupText>
                                                <i class="fas fa-eye" onClick={() => { setShowOldPassword(false) }}></i>
                                            </InputGroupText>}
                                        </InputGroupAddon>
                                        <Input name="oldPassword" style={{ paddingLeft: '10px' }}
                                            placeholder={t("Old password")}
                                            type={showOldPassword ? "text" : "password"}
                                            onChange={handleChange}
                                        />
                                    </InputGroup>
                                </FormGroup>

                                <FormGroup>
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            {!showNewPassword && <InputGroupText>
                                                <i className="fas fa-eye-slash" onClick={() => { setShowNewPassword(true) }}></i>
                                            </InputGroupText>}
                                            {showNewPassword && <InputGroupText>
                                                <i class="fas fa-eye" onClick={() => { setShowNewPassword(false) }}></i>
                                            </InputGroupText>}
                                        </InputGroupAddon>
                                        <Input
                                            style={{ paddingLeft: '10px' }}
                                            name="password"
                                            placeholder={t("Password")}
                                            type={showNewPassword ? "text" : "password"}
                                            autoComplete="new-password"
                                            onChange={handleChange}
                                        />

                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <InputGroup className="input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            {!showConfirmPassword && <InputGroupText>
                                                <i className="fas fa-eye-slash" onClick={() => { setShowConfirmPassword(true) }}></i>
                                            </InputGroupText>}
                                            {showConfirmPassword && <InputGroupText>
                                                <i class="fas fa-eye" onClick={() => { setShowConfirmPassword(false) }}></i>
                                            </InputGroupText>}
                                        </InputGroupAddon>
                                        <Input
                                            style={{ paddingLeft: '10px' }}
                                            placeholder={t("Confirm Password")}
                                            type={showConfirmPassword ? "text" : "password"}
                                            name="confirmPassword"
                                            autoComplete="confirm-password"
                                            onChange={handleChange}
                                        />
                                    </InputGroup>
                                    <small style={{ color: 'red' }}>{passwordError}</small>
                                </FormGroup>

                                <Row className="mt-3">
                                    <Col xs="12" className="text-center" >
                                        <small style={{ color: 'red' }}>{error}</small>
                                    </Col>
                                </Row>

                                <div className="text-center">

                                    {!isLoading && <Button className="mt-3" color="primary" type="button" onClick={handleChangePassword}>
                                        {t("Save")}
                                    </Button>}
                                    {isLoading && <Spinner style={{ marginTop: '10px' }} color="primary" />}
                                </div>
                            </Form>
                        </CardBody>
                    </Card>

                </Col>
                <Col xs="4"></Col>
            </Row>
        </>
    );
};

export default ChangePassword;
