import React from "react";
import { useLocation, Route, Switch, Redirect ,Link} from "react-router-dom";
// reactstrap components
import { Container, Row ,NavbarBrand} from "reactstrap";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";

import routes from "routes.js";
import { useTranslation } from "react-i18next";


// import image from "assets/img/yamahaIcons/"
const Auth = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const {i18n} = useTranslation();
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {

        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
   
    <div style={{backgroundColor:'white'}}>
      
      {/* <div className="header">
      <NavbarBrand to="/" tag={Link}>
            <img
            style={{ height:'30px'}}
              alt="..."
              src={
                require("../assets/img/yamahaIcons/logo.jpg").default
              }
            />
          </NavbarBrand>
      </div> */}
      <div className="header">

      </div>
      <div className="main-content imageBg" ref={mainContent}>
      
        <AuthNavbar />
        {/* <div className="header py-7 py-lg-8"> */}
          {/* <Container>
            <div className="header-body text-center mb-7">
              <Row className="justify-content-center">
                <Col lg="5" md="6" >
                  <h1 className="text-white">Welcome!</h1>
                  <p className="text-lead text-light">
                    Use these awesome forms to login or create new account in
                    your project for free.
                  </p>
                </Col>
              </Row>
            </div>
          </Container> */}
          {/* <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div> */}
        {/* </div> */}
        {/* Page content */}
        <Container className="mt--3 pb-8">
        <div style={{ textAlign: 'center', margin: '10px' }}>
          <button style={{color: i18n.language=='ma'?'black':'white', backgroundColor:i18n.language=='ma'?'white':'#1da1f2', paddingInline:"5px", border: '1px solid black'}} onClick={() => i18n.changeLanguage('en')}>English</button>
          <button style={{color: i18n.language=='ma'?'white':'black', backgroundColor:i18n.language=='ma'?'#1da1f2':'white', paddingInline:"5px", border: '1px solid black'}} onClick={() => i18n.changeLanguage('ma')}>Malay</button>
        
      </div>
          <Row className="justify-content-center">
            <Switch>
              {getRoutes(routes)}
              <Redirect from="*" to="/auth/login" />
            </Switch>
          </Row>
        </Container>
      </div>
      <AuthFooter />
    </div>
    </>
  );
};

export default Auth;
