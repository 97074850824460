import React, { useState, useEffect } from 'react';
import { Row, Col, Spinner, Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, FormGroup, InputGroupText, Input } from 'reactstrap';
import "../../../layouts/layouts.css"
import Select from 'react-select';
import memberService from "../../service/APIService"
import moment from "moment"
import  bikeImage from "assets/img/yamahaIcons/bike2.imageset/Rectangle654@2x.png"
import NewBikeOtherDetails from './NewBikeOtherDetails';

const NewBikeDetailConfirmation = (props) => {
    const {
        buttonLabel,
        className
    } = props;

    const [modal, setModal] = useState(false);


    const toggle = () => setModal(!modal);

   const handleSave =(response) =>{
        props.parentCallback(response)
    }

    return (
        <div>
            {/* <Button color="danger" onClick={toggle}>{buttonLabel}</Button> */}
            <Modal size="sm" isOpen={props.openPopup} className={className}>
                <ModalHeader toggle={props.closePopup}>Confirmation</ModalHeader>
                <ModalBody className="">
                    <div className="d-flex justify-content-center">
                <i className="fas fa-check-circle"  style={{color:'#12bf5f',fontSize:'60px',display:'block'}}></i>
                </div>
                    {/* <i className="fas fa-thumbs-up" style={{color:'#12bf5f',fontSize:'90px'}}></i> */}
                        {/* <h3 className="mt-3">Congratulations!</h3> */}
                        <h4 style={{textAlign:'center'}}>Vehicle Details Uploaded Successfully</h4>
                        <br/><br/>
                        <p style={{textAlign:'center'}}>Bike details uploaded.We will notify you as soon as it is validated by our team.</p>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-center">
               <Button className="mt-3" color="primary" type="button" onClick={props.parentCallback}>
                                    Proceed
                                </Button>

                </ModalFooter>
            </Modal>
        </div>
    );
}

export default NewBikeDetailConfirmation;