import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Row,
    Col, Form, InputGroup, InputGroupAddon, InputGroupText, Input, Spinner, CardFooter
} from "reactstrap";
// core components
import React, { useState, useEffect } from 'react';
import memberService from "../service/APIService";
import { Link } from "react-router-dom"
import "../../views/LoginComponents/login.css"
import filter from "assets/img/yamahaIcons/filter.svg"
import bag from "assets/img/yamahaIcons/blueBag.svg"
import { useHistory } from 'react-router';
import AlertDialogSlide from "../../views/MyVouchers/AlertDialogSlide"
import SubscriptionPopup from '../../views/Subscriptions/SubscriptionPopup'
import firebase from "utils/firebase";
import {t} from 'i18next';

const BagDetail = () => {
    const [userId, setUserId] = useState(sessionStorage.getItem('userId'))
    const [memberPoints, setMemberPoints] = useState(sessionStorage.getItem('memberPoints') ? sessionStorage.getItem('memberPoints') : 0)
    const [productsData, setProductsData] = useState()
    const [showConfirmationScreen, setShowConfirmationScreen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [redemptionPoints, setRedemptionPoints] = useState(0)
    const [pointsAvailable, setPointsAvailable] = useState(true)
    const [selectedItemsForOrderPunch, setSelectedItemsForOrderPunch] = useState()
    const [openAddressPopup, setShowAddressPopup] = useState(false)
    const [finalListOfProducts, setFinalListOfProducts] = useState()
    const [remainingProduct, setRemainingProduct] = useState([])
    const [isOpen,setIsOpen]= useState(false)
    const [membershipDetails,setMembershipDetails] = useState(JSON.parse(sessionStorage.getItem("membershipDetails")))
    const [showSubscriptionPopup, setShowSubscriptionPopup] = React.useState(false)
    const [selectedProductsInCart,setSelectedProductsInCart] = useState()
    const history = useHistory();

    const [productCount, setProductCount] = React.useState(0)
    let quantity = 0

    const newProductData = []



    useEffect(() => {
        setIsLoading(true)
        getMemberPointsBalance()
        firebase.logEventForAnalytics("Cart")
    }, [])

    const getMemberPointsBalance = () => {
        let request = {
            fetchLimit: 1000,
            paginationBookmark: 0
        }
        memberService.getMemberPointsBalance(userId, request).then(response => {
            setMemberPoints(response.data && response.data.totalPoints)
            sessionStorage.setItem('memberPoints', response.data && response.data.totalPoints)

        }).catch(error => { }).finally(() => {
            fetchProductsInCart()

        })
    }
    const calculateTotalSalesPoints = (aProduct) => {
        // aProduct.productSalePrice!==null?aProduct.productSalePrice:(aProduct.variationRegularPrice!==null?aProduct.variationRegularPrice:0)
        if (aProduct.variationQtyInCart !== null && aProduct.variationSalePrice !== null) {
            let newValue = aProduct.variationQtyInCart * aProduct.variationSalePrice
            return newValue
        }
        else if (aProduct.productQtyInCart !== null && aProduct.productSalePrice !== null) {
            let newValue = aProduct.productQtyInCart * aProduct.productSalePrice
            return newValue
        }
        else return 0;
    }

    const calculateTotalRegularPoints = (aProduct) => {
        // aProduct.productSalePrice!==null?aProduct.productSalePrice:(aProduct.variationRegularPrice!==null?aProduct.variationRegularPrice:0)
        if (aProduct.variationQtyInCart !== null && aProduct.variationRegularPrice !== null) {
            let newValue = aProduct.variationQtyInCart * aProduct.variationRegularPrice
            return newValue
        }
        else if (aProduct.productQtyInCart !== null && aProduct.productRegularPrice !== null) {
            let newValue = aProduct.productQtyInCart * aProduct.productRegularPrice
            return newValue
        }
        else return 0;
    }

    const showQtyInCart = (aProduct) => {
        if (aProduct.variationQtyInCart !== null) {

            return aProduct.variationQtyInCart
        }
        else if (aProduct.productQtyInCart !== null) {
            return aProduct.productQtyInCart
        }
        else return 0;
        // aProduct.variationQtyInCart!==null?aProduct.variationQtyInCart:(aProduct.productQtyInCart!==null?aProduct.productQtyInCart:0)
    }

    const handleReduceQty = (aProduct) => {
        let isProductInBag = false

        finalListOfProducts && finalListOfProducts.forEach(allProduct => {
            if (allProduct.productId === aProduct.productId) {
                isProductInBag = true
            }
            else {
                isProductInBag = false
            }
        })
        if (aProduct.variationQtyInCart > 1) {
            aProduct.variationQtyInCart = aProduct.variationQtyInCart - 1
            let productData = productsData.filter(aProd => aProd.id === aProduct.id)

            setFinalListOfProducts(finalListOfProducts)

            setProductsData(productData);
            // if(isProductInBag===false) 
            calculateTotalPointsToRedeem()

            return aProduct
        }
        else if (aProduct.productQtyInCart > 1) {
            aProduct.productQtyInCart = aProduct.productQtyInCart - 1
            let productData = productsData.filter(aProd => aProd.id === aProduct.id)

            setFinalListOfProducts(finalListOfProducts)

            setProductsData(productData);
            // if(isProductInBag===true) 
            calculateTotalPointsToRedeem()

            return aProduct
        }
        return aProduct + 1

    }
    const handleIncreaseQty = (aProduct) => {
        let isProductInBag = true
        finalListOfProducts && finalListOfProducts.forEach(allProduct => {
            if (allProduct.productId === aProduct.productId) {
                allProduct.isProductInBag = true
            }
            else {
                allProduct.isProductInBag = false
            }
        })

        if (aProduct.variationQtyInCart < aProduct.variationQtyInStock) {
            aProduct.variationQtyInCart = aProduct.variationQtyInCart + 1
            let productData = productsData.filter(aProd => aProd.id === aProduct.id)
            setProductsData(productData);
            // if(isProductInBag===true) 
            if (finalListOfProducts && finalListOfProducts.map(aData => {
                if (aData.isProductInBag)
                    calculateTotalPointsToRedeem()

            }))
            

                return aProduct
        }
        else if (aProduct.stockQuantity !== null) {
            if (aProduct.productQtyInCart < aProduct.stockQuantity) {
                aProduct.productQtyInCart = aProduct.productQtyInCart + 1
                let productData = productsData.filter(aProd => aProd.id === aProduct.id)
                setProductsData(productData);
                // if(isProductInBag===true) 
                if (finalListOfProducts && finalListOfProducts.map(aData => {
                    if (aData.isProductInBag)
                        calculateTotalPointsToRedeem()

                }))
                    // calculateTotalPointsToRedeem()
                    // else null

                    return aProduct
            }
        }
        return aProduct + 1
    }

    const calculateTotalPointsToRedeem = () => {
        let sumOfPoints = 0
        finalListOfProducts.forEach(aProduct => {
            if (aProduct.variationSalePrice != null && aProduct.variationQtyInCart !== null) {
                let sumofSalePrice = parseInt(aProduct.variationSalePrice) * parseInt(aProduct.variationQtyInCart)
                sumOfPoints = sumOfPoints + sumofSalePrice

            }
            else if (aProduct.productSalePrice != null && aProduct.productQtyInCart !== null) {
                let sumofSalePrice = parseInt(aProduct.productSalePrice) * parseInt(aProduct.productQtyInCart)
                sumOfPoints = sumofSalePrice + sumOfPoints
            }
            else if(aProduct.variationSalePrice == null && aProduct.variationQtyInCart !== null){
                let sumofPrice = parseInt(aProduct.variationRegularPrice) * parseInt(aProduct.variationQtyInCart)
                sumOfPoints = sumOfPoints + sumofPrice
            }
            else if(aProduct.productSalePrice == null && aProduct.productQtyInCart !== null){
                let sumofPrice = parseInt(aProduct.productRegularPrice) * parseInt(aProduct.productQtyInCart)
                sumOfPoints = sumofPrice + sumOfPoints
            }

        })
        if (memberPoints - sumOfPoints <= memberPoints) { setPointsAvailable(true) }
        else { setPointsAvailable(false) }
        setRedemptionPoints(sumOfPoints)
    }




    const fetchProductsInCart = () => {
        setIsLoading(true)
        var p = JSON.parse(sessionStorage.getItem("Rem"))
        console.log(p);
        let productsData = []
        // if(p !== null )
        // {
        //     productsData = [...p];
        // }
    

        let productData = {}
        memberService.fetchProductsInCart().then(response => {
            console.log(response.data)
            response && response.data.map(aProduct => {
                console.log("123_1",aProduct)
                if (aProduct.variationsInCart.length > 0) {
                    aProduct.variationsInCart && aProduct.variationsInCart.map(aVariant => {
                        aProduct.variations.map(aVariation => {
                            console.log("123_2",aVariant,aVariation)
                            if (aVariation.id === aVariant.variationId) {
                                console.log("123_3",aVariant.variationId)
                                let size=''
                                let color=''
                              aVariation.attributes.forEach(anAttribute => {
                                                                        if(anAttribute.name==='Color'){color=anAttribute.option}
                                                                        if(anAttribute.name==='Size'){size=anAttribute.option}
                                                                        }
                                                            )
                                productData = {
                                    isSelected: aVariation.stock_quantity<1?false:true,
                                    variationId: aVariant.variationId,
                                    productId: aProduct.id,
                                    productSalePrice: null,
                                    productRegularPrice: null,
                                    variationSalePrice: aVariation.sale_price,
                                    variationRegularPrice: aVariation.regular_price,
                                    variationQtyInCart: Math.min(aVariant.quantity,aVariation.stock_quantity),
                                    variationQtyInStock: aVariation.stock_quantity,
                                    productQtyInStock: aProduct.quantityLimit,
                                    productQtyInCart: null,
                                    variationImage: aProduct.image,
                                    productImage: aProduct.images && aProduct.images[0],
                                    variationProductAttributes: aVariation.attributes,
                                    size:size,
                                    color:color,
                                    productAttributes: null,
                                    variationProductName: aProduct.name,
                                    variationProductDescription: aProduct.description && aProduct.description.replace(/<[^>]*>?/gm, ''),
                                    woocommerceId: aProduct.woocommerceId,
                                    
                                    //stockExists: aVariation.stock_quantity<aVariant.quantity?false:true                                  

                                }
                                console.log("123",productData)
                                
                                productsData.push(productData)
                                // if(!(aVariation.stock_quantity<aVariant.quantity)){
                                //     selectedProductsInCart.push(product)
                                // }
                            }

                        })

                    })
                }
                else {
                    productData = {
                        isSelected: aProduct.stockQuantity<1?false:true,
                        variationId: null,
                        productId: aProduct.id,
                        productSalePrice: aProduct.prices.sale_price,
                        productRegularPrice: aProduct.prices.regular_price,
                        variationSalePrice: null,
                        variationRegularPrice: null,
                        variationQtyInCart: null,
                        variationQtyInStock: null,
                        productQtyInStock: aProduct.quantityLimit,
                        productQtyInCart: aProduct.quantityInCart,
                        variationImage: null,
                        productImage: aProduct.images && aProduct.images[0],
                        variationProductAttributes: null,
                        productAttributes: aProduct.attributes,
                        variationProductName: aProduct.name,
                        stockAvailablity: aProduct.inStockStatus,
                        variationProductDescription: aProduct.description && aProduct.description.replace(/<[^>]*>?/gm, ''),
                        woocommerceId: aProduct.woocommerceId,
                        stockQuantity: aProduct.stockQuantity,
                        //stockExists: aProduct.stockQuantity<aProduct.quantityInCart?false:true
                    }
                    
                        productsData.push(productData)
                        
                        }
                        // if(!(aProduct.stockQuantity<aProduct.quantityInCart)){
                        //     selectedProductsInCart.push(product)
                        // }
                }
                
            )
            console.log(productsData,finalListOfProducts,selectedProductsInCart)
            setFinalListOfProducts(productsData.filter(productData => productData.isSelected===true))
            //setFinalListOfProducts(productsData)
            //setSelectedProductsInCart(productsData.filter(productData => productsData.stockExists))
            

            let sumOfPoints = 0
            productsData.forEach(aProduct => {
                if (aProduct.productSalePrice != null && aProduct.productQtyInCart !== null) {
                    let sumofSalePrice = parseInt(aProduct.productSalePrice) * parseInt(aProduct.productQtyInCart)
                    if(aProduct.stockQuantity && aProduct.stockQuantity>=aProduct.productQtyInCart) sumOfPoints = sumofSalePrice + sumOfPoints
                }
                else if (aProduct.variationSalePrice != null && aProduct.variationQtyInCart !== null) {
                    let sumofSalePrice = parseInt(aProduct.variationSalePrice) * parseInt(aProduct.variationQtyInCart)
                    if (aProduct.variationQtyInStock && aProduct.variationQtyInStock>=aProduct.variationQtyInCart)sumOfPoints = sumofSalePrice + sumOfPoints

                }
                else if (aProduct.variationRegularPrice != null && aProduct.variationQtyInCart !== null) {
                    let sumofRegularPrice = parseInt(aProduct.variationRegularPrice) * parseInt(aProduct.variationQtyInCart)
                    if (aProduct.variationQtyInStock && aProduct.variationQtyInStock>=aProduct.variationQtyInCart)sumOfPoints = sumofRegularPrice + sumOfPoints
                }
                else if (aProduct.productRegularPrice != null && aProduct.productQtyInCart !== null) {
                    let sumofRegularPrice = parseInt(aProduct.productRegularPrice) * parseInt(aProduct.productQtyInCart)
                    if(aProduct.stockQuantity && aProduct.stockQuantity>=aProduct.productQtyInCart) sumOfPoints = sumofRegularPrice + sumOfPoints
                }

            })
            if (memberPoints - sumOfPoints <= memberPoints) { setPointsAvailable(true) }
            else { setPointsAvailable(false) }
            setRedemptionPoints(sumOfPoints)
            setProductCount(productsData && productsData.length)
            sessionStorage.setItem("quantityInCart", productsData.length)
            setProductsData(productsData)
        }).catch(error => {
        }).finally(e => {
            setIsLoading(false)
        })
    }

    const handleChange = (event, aProduct) => {
        console.log("finalList",finalListOfProducts)
        let selectedItem = []
        if (event.target.checked) {
            aProduct.isSelected = true
            let productData = productsData.filter(aProd => aProd.id === aProduct.id)
            setProductsData(productData)
            selectedItem.push(aProduct)
            let filteredList = finalListOfProducts
            filteredList.push(selectedItem[0])
            setFinalListOfProducts(filteredList)
            let sumOfPoints = 0
            console.log(filteredList)
            filteredList.forEach(aProduct => {
                if (aProduct.productSalePrice != null && aProduct.productQtyInCart !== null) {
                    let sumofPrice = parseInt(aProduct.productSalePrice) * parseInt(aProduct.productQtyInCart)
                    sumOfPoints = sumofPrice + sumOfPoints
                }
                else if (aProduct.variationSalePrice != null && aProduct.variationQtyInCart !== null) {
                    let sumofPrice = parseInt(aProduct.variationSalePrice) * parseInt(aProduct.variationQtyInCart)
                    sumOfPoints = sumOfPoints + sumofPrice

                }
                else if(aProduct.variationSalePrice == null && aProduct.variationQtyInCart !== null){
                    let sumofPrice = parseInt(aProduct.variationRegularPrice) * parseInt(aProduct.variationQtyInCart)
                    sumOfPoints = sumOfPoints + sumofPrice
                }
                else if(aProduct.productSalePrice == null && aProduct.productQtyInCart !== null){
                    let sumofPrice = parseInt(aProduct.productRegularPrice) * parseInt(aProduct.productQtyInCart)
                    sumOfPoints = sumofPrice + sumOfPoints
                }
            })

            setRedemptionPoints(sumOfPoints)
        }
        else {
            let selectedItemsForOrderPunch = []
            aProduct.isSelected = false
            let productData = productsData.filter(aProd => aProd.id === aProduct.id)
            setProductsData(productData)
            if (aProduct.variationId !== null)
                selectedItem.push(aProduct.variationId)
            else selectedItem.push(aProduct.productId)
            selectedItemsForOrderPunch = aProduct.variationId !== null ? productsData.filter(aData => !selectedItem.includes(aData.variationId))
                : productsData.filter(aData => !selectedItem.includes(aData.productId))
            let sumOfPoints = 0
            setSelectedItemsForOrderPunch(selectedItemsForOrderPunch)
            if (selectedItemsForOrderPunch.length === 0) {
                setRedemptionPoints(0)
                setFinalListOfProducts([])
            }
            
            else {
                let filteredList = []
                let filteredList2 = []
                if (aProduct.variationId !== null)
                {
                    filteredList = finalListOfProducts.filter(aProduct => !selectedItem.includes(aProduct.variationId))
                    filteredList2 = finalListOfProducts.filter(aProduct => selectedItem.includes(aProduct.variationId))
                    
                }
                else filteredList = finalListOfProducts.filter(aProduct => !selectedItem.includes(aProduct.productId))
                setFinalListOfProducts(filteredList)
                filteredList.forEach(aProduct => {
                    if (aProduct.productSalePrice != null && aProduct.productQtyInCart !== null) {
                        let sumofSalePrice = parseInt(aProduct.productSalePrice) * parseInt(aProduct.productQtyInCart)
                        sumOfPoints = sumofSalePrice + sumOfPoints
                    }
                    else if (aProduct.variationSalePrice != null && aProduct.variationQtyInCart !== null) {
                        let sumofSalePrice = parseInt(aProduct.variationSalePrice) * parseInt(aProduct.variationQtyInCart)
                        sumOfPoints = sumOfPoints + sumofSalePrice
                    }
                    else if(aProduct.variationSalePrice == null && aProduct.variationQtyInCart !== null){
                        let sumofPrice = parseInt(aProduct.variationRegularPrice) * parseInt(aProduct.variationQtyInCart)
                        sumOfPoints = sumOfPoints + sumofPrice
                    }
                    else if(aProduct.productSalePrice == null && aProduct.productQtyInCart !== null){
                        let sumofPrice = parseInt(aProduct.productRegularPrice) * parseInt(aProduct.productQtyInCart)
                        sumOfPoints = sumofPrice + sumOfPoints
                    }
                })
                setRedemptionPoints(sumOfPoints)
                setRemainingProduct(filteredList2);            }
        }
    }
    console.log(remainingProduct);

    const handleAddressSelection = () => {
        // setShowAddressPopup(true)
        //<Link to={{ pathname: '/admin/addressDetail', state: { finalListOfProducts: finalListOfProducts, redemptionPoints: redemptionPoints , remainingProduct : remainingProduct} }}

        history.push({pathname: '/admin/addressDetail', state: { finalListOfProducts: finalListOfProducts, redemptionPoints: redemptionPoints , remainingProduct : remainingProduct}})
        console.log("mkwmkmekcmkwmkcmdeckd");
        console.log("finalList",finalListOfProducts)
    }

    const isQuantityAvailable = (aProduct) =>{
        if(aProduct.variationQtyInCart!== null){
            console.log("qty available?", showAvailableQty(aProduct))
            if (aProduct.variationQtyInCart > aProduct.variationQtyInStock){
                aProduct.variationQtyInCart=aProduct.variationQtyInStock
                return false
            }
            else{
                return true
            }
            
        }
        else if(aProduct.productQtyInCart!== null){
            if (aProduct.productQtyInCart > aProduct.stockQuantity){
                aProduct.productQtyInCart = aProduct.stockQuantity
                return false
            }
            else{
                return true
            }
        }
        else
        { return false}
    }

    const showAvailableQty = (aProduct) =>{
        if(aProduct.variationQtyInStock !==null){
            return aProduct.variationQtyInStock
        }
        else if(aProduct.stockQuantity!==null){
            return aProduct.stockQuantity
        }
        else return 0
    }

    const selectedMaxQty = (aProduct) => {
        if(aProduct.variationQtyInStock !==null){
            return aProduct.variationQtyInStock==aProduct.variationQtyInCart
        }
        else if(aProduct.stockQuantity!==null){
            return aProduct.stockQuantity==aProduct.productQtyInCart
        }
        else return false
    }

    const removeFromCart = (aProduct) => {
        console.log(aProduct)
        // if(sessionStorage.getItem("Rem")!==null)
        // {
        //     var x =productsData;
        //     setProductsData(x.filter(y=>y.productId === aProduct.productId));
        // }
        memberService.removeProductsFromCart(aProduct.productId,aProduct.variationId).then(response => {
            fetchProductsInCart()
        })
    }

    const addToWishlist = (aProduct) => {
        memberService.addProductToWishlist(aProduct.productId).then(response => {
            removeFromCart(aProduct)
        })
    }

    const handlePopup =()=>{
        setIsOpen(true);
    }
    const handlePopupCallback =(bool, clickedButton)=>{
        if(clickedButton == 0){
            setShowSubscriptionPopup(true);
        }
        else if(clickedButton == 1){
            //for add bike.
            console.log("Add bike");
            console.log(history.location);
           history.push ({pathname : "/admin/settings", state: {activeSetting : true}});
        }
        setIsOpen(bool);

    }

    const handelClosePopup = () =>{
        setShowSubscriptionPopup();
    }


    return (
        <>
            < >
            { showSubscriptionPopup  &&<SubscriptionPopup openPopup={showSubscriptionPopup}  handelClosePopup = {handelClosePopup}
               membershipDetails={membershipDetails}  closePopup={() => { setShowSubscriptionPopup(false) }}></SubscriptionPopup>} 
             {isOpen && <AlertDialogSlide isOpen={isOpen} handle={handlePopupCallback}/>}
                <Row >
                    <Col className="order-xl-1" xl="12">
                        <Card className="bg-white shadow" >
                            <CardHeader className="bg-white border-0">
                                <Row className=" mt-3">
                                    <Col xs="12">
                                        <Card className=" mt-4">
                                            <Row>
                                                <Col xs="12" className="d-flex">
                                                    <img src={bag} alt="" style={{ marginTop: '1px 10px', marginLeft: '20px' }} />
                                                    <p className="mt-1" style={{ margin: '0px 10px', color: '#138de8' }}>{t("Bag")} </p>

                                                    <p className="mt-1" style={{ margin: '0px 5px', color: openAddressPopup ? '#138de8' : '' }}>-----------
                                                        <i className="fas fa-map-marker-alt mt-1" style={{ marginRight: '10px', marginLeft: '10px' }}></i> {t("Address")}</p>

                                                    <p className="mt-1" style={{ margin: '0px 5px' }}> -----------
                                                        <i className="far fa-check-square mt-1" style={{ marginRight: '10px', marginLeft: '10px' }}></i>
                                                        {t("Order")}</p></Col>
                                            </Row>


                                            {!openAddressPopup && !showConfirmationScreen && <CardBody>
                                                {isLoading &&
                                                    <div className="d-flex" style={{ margin: '10% 40%' }}><Spinner style={{ marginTop: '10px' }} color="primary" />
                                                    </div>}
                                                {!isLoading && productsData && productsData.length === 0 &&
                                                    <CardBody className="d-flex justify-content-center" >
                                                        <p className="waterMark">{t("No Products Available")}</p>
                                                    </CardBody>}
                                                {!isLoading && productsData && productsData.length > 0 &&
                                                    <>
                                                    
                                                        {productsData && productsData.map((aProduct, key) => {
                                                            console.log(aProduct)
                                                            console.log(finalListOfProducts)
                                                            // getQtyInStock(aProduct.quantityInCart)
                                                            // qtyInStock = aProduct.quantityInCart
                                                            return (
                                                                <>
                                                                    <Card>
                                                                        <CardBody>
                                                                            <Row>
                                                                                <Col xs="5">
                                                                                    {showAvailableQty(aProduct) > 0 &&
                                                                                    <input style={{ margin: '10px' }} name="productChecked" type="checkbox"
                                                                                        checked={(aProduct.stockQuantity || aProduct.variationQtyInStock) && aProduct.isSelected} onChange={(e) => handleChange(e, aProduct)} />
                                                                                    }

                                                                                    <img src={aProduct.productImage !== null ? aProduct.productImage : aProduct.variationImage.src} height="210" width="220" alt="" />
                                                                                </Col>
                                                                                <Col xs="7">
                                                                                    <p className="productTitleDetail mt-2">{aProduct.variationProductName}</p>
                                                                                    <p className="productTitleDefinition mt-3">{aProduct.variationProductDescription && aProduct.variationProductDescription.replace(/<[^>]*>?/gm, '')}</p>
                                                                                    {aProduct.variationProductAttributes && aProduct.variationProductAttributes.length > 1 &&
                                                                                        <Row >
                                                                                            <Col xs="6">
                                                                                                <Button style={{ width: '50%' }}>{aProduct.variationProductAttributes[0].option}</Button>
                                                                                            </Col>
                                                                                            <Col xs="6">
                                                                                                <Button style={{ width: '50%' }}>{aProduct.variationProductAttributes[1].option}</Button>

                                                                                            </Col>
                                                                                        </Row>}
                                                                                    {aProduct.variationProductAttributes && aProduct.variationProductAttributes.length === 1 &&
                                                                                        <Row >
                                                                                            <Col xs="6">
                                                                                                <Button style={{ width: '50%' }}>{aProduct.variationProductAttributes[0].option}</Button>
                                                                                            </Col>
                                                                                        </Row>}

                                                                                    {showAvailableQty(aProduct)>0 && <Row className="mt-3" >
                                                                                        <Col xs="3"><p className="productQuantityLabel">{t("Unit Points")}</p>
                                                                                            <p className="mt--4" style={{ color: '#373A3C', fontSize: '20px' }}>{aProduct.productSalePrice !== null ? aProduct.productSalePrice : (aProduct.variationSalePrice !== null ? aProduct.variationSalePrice : 0)}</p>
                                                                                            {/* <p className="mt--4" style={{ color: '#373A3C', fontSize: '20px', textDecoration: "line-through" }}>{aProduct.productRegularPrice !== null ? aProduct.productRegularPrice : (aProduct.variationRegularPrice !== null ? aProduct.variationRegularPrice : 0)}</p> */}

                                                                                            {(aProduct.variationRegularPrice !== null && aProduct.variationRegularPrice !== aProduct.variationSalePrice) &&
                                                                                                <p className="mt--4" style={{ color: '#373A3C', fontSize: '20px', textDecoration: aProduct.variationSalePrice ? "line-through" : "" }}>{aProduct.variationRegularPrice}</p>}

                                                                                            {(aProduct.productRegularPrice !== null && aProduct.productRegularPrice !== aProduct.productSalePrice) &&
                                                                                                <p className="mt--4" style={{ color: '#373A3C', fontSize: '20px', textDecoration: aProduct.productSalePrice ? "line-through" : "" }}>{aProduct.productRegularPrice}</p>}



                                                                                        </Col>
                                                                                        <Col xs="3">
                                                                                            <Button className="d-flex mt-3 justify-content-center" style={{ backgroundColor: '#F5F8FA', height: '40px', width: '100%' }} >
                                                                                                <i className="fas fa-minus" style={{ color: '#97AABD', margin: '3px' }} onClick={() => handleReduceQty(aProduct)}></i>

                                                                                                <p className="mt--2 productQuantity" style={{ marginLeft: '10px', marginRight: '10px' }}>{showQtyInCart(aProduct)}</p>
                                                                                                {/* {!isQuantityAvailable(aProduct) && 
                                                                                                <p className="mt--2 productQuantity" style={{ marginLeft: '10px', marginRight: '10px' }}>.....{showAvailableQty(aProduct)}</p>
                                                                                                } */}
                                                                                                

                                                                                                <i className="fas fa-plus" style={{ color: '#97AABD', margin: '3px' }} onClick={() => handleIncreaseQty(aProduct)}></i>
                                                                                            </Button></Col>
                                                                                        <Col xs="3"><p className="productQuantityLabel">{t("Total Points")}</p>
                                                                                            <p className="mt--4" style={{ color: '#373A3C', fontSize: '20px' }}>{aProduct.variationSalePrice ? calculateTotalSalesPoints(aProduct) : ""}</p>
                                                                                            {aProduct.productSalePrice !== null && <p className="mt--4" style={{ color: '#373A3C', fontSize: '20px' }}>{calculateTotalSalesPoints(aProduct)}</p>}
                                                                                            {(aProduct.productRegularPrice !== null && aProduct.productRegularPrice !== aProduct.productSalePrice) &&
                                                                                                <p className="mt--4" style={{ color: '#373A3C', fontSize: '20px', textDecoration: aProduct.productRegularPrice ? "line-through" : "" }}>{calculateTotalRegularPoints(aProduct)}</p>}
                                                                                            {(aProduct.variationRegularPrice !== null && aProduct.variationRegularPrice !== aProduct.variationSalePrice) &&
                                                                                                <p className="mt--4" style={{ color: '#373A3C', fontSize: '20px', textDecoration: aProduct.variationSalePrice ? "line-through" : "" }}>{calculateTotalRegularPoints(aProduct)}</p>}

                                                                                        </Col>
                                                                                        </Row>}
                                                                                    { showAvailableQty(aProduct)>0 && (!isQuantityAvailable(aProduct)||selectedMaxQty(aProduct)) &&<Row className="mt-3"><Col xs="5"><small style={{color:"red"}}>Quantity available in stock: {showAvailableQty(aProduct)} </small></Col></Row>}
                                                                                    {/* { showAvailableQty(aProduct)>0 &&  selectedMaxQty(aProduct) && <Row className="mt-3"><Col xs="5"><small style={{color:"red"}}>Quantity available in stock: {showAvailableQty(aProduct)} </small></Col></Row>}
                                                                                             */}
                                                                                    {showAvailableQty(aProduct)<1 && <Row className="mt-3"><Col xs="5"><small style={{color:"red"}}>{t("Stock currently unavailable")}</small></Col></Row>}
                                                                                    <Button color="primary" type="button" style={{ marginRight: '15px', height: "40px ", width: '200px' }} onClick={() => removeFromCart(aProduct)}>
                                                                                        {t("Remove")}
                                                                                    </Button>
                                                                                    <Button color="primary" type="button" style={{ marginRight: '15px', height: "40px ", width: '200px' }} onClick={() => addToWishlist(aProduct)} >
                                                                                        {t("Move to Wishlist")}
                                                                                    </Button>
                                                                                </Col>
                                                                            </Row>
                                                                        </CardBody>

                                                                    </Card>

                                                                </>
                                                            )

                                                        })
                                                        }
                                                    </>
                                                }
                                            </CardBody>}
                                            {productsData && productsData.length > 0 && <CardFooter >
                                                <Row>
                                                    <Col xs="6">
                                                        <div className="d-flex " style={{ marginRight: '20px' }}>
                                                            <p className="memberPointsLabel ">{t("Total Amount")} </p>
                                                            <p className="memberPoints mt--1"> {redemptionPoints && redemptionPoints.toLocaleString()} {t("Points")}</p>
                                                        </div>

                                                    </Col>
                                                    <Col xs={redemptionPoints-memberPoints>memberPoints ? "6" : "12"} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                        {(redemptionPoints-memberPoints>memberPoints) &&  <Col xs="6">
                                                            <p className="memberPointsLabel">
                                                                {t("Require ")}{(redemptionPoints - memberPoints).toLocaleString()}{t(" points to place order")}
                                                            </p>
                                                        </Col>}

                                                            <Button color="primary" type="button" style={{ marginRight: '15px', width: '200px', justifyContent: 'flex-end' }}
                                                                disabled={redemptionPoints-memberPoints>memberPoints  || finalListOfProducts && finalListOfProducts.length === 0} onClick={ !(membershipDetails.status == "Activated") ? handlePopup : handleAddressSelection}>
                                                                {t("Proceed to checkout")}
                                                            </Button>
                                                       
                                                    </Col>
                                                </Row>
                                            </CardFooter>}
                                        </Card>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </>
        </>
    );
};

export default BagDetail;

