/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Spinner
} from "reactstrap";
import "../../views/LoginComponents/login.css"
import {  Redirect, Link } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import memberService from "../service/APIService"
import jwt_decode from 'jwt-decode';
import { sha512 } from "js-sha512";
import firebase from "utils/firebase";
import { useTranslation } from "react-i18next";

const Register = (props) => {
  const [data, setData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false)
  const [nameError, setNameError] = React.useState(false)
  const [passwordError, setPasswordError] = React.useState(false)
  const [error, setError] = React.useState("");
  const [privacyError, setPrivacyError] = React.useState("");
  const [isLogin, setIsLogin] = useState(false);
  const [showNewPassword,setShowNewPassword] = useState(false)
  const [showConfirmPassword,setShowConfirmPassword] = useState(false)
  firebase.logEventForAnalytics("SignUp")
  

  const [signupType,setSignupType] = useState(props && props.location && props.location.state === "Username"?"Username":"Mobile");
  const handleChange = (e) => {
    setPrivacyError("")
    setPasswordError("")
    if (e.target.name === 'userName') {
      setNameError("")
    }
    if (e.target.name === 'password' || e.target.name === 'confirmPassword') {
      setPasswordError("")
    }
    if (e.target.name !== 'privacyTerms') {
      setData({
        ...data,
        [e.target.name]: e.target.value,
        [e.target.name + "_error"]: null,
      });
    }
    else {
      setData({
        ...data,
        [e.target.name]: e.target.checked,

      });
    }
  };
  console.log("environment",process.env.REACT_APP_ENV);

    const termsAndConditionUrl =  process.env.REACT_APP_ENV === "PROD" ? process.env.REACT_APP_TERMS_AND_CONDITION_URL_PROD :  process.env.REACT_APP_ENV === "DEV" ? process.env.REACT_APP_TERMS_AND_CONDITION_URL :  process.env.REACT_APP_TERMS_AND_CONDITION_URL_STAGE;
    console.log("termsurl",termsAndConditionUrl);
   const privacyPolicyUrl =  process.env.REACT_APP_ENV === "PROD" ? process.env.REACT_APP_PRIVACY_URL_PROD :  process.env.REACT_APP_ENV === "DEV" ? process.env.REACT_APP_PRIVACY_URL :  process.env.REACT_APP_PRIVACY_URL_STAGE;



  const handleSignUp = (e) => {
    if (handleError()) {

      if (privacyError === "") {
        setIsLoading(true)
        memberService.handleSignUp(data).then((response) => {
          var token = response.data && response.data.accessToken
          var decoded = jwt_decode(token);
          sessionStorage.setItem("accessToken", response.data.accessToken)
          sessionStorage.setItem("refreshToken", response.data.refreshToken)
          sessionStorage.setItem("userId", decoded.sub)
          sessionStorage.setItem("userType", decoded.userType)
          let userDetails = { userName: '', password: '' }
          userDetails.userName = data.userName
          userDetails.password = data.password
          sessionStorage.setItem("hashedPassword", JSON.stringify(sha512(data.password)))
          sessionStorage.setItem("userName", JSON.stringify(data.userName))

          sessionStorage.setItem("userDetails", JSON.stringify(userDetails))
          localStorage.setItem("userDetails",JSON.stringify(response.data))
          sessionStorage.setItem("showSubscriptionPopup",true)
          setIsLogin(true)
        }).catch(error => {
          setError(error && error.response && error.response.data && error.response.data.message)
        }).finally(e => {
          setIsLoading(false)
        })
      }
    }
    else {
      return
    }
  }

  const handleError = () => {
    var passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/;

    let alphaNumericRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/;
    if (!alphaNumericRegex.test(data.userName)) {
      setNameError("Should be at least 8 alphanumeric characters.")
        return false
    }
    if (data.userName === undefined || data.userName === "" || data.userName.length < 8) {
      setNameError("Should be at least 8 alphanumeric characters")
      return false
    }
    if (!passwordRegex.test(data.password)) {
      setPasswordError("Should be 8-32 characters consisting of at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character")
      return false
    }
    if (data.confirmPassword === undefined) {
      setPasswordError("Please enter password again")                               
      return false
    }
    if (data.password !== data.confirmPassword) {
      setPasswordError("Passwords do not match")
      return false
    }
    if (!data.privacyTerms) {
      setPrivacyError("Please agree to terms and conditions.")
      return false
    }
    return true
  }

  const { t } = useTranslation();

  return (
    <>
    {isLogin &&  <Redirect to={{
        pathname: '/auth/initialDetails',
        state: { signupType: signupType}
      }}
      />}
      {/* {isLogin && <Redirect to="/auth/initialDetails"></Redirect>} */}
      <Col lg="5" md="7" className="pb-8 mt-8">
        <span className="login_header">{t("User Information")}</span>
        <Card className="bg-secondary shadow module-border-wrap">
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form">
              {props && props.location && props.location.state === "Username" && 
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-single-02" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input name="userName" 
                    style={{ paddingLeft: '10px' }} 
                    placeholder={t("Username")} 
                    type="text" 
                    onChange={handleChange} />
                </InputGroup>
                { nameError && <small style={{ color: 'red' }}>{t(nameError)}</small> }
              </FormGroup>}
              {props && props.location && props.location.state === "Mobile" && <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-mobile-alt"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input style={{ paddingLeft: '10px' }} placeholder="Mobile Number" type="text" />
                </InputGroup>
              </FormGroup>}
              {props && props.location && props.location.state === "Username" && <FormGroup>
                <InputGroup className="input-group-alternative">
                {!showNewPassword &&   <InputGroupText>
                   <i className="fas fa-eye-slash" onClick={()=>{setShowNewPassword(true)}}></i>
                    </InputGroupText>}
                    { showNewPassword &&  <InputGroupText>
                  <i class="fas fa-eye" onClick={()=>{setShowNewPassword(false)}}></i>
                    </InputGroupText>}
                  <Input
                    style={{ paddingLeft: '10px' }}
                    name="password"
                    placeholder={t("Password")}
                    type={showNewPassword?"text":"password"}
                    autoComplete="new-password"
                    onChange={handleChange}
                  />
                </InputGroup>
              </FormGroup>}
              {props && props.location && props.location.state === "Username" && <FormGroup>
                <InputGroup className="input-group-alternative">
                {!showConfirmPassword &&   <InputGroupText>
                   <i className="fas fa-eye-slash" onClick={()=>{setShowConfirmPassword(true)}}></i>
                    </InputGroupText>}
                    { showConfirmPassword &&  <InputGroupText>
                  <i class="fas fa-eye" onClick={()=>{setShowConfirmPassword(false)}}></i>
                    </InputGroupText>}
                  <Input
                    style={{ paddingLeft: '10px' }}
                    placeholder={t("Confirm Password")}
                    type={showConfirmPassword?"text":"password"}
                    name="confirmPassword"
                    autoComplete="confirm-password"
                    onChange={handleChange}
                  />
                </InputGroup>
                {passwordError && <small style={{ color: 'red' }}>{t(passwordError)}</small>}
              </FormGroup>}
              <Row className="mt-3">
                <Col xs="12" className="text-center" >
                  { privacyError && <small style={{ color: 'red' }}>{t(privacyError)}</small> }
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs="12" className="text-center" >
                  <small style={{ color: 'red' }}>{error}</small>
                </Col>
              </Row>
              {props && props.location && props.location.state === "Mobile" && <div style={{ textAlign: 'right', margin: '10px' }}>
                <Link className="text-link" to={{ pathname: '/auth/sendOTP', state: props && props.location && props.location.state }}>
                  <small>Send OTP</small>
                </Link>
              </div>}
              <Row className="mt-4" >
                <Col xs="12">
                  <div style={{ display: 'flex' }}>
                    <input style={{ margin: '10px' }} name="privacyTerms"
                      type="checkbox" onChange={(e) => handleChange(e)}
                    />
                    <label>
                      <span className="text-muted" style={{ fontSize: '11px' }}>
                        {t("I have read, understood and accepted the")}
                        {" "}
                        <a href={termsAndConditionUrl}target="_blank">
                          {t("Terms and conditions")}
                        </a> {t("and the")}
                        {" "}
                        <a href={privacyPolicyUrl}target="_blank">
                          {t("Privacy Policy")}
                        </a>
                      </span>
                    </label>
                  </div>
                </Col>
              </Row>

              <div className="text-center">
                <Link to={{ pathname: '/auth/login', state: props && props.location && props.location.state }} style={{ marginRight: '15px' }}>
                  <Button className="mt-3" color="primary" type="button" >
                    {t("Cancel")}
                  </Button>
                </Link>
                {!isLoading && <Button className="mt-3" color="primary" type="button" onClick={handleSignUp}>
                  {t("Proceed")}
                </Button>}
                {isLoading && <Spinner style={{ marginTop: '10px' }} color="primary" />}
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Register;
