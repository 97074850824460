
import {
    Button,
    Card, CardFooter, CardHeader,
    CardBody,
    Row, Input,
    Col,
    Spinner
} from "reactstrap";
import "../../views/LoginComponents/login.css"
import { Redirect, Link } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import memberService from "../service/APIService"
import classnames from 'classnames';
import yamahaVoucherTitle from "assets/img/yamahaIcons/yamahaVoucherTitle.png"
import relationship from "assets/img/yamahaIcons/relationship.svg"
import countryFlag from "assets/img/yamahaIcons/countryFlag.svg"
import QRCode from "react-qr-code";
import Tooltip from '@material-ui/core/Tooltip';
import { ToastsStore } from "react-toasts";

import moment from "moment"
import OTPVerification from "./OTPVerification";
import {t} from 'i18next';

function EventsAdded(props) {
    const [activeTab, setActiveTab] = useState('1');
    const [userId, setUserId] = useState(sessionStorage.getItem('userId'))
    const [userDetails, setUserDetails] = useState(JSON.parse(sessionStorage.getItem('userDetails')))
    const assetBaseUrl = process.env.REACT_APP_ENV === "PROD" ? process.env.REACT_APP_ASSET_BASE_URL_PROD :  process.env.REACT_APP_ENV === "DEV" ? process.env.REACT_APP_ASSET_BASE_URL :  process.env.REACT_APP_ASSET_BASE_STAGE;


    const [eventsAttended, setEventsAttended] = useState()
    const [openVerificationPopup, setOpenVerificationPopup] = useState(false)
    const [isLoading, setIsLoading] = useState(false)


    useEffect(() => {
        
        memberService.getEventsAttended(userId).then(response => {
            setEventsAttended(response.data.responseObjects)
        })

    }, [])


    const getFormattedTransactionDate = (transactionTime) => {
        let newTransactionTime = moment(transactionTime).format('DD MMM')
        return newTransactionTime;
    }

    return (
        <>


            <Row>
                <Col className="order-xl-1" xl="12">
                    <Card className="bg-white shadow">
                        <CardHeader style={{ height: '60px' }}>
                        {eventsAttended && eventsAttended.length===0 && 
                            <p>{t("Sorry we could not fetch events attended for you right now. Please try again later")}</p>}
                            {eventsAttended && eventsAttended.length>0 &&  <Row>
                                <Col xs="2"><p className="memberPointsLabel">{t("Date")}</p></Col>
                                <Col xs="6"><p className="memberPointsLabel">{t("Event")}</p></Col>
                                <Col xs="3" className="d-flex" style={{ justifyContent: 'center', marginLeft: '30px' }}><p className="memberPointsLabel">{t("Points Earned")}</p></Col>

                            </Row>}
                        </CardHeader>
                        <CardBody>
                          
                            {eventsAttended && eventsAttended.map(anEvent => {
                                return (<Card className="mb-2" >
                                    <Row style={{ padding: '10px', paddingBottom: '0px' }}>
                                        <Col xs="2"><Button style={{ border: 'none', width: '44px', padding: '3px', height: '43px', fontSize: '13px', fontWeight: '600', cursor: 'auto' }} color="primary" type="button">
                                            {getFormattedTransactionDate(anEvent.transactionDate)}
                                        </Button></Col>
                                        <Col xs="6"><p className="textHeading mt-2">{anEvent.campaignTitle}</p></Col>
                                        <Col xs="3" className="d-flex " style={{ justifyContent: 'center' }}><p className="eventsTitle mt-2">{anEvent.pointsRewarded}</p></Col>

                                    </Row>
                                </Card>)
                            })}
                        </CardBody>
                    </Card>
                </Col>

            </Row>

        </>
    );
};

export default EventsAdded;
