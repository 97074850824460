
import {
    Button, CardHeader,
    Card, CardFooter,
    CardBody,
    Row, Input,
    Col,
    Spinner
} from "reactstrap";
import "../../views/LoginComponents/login.css"
import React, { useEffect, useState } from 'react';
import memberService from "../service/APIService"
import SelectPlateNumber from "./SelectPlateNumber";
import MembershipDetail from "./MembershipDetail";
import OTPVerification from "views/Settings/OTPVerification";
import AssignedUserConfirmationPopup from "./AssignedUserConfirmationPopup";
import { ToastsStore } from "react-toasts";
import { useHistory } from 'react-router';
import AlertDialogSlide from "../../views/MyVouchers/AlertDialogSlide"
import SubscriptionPopup from '../../views/Subscriptions/SubscriptionPopup'
import { getAnalytics, logEvent } from "firebase/analytics";
import firebase from "utils/firebase.js"
import {t} from 'i18next';

function ManageUserDashboard(props) {
    const [userId, setUserId] = useState(sessionStorage.getItem('userId'))
    const [bikeDetails, setBikeDetails] = useState({})
    const [assigneeDetails, setAssigneeDetails] = useState()
    const [userDetails, setUserDetails] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [showSelectPlateNumber, setShowSelectPlateNumber] = useState(false)
    const [openMembershipScreen, setOpenMembershipScreen] = useState(false)
    const [showConfirmationScreen,setShowConfirmationScreen] = useState(false)
    const [showOTPScreen,setShowOTPScreen] = useState(false)
    const [confirmRequest,setConfirmRequest] = useState()
    const [error,setError]= useState()
    const [approvedBikes, setApprovedBikes] = useState()
    const [selectedBike, setSelectedBike] = useState()
    const [idTodelete,setIdToDelete] = useState(null)
    const [isOpen,setIsOpen]= useState(false)
    const [membershipDetails,setMembershipDetails] = useState(JSON.parse(sessionStorage.getItem("membershipDetails")))
    const [showSubscriptionPopup, setShowSubscriptionPopup] = React.useState(false)
    const [otp2,setOtp2]= useState("")

    
    const history = useHistory();


    useEffect(() => {
        getMemberProfile()      
        firebase.logEventForAnalytics('Manage User')
    }, [])

    const getMemberProfile = () => {
        setIsLoading(true)
        memberService.handleMemberProfile(userId).then(response => {
            setIsLoading(false)
            setUserDetails(response.data)
            let assigneeDetails = response.data.bikes.filter(aBike => { return aBike.status === 'Assigned' }).filter(aAssignee=>aAssignee.assignedToUserId!=userDetails.id)
            setAssigneeDetails(assigneeDetails)
            setBikeDetails(response.data.bikes)
            let approvedBikes = response.data.bikes.filter(aBike => { return aBike.status === 'Verified' })
            setApprovedBikes(approvedBikes)
        })
    }

    const removingUser = (anAssignee) => {
        setIdToDelete(anAssignee.id)
        setIsDeleting(true)
        memberService.removeBikeAssignee(anAssignee.id).then(response => {
            ToastsStore.success("User removed successfully")
            getMemberProfile()
            setIsDeleting(false)

        })
    }

    const handleAssignPopup = () => {
        console.log("tetsfgsyv");
        setShowSelectPlateNumber(true)
    }

    const handlePlateNumber = (selectedId) => {
        setShowSelectPlateNumber(false)
        let selectedBike = approvedBikes.filter(aBike => aBike.id === selectedId)
        setSelectedBike(selectedBike[0])
        setOpenMembershipScreen(true)
    }

    

    const handleMembershipDetail = (request) => {
        setConfirmRequest(request)
        setOpenMembershipScreen(false)
        setShowOTPScreen(true)
    }

    console.log("request", showSelectPlateNumber)


    const handleShowConfirmation = (otp)=>{
        console.log(otp)
        setOtp2(otp)
        // handleVerifyOTP(otp)
        setShowConfirmationScreen(true)
        setShowOTPScreen(false)
    }

    const handleVerifyOTP = (data) => {    
        let otp = data.otp1 + data.otp2 + data.otp3 + data.otp4 + data.otp5 + data.otp6
        let request = {
          "emailOrMobile": confirmRequest.mobileNumber,
          "verificationType": "MobileNumber",
          "otp": otp,
       //   "bypassCode": "123456"
        }
        memberService.verifyEnteredOTP(request).then(response => {
          if (response) {
            setShowConfirmationScreen(true)
            setShowOTPScreen(false)
          }
        }).catch(error => {
          setError(error && error.response && error.response.data && error.response.data.message)
        })}

    const handleAssign = () =>{
        console.log();

        let request ={}
        setShowConfirmationScreen(false)
        if(confirmRequest.mobile!=null){
            request=
            {
                "mobile": {
                    "countryCode": "+60",
                    "mobileNumber": "+60"+confirmRequest.mobile.mobileNumber
                },
            //    "bypassCode": "123456",
                "otp": otp2
            
        }
        }
        else{
            request=
            {
              "membershipNumber":confirmRequest.membershipNumber,
             //   "bypassCode": "123456",
                "otp": otp2
            
        }
        }
        memberService.addBikeConfirmAssignee(selectedBike.id,request).then(response=>{
            setShowConfirmationScreen(false)
            // props.parentCallback()
            ToastsStore.success(t("Congratulations! Bike assigned successfully."))

            getMemberProfile()

        }).catch(error=>
            setError(error && error.response && error.response.data && error.response.data.message)
            ).finally(()=>{
                // props.parentCallback()

            })
    }

    const handlePopup =()=>{
        setIsOpen(true);
    }
    const handlePopupCallback =(bool, clickedButton)=>{
        if(clickedButton == 0){
            setShowSubscriptionPopup(true);
        }
        else if(clickedButton == 1){
            //for add bike.
            console.log("Add bike");
            console.log(history.location);
           history.push ({pathname : "/admin/settings", state: {activeSetting : true}});
        }
        setIsOpen(bool);

    }

    const handelClosePopup = () =>{
        setShowSubscriptionPopup();
    } 



        console.log(confirmRequest);

    return (
        <>
            { showSubscriptionPopup  &&<SubscriptionPopup openPopup={showSubscriptionPopup} handelClosePopup = {handelClosePopup}
               membershipDetails={membershipDetails}  closePopup={() => { setShowSubscriptionPopup(false) }}></SubscriptionPopup>} 
             {isOpen && <AlertDialogSlide isOpen={isOpen} handle={handlePopupCallback}/>}
            {showSelectPlateNumber && <SelectPlateNumber openPopup={showSelectPlateNumber} approvedBikes={approvedBikes}
                closePopup={() => { setShowSelectPlateNumber(false) }} parentCallback={handlePlateNumber}></SelectPlateNumber>}
            {openMembershipScreen && <MembershipDetail openPopup={openMembershipScreen} bikeDetails={selectedBike} 
                closePopup={() => { setOpenMembershipScreen(false) }} parentCallback={handleMembershipDetail}></MembershipDetail>}
            {showOTPScreen && <OTPVerification type="ManageUser" openPopup={showOTPScreen}  
                   closePopup={() => { setShowOTPScreen(false) }} parentCallback={handleShowConfirmation} mobileNumber={confirmRequest.mobile.mobileNumber}></OTPVerification>}
            {showConfirmationScreen && <AssignedUserConfirmationPopup  openPopup={showConfirmationScreen} 
                   closePopup={() => { setShowConfirmationScreen(false) }} parentCallback={handleAssign}></AssignedUserConfirmationPopup>}
            <Row>
                <Col className="order-xl-1" xl="12">
                    <Card className="bg-white shadow" >
                        {isLoading && <Spinner style={{ margin: '10% 40%' }} color="primary" />}

                        {!isLoading && <CardBody className="mt-2">
                            <Row  className="pb-2"><Col xs="12" className="d-flex  mt-3" style={{ justifyContent: 'flex-end' }}>
                                <Button color="primary" onClick={!(membershipDetails && membershipDetails.status == "Activated") ? handlePopup : handleAssignPopup } style={{ cursor: 'pointer' }}>{t("Assign User")}</Button>
                            </Col>
                            </Row>
                            {assigneeDetails && assigneeDetails.length===0 && <Row>
                                <Col xs="12" className="d-flex justify-content-center">
                                    <p className='waterMark'>{t("No bike assigned to any other user")}</p>
                                </Col>
                            </Row>
                            }
                            <Row>
                            
                            {/* userDetails.icNumber!=null && anAssignee.status==='Assigned' && anAssignee.assignedToUserId!==userDetails.id && */}
                                {assigneeDetails && assigneeDetails.filter(aAssignee=>aAssignee.assignedToUserId!=userDetails.id).map((anAssignee, key) => {
                                    return (<Col xs="4">
                                        <Card className="mt-4">
                                            <Card className="d-flex textHeading mt--4 headerCard" >
                                                <p className="d-flex" style={{ justifyContent: 'space-between' }} >User {key + 1}
                                                    {/* {!isDeleting &&<i className="fas fa-trash-alt mt-1" style={{ color: "#138de8" }} onClick={() => removingUser(anAssignee)}></i>} */}
                                                    {userDetails.icNumber!=null && anAssignee.status==='Assigned' && anAssignee.assignedToUserId!==userDetails.id && (isDeleting && (idTodelete===anAssignee.id) ?<div ><Spinner color="primary" /></div>:
                                                    <i className="fas fa-trash-alt mt-1" style={{ color: "#138de8" }} onClick={() => removingUser(anAssignee)}></i>)
                                                    }

                                                </p>


                                            </Card>

                                            <CardBody>
                                                <p className="textHeading memberCardBorder">{anAssignee.assignedUsername}</p>
                                               {anAssignee.assignedUserEmail!=null && <p className="textHeading memberCardBorder">{anAssignee.assignedUserEmail }</p>}
                                                <p className="textHeading memberCardBorder">{anAssignee.assignedUserMobile}</p>
                                                <p className="textHeading memberCardBorder">{anAssignee.assignedUserMembershipNumber}</p>
                                                <p className="textHeading memberCardBorder">{anAssignee.plateNumber}</p>

                                            </CardBody>

                                        </Card>
                                    </Col>)
                                })}
                            </Row>


                        </CardBody>
                        }
                    </Card>
                </Col>

            </Row>

        </>
    );
};

export default ManageUserDashboard;
