import React, { useState, useEffect } from 'react';
import { Row, Col, Spinner, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input } from 'reactstrap';
import "../../layouts/layouts.css"
import { useTranslation } from "react-i18next";
const SelectPlateNumber = (props) => {
    const [approvedBikes, setApprovedBikes] = useState(props && props.approvedBikes)
    const [selectedPlateNumber, setSelectedPlateNumber] = useState(null)
    const {
        buttonLabel,
        className
    } = props;
    const [modal, setModal] = useState(false);

    const { t } = useTranslation(); 

    const handlePlateSelection = () => {
        props.parentCallback(selectedPlateNumber)
    }
    const handleChange = (event) => {
        setSelectedPlateNumber(event.target.value)
    }
    return (
        <div>
            {/* <Button color="danger" onClick={toggle}>{buttonLabel}</Button> */}
            <Modal size="sm" isOpen={props.openPopup} className={className}>
                <ModalHeader toggle={props.closePopup}>{t("Select the bike")}</ModalHeader>
                <ModalBody className="">
                    {approvedBikes && approvedBikes.length === 0 && <p className="waterMark">No bikes available to assign</p>}
                    {approvedBikes && approvedBikes.length > 0 && <FormGroup tag="fieldset">
                        {approvedBikes && approvedBikes.map((aBike, key) => {
                            return (
                                <FormGroup check>
                                    <Label check>
                                        <Input type="radio" value={aBike.id} name="radio1" onChange={handleChange} />{' '}
                                        {aBike.plateNumber}
                                    </Label>
                                </FormGroup>
                            )
                        })}
                    </FormGroup>}
                </ModalBody>
                <ModalFooter className="d-flex justify-content-center">
                    <Button className="mt-3" color="primary" type="button" onClick={handlePlateSelection}
                        disabled={approvedBikes && approvedBikes.length === 0 || selectedPlateNumber == null}>
                        Proceed
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}
export default SelectPlateNumber;