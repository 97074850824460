
import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container,Row,Col ,Button} from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import buy from "assets/img/yamahaIcons/buy.svg";
import emptyCart from "assets/img/yamahaIcons/emptyCart.svg"
import heart from "assets/img/yamahaIcons/heart.svg"
import wishlistedHeart from "assets/img/yamahaIcons/wishlistedHeart.svg"
import notify from "assets/img/yamahaIcons/notify.svg"
import bikeDetails from "assets/img/yamahaIcons/bikeDetails.png"
import bikeNotAdded from "assets/img/yamahaIcons/bikeNotAdded.png"
import exportImg from "assets/img/yamahaIcons/export.png"
import routes from "routes.js";
import "../layouts/layouts.css"
import {Link} from "react-router-dom";
import memberService from "views/service/APIService";
import { transform } from "typescript";
import { useTranslation } from "react-i18next";

const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
  const [userId,setUserId] = sessionStorage.getItem("userId")
  const [productCount,setProductCount] = React.useState(sessionStorage.getItem('quantityInCart')?sessionStorage.getItem('quantityInCart'):0)
  const [wishlistedCount,setWishlistedCount] = React.useState(sessionStorage.getItem('wishlistedProductCount')?sessionStorage.getItem('wishlistedProductCount'):0)
  const [notificationCount,setNotificationCount] = React.useState(sessionStorage.getItem('notificationCount')?sessionStorage.getItem('notificationCount'):0)

  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  React.useEffect (() => {
    fetchProductsInCart()
    fetchWishListedProducts()
    fetchNotifications()
  })
  const fetchNotifications = (userId) => {
    memberService.fetchMemberNotifications(userId).then(response => {
        let notReadNotificationsList = response && response.data && response.data.notifications && response.data.notifications.filter(aNotification=>
            aNotification.readStatus==='NotRead'
        )
        sessionStorage.setItem("notificationCount",notReadNotificationsList.length )
        setNotificationCount(notReadNotificationsList.length)
    })
}

  const fetchWishListedProducts = () => {
    memberService.fetchWishlistedProducts().then(response => {
        let whiteListedIds = [];
        if(response.data && response.data.length===0){
          setWishlistedCount(0)
        }
        else{
          setWishlistedCount(response.data && response.data.length)
        }
    })
}

  const fetchProductsInCart = () => {
    let productsData = []
    let productData = {}
    memberService.fetchProductsInCart().then(response => {
        response && response.data.map(aProduct => {
            if (aProduct.variationsInCart.length > 0) {
                aProduct.variationsInCart && aProduct.variationsInCart.map(aVariant => {
                    aProduct.variations.map(aVariation => {
                        if (aVariation.id === aVariant.variationId) {
                            productData = {
                                isSelected: true,
                                variationId: aVariant.variationId,
                                productId: aProduct.id,
                                productSalePrice: null,
                                productRegularPrice: null,
                                variationSalePrice: aVariation.sale_price,
                                variationRegularPrice: aVariation.regular_price,
                                variationQtyInCart: aVariant.quantity,
                                variationQtyInStock: aVariation.stock_quantity,
                                productQtyInStock: aProduct.quantityLimit,
                                productQtyInCart: null,
                                variationImage: aVariation.image,
                                productImage: null,
                                variationProductAttributes: aVariation.attributes,
                                productAttributes: null,
                                variationProductName: aProduct.name,
                                variationProductDescription: aProduct.description && aProduct.description.replace(/<[^>]*>?/gm, ''),
                                woocommerceId: aProduct.woocommerceId

                            }
                            productsData.push(productData)
                        }

                    })

                })
            }
            else {
                productData = {
                    isSelected: true,
                    variationId: null,
                    productId: aProduct.id,
                    productSalePrice: aProduct.prices.sale_price,
                    productRegularPrice: aProduct.prices.regular_price,
                    variationSalePrice: null,
                    variationRegularPrice: null,
                    variationQtyInCart: null,
                    variationQtyInStock: null,
                    productQtyInStock: aProduct.quantityLimit,
                    productQtyInCart: aProduct.quantityInCart,
                    variationImage: null,
                    productImage: aProduct.images && aProduct.images[0],
                    variationProductAttributes: null,
                    productAttributes: aProduct.attributes,
                    variationProductName: aProduct.name,
                    variationProductDescription: aProduct.description && aProduct.description.replace(/<[^>]*>?/gm, ''),
                    woocommerceId: aProduct.woocommerceId
                }
                productsData.push(productData)
            }
        })
  setProductCount(productsData && productsData.length)
    }).catch(error => {
    }).finally(e => {
    })
}

  const handleLogout = () =>{
    sessionStorage.clear();
    window.location.reload();
  }

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  return (
  <>
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("../assets/img/brand/argon-react.png").default,
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContent} style={{overflowX:'hidden'}}
      >
     <Row style={{marginBottom:'15px'}} 
       className="header "
       style={{
         minHeight: "50px",
         marginLeft:"-28px",
         boxShadow:"0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
         backgroundColor: "white",
       }}>
    
       <Col xs="7" style={{display:'flex'}}className="" ><h4 style={{marginTop:'10px',marginLeft:'20px'}}> {userDetails && userDetails.name} ,</h4> <h5 style={{margin:'13px 5px'}}>{userDetails.membershipNumber}</h5>
       
      </Col>
      <Col xs="3" style={{display:'flex'}}className="" >
      <div style={{ textAlign: 'center', margin: '10px' }}>
          <button style={{color: i18n.language=='ma'?'black':'white', backgroundColor:i18n.language=='ma'?'white':'#1da1f2', paddingInline:"5px", border: '1px solid black'}} onClick={() => i18n.changeLanguage('en')}>English</button>
          <button style={{color: i18n.language=='ma'?'white':'black', backgroundColor:i18n.language=='ma'?'#1da1f2':'white', paddingInline:"5px", border: '1px solid black'}} onClick={() => i18n.changeLanguage('ma')}>Malay</button>
        
      </div>
      </Col>
       
       <Col xs="1"style={{display:'flex',justifyContent:'flex-end',marginLeft:'24px',marginTop:'4px' }}>
        
       <Link to={{ pathname: "/admin/settings", state: {bikeSetting : true}}}>
       {!(userDetails && userDetails.ownershipStatus && userDetails.ownershipStatus=="Owner" && userDetails.bikes.length==0) &&   <img src={bikeDetails} alt=""style={{marginRight:'10px',height:'20px',width:'18px',marginTop:'10px' }}/>}
       {(userDetails && userDetails.ownershipStatus && userDetails.ownershipStatus=="Owner" && userDetails.bikes.length==0) && <img src={bikeNotAdded} alt=""style={{marginRight:'10px',height:'20px',width:'18px',marginTop:'10px'}}/>}
       </Link>
       <Link to={{ pathname: '/admin/notifications', }}>
       {notificationCount > 0 && <img src={notify} alt=""style={{marginRight:'10px',height:'20px',width:'18px',marginTop:'10px'}}/>}
       {notificationCount == 0 && <i className="far fa-bell" style={{color:'#138de8',marginRight:'10px',marginTop:'12px'}} ></i>}
       </Link>
       
       <Link to={{ pathname: '/admin/wishListDetail', }}>
      {wishlistedCount===0 && <img src={heart} alt=""style={{marginRight:'10px',height:'20px',width:'18px',marginTop:'10px'}}/>}
      {wishlistedCount>0 && <img src={wishlistedHeart} alt=""style={{marginRight:'10px',height:'20px',width:'18px',marginTop:'10px'}}/>}

       </Link>
       
       <Link to={{ pathname: '/admin/bagDetail', }}>
        {productCount===0 && <img src={emptyCart} alt=""style={{marginRight:'10px',height:'20px',width:'18px',marginTop:'10px'}}/>}
       {productCount>0 && <img src={buy} alt=""style={{marginRight:'10px',height:'20px',width:'18px',marginTop:'10px'}}/>}
       </Link>
       <Button color="primary" type="button" style={{marginRight:'10px',marginTop:'5px',height:'30px'}} 
       onClick={handleLogout}><span className="d-flex"><img src={exportImg} alt=""style={{marginRight:'5px',height:'12px',width:'12px',marginTop:'-3px'}}/><p style={{marginTop:'-8px',fontSize:'13px',width:'max-content'}}>{t("Logout")}</p> </span></Button>
       </Col>
     </Row>

        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/admin/index" />
        </Switch>
        <Container fluid>
        </Container>
      </div>
    </>
  );
};

export default Admin;
