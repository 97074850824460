import React, { useState,useEffect } from 'react';
import { Row, Col,Spinner, Button, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody, Form, Input } from 'reactstrap';
import "../../layouts/layouts.css"
import Select from 'react-select';
import memberService from "../service/APIService"
import { t } from 'i18next';

const OTPVerification = (props) => {
    const {
        buttonLabel,
        className
    } = props;

    const [modal, setModal] = useState(false);
    const [data, setData] = useState({})
        let timeLeft = 60;
        const [timeLeftToResend, setTimeLeftToResend] = useState(60);
        const [mobileNumber, setMobileNumber] = useState(props && props.mobileNumber)
        const [nextScreen, setNextScreen] = useState(false)
        const [isLoading, setIsLoading] = React.useState(false)
        const [error, setError] = React.useState("");
    
      console.log(props);


        const countdown = () => {
          if (document.getElementById("seconds")) {
            timeLeft--;
            document.getElementById("seconds").innerHTML = String(timeLeft);
            if (timeLeft > 0) {
              setTimeout(countdown, 1000);
            }
            setTimeLeftToResend(timeLeft);
          }
        };
      
      
        useEffect(() => {
          setTimeLeftToResend(60)
          setTimeout(countdown, 1000);
        }, []);

    const toggle = () => setModal(!modal);
   

 
    useEffect(()=>{
        setData({
            ...data,
            ["defaultStatus"]: "OtherAddress"
        })
    },[])

    const useOTPFields = () => {
        const [ssnValues, setValue] = React.useState({
          otp1: "",
          otp2: "",
          otp3: "",
          otp4: "",
          otp5: "",
          otp6: "",
    
        });
    
        return {
          handleChange: e => {
            const { maxLength, value, name } = e.target;
            const [fieldName, fieldIndex] = name.split("-");
            setError("")
            // Check if they hit the max character length
            if (value.length >= maxLength) {
              // Check if it's not the last input field
              if (parseInt(fieldIndex, 10) < 6) {
                // Get the next input field
                const nextSibling = document.querySelector(
                  `input[name=otp-${parseInt(fieldIndex, 10) + 1}]`
                );
    
                // If found, focus the next field
                if (nextSibling !== null) {
                  nextSibling.focus();
                }
              }
            }
            else {
              if (parseInt(fieldIndex, 10) === 6) {
                // Get the next input field
                const prevSibling = document.querySelector(
                  `input[name=otp-${parseInt(fieldIndex, 10) - 1}]`
                );
    
                // If found, focus the next field
                if (prevSibling !== null) {
                  prevSibling.focus();
                }
              }
              else if (parseInt(fieldIndex, 10) === 5) {
                // Get the next input field
                const prevSibling = document.querySelector(
                  `input[name=otp-${parseInt(fieldIndex, 10) - 1}]`
                );
    
                // If found, focus the next field
                if (prevSibling !== null) {
                  prevSibling.focus();
                }
              }
              if (parseInt(fieldIndex, 10) === 4) {
                // Get the next input field
                const prevSibling = document.querySelector(
                  `input[name=otp-${parseInt(fieldIndex, 10) - 1}]`
                );
    
                // If found, focus the next field
                if (prevSibling !== null) {
                  prevSibling.focus();
                }
              }
              if (parseInt(fieldIndex, 10) === 3) {
                // Get the next input field
                const prevSibling = document.querySelector(
                  `input[name=otp-${parseInt(fieldIndex, 10) - 1}]`
                );
    
                // If found, focus the next field
                if (prevSibling !== null) {
                  prevSibling.focus();
                }
              }
              if (parseInt(fieldIndex, 10) === 2) {
                // Get the next input field
                const prevSibling = document.querySelector(
                  `input[name=otp-${parseInt(fieldIndex, 10) - 1}]`
                );
    
                // If found, focus the next field
                if (prevSibling !== null) {
                  prevSibling.focus();
                }
              }
              if (parseInt(fieldIndex, 10) === 1) {
                // Get the next input field
                const prevSibling = document.querySelector(
                  `input[name=otp-${parseInt(fieldIndex, 10) - 1}]`
                );
    
                // If found, focus the next field
                if (prevSibling !== null) {
                  prevSibling.focus();
                }
              }
            }
    
            setValue({
              ...value,
              [`otp${fieldIndex}`]: value
            });
            setData({
              ...data,
              [`otp${fieldIndex}`]: value
            });
          }
        };
      };

      const OTPField = () => {
        const { handleChange } = useOTPFields();
    
        return (
          <>   
          {/* <Row>
              <Col xs={12} style={{ textAlign: 'center'}}> */}
              {/* </Col>
            </Row>  */}
            <Row>
              <div className="send_otp_input_div">
                <input className="send_otp_input"
                  style={{ textAlign: 'center',border:'1px solid #ff9017' }}
                  type="number"
                  name="otp-1"
                  maxLength={1}
                  onChange={handleChange} 
                  onKeyDown={(evt) => ["e", "E", "+", "-","."].includes(evt.key) && evt.preventDefault()}/>
                <input className="send_otp_input"
                  style={{ textAlign: 'center',border:'1px solid #ff9017' }}
                  type="number"
                  name="otp-2"
                  maxLength={1}
                  onChange={handleChange} 
                  onKeyDown={(evt) => ["e", "E", "+", "-","."].includes(evt.key) && evt.preventDefault()}/>
                <input className="send_otp_input"
                  style={{ textAlign: 'center' ,border:'1px solid #ff9017'}}
                  type="number"
                  name="otp-3"
                  maxLength={1}
                  onChange={handleChange} 
                  onKeyDown={(evt) => ["e", "E", "+", "-","."].includes(evt.key) && evt.preventDefault()}/>
                <input className="send_otp_input"
                  style={{ textAlign: 'center' ,border:'1px solid #ff9017'}}
                  type="number"
                  name="otp-4"
                  maxLength={1}
                  onChange={handleChange} 
                  onKeyDown={(evt) => ["e", "E", "+", "-","."].includes(evt.key) && evt.preventDefault()}/>
                <input className="send_otp_input"
                  style={{ textAlign: 'center' ,border:'1px solid #ff9017'}}
                  type="number"
                  name="otp-5"
                  maxLength={1}
                  onChange={handleChange} 
                  onKeyDown={(evt) => ["e", "E", "+", "-","."].includes(evt.key) && evt.preventDefault()}/>
                <input className="send_otp_input"
                  style={{ textAlign: 'center' ,border:'1px solid #ff9017'}}
                  type="number"
                  name="otp-6"
                  maxLength={1}
                  onChange={handleChange} 
                  onKeyDown={(evt) => ["e", "E", "+", "-","."].includes(evt.key) && evt.preventDefault()}/>
              </div>
            </Row>
            
          </>
        );
      };

      const resendOTP = () => {
        
        sendOtp()
        setTimeLeftToResend(60)
        setTimeout(countdown, 1000);
      }


      const sendOtp = () => {
        
        setIsLoading(true)
        memberService.handleSendOtp("+60"+ mobileNumber).then(response=>{
            // setNextScreen(true)
        }).catch(error => {
            setError(error && error.response && error.response.data && error.response.data.message)
        }).finally(e => {
            setIsLoading(false)
            // setNextScreen(true)
        })
      }


      const handleOTPVerification = () =>{
        if(data.otp1 == undefined || data.otp1 == "" || data.otp2 == undefined || data.otp2 == ""
        || data.otp3 == undefined  || data.otp3 == "" || data.otp4 == undefined || data.otp4 == ""
         || data.otp5 == undefined || data.otp5 == "" || data.otp6 == undefined || data.otp6 == ""){
            setError("Please enter OTP.") 
          }
          else{
            let otp = data.otp1 + data.otp2 + data.otp3 + data.otp4 + data.otp5 + data.otp6
            props.parentCallback(otp)
          }
    


        // props.parentCallback()
      }

    return (
        <div>
            {/* <Button color="danger" onClick={toggle}>{buttonLabel}</Button> */}
            <Modal size="sm" isOpen={props.openPopup} className={className}>
                <ModalHeader toggle={props.closePopup}>OTP Verification</ModalHeader>
                <ModalBody>
                <Card className="bg-white shadow module-border-wrap">
          <CardBody className="px-lg-5 py-lg-5">

            <Row  style={{textAlign: 'center'}}>
            <Col xs="12" className="pb-2">
          {props && props.type==="ManageUser" && <span style={{fontSize: '14px' }} >{t("Enter the OTP sent to user mobile phone")}</span>}
          {props && props.type==="EditProfile" && <span style={{fontSize: '14px' }} >{t("Please enter the OTP sent to your updated mobile number")}</span>}
          {props && props.type==="BikeDetails" && <span style={{fontSize: '14px' }} >{t("Please enter the OTP sent to your updated mobile number")}</span>}

            </Col>

              <Col xs="12">
                {OTPField()}
              </Col>
            </Row>
            <Form role="form">
              <div style={{ margin: '10px'}}>
                <small style={{ marginTop: '10px' }}>{t("Didn't receive an OTP?")}</small>
                {timeLeftToResend > 0 && <div style={{ display: 'flex', marginTop: '10px' }}><small style={{ color: 'grey', display: 'flex'  }}>Resend OTP after   
                <div id="clock">
                  <span id="seconds" style={{ marginLeft: '2px' }}> 60 </span>
                </div>  <span style={{ marginLeft: '2px' }}> seconds</span></small></div>}
                {timeLeftToResend === 0 && <div style={{ display: 'flex', marginTop: '10px' }}>
                    <small  style={{ fontWeight: 900 }} onClick={() => resendOTP()}>{t("Resend")}</small>
                </div>}
              </div>
            </Form>

          </CardBody>
        </Card>
        <div className="text-center">
        <small style={{ color: 'red' }}>{error}</small>
</div>
      
                </ModalBody>
                <ModalFooter className="d-flex justify-content-center">
                    <Button  color="primary" onClick={props.closePopup}>{t("Cancel")}</Button>
                    <Button  color="primary" onClick={handleOTPVerification}>{t("Okay")}</Button>

                    {/* {!isLoading && <Button color="primary" onClick={saveAddress}>Save Address</Button>}
                    {isLoading && <Spinner style={{ marginTop: '10px' }} color="primary" />} */}

                </ModalFooter>
            </Modal>
        </div>
    );
}

export default OTPVerification;