import React, { useState, useEffect } from 'react';
import { Row, Col, Spinner, Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import "../../../layouts/layouts.css"
import Select from 'react-select';
import memberService from "../../service/APIService"
import moment from "moment"
import bikeImage from "assets/img/yamahaIcons/bike2.imageset/tuningFork.png"
import NotificationModal from 'components/Utilities/NotificationModal';
import MembershipDetail from 'views/ManageUser/MembershipDetail';
import OTPVerification from '../OTPVerification';
import AssignedUserConfirmationPopup from 'views/ManageUser/AssignedUserConfirmationPopup';
import { ToastsStore } from "react-toasts";
import { useHistory } from 'react-router';
import AlertDialogSlide from "../../MyVouchers/AlertDialogSlide"
import SubscriptionPopup from '../../Subscriptions/SubscriptionPopup'
import firebase from 'utils/firebase';
import {t} from 'i18next';

const BikeDetailsModal = (props) => {
    const [bikeDetails, setBikeDetails] = useState(props && props.bikeDetails)
    const [userDetails, setUserDetails] = useState(JSON.parse(sessionStorage.getItem('userDetails')))
    const [notificationAlertPopup, setNotificationAlertOpen] = useState(false)
    const [message, setMessage] = useState()
    const [showLoader, setShowLoader] = useState(false)
    const [showAssignUser, setShowAssignUser] = useState(false)
    const [openMembershipScreen, setOpenMembershipScreen] = useState(false)
    const [showConfirmationScreen, setShowConfirmationScreen] = useState(false)
    const [showOTPScreen, setShowOTPScreen] = useState(false)
    const [confirmRequest, setConfirmRequest] = useState()
    const { buttonLabel, className } = props;
    const [modal, setModal] = useState(false);
    const [otp,setOTP] = useState("")
    const [isOpen,setIsOpen]= useState(false)
    const [membershipDetails,setMembershipDetails] = useState(JSON.parse(sessionStorage.getItem("membershipDetails")))
    const [showSubscriptionPopup, setShowSubscriptionPopup] = React.useState(false)
    const history = useHistory();
    
    
    const toggle = () => setModal(!modal);
    const getFormattedRegDate = (regDate) => {

        if (regDate !== undefined) {
            let newRegDate = moment(regDate).format('DD MMM YYYY')
            return newRegDate;
        }
        else return "--"
    }

    const handleRemoveUser = () => {
        setMessage("Are you sure you want to remove this user?")
        setNotificationAlertOpen(true)
    }
    const removingUser = () => {
        setShowLoader(true)
        memberService.removeBikeAssignee(bikeDetails.id).then(response => {
            props.handleUser()
            setShowLoader(false)
        })
    }

    const handleAssignUser = () => {
        setShowAssignUser(true)
    }

    const handleMembershipDetail = (request) => {
        setConfirmRequest(request)
        setOpenMembershipScreen(false)
        setShowAssignUser(false)
        setShowOTPScreen(true)
    }

    const handleShowConfirmation = (otp) => {
        setOTP(otp)
        setShowConfirmationScreen(true)
        setShowOTPScreen(false)
    }

    const handleAssign = (otp) => {
        setOTP(otp);
        let request = {}
        setShowConfirmationScreen(false)
        if (confirmRequest.mobile && confirmRequest.mobile.mobileNumber != null) {
            request =
            {
                "mobile": {
                    "countryCode": "+60",
                    "mobileNumber": confirmRequest.mobile && confirmRequest.mobile.mobileNumber
                },
         //       "bypassCode": "123456",
                "otp": otp
            }
        }
        else {
            request =
            {
                "membershipNumber": confirmRequest.membershipNumber,
           //     "bypassCode": "123456",
                "otp": otp
            }
        }
        memberService.addBikeConfirmAssignee(bikeDetails.id, request).then(response => {
            setShowConfirmationScreen(false)
            props.handleUser()
            ToastsStore.success(t("Congratulations! Bike assigned successfully."))

        }).catch(error => ToastsStore.error(t("Invalid OTP"))
        ).finally(() => {
          //  props.handleUser()
        })
    }

    const handlePopup =()=>{
        setIsOpen(true);
    }
    const handlePopupCallback =(bool, clickedButton)=>{
        if(clickedButton == 0){
            setShowSubscriptionPopup(true);
        }
        else if(clickedButton == 1){
            if(props != null){
                props.handelAutoAddBike();
            }
        }
        setIsOpen(bool);

    }


    const handelClosePopup = () =>{
        setShowSubscriptionPopup();
    }


    console.log(confirmRequest);
    return (
        <div>
            { showSubscriptionPopup  &&<SubscriptionPopup openPopup={showSubscriptionPopup} handelClosePopup = {handelClosePopup}
               membershipDetails={membershipDetails}  closePopup={() => { setShowSubscriptionPopup(false) }}></SubscriptionPopup>} 

             {isOpen && <AlertDialogSlide isOpen={isOpen} handle={handlePopupCallback}/>}
            {/* <Button color="danger" onClick={toggle}>{buttonLabel}</Button> */}
            {notificationAlertPopup && <NotificationModal message={message} openPopup={notificationAlertPopup} parentCallback={removingUser}
                showLoader={showLoader} closePopup={() => { setNotificationAlertOpen(false) }}></NotificationModal>}
            {showAssignUser && <MembershipDetail openPopup={showAssignUser} bikeDetails={bikeDetails}
                closePopup={() => { setShowAssignUser(false) }} parentCallback={handleMembershipDetail}></MembershipDetail>}
            {showOTPScreen && <OTPVerification type="BikeDetails" openPopup={showOTPScreen} mobileNumber = {confirmRequest && confirmRequest.mobile &&  confirmRequest.mobile.mobileNumber}
              membershipNumber={confirmRequest && confirmRequest.membershipNumber}  closePopup={() => { setShowOTPScreen(false) }} parentCallback={handleAssign}></OTPVerification>}
            {showConfirmationScreen && <AssignedUserConfirmationPopup openPopup={showConfirmationScreen}
                closePopup={() => { setShowConfirmationScreen(false) }} parentCallback={handleAssign}></AssignedUserConfirmationPopup>}

            <Modal size="sm" isOpen={props.openPopup} className={className}>
                <ModalHeader toggle={props.closePopup}>{t("Bike Details")}</ModalHeader>
                <ModalBody>
                    <Row><Col xs="12" className="d-flex justify-content-center">
                        <img src={bikeImage} alt="" height="60" width="60" />
                    </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs="6"><p className="bikeTitle">{t("Plate No.")}</p></Col>
                        <Col xs="6"><p className="bikeTextCapital">{bikeDetails.plateNumber || '--'}</p></Col>
                    </Row>
                    <Row>
                        <Col xs="6"><p className="bikeTitle">{t("Engine No.")}</p></Col>
                        <Col xs="6"><p className="bikeTextCapital">{bikeDetails.engineNumber || '--'}</p></Col>
                    </Row>
                    <Row>
                        <Col xs="6"><p className="bikeTitle">{t("Chassis No.")}</p></Col>
                        <Col xs="6"><p className="bikeTextCapital">{bikeDetails.chassisNumber || '--'}</p></Col>
                    </Row>
                    <Row>
                        <Col xs="6"><p className="bikeTitle">{t("Model No.")}</p></Col>
                        <Col xs="6"><p className="bikeTextCapital">{bikeDetails.modelNumber || '--'}</p></Col>
                    </Row>
                    <Row>
                        <Col xs="6"><p className="bikeTitle">{t("Registration Date")}</p></Col>
                        <Col xs="6"><p className="bikeText">{getFormattedRegDate(bikeDetails.registrationDate)}</p></Col>
                    </Row>
                    <Row>
                        <Col xs="6"><p className="bikeTitle">{t("User")}</p></Col>
                        <Col xs="6"><p className="bikeText">{bikeDetails.assignedUsername == null ? userDetails.name : bikeDetails.assignedUsername}</p></Col>
                    </Row>

                </ModalBody>
                <ModalFooter className="d-flex justify-content-center">
                    <Button color="primary" onClick={props.closePopup}>{t("Cancel")}</Button>
                    {bikeDetails.status === 'Verified' && <Button color="primary" onClick={!(membershipDetails.status == "Activated") ? handlePopup : handleAssignUser}>{t("Assign User")}</Button>}
                    { bikeDetails.status === 'Assigned' && bikeDetails.assignedToUserId !== userDetails.id && <Button color="primary" onClick={handleRemoveUser}>{t("Remove User")}</Button>}
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default BikeDetailsModal;