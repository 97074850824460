/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import React, { useState, useEffect } from 'react';
import memberService from "../service/APIService";
import ticket from "assets/img/yamahaIcons/ticketBlue.svg"
import {  Link } from "react-router-dom";
import moment from "moment"
import SubscriptionPopup from "views/Subscriptions/SubscriptionPopup";
import { getAnalytics, logEvent } from "firebase/analytics";
import firebase from "utils/firebase.js"
import { useTranslation } from "react-i18next";
const Home = () => {
    const [pointsData, setPointsData] = useState({})
    const [notificationsData, setNotificationsData] = useState({})
    const [multipleCampaignsData, setMultipleCampaignsData] = useState([])
    const [membershipDetails,setMembershipDetails] = useState({})
    const [reactivate,setReactivate] = useState(false)
    const [showSubscriptionPopup, setSubscriptionPopup] = useState(false)
    const [userId, setUserId] = useState(sessionStorage.getItem('userId'))
    const [isFromSignupPopupFlowOK, setIsFromSignupPopupFlowOK] = useState(false)



    console.log(multipleCampaignsData);

    const { t } = useTranslation();

    useEffect(() => {
        handleRedirection();
        fetchMemberPoints()
        fetchNotifications()
        fetchMultipleCampaigns()
        getMembershipDetails()
        
        firebase.logEventForAnalytics('Home');
    }, [])

    const getFormattedExpiryDate = (expiryDate) => {
        if(expiryDate === null || expiryDate === undefined)
        {
            return "-";
        }
        let newExpiryDate = moment(expiryDate).format('DD MMM YYYY')
        return newExpiryDate;
    }

    const handleRedirection = () => {
        if((sessionStorage.getItem("redirecturl")!== null && sessionStorage.getItem("redirecturl")!== undefined && sessionStorage.getItem("redirecturl")!== "") &&
        sessionStorage.getItem("client_id")!== null && sessionStorage.getItem("client_id")!== undefined && sessionStorage.getItem("client_id")!== "")
        {
         var redirect_uri = sessionStorage.getItem("redirecturl") ;
         var client_id = sessionStorage.getItem("client_id");
         var userName = JSON.parse(sessionStorage.getItem("userName"))
         var psd = JSON.parse(sessionStorage.getItem("hashedPassword"))
    
         let request ={
           "client_id": client_id,
            "redirect_uri": redirect_uri,
            "credentialType": "Username",
            "credentials": {
             "username": userName,
            "hashedPassword": psd
      }
         }
         memberService.redirectGOS(request).then(response=> {
           console.log(response);
         })
         window.location.replace(redirect_uri);

     }
      }

      let isFromSessionStorafeFlowBool = sessionStorage.getItem("isFromSignupPageFlow");


    const extendMembership = () => {

        setSubscriptionPopup(true);
        membershipDetails.membershipExpiryDate ?  new Date(membershipDetails.membershipExpiryDate) < new Date() ? firebase.logButtonClicked("Membership Reactivated") : firebase.logButtonClicked("Membership Extended") : firebase.logButtonClicked("Membership Activated")
       
        // memberService.extendMembership(userId).then(response=> {
        //     console.log("extendmembership called in Home --")
        //     setSubscriptionPopup(true);         
        //  })
    }


    // useEffect(() => {
    //     console.log(isFromSessionStorafeFlowBool, " above");
    //     if(isFromSessionStorafeFlowBool){
    //         setSubscriptionPopup(true);
    //         console.log(isFromSessionStorafeFlowBool, " inside");
    //         sessionStorage.removeItem("isFromSignupPageFlow");
    //         isFromSessionStorafeFlowBool = false;
    //     }
    //     console.log(isFromSessionStorafeFlowBool, " outside");
        
    // }, [isFromSessionStorafeFlowBool])




    const getMembershipDetails =()=>{
        var date = new Date();

        memberService.getMembershipDetails(userId).then(response=>{
            setMembershipDetails(response.data)
           sessionStorage.setItem("membershipDetails", JSON.stringify(response.data))
           let expiryDate= moment(response.data.membershipExpiryDate).subtract(6, 'months').format('DD MMM YYYY')
           let currentDate=moment(date).format('DD MMM YYYY')
           console.log(isFromSessionStorafeFlowBool, "isFromSessionStorafeFlowBool");
           console.log(response.data.membershipExpiryDate);
           if(isFromSessionStorafeFlowBool){
                setSubscriptionPopup(true);
                console.log(isFromSessionStorafeFlowBool, " inside");
                sessionStorage.removeItem("isFromSignupPageFlow");
                isFromSessionStorafeFlowBool = false;
                //for zero and OK
                if(response.data.status == "Activated") {
                    setIsFromSignupPopupFlowOK(true);
                    console.log("setIsFromSignupPopupFlowOK(true);")
                }
           }   
           if(!response.data.membershipExpiryDate){
                setReactivate(true)
                console.log("NULL")
           }
           else if(currentDate > expiryDate){
                setReactivate(true)
                console.log("reactivate")

           }
           else{
               setReactivate(false)
               console.log("activate")
            }

        }).catch(error => {
            // setError(error && error.response && error.response.data && error.response.data.message)
            setReactivate(true);
        }).finally(e => {
    
        })
    }

    const fetchMemberPoints = () => {
        memberService.fetchMemberPoints(userId).then(response => {
            setPointsData(response.data)
        }).catch(error => {
            // setError(error && error.response && error.response.data && error.response.data.message)
        }).finally(e => {

        })
    }

    const fetchNotifications = (userId) => {
        memberService.fetchMemberNotifications(userId).then(response => {
            setNotificationsData(response.data)
        }).catch(error => {
            // setError(error && error.response && error.response.data && error.response.data.message)
        }).finally(e => {

        })
    }

    const fetchMultipleCampaigns = () => {
        memberService.getMultipleCampaigns().then(response => {
            setMultipleCampaignsData(response.data.campaignList)
        }).catch(error => {
            // setError(error && error.response && error.response.data && error.response.data.message)
        }).finally(e => {

        })
    }
    const handelClosePopup = () =>{
        setSubscriptionPopup(false);
    }  
    return (
        <>
        {showSubscriptionPopup && <SubscriptionPopup openPopup={showSubscriptionPopup} handelClosePopup = {handelClosePopup} userID = {userId} isFromSignupPopupFlowOK = {isFromSignupPopupFlowOK}
               membershipDetails={membershipDetails}  closePopup={() => { setSubscriptionPopup(false) }}></SubscriptionPopup>}
          { multipleCampaignsData.length>0 &&  <UserHeader multipleCampaignsData={multipleCampaignsData} />}
            {/* Page content */}
            < >
                <Row>
                    <Col className="order-xl-1" xl="12">
                        <Card className="bg-white shadow" style={{ height:'32vh'}}>
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="7">
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col xs="6">  <h5 style={{ textTransform: "uppercase" }}>{t("My reward points")}</h5></Col>
                                                </Row>
                                                <Row>
                                                    <Col xs="6">  <p style={{ fontSize: '28px', fontWeight: '300' }}>
                                                        <img style={{ height: '30px', width: '30px' }} src={ticket} alt="" /><span style={{ color: '#138de8', marginLeft: '20px' }}>{pointsData && pointsData.totalPoints && pointsData.totalPoints.toLocaleString()} {t("Points")}</span></p></Col>

                                                    <Col xs="6">  
                                                    <Link to={{ pathname: '/admin/transactionHistory' }}>
                                                        <Button color="primary" type="button" style={{ marginRight: '10px', height: '40px' }}>
                                                            {t("Transaction history")}  
                                                        </Button>
                                                    </Link>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xs="5">
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col xs="8">  <h5 style={{ textTransform: "uppercase" }}>{ membershipDetails.membershipExpiryDate != null ?  new Date(membershipDetails.membershipExpiryDate) < new Date() ? t("Your membership expired on") : t("Your membership expires on"): t("Please activate your membership")}</h5></Col>
                                                </Row>
                                                <Row>
                                                    <Col xs="6">  <p style={{ fontSize: '28px', fontWeight: '300' }}><span style={{ color: '#138de8' }}>{membershipDetails.membershipExpiryDate ? getFormattedExpiryDate(membershipDetails.membershipExpiryDate):" "}</span></p></Col>

                                                    {(new Date(new Date().setMonth(new Date().getMonth() + 6))<new Date(membershipDetails.membershipExpiryDate)?false:true)&&
                                                     <Col xs="6">  <Button type="button" style={{ marginRight: '10px', height: '40px', border: '1px solid #ff9017'}} onClick={e=>extendMembership()}
                                                    ><span style={{ color: '#ff9017' }}>{ membershipDetails.membershipExpiryDate ?  new Date(membershipDetails.membershipExpiryDate) < new Date() ? "REACTIVATE" : "EXTEND" : "Activate"}</span>  </Button></Col>}
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                
            </>
            
        </>
    );
};

export default Home;
