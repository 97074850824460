
import {
    Button,
    Card, CardFooter,
    CardBody,
    Row, Input,
    Col,
    Spinner
} from "reactstrap";
import "../../../views/LoginComponents/login.css"
import React, { useEffect, useState } from 'react';
import memberService from "../../service/APIService"
import { ToastsStore } from "react-toasts";
import bikeImage from "assets/img/yamahaIcons/bike2.imageset/tuningFork.png"
import infoIcon from "assets/img/yamahaIcons/infoIcon.svg"

import moment from "moment"
import OTPVerification from "../OTPVerification";
import BikeDetailsModal from "./BikeDetailsModal";
import NewBikeJPJDetails from "./NewBikeJPJDetails";
import NewBikeDetailConfirmation from "./NewBikeDetailConfirmation";
import { Tooltip } from "@material-ui/core";
import firebase from "utils/firebase";
import {t} from 'i18next';
// import { useHistory } from 'react-router';
// import AlertDialogSlide from "../../MyVouchers/AlertDialogSlide"
// import SubscriptionPopup from '../../Subscriptions/SubscriptionPopup'

function BikeDetails(props) {
    const [userId, setUserId] = useState(sessionStorage.getItem('userId'))
    const [userDetails, setUserDetails] = useState(JSON.parse(sessionStorage.getItem('userDetails')))
    const [bikeDetails, setBikeDetails] = useState()
    const [showBikeDetail, setShowBikeDetails] = useState(false)
    const [addNewBike, setAddNewBike] = useState(false)
    const [showConfirmationScreen, setShowConfirmationScreen] = useState(false)
    const [selectedBike, setSelectedBike] = useState()
    const [newResponse, setNewResponse] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [profileImage,setProfileImage]=  useState(JSON.parse(sessionStorage.getItem('userDetails')).profileImageName)
    const assetBaseUrl = process.env.REACT_APP_ENV === "PROD" ? process.env.REACT_APP_ASSET_BASE_URL_PROD :  process.env.REACT_APP_ENV === "DEV" ? process.env.REACT_APP_ASSET_BASE_URL :  process.env.REACT_APP_ASSET_BASE_STAGE;

    // const [isOpen,setIsOpen]= useState(false)
    // const [membershipDetails,setMembershipDetails] = useState(JSON.parse(sessionStorage.getItem("membershipDetails")))
    // const [showSubscriptionPopup, setShowSubscriptionPopup] = React.useState(false)
    // const history = useHistory();
    const [userDetails2, setUserDetails2] = useState(JSON.parse(sessionStorage.getItem('membershipDetails')))

    console.log("pROPS" , props);

    useEffect(() => {
        getMemberProfile()
        if(props != null && props.isFromRedirected === true){
            handleAddNewBike();
            console.log("props", props.isFromRedirected);
        }
        console.log("props1234", props.isFromRedirected);
        
    }, [props.isFromRedirected])
    useEffect(()=>{
        if (addNewBike){
            firebase.logEventForAnalytics('Add New Bike Popup')
        }
        else{
            if(showBikeDetail)
            firebase.logEventForAnalytics('Added Bike Details')
        }
    },[showBikeDetail,addNewBike])

    const getMemberProfile = () => {
        setIsLoading(true)
        memberService.handleMemberProfile(userId).then(response => {
            setIsLoading(false)
            // setUserDetails(response.data)
            setBikeDetails(response.data.bikes);
            console.log(response.data.bikes);
        })
    }

    const getBikeStatus = (status) => {
        switch (status) {
            case "Assigned":
                return "Assigned"
            case "Pending":
                return "Approval Pending"
            case "Verified":
                return "Approved"
            case "Rejected":
                return "Rejected"
            case "PreOwned":
                return "PreOwned"
            case "Removed":
                return "Removed"
            case "PreAssigned":
                return "Pre Assigned"
            default:
                return
        }
    }


    const getFormattedRegDate = (regDate) => {
        if(regDate!==undefined)
       { let newRegDate = moment(regDate).format('DD MMM YYYY')
        return newRegDate;}
        else return "--"
    }

    const handleBikeDetails = (bike) => {
        setSelectedBike(bike)
        setShowBikeDetails(true)
    }

    const handleAddNewBike = () => {
        setAddNewBike(true)
    }

    const handleSave = (response) => {
        setAddNewBike(false)
        if (response.status === "Verified" && response.vocVerificationMode === "Jpj") {
            userDetails.bikes.push(response)
            (userDetails.bikes)
            updateMemberProfileDetails()
        }
        else {
            setShowConfirmationScreen(true)

            setNewResponse(response)
        }
    }

    const handleSaveManualDetails = () => {
        setShowConfirmationScreen(false)
        userDetails.bikes.push(newResponse)
        setBikeDetails(userDetails.bikes)
        console.log(bikeDetails)
        updateMemberProfileDetails()
    }

    const updateMemberProfileDetails = () => {
        let request = userDetails
        memberService.updateMemberProfileDetails(request, userId).then(response => {
            sessionStorage.setItem("userDetails", JSON.stringify(response.data))
            localStorage.setItem("userDetails",JSON.stringify(response.data))
            setUserDetails(response.data)
        })
    }

    const handleUser = () => {
        setShowBikeDetails(false)
        getMemberProfile()
    }

    // const handlePopup =()=>{
    //     setIsOpen(true);
    // }
    // const handlePopupCallback =(bool, clickedButton)=>{
    //     if(clickedButton == 0){
    //         setShowSubscriptionPopup(true);
    //     }
    //     else if(clickedButton == 1){
    //         //for add bike.
    //         console.log("Add bike");
    //         console.log(history.location);
    //        history.push ({pathname : "/admin/settings", state: {activeSetting : true}});
    //     }
    //     setIsOpen(bool);

    // }
    const handelAutoAddBike = ()=>{
        setShowBikeDetails(false);
        handleAddNewBike();

    }



    return (
        <>
        

            {showBikeDetail && <BikeDetailsModal handelAutoAddBike = {handelAutoAddBike} bikeDetails={selectedBike} openPopup={showBikeDetail} closePopup={() => { setShowBikeDetails(false) }} 
                handleUser={handleUser}></BikeDetailsModal>}
            {addNewBike && <NewBikeJPJDetails openPopup={addNewBike} closePopup={() => { setAddNewBike(false) }}
                parentCallback={handleSave}></NewBikeJPJDetails>}
            {showConfirmationScreen && <NewBikeDetailConfirmation openPopup={showConfirmationScreen} closePopup={() => { setShowConfirmationScreen(false) }}
                parentCallback={handleSaveManualDetails}></NewBikeDetailConfirmation>}


            {isLoading && <Row><Col xs="12" className="d-flex justify-content-center" style={{ marginTop: '20%' }}><Spinner style={{ marginTop: '10px' }} color="primary" />
            </Col></Row>}
            {!isLoading && <Row>
                <Col className="order-xl-1" xl="4">
                    <Card className="bg-white shadow">
                        <CardBody>

                            <div >
                                <Row>
                                    <Col xs="12" className="d-flex justify-content-center">
                                     {JSON.parse(sessionStorage.getItem('userDetails')).profileImageName &&   <img src={assetBaseUrl +  JSON.parse(sessionStorage.getItem('userDetails')).profileImageName} className="profileImage" />}
                                     {!JSON.parse(sessionStorage.getItem('userDetails')).profileImageName &&   <Button className="ma_btn mt-3 mb-3 profile_img_btn" color="primary" type="button" >
                    <i style={{ fontSize: '20px', margin: '2px' }} class="fas fa-user"></i>
                  </Button>}

                                    </Col>
                                </Row>
                                <Row className="mt-3" style={{ borderBottom: '1px solid #dddd' }}>
                                    <Col xs="12" className="d-flex justify-content-center">
                                        <p className="textDescription">{userDetails.name}</p>
                                    </Col>
                                </Row>
                                <Row className="mt-3" style={{ borderBottom: '1px solid #dddd' }}>
                                    <Col xs="6" className="mt-3 d-flex"> <p className="textHeading">{t("Membership Number")}</p>   </Col>
                                    <Col xs="6" className="mt-3 d-flex">  <p className="memberDescriptionText">{userDetails.membershipNumber}</p> </Col>
                                </Row>
                                <Row className="mt-3" style={{ borderBottom: '1px solid #dddd' }}>
                                    <Col xs="6" className="mt-3 d-flex"> <p className="textHeading">{t("Name")}</p>   </Col>
                                    <Col xs="6" className="mt-3 d-flex">  <p className="memberDescriptionText">{userDetails.name}</p> </Col>
                                </Row>
                                <Row className="mt-3" style={{ borderBottom: '1px solid #dddd' }}>
                                    <Col xs="6" className="mt-3 d-flex"> <p className="textHeading">{t("Status")}</p>   </Col>
                                    <Col xs="6" className="mt-3 d-flex">  <p className="memberDescriptionText">{userDetails.membershipStatus}</p> </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs="6" className="mt-3 d-flex"> <p className="textHeading">{t("Tier")}</p>   </Col>
                                    <Col xs="6" className="mt-3 d-flex">  <p className="memberDescriptionText" style={{textTransform: "capitalize"}}>{userDetails2 && userDetails2.tier}</p> </Col>
                                </Row>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                
                <Col className="order-xl-1" xl="8">
                    <Card className="bg-white shadow" style={{ height: '480px' }}>
                        <Row className="mt-3"><Col xs="4">
                            <p style={{ color: '#373A3C', marginLeft: '10px' }}>{t("Bike Details")}</p></Col>
                            <Col xs="8" className="d-flex" style={{ justifyContent: 'space-between' }}>
                                <p>{t("Have another Yamaha bike?")}</p>
                                <Button style={{ margin: '0px 10px 10px' }} color="primary" onClick={handleAddNewBike}>
                                    {t("Add Bike")}</Button>
                            </Col></Row>
                        {bikeDetails && bikeDetails.map(aBike => {
                            return (<Card style={{ padding: '10px', paddingBottom: '0px' }} onClick={() => handleBikeDetails(aBike)}>
                                <Row>
                                    <Col xs="1">
                                        <img src={bikeImage} alt="" height="50" width="40" />
                                    </Col>
                                    <Col xs="2" className="d-flex mt-2">
                                        <p className="bikeTitle">{t("User")}</p>
                                        <p className="bikeNameText">{aBike.assignedUsername || userDetails.name}</p>
                                    </Col>
                                    <Col xs="3" className="d-flex mt-2">
                                        <p className="bikeTitle">{t("Plate no.")}</p>
                                        <p className="bikeTextCapital">{aBike.plateNumber}</p>
                                    </Col>
                                    <Col xs="3" className="d-flex mt-2">
                                        <p className="bikeTitle">{t("Reg. Date")}</p>
                                      { aBike.registrationDate!==null && <p className="bikeText">{getFormattedRegDate(aBike.registrationDate)}</p>}
                                    </Col>
                                  
                                    <Col xs="3">
                                        {aBike.status === 'Assigned' && <p className="bikeStatusAssigned">{getBikeStatus(aBike.status)}</p>}
                                        {aBike.status === 'Pending' && <p className="bikeStatusPending">{getBikeStatus(aBike.status)}</p>}
                                        {aBike.status === 'Verified' && <p className="bikeStatusApproved">{getBikeStatus(aBike.status)}</p>}
                                        {aBike.status === 'Rejected' && 
                                        <span className="d-flex">
                                           <Tooltip title={aBike.rejectedReason?aBike.rejectedReason:"No reason to show"}>
                                             <img className="mt-3" src={infoIcon} alt="" height="15" width="15" />
                                        </Tooltip>
                                        <p className="bikeStatusRejected ">{getBikeStatus(aBike.status)}</p></span>}
                                    </Col>

                                </Row>

                            </Card>)
                        })}

                    </Card>
                </Col>

                                
            </Row>}

        </>
    );
};

export default BikeDetails;
