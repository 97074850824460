import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import sampleImageVoc from "assets/img/yamahaIcons/sampleVOC.png"

const PicturePreview = (props) => {
  const {
    className
  } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div>
      <i  onClick={toggle} style={{marginTop:'25px'}}class="fa fa-question-circle" aria-hidden="true"></i>
      <Modal isOpen={modal} toggle={toggle} className={className} style={{marginTop:'150px'}}>
        <ModalHeader toggle={toggle}>Sample VOC Image</ModalHeader>
        <ModalBody>
        <img style={{maxWidth:'450px'}}src={sampleImageVoc} alt=""/>
        </ModalBody>
        <ModalFooter style={{display:'flex',justifyContent:'center'}}>
           
          {/* <Button color="primary" onClick={toggle}>Do Something</Button>{' '} */}
          <Button color="primary" onClick={toggle}>OK</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default PicturePreview;