import { Button, Container, Row } from "reactstrap";
import React, { useState, useEffect } from 'react';
import "layouts/layouts.css"
import forward from "assets/img/yamahaIcons/forward.svg"
import back from "assets/img/yamahaIcons/back.svg"
import {  Link } from "react-router-dom";
import memberService from "../../views/service/APIService";
import { init } from "i18next";

const UserHeader = (props) => {

  const [multipleCampaignsData, setMultipleCampaignsData] = useState({})
  const [initialCampaign, setInitialCampaign] = React.useState()
  const [nextId, setNextId] = React.useState(0)
  const [finalId,setFinalId] = React.useState(0)

  useEffect(() => {
    fetchMultipleCampaigns()
   
  }, [])

  const fetchMultipleCampaigns = () => {
    memberService.getMultipleCampaigns().then(response => {
      setMultipleCampaignsData(response.data)
      setFinalId(response.data.campaignList && response.data.campaignList.length)
        let initialCampaign = response.data.campaignList[0]
        setInitialCampaign(initialCampaign)
    }).catch(error => {
        // setError(error && error.response && error.response.data && error.response.data.message)
    }).finally(e => {

    })
}

  const handleDetails = () => {
  }
  const handlePrev = () => {
    if(nextId!==0)
   { setNextId(nextId - 1)
    let initialCampaign = multipleCampaignsData.campaignList[nextId - 1]
    setInitialCampaign(initialCampaign)
}

  }
  const handleNext = () => {
    if(nextId <  finalId-1)
   { setNextId(nextId + 1)
    let initialCampaign = multipleCampaignsData.campaignList[nextId + 1]
    setInitialCampaign(initialCampaign)}
    console.log(initialCampaign)
  }

  return (
    <>
      <div className="header pb-4 d-flex align-items-center ">
        {/* Mask */}
        <span />
        {/* Header container */}
        <Container className="pt-2 d-flex align-items-center bg-white" fluid   >

          <Row style={{ width: '100%' }}>
            <div className="wrapper" style={{cursor:'pointer'}} >
            <Link to={{ pathname: '/admin/eventDetails', state: {initialCampaign:initialCampaign} }} style={{ marginRight: '15px' }}>
              <span className="" onClick={handleDetails}>   
{              <img src={initialCampaign && initialCampaign.bannerImageUrl} alt="" height="400" width="1170" />
}              </span>
              </Link>

              {nextId!==0 && <img src={back} className=" glyphicon fas fa-arrow-circle-left" onClick={handlePrev} alt="" />}
             { nextId < finalId -1 && <img src={forward} style={{ marginLeft: '1026px' }} className=" glyphicon fas fa-arrow-circle-right" onClick={handleNext} />}
              {initialCampaign && initialCampaign.eventSchedule.linkName != null &&
              <a href={initialCampaign.eventSchedule.linkUrl} target="_blank" rel="noopener noreferrer"><Button className="buttonImageName" color="primary" type="button" onClick={handleNext}>
                {initialCampaign.eventSchedule.linkName}
              </Button>
              </a> }
            </div>

          </Row>
        </Container>
      </div>
    </>
  );
};

export default UserHeader;
