import React, { useState, useEffect } from 'react';
import { Row, Col, Spinner, Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import "../../layouts/layouts.css"
import Select from 'react-select';
import memberService from "../service/APIService"
import moment from "moment"
import bikeImage from "assets/img/yamahaIcons/bike2.imageset/Rectangle654@2x.png"
import NotificationModal from 'components/Utilities/NotificationModal';
import MembershipDetail from 'views/ManageUser/MembershipDetail';
//import OTPVerification from '../OTPVerification';
import AssignedUserConfirmationPopup from 'views/ManageUser/AssignedUserConfirmationPopup';
import { ToastsStore } from "react-toasts";
import { useHistory } from 'react-router';
import AlertDialogSlide from "../MyVouchers/AlertDialogSlide"
import SubscriptionPopup from '../Subscriptions/SubscriptionPopup'
import firebase from 'utils/firebase';
import { useTranslation } from 'react-i18next';

const TicketDetailsModal = (props) => {
    //const [ticketDetails,setTicketDetails] = useState(props && props.ticketDetails)
    //const { buttonLabel, className } = props
    const [modal, setModal] = useState(false)
   
    const [isOpen,setIsOpen]= useState(false)
    const [showSubscriptionPopup, setShowSubscriptionPopup] = React.useState(false)
    const history = useHistory()
    const [subject,setSubject]=useState()
    const [ticketId, setTicketId]= useState(props && props.ticketId)
    const [details,setDetails]= useState([])
    const [refreshState,setRefreshState]  = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [imgUrl, setImgUrl] = useState()
    const tickets = useState(props && props.tickets)
    // const ticketDetails= memberService.getParticularTicketInfo(props)
    // console.log("ticketDetails")
    // console.log(ticketDetails)

    const { t } = useTranslation();

    useEffect(() => {
        // const analytics = getAnalytics(app);
         firebase.logEventForAnalytics('Ticket Details');
     }, [])
    
    useEffect(() => {
        fetchTicketDetails(ticketId)
        console.log(ticketId)
    }, [refreshState])

    const getTicketStatus = (status) => {
        switch(status) {
            case "2":
                return "Open"
              break;
            case "3":
                return "Pending"
              break;
            case "4":
                return "Resolved"
              break;
            default:
                return "Closed"
          }
    }

    const getTicketStatusColor= (status) => {
      switch(status) {
        case "2":
            return "red"
          break;
        case "3":
            return "red"
          break;
        case "4":
            return "green"
          break;
        default:
            return "green"
      }
    }
    
    // ticketDetails.then((value)=>{
    //     console.log("value")
    //     console.log(value)
    //     setDetails(value.data)
    //     console.log(details)
    //     })
    
    // console.log("details")
     

     const fetchTicketDetails = (id) => {
        setIsLoading(true)
        memberService.getParticularTicketInfo(id).then(response => {
       
        setDetails(response.data)
        console.log(details)
        
        
        })
        setIsLoading(false)
    }

    console.log(details)
    
    
    

    const toggle = () => setModal(!modal);

    return (
        
        
        <div>
            
            <Modal size="lg" isOpen={props.openPopup} className="buttonlabel" >
                <ModalHeader toggle={props.closePopup}><p className="ticketTextCapital">Ticket Details {isLoading && <Spinner style={{ marginTop: '10px'}} color='primary'/>}</p></ModalHeader>
                
                <ModalBody>
                
                    <Row style={{marginTop:"-20px"}}>
                        <Col xs="3"><p className="ticketTitle">{t("Ticket Id")}</p></Col>
                        {details.id !== "undefined" &&
                         <Col xs="9">
                            {details.id &&
                            <p className="ticketText"> {details.id}</p>}
                            {!details.id &&
                            <p className="ticketText" style={{color:'grey'}}>Loading...</p>}
                         
                         </Col> }
                    </Row>
                    <Row >
                        <Col xs="3"><p className="ticketTitle">{t("Created On")}</p></Col>
                        {details.createdAt !== "undefined" &&
                         <Col xs="9">
                            {details.createdAt &&
                            <p className="ticketText"> {details.createdAt}</p>}
                            {!details.createdAt &&
                            <p className="ticketText" style={{color:'grey'}}>Loading...</p>}
                         
                         </Col> }
                    </Row>
                    <Row>
                        <Col xs="3"><p className="ticketTitle">{t("Status")}</p></Col>
                        {details.status  &&
                         <Col xs="9">
                            {details.status &&
                            <p className="ticketText" style={{color:getTicketStatusColor(details.status)}}>{t(getTicketStatus(details.status))} </p>}
                            {!details.status &&
                            <p className="ticketText" style={{color:'grey'}}>Loading...</p>}
                         </Col> }
                    </Row>
                    <Row>
                        <Col xs="3"><p className="ticketTitle">{t("Category")}</p></Col>
                        {details.category !== "undefined" &&
                         <Col xs="9">
                            {details.category && 
                            <p className="ticketText">{details.category} </p>}
                            {!details.category &&
                            <p className="ticketText" style={{color:'grey'}}>Loading...</p>}
                         
                         </Col> }
                    </Row>
                    <Row>
                        <Col xs="3"><p className="ticketTitle">{t("Subject")}</p></Col>
                        {details.subject !== "undefined" &&
                         <Col xs="9">
                            {details.subject &&
                            <p className="ticketText">{details.subject}</p>}
                            {!details.subject &&
                            <p className="ticketText" style={{color:'grey'}}>Loading...</p>}
                            </Col> }
                    </Row>
                    <Row>
                        <Col xs="3"><p className="ticketTitle">{t("Description")}</p></Col>
                        {details.description !== "undefined" &&
                         <Col xs="9">
                            {details.description &&
                            <p className="ticketText" style={{textAlign:'justify'}}>{details.description} </p>}
                            {!details.description &&
                            <p className="ticketText" style={{color:'grey'}}>Loading...</p>}
                            </Col> }
                    </Row>
                   
                    {details.comment && <Row>
                        <Col xs="3"><p className="ticketTitle">{t("Comment by Yamaha<")}</p></Col>
                        {details.comment !== "undefined" &&
                         <Col xs="9">
                            
                            <p className="ticketText">{details.comment}</p>
                            
                            </Col> }
                    </Row>}
                    {(details.attachmentUrl)&&
                    <Row>
                        
                        <Col xs="3"><p className="ticketTitle">{t("Attachments")}</p></Col>
                        <Col xs="9">
                            <img src= {details.attachmentUrl} style={{ height: '450px', width:'450px' }}/>
                        </Col>       
                    
                    
                    </Row>
}
                    

                    

                </ModalBody>
                {/* <ModalFooter className="d-flex justify-content-center">
                    <Button color="primary" onClick={props.closePopup}>Cancel</Button>
                    {bikeDetails.status === 'Verified' && <Button color="primary" onClick={!(membershipDetails.status == "Activated") ? handlePopup : handleAssignUser}>Assign User</Button>}
                    { bikeDetails.status === 'Assigned' && bikeDetails.assignedToUserId !== userDetails.id && <Button color="primary" onClick={handleRemoveUser}>Remove User</Button>}
                </ModalFooter> */}
            </Modal>
        </div>
    )
}

export default TicketDetailsModal