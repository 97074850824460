
import {
  Button,
  Card,
  CardBody,
  Form,
  Row, Spinner,
  Col,
} from "reactstrap";
import "../../views/LoginComponents/login.css"
import { Link, Redirect } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import memberService from "../service/APIService"
import jwt_decode from 'jwt-decode';
import { ToastsStore } from "react-toasts";
import { sha512 } from "js-sha512";
import { useHistory } from 'react-router';
import {t} from 'i18next';

function SendOTP(props) {
  let timeLeft = 60;
  const [timeLeftToResend, setTimeLeftToResend] = useState(60);
  const [mobileNumber, setMobileNumber] = useState(props && props.location && props.location.state && (props.location.state.mobileNumber || props.location.state.mobile))
  const [nextScreen, setNextScreen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState("");
  const [isForgotPassword, setForgotPassword] = useState(props && props.location && props.location.state && props.location.state.forgotPassword)
  const [initialScreen, setInitialScreen] = useState(false)
  const [otherScreen, setOtherScreen] = useState(false)
  const [profileScreen, setProfileScreen] = useState(false)
  const [resetScreen, showResetScreen] = useState(false)
  const [userId, setUserId] = useState(sessionStorage.getItem('userId'))
  const [data, setData] = useState({});
  const [signupType, setSignupType] = useState(props && props.location && props.location.state && props.location.state.signupType)
  const countdown = () => {
    if (document.getElementById("seconds")) {
      timeLeft--;
      document.getElementById("seconds").innerHTML = String(timeLeft);
      if (timeLeft > 0) {
        setTimeout(countdown, 1000);
      }
      setTimeLeftToResend(timeLeft);
    }
  };

  const history = useHistory();

  useEffect(() => {
    setTimeLeftToResend(60)
    setTimeout(countdown, 1000);
    
  }, []);


  

  const handleVerifyOTP = () => {
    if (props.location.state.isLoginAsFan || props.location.state.signupType === "Username") {
      if (data.otp1 == undefined || data.otp1 == "" || data.otp2 == undefined || data.otp2 == ""
        || data.otp3 == undefined || data.otp3 == "" || data.otp4 == undefined || data.otp4 == ""
        || data.otp5 == undefined || data.otp5 == "" || data.otp6 == undefined || data.otp6 == "") {
        setError("Please enter OTP.")
      }
      else {
        let otp = data.otp1 + data.otp2 + data.otp3 + data.otp4 + data.otp5 + data.otp6
        let request = {
          "emailOrMobile": "+60" + mobileNumber,
          "verificationType": "MobileNumber",
          "otp": otp,
        //  "bypassCode": "123456"
        }
        memberService.verifyOTP(userId, request).then(response => {
          if (response) {
            handleSendOTP()
          }
        }).catch(error => {
          setError(error && error.response && error.response.data && error.response.data.message)
          return;
        })
      }
    }
    else if (props.location.state.forgotPassword || !props.location.state.isSignup && props.location.state.forgotPassword != undefined && props.location.state.isSignup != undefined) {
      if (data.otp1 == undefined || data.otp1 == "" || data.otp2 == undefined || data.otp2 == ""
        || data.otp3 == undefined || data.otp3 == "" || data.otp4 == undefined || data.otp4 == ""
        || data.otp5 == undefined || data.otp5 == "" || data.otp6 == undefined || data.otp6 == "") {
        setError("Please enter OTP.")
      }
      else {
        let otp = data.otp1 + data.otp2 + data.otp3 + data.otp4 + data.otp5 + data.otp6
        let request = {
          "emailOrMobile": "+60" + mobileNumber,
          "verificationType": "MobileNumber",
          "otp": otp,
        //  "bypassCode": "123456"
        }
        if(!props.location.state.isSignup){
          memberService.verifyEnteredOTP( request).then(response => {
            if (response) {
              handleSendOTP()
            }
          }).catch(error => {
            setError(error && error.response && error.response.data && error.response.data.message)
          //   if((sessionStorage.getItem("redirecturl")!== null && sessionStorage.getItem("redirecturl")!== undefined && sessionStorage.getItem("redirecturl")!== "") &&
          //   sessionStorage.getItem("client_id")!== null && sessionStorage.getItem("client_id")!== undefined && sessionStorage.getItem("client_id")!== "")
          //   {
          //  history.push ({pathname : "/auth/register", state: "Username"});
          //   }       
                 return;
          })
        }
        else{
        memberService.verifyOTP(userId, request).then(response => {
          if (response) {
            handleSendOTP()
          }
        }).catch(error => {
          setError(error && error.response && error.response.data && error.response.data.message)
          console.log("saasas")
          return;

        })
      }}
    }
    else {
      console.log("Hello")
      handleSendOTP()
    }
  }


  const handleSendOTP = () => {
    if (!props.location.state.forgotPassword) {
      // if (props.location && props.location.state && props.location.state.isLoginAsFan) {
      //     showResetScreen(false)
      // }
      // else {
      //   console.log("userId at limne 137 in handlesendOtp" , userId);
      //     handleLogin()
      //   showResetScreen(false)
      // }
      handleLogin()
    }
    else {
      showResetScreen(true)
    }
  }


  const handleLogin = () => {
    if (data.otp1 == undefined || data.otp1 == "" || data.otp2 == undefined || data.otp2 == ""
      || data.otp3 == undefined || data.otp3 == "" || data.otp4 == undefined || data.otp4 == ""
      || data.otp5 == undefined || data.otp5 == "" || data.otp6 == undefined || data.otp6 == "") {
      setError("Please enter OTP.")
    }
    else {
      setIsLoading(true)

      let otp = data.otp1 + data.otp2 + data.otp3 + data.otp4 + data.otp5 + data.otp6
      memberService.handleLoginViaMobile(mobileNumber, "MobileNumber", otp).then((response) => {
       // setIsLoading(false)
        var token = response.data && response.data.accessToken
        var decoded = jwt_decode(token);
        sessionStorage.setItem("userId", decoded.sub)
        sessionStorage.setItem("accessToken", response.data.accessToken)
        sessionStorage.setItem("refreshToken", response.data.refreshToken)
        sessionStorage.setItem("showSubscriptionPopup",true)
        handleMemberProfile()
      }).catch(error => {
        setError("OTP Not Verified")
      }).finally(e => {
        setIsLoading(false);

      })
    }
  };


  const handleMemberProfile = () => {
    setIsLoading(true)
    let userId = sessionStorage.getItem("userId")
    memberService.handleMemberProfile(userId).then(response => {
      sessionStorage.setItem("userDetails", JSON.stringify(response.data))
      localStorage.setItem("userDetails",JSON.stringify(response.data))
      if (response.data && response.data.bikes && response.data.bikes.length === 0 && response.data.cards && response.data.cards.length > 0) {
        setInitialScreen(false)
        setOtherScreen(false)
        setProfileScreen(false)
        sessionStorage.setItem("profileSetupStatus", "Completed")
        window.location.reload()
        
      }
      else if (response && response.data && response.data.bikes && response.data.bikes.length === 0 && response.data.cards && response.data.cards.length === 0) { setInitialScreen(true) }
      else if (response && response.data && response.data.bikes && response.data.bikes.length > 0) {
        if (response.data.bikes[0].plateNumber !== null && response.data.bikes[0].icNumber !== null && response.data.bikes[0].chassisNumber !== null
          && response.data.bikes[0].engineNumber !== null && response.data.cards && response.data.cards.length === 0) {
          setInitialScreen(false)
          setOtherScreen(false)
          setProfileScreen(true)
          sessionStorage.setItem("profileSetupStatus", "OtherBikeDetails")

        }
        else if (response.data.bikes[0].plateNumber !== null && response.data.bikes[0].icNumber !== null && response.data.bikes[0].chassisNumber !== null
          && response.data.bikes[0].engineNumber !== null && response.data.cards && response.data.cards.length > 0) {
          setInitialScreen(false)
          setOtherScreen(false)
          setProfileScreen(false)
          sessionStorage.setItem("profileSetupStatus", "Completed")
          window.location.reload()
        
        }
        else if (response.data.bikes[0].plateNumber !== null && response.data.bikes[0].icNumber !== null) {
          setInitialScreen(false)
          setOtherScreen(true)
          setProfileScreen(false)
          sessionStorage.setItem("profileSetupStatus", "InitialBikeDetails")
        }
        else {
          setOtherScreen(false)
          setInitialScreen(true)
          setProfileScreen(false)
        }
      }
    }).catch(error => {
      setError(error && error.response && error.response.data && error.response.data.message)
    }).finally(e => {
      setIsLoading(false)
    })
  }
  const resendOTP = () => {
    // if(isResendOTP)
    sendOtp()
    setTimeLeftToResend(60)
    setTimeout(countdown, 1000);
  }
  const sendOtp = () => {
    setIsLoading(true)
    let mobileNumber = props && props.location && props.location.state && props.location.state.mobile;
     console.log(props,props.location.state.mobile)
    memberService.handleSendOtp("+60" + mobileNumber).then(response => {
      ToastsStore.success("OTP sent successfully")
      // setNextScreen(true)
    }).catch(error => {
      setError(error && error.response && error.response.data && error.response.data.message)
    }).finally(e => {
      setIsLoading(false)
      // setNextScreen(true)
    })
  }
  const useOTPFields = () => {
    const [ssnValues, setValue] = React.useState({
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      otp5: "",
      otp6: "",

    });

    return {
      handleChange: e => {
        setError("")

        const { maxLength, value, name } = e.target;
        const [fieldName, fieldIndex] = name.split("-");
        // Check if they hit the max character length
        if (value.length >= maxLength) {
          // Check if it's not the last input field
          if (parseInt(fieldIndex, 10) < 6) {
            // Get the next input field
            const nextSibling = document.querySelector(
              `input[name=otp-${parseInt(fieldIndex, 10) + 1}]`
            );

            // If found, focus the next field
            if (nextSibling !== null) {
              nextSibling.focus();
            }
          }
        }
        else {
          if (parseInt(fieldIndex, 10) === 6) {
            // Get the next input field
            const prevSibling = document.querySelector(
              `input[name=otp-${parseInt(fieldIndex, 10) - 1}]`
            );

            // If found, focus the next field
            if (prevSibling !== null) {
              prevSibling.focus();
            }
          }
          else if (parseInt(fieldIndex, 10) === 5) {
            // Get the next input field
            const prevSibling = document.querySelector(
              `input[name=otp-${parseInt(fieldIndex, 10) - 1}]`
            );

            // If found, focus the next field
            if (prevSibling !== null) {
              prevSibling.focus();
            }
          }
          if (parseInt(fieldIndex, 10) === 4) {
            // Get the next input field
            const prevSibling = document.querySelector(
              `input[name=otp-${parseInt(fieldIndex, 10) - 1}]`
            );

            // If found, focus the next field
            if (prevSibling !== null) {
              prevSibling.focus();
            }
          }
          if (parseInt(fieldIndex, 10) === 3) {
            // Get the next input field
            const prevSibling = document.querySelector(
              `input[name=otp-${parseInt(fieldIndex, 10) - 1}]`
            );

            // If found, focus the next field
            if (prevSibling !== null) {
              prevSibling.focus();
            }
          }
          if (parseInt(fieldIndex, 10) === 2) {
            // Get the next input field
            const prevSibling = document.querySelector(
              `input[name=otp-${parseInt(fieldIndex, 10) - 1}]`
            );

            // If found, focus the next field
            if (prevSibling !== null) {
              prevSibling.focus();
            }
          }
          if (parseInt(fieldIndex, 10) === 1) {
            // Get the next input field
            const prevSibling = document.querySelector(
              `input[name=otp-${parseInt(fieldIndex, 10) - 1}]`
            );

            // If found, focus the next field
            if (prevSibling !== null) {
              prevSibling.focus();
            }
          }
        }

        setValue({
          ...value,
          [`otp${fieldIndex}`]: value
        });
        setData({
          ...data,
          [`otp${fieldIndex}`]: value
        });
      }
    };
  };

  const OTPField = () => {
    const { handleChange } = useOTPFields();

    return (
      <>
        {initialScreen && <Redirect to="/auth/initialDetails" />}
        {otherScreen && <Redirect to="/auth/otherDetails" />}
        {profileScreen && <Redirect to="/auth/profile" />}
        {resetScreen && <Redirect to={{ pathname: "/auth/resetPassword", state: { mobileNumber: mobileNumber } }}></Redirect>}
        <Row>
          <Col xs={12} style={{ textAlign: 'center', margin: '15px' }}>
            <span>{t("Enter the OTP sent to your mobile phone")}</span>
          </Col>
        </Row>
        <Row>
          <div className="send_otp_input_div">
            <input className="send_otp_input"
              style={{ textAlign: 'center', border: '1px solid #ff9017' }}
              type="number"
              name="otp-1"
              maxLength={1}
              onChange={handleChange}
              onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()} />
            <input className="send_otp_input"
              style={{ textAlign: 'center', border: '1px solid #ff9017' }}
              type="number"
              name="otp-2"
              maxLength={1}
              onChange={handleChange}
              onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()} />
            <input className="send_otp_input"
              style={{ textAlign: 'center', border: '1px solid #ff9017' }}
              type="number"
              name="otp-3"
              maxLength={1}
              onChange={handleChange}
              onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()} />
            <input className="send_otp_input"
              style={{ textAlign: 'center', border: '1px solid #ff9017' }}
              type="number"
              name="otp-4"
              maxLength={1}
              onChange={handleChange}
              onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()} />
            <input className="send_otp_input"
              style={{ textAlign: 'center', border: '1px solid #ff9017' }}
              type="number"
              name="otp-5"
              maxLength={1}
              onChange={handleChange}
              onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()} />
            <input className="send_otp_input"
              style={{ textAlign: 'center', border: '1px solid #ff9017' }}
              type="number"
              name="otp-6"
              maxLength={1}
              onChange={handleChange}
              onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()} />
          </div>
        </Row>

      </>
    );
  };
  return (
    <>
      <Col lg="5" md="7" className="mt-8" style={{ height: '550px' }}>
        <span className="login_header">OTP</span>
        <Card className="bg-secondary shadow module-border-wrap" >
          <CardBody className="px-lg-5 py-lg-5">

            <Row >
              <Col xs="12">
                {OTPField()}
              </Col>
            </Row>
            <Form role="form">
              <div style={{ margin: '10px', display: 'flex', justifyContent: 'space-between' }}>
                <small style={{ marginTop: '10px' }}>{t("Didn't receive an OTP?")}</small>

                {/* <Link className="text-link" to={{ pathname: '/auth/sendOTP', state:"Mobile"  }}> */}
                {timeLeftToResend > 0 && <div style={{ display: 'flex', marginTop: '10px' }}><small style={{ color: 'grey', display: 'flex'  }}>Resend OTP after   <div id="clock">
                  <span id="seconds" style={{ marginLeft: '2px' }}> 60 </span>
                </div>  <span style={{ marginLeft: '2px' }}> seconds</span></small></div>}
                {timeLeftToResend === 0 && <div style={{ display: 'flex', marginTop: '10px' }}>
                  {/* <Link to={{ pathname: '/auth/loginViaMobile', state: { type: props && props.location && props.location.state } }} style={{ marginRight: '15px' }}> */}
                  <small onClick={() => resendOTP()} style={{ cursor: 'pointer' , fontWeight: 900}}>{t("Resend")}</small>
                  {/* </Link> */}
                </div>}
                {/* </Link> */}
              </div>
              <div className="text-center">
                <small style={{ color: 'red' }}>{error}</small></div>
              <div className="text-center">
                {signupType === "Mobile" && <Link to={{ pathname: '/auth/loginViaMobile', state: { type: props && props.location && props.location.state, forgotPassword: isForgotPassword } }} style={{ marginRight: '15px' }}>
                  <Button className="mt-3" color="primary" type="button" >
                    {t("Cancel")}
                  </Button>
                </Link>}

                {signupType === "Username" && <Link to={{ pathname: '/auth/profile', state: { type: props && props.location && props.location.state, forgotPassword: isForgotPassword, navigatingBack: true } }} style={{ marginRight: '15px' }}>
                  <Button className="mt-3" color="primary" type="button" >
                    {t("Cancel")}
                  </Button>
                </Link>}
                {!isLoading && <Button className="mt-3" color="primary" type="button" onClick={handleVerifyOTP}>
                  {t("Proceed")}
                </Button>}
                {isLoading && <Spinner style={{ marginTop: '10px' }} color="primary" />}
              </div>
            </Form>

          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default SendOTP;
