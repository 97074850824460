import React, { useState, useEffect } from 'react';
import { Row, Col, Spinner, Button, Modal, ModalHeader, ModalBody,
     ModalFooter, InputGroup, Card, CardBody,Form,FormGroup, Input } from 'reactstrap';
import "../../../layouts/layouts.css"
import Select from 'react-select';
import memberService from "../../service/APIService"
import moment from "moment"
import  bikeImage from "assets/img/yamahaIcons/bike2.imageset/Rectangle654@2x.png"
import PicturePreview from 'components/Utilities/PicturePreview';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import NewBikeDetailConfirmation from './NewBikeDetailConfirmation';
const NewBikeOtherDetails = (props) => {
    const [bikeDetails,setBikeDetails] = useState(props && props.bikeDetails)
    const [userDetails,setUserDetails] = useState(JSON.parse(sessionStorage.getItem('userDetails')))
    const [data, setData] = React.useState({});
    const [vocUploadLoader,setVOCUploadLoader] = React.useState(false)
    const [error, setError] = React.useState("");
    const [engineNumberError, setEngineNumberError] = React.useState('');
    const [chassisNumberError, setChassisNumberError] = React.useState('');
    const [vocUploadError, setVOCUploadError] = React.useState('');
    const [modelNameError, setModelNameError] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false)
    const [vehicleModels, setVehicleModels] = React.useState([])
    const [vocImageList, setVOCImageList] = React.useState([])
    const [showVoc, setShowVoc] = React.useState(false)
    const [modelName, setModelName] = React.useState(userDetails && userDetails.bikes && userDetails.bikes[0] && userDetails.bikes[0].modelNumber!=null?userDetails && userDetails.bikes && userDetails.bikes[0] && userDetails.bikes[0].modelNumber:"")
    const [nextScreen,setNextScreen] = useState(false)
    const vocImageBaseUrl = process.env.REACT_APP_ENV === "PROD" ? process.env.REACT_APP_VOC_IMAGE_BASE_URL_PROD :  process.env.REACT_APP_ENV === "DEV" ? process.env.REACT_APP_VOC_IMAGE_BASE_URL :  process.env.REACT_APP_VOC_IMAGE_BASE_STAGE;
    console.log("image url", vocImageBaseUrl);
    //const vocImageBaseUrl = process.env.REACT_APP_VOC_IMAGE_BASE_URL

    const {
        buttonLabel,
        className
    } = props;

    const [modal, setModal] = useState(false);


    const toggle = () => setModal(!modal);
    const getFormattedRegDate= (regDate) => {
        let newRegDate = moment(regDate).format('DD MMM YYYY')
        return newRegDate;
    }
    const testEngineNumber = (x) => {
        var count = (x.match(/-/g) || []).length;
        console.log(count); 
        if(count > 1){
            return false;
        }
        return true;
    }
    const handleError = () => {
        // "(?=.*[-A-Z])(?=.*[0-9])(?=.*[a-z]).{7,20}";
        let engineRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+-[a-zA-Z0-9]*$/
        let alphaNumericRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/;
        
        if(!(engineRegex.test(data.engineNumber)||alphaNumericRegex.test(data.engineNumber))){
            setEngineNumberError("Engine number should be between 7 to 20 characters and in alphanumeric combination with maximum one hyphen '-'")
            return false
        }
        var temp = data.engineNumber;
        if (!testEngineNumber(temp)) {
            setEngineNumberError("Engine number should be between 7 to 20 characters and in alphanumeric combination with maximum one hyphen '-'")
            return false
        }
        if (data.engineNumber.length < 7 || data.engineNumber.length > 20) {
            setEngineNumberError("Engine number should be between 7 to 20 characters and in alphanumeric combination with maximum one hyphen '-'")
            return false
        }
        if (data.engineNumber === undefined || data.engineNumber === "") {
            setEngineNumberError("Engine number should be between 7 to 20 characters and in alphanumeric combination with maximum one hyphen '-'")
            return false
        }
    
        if (!alphaNumericRegex.test(data.chassisNumber)) {
            setChassisNumberError("Chassis number should not contain special characters or spaces.")
            return false
        }
        if (data.chassisNumber === undefined || data.chassisNumber === "") {
            setChassisNumberError("Chassis number cannot be empty")
            return false
        }
        if (data.chassisNumber && (data.chassisNumber.length < 7 || data.chassisNumber.length > 20)) {
            setChassisNumberError("Chassis number should be between 7 to 20 characters and in alphanumeric combination")
            return false
        }
        if (modelName === "") {
            setModelNameError("Please select model name")
            return false
        }
        if (vocImageList.length === 0 || vocImageList.length > 4) {
            setVOCUploadError("Please upload upto 4 VOC documents")
            return false
        }
        return true
    }


    const deleteFile = (name) => {
        vocImageList.splice(vocImageList.indexOf(name), 1);
        setVOCImageList([...vocImageList])
        setShowVoc(true)

    }


    const onFileChange = (event) => {
        setVOCUploadError("")
        if (event.target.files && event.target.files.length) {
            setShowVoc(true)
            setVOCUploadLoader(true)
            uploadFile(event.target.files)
        }

    }

    useEffect(() => {
        setVOCImageList(vocImageList)
    }, [vocImageList])

    useEffect(() => {
        fetchVehicleModels()
    }, [])

    const vocShow = () => {
        return (
            <>
                {vocImageList && vocImageList.map((anImage) => {
                    if (anImage && anImage.type && anImage.type.includes('pdf')) {
                        return (<div>
                            <Card style={{ marginRight: '10px' }}>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <i style={{ color: "#138de8", marginRight: '-7px', cursor: 'pointer' }} class="fas fa-times-circle" onClick={() => deleteFile(anImage.name)}></i>
                                </div>
                                <CardBody>
                                    <i className="fas fa-file-pdf" style={{ color: 'red', fontSize: '30px' }}></i>
                                </CardBody>
                            </Card>
                        </div>)
                    }
                    else if (anImage && anImage.type && (anImage.type.includes('image') || anImage.type.includes('png'))) {
                        return (<div>
                            <Card style={{ marginRight: '10px' }}>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <i style={{ color: "#138de8", marginRight: '-7px', cursor: 'pointer' }} class="fas  fa-times-circle" onClick={() => deleteFile(anImage.name)}></i>
                                </div>
                                <CardBody>
                                <img src={vocImageBaseUrl + anImage.name}  style={{ height: '30px', width:'30px' }} />
                                    {/* <i className="fas fa-file-image" style={{ color: 'red', fontSize: '30px' }}></i> */}
                                </CardBody>
                            </Card>
                        </div>)
                    }
                })}


            </>)

    }

    const vocUpload = () => {
        return (
            <>
             {!vocUploadLoader && <Button className="ma_btn mt-3 mb-3" color="primary" type="button" >
                    Upload Copy of VOC <i className="fa fa-upload" aria-hidden="true"></i>
                    <input type="file" accept=".jpg,.jpeg,.png" onChange={(event) => onFileChange(event)} />
                </Button>}
                {vocUploadLoader && <div style={{ display: 'flex',justifyContent: 'center'}}><span style={{marginRight:'10px'}}>Uploading</span> <Spinner size="sm"  color="primary"/></div> }
                <PicturePreview>
                </PicturePreview>
                           </>)
    }

    const uploadFile = (files) => {
        let fileName = files[0].name
        let actualFileName = sessionStorage.getItem("userId") + " " + fileName
        memberService.fetchs3PresignedURl(actualFileName).then(res => {
            if (res) {
                memberService.uploadToS3(res, files[0]).then(res => {
                    let fileList = files;
                    let filepush = []
                    filepush.push(fileList[0])
                    let actualFileName = sessionStorage.getItem("userId") +" "+ fileList[0].name
                    let fileName = actualFileName.substring(0, actualFileName.lastIndexOf(".") )
                    vocImageList.push({ name: actualFileName, type: fileList[0].type, index: vocImageList.length + 1,fileName:fileName })
                    setVOCImageList([...vocImageList])
                    setShowVoc(true)

                })
            }
        }).catch(error => {
            setError(error && error.response && error.response.data && error.response.data.message)
        }).finally(e => {
            setVOCUploadLoader(false)
            // setNextScreen(true)
        })
    
    }


    const fetchVehicleModels = () => {
        memberService.fetchVehicleModels().then(response => {
            let vehicleModels = []
            response.data.bikeModels.map((aModel, key) => {
                vehicleModels.push({ id: aModel.id, bikeModelName: aModel.bikeModelName })
            })
            setVehicleModels(vehicleModels)
        })
    }

    const handleChange = (e) => {
        setError("")
        if (e.target.name === 'engineNumber')
            setEngineNumberError("")
        if (e.target.name === 'chassisNumber')
            setChassisNumberError("")
        if (e.currentTarget && e.currentTarget.textContent) {
            setModelName(e.currentTarget.textContent)
            setModelNameError("")
        }
        if (vocImageList.length === 4) {
            setVOCUploadError("")
        }
        if (e.target.name === 'engineNumber' || e.target.name === 'chassisNumber'){
            setData({
                ...data,
                [e.target.name]: e.target.value.toUpperCase(),
                [e.target.name + "_error"]: null,
            });
        }
        else{
            setData({
                ...data,
                [e.target.name]: e.target.value,
                [e.target.name + "_error"]: null,
            });
        }
    }

    const handleSaveVehicleOtherInfo = () => {

        
        let userDetails = JSON.parse(sessionStorage.getItem('userDetails'))
        if (handleError()) {
            let vocImageListToUpload = []
            vocImageList && vocImageList.map(aDoc => {
                vocImageListToUpload.push(aDoc.name)
            })
            setIsLoading(true)
            let request = {
                "engineNumber": data.engineNumber,
                "modelName": modelName,
                "chassisNumber": data.chassisNumber,
                "plateNumber": bikeDetails.plateNumber,
                "icNumber": bikeDetails.icNumber ||  userDetails.icNumber,
                "vocCopyfileNames": vocImageListToUpload
            }
            memberService.updateVehicleJPJInfo(request, bikeDetails.id).then((response) => {
            props.parentCallback(response.data)               

            }).catch(error => {
                setError(error && error.response && error.response.data && error.response.data.message)
            }).finally(e => {
                setIsLoading(false)
            })
        }
        else {
            return
        }

    }

   
    const deleteBikeDetails = () => {
        setIsLoading(true)
        let request = {
            
            "plateNumber": props.plateNumber,
            "icNumber": props.icNumber,
            "id":props.bikeDetails.id
        }
        memberService.deleteVehicle(request).then((response) => {
                console.log(response);
        }).catch(error => {
        }).finally(e => {
            setIsLoading(false)
            props.closePopup();
        })
}

    

    useEffect(() => {
       
    }, [])

    return (
        <div>
            {/* <Button color="danger" onClick={toggle}>{buttonLabel}</Button> */}
            <Modal size="md" isOpen={props.openPopup} className={className}>
                <ModalHeader toggle={deleteBikeDetails}>Bike Details</ModalHeader>
                <ModalBody>
                <Card>
                    <CardBody className="px-lg-5 py-lg-5">
                        <Form role="form">
                            <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                    <Input
                                        name="engineNumber"
                                        placeholder="Engine Number"
                                        value={data && data.engineNumber}
                                        autoComplete="new-engineNumber"
                                        onChange={handleChange}
                                        maxLength='20'
                                        style={{ textTransform: "uppercase" }}
                                    />
                                </InputGroup>
                                <small style={{ color: 'red' }}>{engineNumberError}</small>
                            </FormGroup>
                            <FormGroup>
                                <InputGroup className="input-group-alternative">
                                    <Input
                                        name="chassisNumber"
                                        value={data && data.chassisNumber}
                                        placeholder="CHASSIS NUMBER"
                                        autoComplete="new-chassisNumber"
                                        onChange={handleChange}
                                        maxLength='20'
                                        style={{ textTransform: "uppercase" }}
                                        type="text"
                                    />
                                </InputGroup>
                                <small style={{ color: 'red' }}>{chassisNumberError}</small>
                            </FormGroup>
                            <Row>
                                <Col xs="12">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="input-organizationName">
                                            Select Model Name
                                        </label>
                                        <Autocomplete
                                            id="modelName"
                                            defaultValue= {{bikeModelName:  modelName}}
                                            options={vehicleModels}
                                            getOptionLabel={(option) => option.bikeModelName}
                                            debug
                                            onChange={handleChange}
                                            name="modelName"
                                            required
                                            renderInput={(params) => <TextField {...params} label="Model Name"

                                            />}
                                        />

                                    </FormGroup>

                                </Col>
                                <Col xs="12" style={{ display: 'flex' }}>
                                    {vocUpload()}
                                </Col>

                            </Row>
                            {showVoc && <Row>{vocShow()}</Row>}
                            <Row className="mt-3">
                                <Col xs="12" className="text-center" >
                                    <small style={{ color: 'red' }}>{modelNameError}</small>
                                    <small style={{ color: 'red' }}>{error}</small>
                                    <small style={{ color: 'red' }}>{vocUploadError}</small>

                                </Col>
                            </Row>
                            
                        </Form>

                    </CardBody>
                </Card>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-center"><div className="text-center">
                                    <Button className="mt-3" color="primary" type="button" onClick={ deleteBikeDetails}>
                                        Cancel
                                    </Button>
                                {!isLoading && <Button className="mt-3" color="primary" type="button" onClick={handleSaveVehicleOtherInfo}>
                                    Proceed
                                </Button>}
                                {isLoading && <Spinner style={{ marginTop: '10px' }} color="primary" />}

                            </div>              

            
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default NewBikeOtherDetails;