import React, { useState, useEffect } from 'react';
import { Row, Col, Spinner, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input } from 'reactstrap';
import "../../layouts/layouts.css"
import BankList from './BankList';
import memberService from 'views/service/APIService';
import closeButton from "../../assets/img/yamahaIcons/close.png"
import ConfirmationDialog1 from "./ConfirmationDialog";
import { useTranslation } from 'react-i18next';


// STATUS: 
// Activated --> Nothing
// ActivationPending --> Proceed 
// ReactivationRequired --> REACTIVATE API
// Incomplete --> SIGN UP API
// Unknown  --> Nothing


const SubscriptionPopup = (props) => {
    const {
        buttonLabel,
        className
    } = props;

    const [showBankList, setShowBankList] = useState(false);
    const [popupData , setPopupData] = useState(props.membershipDetails);
    // const [signUpData , setSignUpData ] = useState([]);
    // const [reactivationData , setReactivationData ] = useState([]);
    const [signupAndreactivationData , setsignupAndreactivationData ] = useState({});
    const [sellerId, setSellerId ] = useState(null);
    const [userId, setUserId] = useState(sessionStorage.getItem('userId'))
    const [open, setOpen] = useState()
    const [proceedBtnStatus, setProceedBtnStatus] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    // const [isFromSignupPopupFlowOK, setIsFromSignupPopupFlowOK] = useState(sessionStorage.getItem("isFromSignupPopupFlowOK"))

    useEffect(() => {

        if(popupData && popupData.status === "Incomplete"){
            fetchSubscriptionPopupData()
        }
        else if(popupData && (popupData.status === "ReactivationRequired" || popupData.status === "Activated"))
        {
            fetchReactivationData();
        }
     }, [])

    const { t } = useTranslation();

    const fetchSubscriptionPopupData = () => {
        setProceedBtnStatus(true);
        memberService.fetchSubscriptionPopupData().then(response => {
            console.log("signup data");
            console.log("user details",sessionStorage.getItem("userDetails"))
            console.log("parsed userDetails session storage: ",JSON.parse(sessionStorage.getItem("userDetails")).id)
            console.log("user details local storage",localStorage.getItem("userDetails"))
            console.log("parsed userDetails: ",JSON.parse(localStorage.getItem("userDetails")))
            console.log("parsed userDetails email: ",JSON.parse(localStorage.getItem("userDetails")).email)
            setsignupAndreactivationData(response.data)
        }).catch(error => {
            console.log("Error")
            // setError(error && error.response && error.response.data && error.response.data.message)
        }).finally(e => {
            setProceedBtnStatus(false);
        })
    }


    const fetchReactivationData = () => {
        setProceedBtnStatus(true);
        memberService.fetchReactivationData().then(response => {
            setsignupAndreactivationData(response.data)
            console.log("reactivate data");
            console.log("user details",sessionStorage.getItem("userDetails"))
            console.log("parsed userDetails session storage: ",JSON.parse(sessionStorage.getItem("userDetails")).id)
            console.log("user details local storage",localStorage.getItem("userDetails"))
            console.log("parsed userDetails: ",JSON.parse(localStorage.getItem("userDetails")))
            console.log("response.data",response.data)
        }).catch(error => {
            console.log("Error")
            // setError(error && error.response && error.response.data && error.response.data.message)
        }).finally(e => {
            setProceedBtnStatus(false);
        })
    }


    const getSellerId = () => {
        memberService.fetchSellerId().then(response => {
            console.log("responseDate at ln 79 in SubscriptionPopup",response.data)
            setSellerId(response.data.paymentOrderId)
            setShowBankList(true)
        }).catch(error => {
            // setError(error && error.response && error.response.data && error.response.data.message)
        }).finally(e => {

        })
    }

    const handleZeroAmountSubscription = () => {
        //API call
        //in response show congrats poppup wih ok
        // if(props.isFromSignupPopupFlowOK!= null && props.isFromSignupPopupFlowOK){
        //     console.log("dnwd", props.setIsFromSignupPopupFlowOK);
        //     window.location.replace(window.location.href);
        // }
        // else {
            if(userId != null && userId!=undefined){
                setIsLoading(true);
                console.log("isLoading");
                memberService.getZeroAmountSubscriptionFee(userId).then(response => {
                    console.log("zeroamount success");
                    //show congrats popup
                    setOpen(true);
                    //handleClose();
        
                }).catch(error => {
                    // setError(error && error.response && error.response.data && error.response.data.message)
                }).finally(e => {
                    setIsLoading(false)
                })
            }
        // }
        
    }

    const handelZeroAmountOk = ()=>{
        if(props.isFromSignupPopupFlowOK!= null && props.isFromSignupPopupFlowOK){
            console.log("dnwd", props.setIsFromSignupPopupFlowOK);
           // window.location.replace(window.location.href);
           props.handelClosePopup();
        }
    }



    const handleClose = () => {
        props.handelClosePopup();
    }


    return (
        <div>
            
            {open && <ConfirmationDialog1  dialogMessage = {`Congratulations! Your membership validity ${popupData.status === "Activated" ? "Extended" : "Reactivated" } successfully`}   open = {true} setOpen = { setOpen }  subscriptionPopupClose = {handleClose} close={props.closePopup} status={popupData.status === "Activated" ? "Extended" : "Reactivated" }/>}

            {showBankList  && <BankList openPopup={showBankList} amount={signupAndreactivationData.amount} sellerId = {sellerId}
                closePopup={() => { setShowBankList(false) }}></BankList>}
            {/* <Button color="danger" onClick={toggle}>{buttonLabel}</Button> */}
            
            {<Modal size="sm" isOpen={props.openPopup} className={className}>
            <img onClick={handleClose} src={closeButton} alt="Logo" className='closeButtonAlterDialogBox'/>
                <ModalHeader style = {{marginTop:"0px"}}>{t("Subscription details")}</ModalHeader>
                <ModalBody className="">
                    <Row>
                        <Col xs={6}>
                            <p className="subscriptionTitle">{t("Subscription fee")} : </p></Col>
                        <Col xs={6}> <p className="subscriptionTitle"> {props. isFromSignupPopupFlowOK!=null && props.isFromSignupPopupFlowOK == true? "RM 0.00" :signupAndreactivationData != null && signupAndreactivationData.amount !=null ?  "RM " +  parseFloat(signupAndreactivationData.amount).toFixed(2) : "-"} </p> </Col>

                    </Row>
                    <Row>
                        <Col xs={6}> <p className="subscriptionTitle">{t("Membership validity")} : </p></Col>
                        <Col xs={6}> <p className="subscriptionTitle"> {props.isFromSignupPopupFlowOK!=null && props.isFromSignupPopupFlowOK == true? popupData.planDurationInMonths + " Months" : signupAndreactivationData != null && signupAndreactivationData.extensionDuration !=null ? signupAndreactivationData.extensionDuration + " Months" : "-"} </p> </Col>
                    </Row>
                    {/* <Row>
                        <Col xs={6}> <p className="subscriptionTitle">Expiry date:</p></Col>
                        <Col xs={6}><p className="subscriptionTitle"> {} </p> </Col>
                    </Row> */}
                    {isLoading && <Spinner style={{ margin: '10% 40%' }} color="primary" />}
                </ModalBody>
                <ModalFooter className="d-flex justify-content-center">
                    <Button disabled={proceedBtnStatus} className="mt-3" color="primary" type="button" onClick={()=>{props.isFromSignupPopupFlowOK!=null && props.isFromSignupPopupFlowOK == true ? handelZeroAmountOk():signupAndreactivationData.amount == 0 ? handleZeroAmountSubscription(): getSellerId()}}>
                         {props.isFromSignupPopupFlowOK!=null && props.isFromSignupPopupFlowOK == true ? "OK" :signupAndreactivationData.amount == 0? popupData.status === "Activated" ? t("Extend") : t("Reactivate") : t("Proceed")}
                        {/* setShowBankList(true) */}
                    </Button>
                    
                </ModalFooter>
            </Modal>}
        </div>
    );
}

export default SubscriptionPopup;