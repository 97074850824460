import React from 'react'
import { useEffect ,useState } from 'react';
import { Table } from 'reactstrap';
import memberService from 'views/service/APIService';
import { Row, Col, Card, Spinner, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input } from 'reactstrap';
import { FormControl, InputLabel, Link, MenuItem ,Select } from '@material-ui/core';
import TicketDetailsModal from "./TicketDetailsModal";
import { maxWidth } from '@mui/system';
import HWMessage from 'HWLibrary/HWMessage';
import firebase from 'utils/firebase';
import { t } from 'i18next';


const TicketPopup = (props) => {
    const [open, setOpen] = useState(props.openPopup);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(props.ticket.id);
    const [ticket,setTicket] = useState(props.ticket)
    const [priority, setPriority ] = useState(props.ticket.priority)
    const [status, setStatus] = useState(props.ticket.status)
    const [priorityList, setPriorityList ] = useState(["Low","Medium","High","Urgent"])
    const [userDetails, setUserDetails] = useState(JSON.parse(sessionStorage.getItem('userDetails')))
    


    console.log(data)

    
   

    const getPriority = (priority) => {
        switch(priority) {
            case 1:
                return "Low"
              break;
            case 2:
                return "Medium"
              break;
            case 3:
                return "High"
              break;
            default:
                return "Urgent"
          }

    }

    const getReversePriority = (priority) => {
        switch(priority) {
            case "Low":
                return 1
              break;
            case "Medium":
                return 2
              break;
            case "High":
                return 3
              break;
            default:
                return 4
          }

    }


   

    const updateTicket = (data,priority2) => {
        setLoading(true);
        memberService.updateTicket(data,priority2).then(response => {
                setOpen(false);
        }).catch(error => {
            console.log("Error")
        }).finally(e => {
            setLoading(false);
            props.setRefreshState(!props.refreshState);
            setOpen(false);
        })
    }
    

    return (
        <div>
           <Modal size="md" isOpen={open} >
           <center>
                <ModalHeader >               
                    
                        <span style = {{ fontWeight : 500, fontSize: 26 }} > 
                            Update Ticket
                         </span> 
                   
                 </ModalHeader >               
                 </center>
                <ModalBody className="">
                
                    <div style={{display: 'flex' , marginBottom:3, justifyContent:"space-between"}}>
                        <div>
                                Priority
                        </div>
                        <div style={{marginRight:45}}>
                                {/* {priority} */}

                                <Select
                                            name = { "priority" }
                                            fullWidth
                                            style = {{ width : 220, borderColor : '#138DE8' }}
                                            value = {  getPriority(priority)}
                                            disabled = { false }
                                            onChange =  { (e) => { console.log("Checking", e.target.value); setPriority (getReversePriority(e.target.value)); } }
                                        >
                                            { priorityList.map ((item, idx) => <MenuItem value = {item} key = {idx}  >  { item } </MenuItem>) }
                                        </Select>
                        </div>
                      </div>
                      <br/>
                    

                </ModalBody>
                <ModalFooter className="d-flex justify-content-center">
                <Button className="mt-3" color="primary" type="button" onClick={e => props.closePopup() } >
                        Cancel
                    </Button>
                    <Button className="mt-3" color="primary" type="button" onClick={e => updateTicket(data,priority) } >
                        Update
                    </Button>
                </ModalFooter>
                
            </Modal>
        </div>
    )

}

const MyTickets = () => {
    const [modal, setModal] = useState(false);
    const [loading,setLoading]  = useState(false);
    const [tickets,setTickets]  = useState([]);
    const [data,setData]  = useState(null);
    const [openPopUp,setOpenPopUp]  = useState(false);
    const [refreshState,setRefreshState]  = useState(false);
    const [userDetails, setUserDetails] = useState(JSON.parse(sessionStorage.getItem('userDetails')))
    const [userId,setUserId] = useState(sessionStorage.getItem("userId"))
    const [ticketDetails, setTicketDetails] = useState(null);
    const [showTicketDetail, setShowTicketDetails] = useState(false)
    const [selectedTicket, setSelectedTicket] = useState()
    const [ticketId, setTicketId]= useState()
    const [subContent, setSubContent] =useState()
    const [truncatedDescription,setTruncatedDescription] = useState()
    

    useEffect(() => {
            fetchAllTickets()
            console.log("refresh")
            //fetchTicketDetails()
     }, [])

     useEffect(() => {
      // const analytics = getAnalytics(app);
       firebase.logEventForAnalytics('My Tickets');
   }, [])


     

     const fetchAllTickets = () => {
      console.log("fetchall")
        setLoading(true);
        memberService.getAllTickets(userId).then(response => {
           setTickets(response.data.tickets)
           
           
            console.log("All tickets data",response.data,tickets);
            setLoading(false);      
        }).catch(error => {
        }).finally(e => {
            setLoading(false);
        })
    }


    const deleteTicket = (id) => {
        setLoading(true);
        memberService.deleteTicket(id).then(response => {
           setTickets(tickets.filter(i=>i.id!==id))
        }).catch(error => {
        }).finally(e => {
          HWMessage.show("Ticket Deleted Successfully", "success" )
            setLoading(false);
        })
    }

    
    const getTicketPriority = (priority) => {
        switch(priority) {
            case 1:
                return "Low"
              break;
            case 2:
                return "Medium"
              break;
            case 3:
                return "High"
              break;
            default:
                return "Urgent"
          }

    }

    const getTicketStatus = (status) => {
        switch(status) {
            case "2":
                return "Open"
              break;
            case "3":
                return "Pending"
              break;
            case "4":
                return "Resolved"
              break;
            default:
                return "Closed"
          }
    }

    const getTicketStatusColor= (status) => {
      switch(status) {
        case "2":
            return "red"
          break;
        case "3":
            return "red"
          break;
        case "4":
            return "green"
          break;
        default:
            return "green"
      }
    }

    const getTicketSource = (source) => {
        switch(source) {
            case 1:
                return "Email"
              break;
            case 2:
                return "Portal"
              break;
            case 3:
                return "Phone"
              break;
              case 7:
                return "Chat"
              break;
              case 9:
                return "Feedback Widget	"
              break;
            default:
                return "Outbound Email	"
          }
    }

    const handleTicketDetails = (id) => {
      
      // fetchTicketDetails(id)
      setTicketId(id)
      //console.log(ticketId)
      setShowTicketDetails(true)
    }

    const handleUser = () => {
      
      setShowTicketDetails(false);
      
    }

    const fetchTicketDetails = (id) => {
      // setLoading(true);
      // const info=memberService.getParticularTicketInfo(id)
      // console.log(info)
      // info.then(response => {
        
      //     console.log(response.data)
      //     console.log("xyz",selectedTicket);
      //     debugger;
          
           
      //    debugger;
      //     console.log("selected ticket");
      //     console.log(selectedTicket)
      //     debugger;
      // }).catch(error => {
      // }).finally(e => {
      //     setLoading(false);
      // })
      memberService.getParticularTicketInfo(id).then(response => {
      // const ticketDetails = response.data;
      // if (ticketDetails) {
      //   setTicketDetails(ticketDetails);
      // }
      setTicketDetails(response.data)
      
      
      })
  }

   



    return (
      <>
      <HWMessage/>
      {console.log("tickets",tickets)}
      {showTicketDetail && <TicketDetailsModal  ticketId={ticketId} openPopup={showTicketDetail} closePopup={() => { setShowTicketDetails(false) }} 
                handleUser={handleUser}></TicketDetailsModal>}
        <div>
     {openPopUp  && <TicketPopup openPopup={openPopUp} refreshState={refreshState} setRefreshState={setRefreshState} ticket={data} closePopup={() => { setOpenPopUp(false) }}></TicketPopup>}
     
     {tickets.length!=0 && <Table bordered hover >
    <thead>
    <tr style= {{maxBlockSize:"20px;"}}>
      
      <th>{t("Ticket Id")}</th>
      <th>{t("Category")}</th>
      <th>{t("Subject")}</th>
      {/* <th >Details</th> */}
      <th>{t("Created On")}</th>
      
      <th>{t("Status")}</th>
      <th>{t("Comment by Yamaha")}</th>
      
    </tr>
  </thead>
     
  
  <tbody >
  {tickets
  //  .filter(function (ticket) {
  //        return ticket.membershipNumber=== userDetails.membershipNumber;
  //    })

    .map((ticket) =>
       
               <tr  style= {{maxBlockSize:"20px;"}} onClick={() => handleTicketDetails(ticket.id)}>
               <td ><u><Link>{ticket.id}</Link></u></td>
               <td >{truncate(ticket.category,25)}</td>
               <td >{truncate(ticket.subject,30)}</td>
               {/* <td >{truncate(ticket.description,15)}</td> */}
               <td >{new Date(ticket.createdAt).toLocaleDateString()}</td>
                                
               <td style={{color:getTicketStatusColor(ticket.status)}}>{t(getTicketStatus(ticket.status))}</td>
               <td >{truncate(ticket.comments,30)}</td>
               {/* <button type='button' >
               <td className="d-flex" style={{justifyContent: 'flex-end' , maxWidth:"60px", margin:"0px", backgroundColor:'white' , borderBlockColor:'grey', borderBlockWidth:'0px' }}><p style={{maxHeight:'10px'}}><i className="fas fa-trash-alt" style={{ color: "#138de8", marginTop:"5px", backgroundColor:'white'}} onClick={e=>{deleteTicket(ticket.id)}}/></p></td>
               </button> */}
               {console.log("asdasec")}
             </tr>
             
            )}
    
  
   
  </tbody> 
  </Table>}
  {tickets.length==0 && <p className="ticketTitle" style={{ display: 'flex',justifyContent: 'center'}}>{t("No Tickets Available")}</p>}

                                       

        </div>
        </>
    )
}

 function truncate(str,size) {
  if (str && str.length>size){
  return str.substr(0, size)+"...";
  }
  return str
}

export default MyTickets
