/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Spinner
} from "reactstrap";
import "../../views/LoginComponents/login.css"
import { Link } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import memberService from "../service/APIService"
import { ToastsStore } from "react-toasts";
import {t} from 'i18next';
const EditUsername = (props) => {
    const [isLoading, setIsLoading] = React.useState(false)
    const [data, setData] = React.useState(JSON.parse(sessionStorage.getItem('userDetails')))
    const [userId, setUserId] = React.useState(sessionStorage.getItem('userId'))
    const [error, setError] = React.useState("");
    const [nameError,setNameError] = React.useState();

    

    const handleChange = (e) => {
        let alphaNumericRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/;

        if (!alphaNumericRegex.test(e.target.value)) {
            setNameError("Please enter a valid name")
            
          }
          else{
            setNameError("")
          }
          setData({
            ...data,
            [e.target.name]: e.target.value,
        });


    };

    const editUsername = (e) => {
        setIsLoading(true)
        memberService.editUserName(userId, data).then((response) => {
            ToastsStore.success("Username set successfully");
        }).catch(error => {
            setError(error && error.response && error.response.data && error.response.data.message)
        }).finally(e => {
            setIsLoading(false)
            getMemberProfile()
        })
    }

    const getMemberProfile = (address) => {
        let userId = sessionStorage.getItem("userId")
        memberService.handleMemberProfile(userId).then(response => {
            sessionStorage.setItem('userDetails',JSON.stringify(response.data))
            localStorage.setItem('userDetails',JSON.stringify(response.data))
            setData(response.data)

        }).catch(error => { }).finally(() => {
        })
    }

    return (
        <>
            <Row style={{ height: '550px' }}>
                <Col xs="3"></Col>
                <Col xs="6">
                    <Card className="bg-white shadow module-border-wrap">
                        <CardBody className="px-lg-5 py-lg-5">
                            <Form role="form">
                                <FormGroup>
                                    <InputGroup className="input-group-alternative mb-3">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-single-02" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input name="username" style={{ paddingLeft: '10px' }} value={data.username}
                                            placeholder={t("Username")} type="text" onChange={handleChange}
                                        />
                                    </InputGroup>
                                </FormGroup>



                                <Row className="mt-3">
                                    <Col xs="12" className="text-center" >
                                        <small style={{ color: 'red' }}>{error}</small>
                                    </Col>
                                </Row>

                                <div className="text-center">

                                    {!isLoading && <Button className="mt-3" color="primary" type="button" onClick={editUsername} disabled={nameError!=""}>
                                        {t("Save")}
                                    </Button>}
                                    {isLoading && <Spinner style={{ marginTop: '10px' }} color="primary" />}
                                </div>
                            </Form>
                        </CardBody>
                    </Card>

                </Col>
                <Col xs="4"></Col>
            </Row>
        </>
    );
};

export default EditUsername;
