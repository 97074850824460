import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
} from "reactstrap";
// core components
import React, { useState, useEffect } from 'react';
import memberService from "../service/APIService";
import { ToastsStore } from "react-toasts";
import moment from "moment"
import forward from "assets/img/yamahaIcons/forward.svg"
import back from "assets/img/yamahaIcons/back.svg"
import firebase from "utils/firebase";
const NotificationDetail = () => {

    const [activeNotification, setActiveNotification] = useState({})
    const [activeNotificationText, setActiveNotificationText] = useState()
    const [notificationsData, setNotificationsData] = useState({})
    const [userId, setUserId] = useState(sessionStorage.getItem('userId'))
    const [nextId, setNextId] = React.useState(0)
    const [finalId, setFinalId] = React.useState(0)
    const [initialNotificationImage, setInitialNotificationImage] = React.useState()
    const [notificationImageUrls, setNotificationImageUrls] = useState([])
    useEffect(() => {
        fetchNotifications(null)
        firebase.logEventForAnalytics("Notifications")
    }, [])

    const fetchNotifications = (type) => {
        memberService.fetchMemberNotifications(userId).then(response => {
            let notReadNotificationsList = response && response.data && response.data.notifications && response.data.notifications.filter(aNotification=>
                aNotification.readStatus==='NotRead'
            )
            sessionStorage.setItem("notificationCount",notReadNotificationsList.length )
            setNotificationsData(response.data)
            if(type!=="saving")
            {setActiveNotification(response && response.data && response.data.notifications && response.data.notifications[0])
            setActiveNotificationText(response && response.data && response.data.notifications && response.data.notifications[0] && response.data.notifications[0].notificationToUserId)
            handleNotificationDetails(response && response.data && response.data.notifications && response.data.notifications[0],"initial")
}

        }).catch(error => {
            // setError(error && error.response && error.response.data && error.response.data.message)
        }).finally(e => {

        })
    }
    const handleNotificationDetails = (notification,state) => {
        setActiveNotification(notification)
        setActiveNotificationText(notification.notificationToUserId)
        setNotificationImageUrls(notification.imageGallery)
        setInitialNotificationImage(notification && notification.imageUrl)
        setFinalId(notification && notification.imageGallery && notification.imageGallery.length)

        if (notification.readStatus !== 'Read') {
            memberService.markNotificationsAsRead(userId, notification.notificationToUserId).then(response => {
                ToastsStore.success("Notification marked as read")
                fetchNotifications("saving")

            }).catch(err => {}).finally(() =>{
            })
        }
    }
    const getFormattedNotificationDate = (transactionTime) => {
        let newTransactionTime = moment(transactionTime).format('DD MMM')
        return newTransactionTime;
    }

    const handlePrev = () => {
        if (nextId !== 0) {
            setNextId(nextId - 1)
            let initialNotificationImage = notificationImageUrls[nextId - 1]
            setInitialNotificationImage(initialNotificationImage)
        }

    }
    const handleNext = () => {
        if (nextId < (finalId - 1)) {
            setNextId(nextId + 1)
            let initialNotificationImage = notificationImageUrls[nextId + 1]
            setInitialNotificationImage(initialNotificationImage)
        }
    }


    return (
        <>
            < >
                <Row>
                    <Col className="order-xl-1" xl="12">
                        <Card className="bg-white shadow" style={{ height: '94vh', overflow: 'auto' }}>
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="12">

                                        <Row className="mt-3">
                                            <Col xs={6}>
                                                <Card className="mt-3" style={{ height: '610px' }} >
                                                    <CardHeader className="d-flex" style={{ justifyContent: 'space-between', height: '60px' }}>
                                                        <p style={{ color: '#373A3C', fontSize: '16px', fontWeight: '600' }}>Notification Summary</p>

                                                    </CardHeader>
                                                    <CardBody className="mt--3" style={{ overflow: 'auto', maxHeight: '700px' }}>
                                                        {notificationsData && notificationsData.notifications && notificationsData.notifications.length === 0 &&
                                                            <p className='waterMark'>No notifications available</p>}
                                                        {notificationsData && notificationsData.notifications && notificationsData.notifications.length > 0 && notificationsData.notifications.map((aNotification, key) => {
                                                            return (
                                                                <Row key={key} style={{
                                                                    width: '100%', borderBottom: '1px solid #dddd', cursor: 'pointer',
                                                                    backgroundColor: aNotification && aNotification.notificationToUserId === activeNotificationText ? '#dddddd33' : ''
                                                                }} onClick={() => handleNotificationDetails(aNotification)}>
                                                                    <Col xs={2} className="d-flex">
                                                                        <Button style={{ border: 'none', width: '100px', height: '53px', fontSize: '13px', fontWeight: '600' }} color="primary" type="button">
                                                                            {getFormattedNotificationDate(aNotification.sentAtTime)}
                                                                        </Button>
                                                                    </Col>
                                                                    <Col xs={10} className=" d-flex notificationText">
                                                                    <p className="notificationText"
                                                                    style={{color: aNotification && aNotification.readStatus != 'Read' ? '#373A3C' : '#969292',
                                                                    fontWeight: (aNotification && aNotification.notificationToUserId === activeNotificationText || aNotification && aNotification.readStatus !== 'Read') ? '600' : '', }}>{aNotification.detail}</p>

                                                                        {/* <p style={{
                                                                        color: aNotification && aNotification.readStatus !== 'Read' || aNotification && aNotification.notificationToUserId === activeNotificationText ? '#373A3C' : '#969292',

                                                                        fontWeight: (aNotification && aNotification.notificationToUserId === activeNotificationText || aNotification && aNotification.readStatus !== 'Read') ? '600' : '', 
                                                                    }}>{aNotification && aNotification.detail} </p> */}
                                                                        <p style={{ color: '#373A3C', fontSize: '14px', marginLeft: '5px', paddingBottom: '10px', paddingTop: '12px' }}>   </p></Col>
                                                                </Row>

                                                            )
                                                        })}


                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col xs={6}>
                                                <Card className="mt-3" style={{ height: '610px' }}>
                                                    <CardHeader className="d-flex" style={{ justifyContent: 'space-between', height: '60px' }}>
                                                        <p style={{ color: '#373A3C', fontSize: '16px', fontWeight: '600' }}> Notifications Detail</p>

                                                    </CardHeader>
                                                    {activeNotification && activeNotification.notificationToUserId != null && <CardBody>
                                                        {/* <Row>
                                                                    <Col xs={12} className="d-flex align-items-center" style={{ justifyContent: 'center' }}>
                                                                        <p style={{ color: '#373A3C', fontSize: '16px' }}>{activeNotification.title}</p>
                                                                    </Col>
                                                                </Row> */}
                                                        {initialNotificationImage && <Row style={{ width: '100%' }}>
                                                            <div className="wrapper" style={{ cursor: 'pointer' }} >

                                                                <img src={initialNotificationImage} alt="" height="210" width="515" />
                                                                {nextId!==0 && <img src={back} className=" glyphiconNotification fas fa-arrow-circle-left" onClick={handlePrev} alt="" />}
                                                                {nextId < (finalId - 1) && <img src={forward} style={{ marginLeft: '450px' }} className=" glyphiconNotification fas fa-arrow-circle-right" onClick={handleNext} />}
                                                            </div>
                                                        </Row>}
                                                        <Row>
                                                            <Col xs={12}>
                                                                <p style={{ color: '#373A3C', fontSize: '16px' }}>{activeNotification.detail}</p>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>}
                                                </Card>
                                            </Col>


                                        </Row>


                                    </Col>

                                </Row>
                            </CardHeader>

                        </Card>
                    </Col>
                </Row>
            </>
        </>
    );
};

export default NotificationDetail;
