
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Row,
    Col,Spinner
} from "reactstrap";
// core components
import React, { useState, useEffect } from 'react';
import memberService from "../service/APIService";
import calendar from "assets/img/yamahaIcons/calendar.png";
import mapSample from "assets/img/yamahaIcons/mapSample.jpeg"
import moment from "moment"
import forward from "assets/img/yamahaIcons/forward.svg"
import back from "assets/img/yamahaIcons/back.svg"
import { Wrapper} from "@googlemaps/react-wrapper";
import firebase from "utils/firebase";
const EventDetails = (props) => {
    const [eventDetails, setEventDetails] = useState(props && props.location && props.location.state &&
        props.location.state.initialCampaign && props.location.state.initialCampaign)

    const [specificEventDetails, setSpecificEventDetails] = useState({})
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [startTime, setStartTime] = useState()
    const [endTime, setEndTime] = useState()
    const [nextId, setNextId] = React.useState(1)
    const [initialContentImage, setInitialContentImage] = React.useState()
    const [contentImageUrls, setContentImageUrls] = useState([])
    const [finalImageId, setFinalImageId] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [latitude, setLatitude] =useState()
    const [longitude, setLongitude] =useState()
    const [detailedAddress,setDetailedAddress] =useState()

    // function initMap() {
    //     // The location of Uluru
    //     const uluru = { lat: -25.344, lng: 131.031 };
    //     // The map, centered at Uluru
    //     const map = new google.maps.Map(document.getElementById("map"), {
    //       zoom: 4,
    //       center: uluru,
    //     });
    //     // The marker, positioned at Uluru
    //     const marker = new google.maps.Marker({
    //       position: uluru,
    //       map: map,
    //     });
    //   }
      
    //   window.initMap = initMap;

   
      
    //   <Wrapper apiKey={"YOUR_API_KEY"} render={render}>
    //     <YourComponent/>
    //   </Wrapper>

    // const ref = React.useRef(null);
    // const [map, setMap] = React.useState();

    // React.useEffect(() => {
    //   if (ref.current && !map) {
    //     setMap(new window.google.maps.Map(ref.current, {}));
    //   }
    // }, [ref, map]);

    useEffect(() => {
        fetchSpecificCampaigns()
        firebase.logEventForAnalytics("Campaign Details")
    }, [])

    const fetchSpecificCampaigns = () => {
        setIsLoading(true)
        memberService.getSpecificCampaigns(eventDetails.id).then(response => {
            console.log(response.data)
            setSpecificEventDetails(response.data)
            if(response.data.eventCoordinates){
            setLatitude(response.data.eventCoordinates.latitude)
            setLongitude(response.data.eventCoordinates.longitude)
            setDetailedAddress(response.data.eventCoordinates.detailedAddress)
            }
            setContentImageUrls(response.data.contentImageUrls)
            setInitialContentImage(response.data.contentImageUrls && response.data.contentImageUrls[0])
            setFinalImageId(response.data.contentImageUrls.length)
            const startDate = moment.utc(eventDetails.eventSchedule && eventDetails.eventSchedule.eventStartTime).format('Do MMM YYYY, h:mm:ss a').split(",")[0]
            const endDate = moment.utc(eventDetails.eventSchedule && eventDetails.eventSchedule.eventEndTime).format('Do MMM YYYY, h:mm:ss a').split(",")[0]
            setStartDate(startDate)
            setEndDate(endDate)
            const startTime = moment.utc(eventDetails.eventSchedule && eventDetails.eventSchedule.eventStartTime).format('LT')
            const endTime = moment.utc(eventDetails.eventSchedule && eventDetails.eventSchedule.eventEndTime).format('LT')
            setStartTime(startTime)
            setEndTime(endTime)
        }).catch(error => {}).finally(() =>{
            setIsLoading(false)
        })
    }

    const handlePrev = () => {
        if (nextId !== 1) {
            setNextId(nextId - 1)
            let initialContentImage = contentImageUrls[nextId - 2]
            setInitialContentImage(initialContentImage)
        }

    }
    const handleNext = () => {
        if (nextId < (contentImageUrls.length)) {
            setNextId(nextId + 1)
            let initialContentImage = contentImageUrls[nextId]
            setInitialContentImage(initialContentImage)
        }
    }

    console.log(contentImageUrls , initialContentImage , nextId);

    return (
        <>
            {/* Page content */}
            < >
                <Row >
                    <Col className="order-xl-1" xl="12">
                        <Card className="bg-white shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center mt-3">
                                {isLoading && 
                                <Col xs="12">
                                <Card className="mt-8" style={{margin:'0 40%'}}><Spinner color="primary" /></Card></Col>}
                                   {!isLoading && <Col xs="12">
                                        <Card className="mt-3">
                                            <CardBody>
                                                <p>{specificEventDetails && specificEventDetails.title}</p>
                                                <Row style={{ width: '100%' }}>
                                                    <div className="wrapper" style={{ cursor: 'pointer' }} >

                                                        <img src={initialContentImage} alt="" height="400" width="1100" />
                                                        {/* <div class="mapouter">
                                                            <div class="gmap_canvas">
                                                                <iframe class="gmap_iframe" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=HummingWave Technologies&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                                                                <a href="https://formatjson.org/">format json</a>
                                                            </div>
                                                              </div> */}
                                                        {nextId !== 1 && <img src={back} className=" glyphiconInside fas fa-arrow-circle-left" onClick={handlePrev} alt="" />}
                                                        {nextId < (contentImageUrls.length) && <img src={forward} style={{ marginLeft: '1010px' }} className=" glyphiconInside fas fa-arrow-circle-right" onClick={handleNext} />}

                                                    </div>

                                                </Row>
                                                {/* <hr /> */}
                                                <p>{specificEventDetails && specificEventDetails.content}</p>
                                                {/* <hr /> */}
                                                <Row><Col xs={12} style={{ display: 'flex', justifyContent: 'center' }}>  {specificEventDetails && specificEventDetails.link &&
                                                    <a href={specificEventDetails.link} target="_blank"><Button className="buttonImageName" color="primary" type="button" >
                                                        {specificEventDetails.linkName}
                                                    </Button>
                                                    </a>}</Col></Row>

                                            </CardBody>
                                            {/* <CardFooter> */}
                                            {eventDetails.eventSchedule && eventDetails.eventSchedule.address && <Row className="align-items-center mt-3 pb-5">
                                                <Col xs="12">
                                                    <>
                                                        <p style={{ margin: '12px' }}> Event Details</p>
                                                        <Row>
                                                            <Col xs={12}>

                                                                <Row>
                                                                    <Col xs={2} className="d-flex mt-2" style={{ justifyContent: 'flex-end' ,cursor: 'pointer'}}>
                                                                        <a href={"http://maps.google.co.uk/maps?q=" + latitude + ","+ longitude } target="_blank"><img src={mapSample} height="70px" width="70px" alt="" />
                                                                        </a>
                                                                    </Col>
                                                                    <Col xs={10}>
                                                                        <p>{detailedAddress}</p>

                                                                    </Col>
                                                                </Row>

                                                                {eventDetails.eventSchedule && eventDetails.eventSchedule.eventStartTime && eventDetails.eventSchedule.eventEndTime &&
                                                                    <Row className="mt--10">
                                                                        <Col xs={1}></Col>
                                                                        <Col xs={5}>
                                                                            {/* <Card style={{ padding: '24px' }}>                                                                        */}
                                                                            <div className="d-flex">
                                                                                <img src={calendar} alt="" width="20" height="20" style={{ marginRight: '15px', marginTop: '5px' }} />
                                                                                <p>{startDate}</p>
                                                                                <p style={{ margin: '0px 10px' }}>To</p>

                                                                                <img src={calendar} alt="" width="20" height="20" style={{ marginRight: '15px', marginTop: '5px' }} />
                                                                                <p>{endDate}</p>
                                                                            </div>
                                                                            {/* </Card> */}

                                                                        </Col>

                                                                    </Row>}
                                                                {eventDetails.eventSchedule && eventDetails.eventSchedule.eventStartTime && eventDetails.eventSchedule.eventEndTime && <Row>
                                                                    <Col xs={1}></Col>

                                                                    <Col xs={5}>
                                                                        {/* <Card>
                                                                <CardBody style={{ display: 'flex', justifyContent: 'center' }}>
                                                                    */}
                                                                        <div className="d-flex">

                                                                            <i className="far fa-clock" style={{ marginTop: '10px', marginRight: '10px', fontSize: '20px' }}></i>
                                                                            <Card className="timerCard"> <p className="timerText">{startTime && startTime.split(":")[0]}</p></Card>
                                                                            {startTime && startTime.includes('AM') && <Card className="timerCard">   <p className="timerText">{startTime && startTime.split(":")[1].split("AM")[0]}</p></Card>}
                                                                            {startTime && startTime.includes('PM') && <Card className="timerCard">   <p className="timerText">{startTime && startTime.split(":")[1].split("PM")[0]}</p></Card>}
                                                                            {startTime && startTime.includes('PM') && <Card className="timerCard timerType">   <p className="timerText timerType">{startTime && startTime.split(":")[1].split(" ")[1]}</p></Card>}
                                                                            {startTime && startTime.includes('AM') && <Card className="timerCard timerType">   <p className="timerText timerType">{startTime && startTime.split(":")[1].split(" ")[1]}</p></Card>}
                                                                            <p style={{ margin: '5px 10px' }}>To</p>


                                                                            <i className="far fa-clock" style={{ marginTop: '10px', marginRight: '10px', fontSize: '20px' }}></i>
                                                                            <Card className="timerCard"> <p className="timerText">{endTime && endTime.split(":")[0]}</p></Card>
                                                                            {endTime && endTime.includes('AM') && <Card className="timerCard">   <p className="timerText">{endTime && endTime.split(":")[1].split("AM")[0]}</p></Card>}
                                                                            {endTime && endTime.includes('PM') && <Card className="timerCard">   <p className="timerText">{endTime && endTime.split(":")[1].split("PM")[0]}</p></Card>}
                                                                            {endTime && endTime.includes('PM') && <Card className="timerCard timerType">   <p className="timerText timerType">{endTime && endTime.split(":")[1].split(" ")[1]}</p></Card>}
                                                                            {endTime && endTime.includes('AM') && <Card className="timerCard timerType">   <p className="timerText timerType">{endTime && endTime.split(":")[1].split(" ")[1]}</p></Card>}
                                                                        </div>

                                                                        {/* </CardBody>
                                                                        </Card> */}
                                                                    </Col>
                                                                </Row>}


                                                            </Col>
                                                        </Row>

                                                    </>
                                                </Col>
                                            </Row>}




                                            {/* </CardFooter> */}
                                        </Card>
                                    </Col>}

                                </Row>

                            </CardHeader>

                        </Card>
                    </Col>
                </Row>
            </>
        </>
    );
};

export default EventDetails;
