
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroup,
    Row,
    Col,
    Spinner
} from "reactstrap";
import "../../views/LoginComponents/login.css"
import { Redirect, Link } from "react-router-dom";
import React, { useEffect } from 'react';
import memberService from "../service/APIService"
import ProvideMoreDetails from "views/Settings/BikeDetails/ProvideMoreDetails";
import { useHistory } from "react-router";
import validator from 'validator';
import firebase from "utils/firebase";
import { useTranslation } from "react-i18next";

function VehicleJPJDetailsScreen(props) {
    const [data, setData] = React.useState({});
    const [error, setError] = React.useState("");
    const [plateNumberError, setPlateNumberError] = React.useState('');
    const [icNumberError, setICNumberError] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false)
    const [nextScreen, setNextScreen] = React.useState(false)
    const [profileScreen, setProfileScreen] = React.useState(false)
    const [userDetails, setUserDetails] = React.useState(JSON.parse(sessionStorage.getItem('userDetails')))
    const [otherScreen, setOtherScreen] = React.useState(false)
    const [addMoreDetails, setAddMoreDetails] = React.useState(false)
    const [signupType, setSignupType] = React.useState(props && props.location && props.location.state && props.location.state.signupType)


    const history = useHistory();

    // useEffect(()=>{
    //     console.log("Delete me", JSON.parse(sessionStorage.getItem("userDetails")).bikes);
    //     const {bikes,...restDetails} = {...userDetails};
    //     console.log("rest Details",restDetails);
    //     console.log("bikes",bikes);
    //     // sessionStorage.removeItem("userDetails");
    //     sessionStorage.setItem("userDetails",JSON.stringify(restDetails));
    //     console.log("new user details",sessionStorage.getItem("userDetails"))
    //   })


    useEffect(() => {

        let bikeDetails = userDetails && userDetails.bikes && userDetails.bikes[0]
        console.log("check bike details",bikeDetails)
        setData({
            ...data,
            ["icNumber"]: bikeDetails && bikeDetails.icNumber,
            ["id"]: bikeDetails && bikeDetails.id,
            ["plateNumber"]: bikeDetails && bikeDetails.plateNumber,
            ["status"]: bikeDetails && bikeDetails.status,
            ["userName"]: userDetails && userDetails.userName,
            ["vocVerificationMode"]: bikeDetails && bikeDetails.vocVerificationMode,

        })
    }, [])

    const { t } = useTranslation();

    const handleChange = (e) => {
        setError("")
        setPlateNumberError("")
        setICNumberError("")
        console.log("vehicleDetailscreenline67",e);
        if (e.target.name === 'plateNumber')
            setPlateNumberError("")
        if (e.target.name === 'icNumber')
            setICNumberError("")
        setData({
            ...data,
            [e.target.name]: e.target.value.toUpperCase(),
            [e.target.name + "_error"]: null,
        });
    }


    const removeAll = () => {
        console.log("code came here1");
        setIsLoading(true);
        // memberService.handlContinueAsFan().then(response => {
        // }).catch(error => {
        //     setError(error && error.response && error.response.data && error.response.data.message)
        // }).finally(e => {
            let request = {
                "icNumber": data.icNumber,
                "plateNumber": data.plateNumber
            }
            memberService.handleVehicleJPJInfo(request).then((response) => {
                if (response.data.status == "Verified") {
                    handleMemberProfile()
                    setOtherScreen(false)
                    // setIsLoading(false)

                }
                else {
                    console.log("respinse",response)
                    sessionStorage.setItem("BikeId",response.data.id)
                    setIsLoading(false)
                    setAddMoreDetails(true)
                    // setOtherScreen(true)
                }
            }).catch(error => {
                if (error && error.response && error.response.data && error.response.data.message 
                    && error.response.data.message == "Not verified"){
                        setIsLoading(false)
                        setAddMoreDetails(true)
                }
                
            })
           // setIsLoading(false);
        // })
    }
    

    
    const handleSaveVehicleJPJInfo = () => {
        
        console.log("userDetails vehcileDetailscreenline110",userDetails)
        //"userDetails.bikes",userDetails.bikes,"userDetails.bikes[0]",userDetails.bikes[0],"userDetails.bikes[0].icNumber",userDetails.bikes[0].icNumber,"data.icNumber",data.icNumber,"userDetails.bikes[0].plateNumber",userDetails.bikes[0].plateNumber,"data.plateNumber",data.plateNumber,"data",data);

        if (userDetails && userDetails.bikes && userDetails.bikes[0] && userDetails.bikes[0].icNumber === data.icNumber && userDetails.bikes[0].plateNumber === data.plateNumber 
            && userDetails.bikes[0].status === 'Verified') {
                console.log("line 114 vehiclejpjscreen");
            
            // setOtherScreen(true)
          //  setIsLoading(false)
            setAddMoreDetails(false)
            setProfileScreen(true)
        }
        else if(userDetails && userDetails.bikes && userDetails.bikes[0] && userDetails.bikes[0].icNumber === data.icNumber && userDetails.bikes[0].plateNumber === data.plateNumber 
            && userDetails.bikes[0].status !== 'Verified'){
         //   setIsLoading(false)
         removeAll();
            console.log("line 122 vehiclejpjscreen");
            setAddMoreDetails(true)
        }
        else {
            if (handleError()) {
                console.log("line 129 vehiclejpjscreen");
                // setIsLoading(true)
               
                removeAll();
                

            }
        }
    }

    const handleDetailScreen = () => {
        setAddMoreDetails(false)
        setProfileScreen(true)
    }

    const handleMemberProfile = () => {
        let userId = sessionStorage.getItem("userId")
        memberService.handleMemberProfile(userId).then(response => {
            sessionStorage.setItem("userDetails", JSON.stringify(response.data))
            localStorage.setItem("userDetails",JSON.stringify(response.data))
            setProfileScreen(true)
        }).catch(error => {
            setError(error && error.response && error.response.data && error.response.data.message)
        }).finally(e => {
        })
    }

    
    const handlContinueAsFan = () => {
        setIsLoading(true);
        firebase.logButtonClicked("Continue as Fan")
        memberService.handlContinueAsFan().then(response => {
            sessionStorage.setItem("userDetails", JSON.stringify(response.data))
            localStorage.setItem("userDetails",JSON.stringify(response.data))
        }).catch(error => {
            setError(error && error.response && error.response.data && error.response.data.message)
        }).finally(e => {
            setIsLoading(false);
          //  <Redirect to={{ pathname: '/auth/profile', state:  }} />
            history.push({pathname : "/auth/profile", state : { isLoginAsFan: 'loginAsFan', signupType: signupType }});
        })
    }


    const handleError = () => {
        var temp = data.plateNumber;
        // /^(\+?6?01)[0|1|2|3|4|6|7|8|9]\-*[0-9]{7,9}$/
        let alphaNumericRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/;
        let alphaNumericRegexForICNumber = /^[A-Za-z0-9-]+$/;
        if (!alphaNumericRegex.test(temp)) {
            setPlateNumberError("Bike plate number should be between 2 to 16 alphanumeric characters and should not contain any special characters or space")
            return false
        }
        // if (data.plateNumber != undefined && data.plateNumber != "") {
        //     var count = (temp.match(/-/g) || []).length;
        //     console.log(count);
        //     if (count > 1 ) {
        //         setPlateNumberError("Plate number should be between 6 to 8 characters")
        //         return false
        //     }
        // }
        if (data.plateNumber == undefined || data.plateNumber == "") {
            setPlateNumberError("Plate enter plate number")
            return false
        }
        if (data.plateNumber && (data.plateNumber.length > 16)) {
            setPlateNumberError("Bike plate number should be between 2 to 16 alphanumeric characters and should not contain any special characters or space")
            return false
        }

        if ((data.icNumber && ( data.icNumber.length > 35)) || (!alphaNumericRegexForICNumber.test(data.icNumber))) {
            setICNumberError("IC number should be at most 35 characters and should not contain any special characters or space")
            return false
        }

        if (data.icNumber == undefined || data.icNumber == "") {
            setICNumberError("Please enter IC number")
            return false
        }
        if (data.icNumber && (data.icNumber.length > 35)) {
            setICNumberError("IC number should be at most 35 characters and should not contain any special characters or space")
            return false
        }
        return true
    }
    return (
        <>
            {otherScreen && <Redirect to={{ pathname: '/auth/otherDetails', state: { signupType: signupType , icNumber: data.icNumber , plateNumber: data.plateNumber  } }} />}
            {profileScreen && <Redirect to={{ pathname: '/auth/profile', state: { signupType: signupType } }} />}
            {addMoreDetails && <ProvideMoreDetails signupType={signupType} icNumber={data.icNumber} plateNumber={data.plateNumber} openPopup={addMoreDetails} closePopup={() => { setAddMoreDetails(false) }}
                parentCallback={handleDetailScreen}></ProvideMoreDetails>}
            <Col lg="5" md="7" className="pb-8 mt-8">
                <Card className="bg-secondary shadow module-border-wrap">
                    <span className="login_title">{t("Are you a Yamaha bike owner?")}</span>
                    <CardBody className="px-lg-5 py-lg-5">
                        <Form role="form">
                            <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                    <Input
                                        name="plateNumber"
                                        placeholder={t("Plate number")}
                                        value={data && data.plateNumber}
                                        autoComplete="new-plateNumber"
                                        onChange={handleChange}
                                       
                                        style={{ textTransform: "uppercase" }}
                                        maxLength='16'
                                    />
                                </InputGroup>
                                {plateNumberError && <small style={{ color: 'red' }}>{t(plateNumberError)}</small>}
                            </FormGroup>
                            <FormGroup>
                                <InputGroup className="input-group-alternative">
                                    <Input
                                        name="icNumber"
                                        value={data && data.icNumber}
                                        placeholder={t("IC number")}
                                        autoComplete="new-icNumber"
                                        onChange={handleChange}
                                        style={{ textTransform: "uppercase" }}
                                        maxLength='35'
                                        type="text"
                                    />
                                </InputGroup>
                                {icNumberError && <small style={{ color: 'red' }}>{t(icNumberError)}</small>}
                            </FormGroup>
                            <Row className="mt-3">
                                <Col xs="12" className="text-center" >
                                    <small style={{ color: 'red' }}>{error}</small>
                                </Col>
                            </Row>
                            <div className="text-center">
                                <Link to={{ pathname: '/auth/login', state: props && props.location && props.location.state }} style={{ marginRight: '15px' }}>
                                    <Button className="mt-3" color="primary" type="button">
                                        {t("Back")}
                                    </Button>
                                </Link>
                                {!isLoading && !addMoreDetails && 
                                    <Button className="mt-3" color="primary" type="button" onClick={handleSaveVehicleJPJInfo}>
                                        {t("Proceed")}
                                    </Button>}
                                {isLoading && !addMoreDetails && <Spinner style={{ marginTop: '10px' }} color="primary" />}

                            </div>

                            <Row className="mt-3">
                                <Col xs="12">
                                    <span style={{ display: 'flex', justifyContent: 'center' }}>{t("OR")}</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12">
                                        <Button className="mt-3" color="primary" type="button" style={{ width: '100%' }} onClick={e=>handlContinueAsFan()}>
                                        {t("I do not have Yamaha Motorcycle")}
                                        </Button>
                                    {/* <Button className="my-4" color="primary" type="button" style={{ width: '100%' }}>
                                        Continue as a fan
                                    </Button> */}
                                </Col>
                            </Row>
                        </Form>
                    </CardBody>
                </Card>
                {/*       
          */}
            </Col>
        </>
    );
};

export default VehicleJPJDetailsScreen;
