/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import "../../views/LoginComponents/login.css"
import { Redirect, Link } from "react-router-dom";
import React, { useState } from 'react';
import memberService from "../service/APIService"
import DatePicker from "react-datepicker";
import { ToastsStore } from "react-toasts";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import "react-datepicker/dist/react-datepicker.css";
import { useEffect } from "react";
import { sha512 } from "js-sha512";
import firebase from "utils/firebase";
import { useTranslation } from "react-i18next";


const MemberProfile = (props) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [error, setError] = React.useState({})
  const [isLogin, setIsLogin] = useState(false);
  const [dropdownOpen, setOpen] = useState(false);
  const [showOthersField, setShowOthersField] = useState(false);
  const [startDate, setStartDate] = useState();
  const [genderList, setGenderList] = useState([{ value: 'Male' }, { value: 'Female' }]);
  const [addressTypeList, setAddressTypeList] = useState([{ value: 'Home' }, { value: 'Work' }, { value: 'Others' }]);
  const [showProfilePicture, setShowProfilePicture] = useState(false);
  const [redirectToOTPScreen, setRedirectToOTPScreen] = useState(false);
  const [hasBikeInitialDetail, setHasBikeInitialDetail] = useState(false);
  const [hasBikeAllDetail, setHasBikeAllDetail] = useState(false);
  let user = JSON.parse(sessionStorage.getItem('userDetails'))
  const [userDetails, setUserDetails] = React.useState(JSON.parse(sessionStorage.getItem('userDetails')))
  const [userId, setUserId] = React.useState(sessionStorage.getItem('userId'))
  const [data, setData] = React.useState(userDetails != null ? userDetails : {});
  const [apiError, setAPIError] = React.useState("")
  const [fileName, setFileName] = React.useState("")
  const [isDetailEditable, setDetailEditable] = React.useState(true)
  const [isLoginAsFan, setLoginAsFan] = React.useState(props && props.location && props.location.state && props.location.state.isLoginAsFan && props.location.state.isLoginAsFan === "loginAsFan" ? true : false)
  const [signupType, setSignupType] = useState(props && props.location && props.location.state && props.location.state.signupType)
  const assetBaseUrl = process.env.REACT_APP_ENV === "PROD" ? process.env.REACT_APP_ASSET_BASE_URL_PROD :  process.env.REACT_APP_ENV === "DEV" ? process.env.REACT_APP_ASSET_BASE_URL :  process.env.REACT_APP_ASSET_BASE_STAGE;

  const [otp , setOTP ] =useState(null);


  useEffect(() => {
    if (signupType == undefined) {
      if (userDetails.credentialType === "Username") {
        setSignupType("Username")
      }
      else { setSignupType("Mobile") }
    }
    handleInitialMemberProfile()
    sessionStorage.setItem("isFromSignupPageFlow", true);
    firebase.logEventForAnalytics("Profile Setup")
  }, [])

  const { t } = useTranslation();

  const handleChange = (e) => {
    setAPIError("")
    setError({
    })
    setError({
      ...error,
      ["mobileNumber"]:error.mobileNumber
    })

    let phoneRegex = /^(\+?6?01)[0|1|2|3|4|6|7|8|9]\-*[0-9]{7,9}$/
    if (e.target.name === "mobileNumber") {
      if (!phoneRegex.test("+60" + e.target.value)) {
        setError({
          ...error,
          ["mobileNumber"]: "Please enter mobile number",
        });
      }
      else {
        setError({
          ...error,
          ["mobileNumber"]: null,
        });
      }
    }
    setData({
      ...data,
      [e.target.name]: e.target.value,
      // [e.target.name]: null,
    });

  };

  const handlePostalCode = () => {
    getStateAndCity()
  }
  const getStateAndCity = () => {
    setIsLoading(true)
    memberService.getStateAndCity(data.postCode).then(response => {
      setData({
        ...data,
        ["city"]: response.data.city,
        ["locationState"]: response.data.state,
      });
      setError({
      })
    }).catch(error => {
      setData({
        ...data,
        ["city"]: "",
        ["locationState"]: "",
      });
      setError({
        ...error,
        ["postCode"]: error.response.data.message,
      });
    }).finally(e => {
      setIsLoading(false)
    })
  }

  const handleAddressType = (event, value) => {
    //  setAddressType(value);
    setError({
    })

    setData({
      ...data,
      ["addressType"]: value.value
    })
    if (value.value == "Others") { setShowOthersField(true) }
    else { setShowOthersField(false) }
  }
  const handleGenderChange = (event, value) => {
    setError({
    })
    setData({
      ...data,
      ["gender"]: value
    })
  }


  const onFileChange = (event) => {
    // setFileName("")
    if (event.target.files && event.target.files.length) {
      let fileList = event.target.files;
      let filepush = []
      filepush.push(fileList[0])
      setShowProfilePicture(true)
      uploadFile(event.target.files)
    }

  }


  const uploadFile = (files) => {
    let fileName = files[0].name.split(".")
    let actualFileName = sessionStorage.getItem("userId") + fileName[0]
    let extension = fileName[1]
    setFileName(actualFileName)
    memberService.fetchs3PresignedURlForProfile(actualFileName).then(res => {
      if (res) {
        memberService.uploadToS3(res, files[0]).then(res => {
          updateMemberProfileDetails(actualFileName)

        })
      }
    })
  }

  const handleBack = () => {
    console.log(userDetails)
    let data = {
        "icNumber": props.location.state.icNumber,
        "plateNumber": props.location.state.plateNumber
    }
    memberService.deleteVehicle(data).then(response => {
    }).catch(error => {
        setError(error && error.response && error.response.data && error.response.data.message)
    }).finally(e => {
    })
  }

  const updateMemberProfileDetails = (actualFileName) => {
    // 9111251138
    let request = data
    request.profileImageName = actualFileName ? actualFileName : userDetails.profileImageName
    memberService.updateMemberProfileDetails(request, userId).then(response => {
      sessionStorage.setItem("userDetails", JSON.stringify(response.data))
      localStorage.setItem("userDetails", JSON.stringify(response.data))
      setData(userDetails != null ? userDetails : {})
      setUserDetails(response.data)
    }).catch(error => {
      setError(error && error.response && error.response.data && error.response.data.message)
    })
  }

  const handleSaveProfile = (e) => {
    let addressType;
    let month = startDate.getMonth() + 1;
    if (month.toString().length < 2) {
      month = "0" + month.toString();
    }
    let date = startDate.getDate();
    if (date.toString().length < 2) {
      date = "0" + date.toString();
    }
    let dateOfBirth = startDate.getFullYear() + "-" + month + "-" + date
    if (handleError() && error.mobileNumber==null) {
      if (data.addressType === 'Others') {
        addressType = data.saveAddressAs
      }
      else {
        addressType = data.addressType
      }
       setIsLoading(true)
      setRedirectToOTPScreen(false)
      let request = {
        "name": data.name,
        "gender": data.gender.value || data.gender,
        "dateOfBirth": dateOfBirth,
        "email": data.email,
        "profileImageName": data.profileImageName,
        "mobile": {
          "countryCode": user && user.mobile && user.mobile.countryCode || "+60",
          "mobileNumber": user && user.mobile && user.mobile.mobileNumber || data.mobileNumber
        },
        "addresses": [{
          "addressLine1": data.addressLine1,
          "addressLine2": data.addressLine2,
          "postCode": data.postCode,
          "city": data.city,
          "state": data.locationState,
          "addressType": addressType,
          "defaultStatus": "DefaultAddress"
        }]
      }
      memberService.updateMemberProfileDetails(request, userId).then(response => {
        //  ToastsStore.success("Profile data saved successfully.");
        // window.location.reload()
        // if (userDetails && userDetails.signupType && userDetails.signupType ==="Mobile"){
          if (signupType ==="Mobile"){
          handleMemberProfile()
        }
        else{
          setRedirectToOTPScreen(true)
        }
      }).catch(error => {
        setAPIError(error && error.response && error.response.data && error.response.data.message)
        setIsLoading(false)
      }).finally(e => { 
      })

    }
    else {
      return
    }
  }
  const handleVerifyOTP = () => {
    let request = {
      "emailOrMobile": data.email,
      "verificationType": "EmailAddress",
      "otp": otp,
   //   "bypassCode": "123456"
    }
    memberService.getEmailVerificationLink(userId, request).then(response => {
      ToastsStore.success("Email verification link sent successfully")
      if (userDetails && userDetails.mobile != null && userDetails.mobile.mobileNumber != null) {
        createDefaultCard()
      }
      else if (userDetails && userDetails.mobile && userDetails.mobile.mobileNumber == null) {
        sendOtp()

      }
    })
  }
  const createDefaultCard = () => {
    setIsLoading(true)
    memberService.createDefaultCard(userId).then(response => {
      ToastsStore.success("Profile data saved successfully.");
      handleMemberProfile()
      sessionStorage.setItem("profileSetupStatus", "Completed")
      // 

      // 
      setAPIError(error && error.response && error.response.data && error.response.data.message)
    }).finally(e => {
      setIsLoading(false)
    })
  }

  const handleInitialMemberProfile = () => {
    let userId = sessionStorage.getItem("userId")
    memberService.handleMemberProfile(userId).then(response => {
      sessionStorage.setItem("userDetails", JSON.stringify(response.data))
      localStorage.setItem("userDetails",JSON.stringify(response.data))
      userDetails && userDetails.dateOfBirth != null ? setStartDate(new Date(userDetails && userDetails.dateOfBirth)) : setStartDate(new Date(new Date().setFullYear(new Date().getFullYear() - 16)))
      if (response.data && response.data.bikes && response.data.bikes[0] && response.data.bikes[0].icNumber != null && response.data.bikes[0].plateNumber != null
        && response.data.bikes[0].engineNumber != null && response.data.bikes[0].chassisNumber != null && response.data.bikes[0].status != 'Verified') {
        setHasBikeAllDetail(true)
        setHasBikeInitialDetail(false)
      }
      else if (response.data && response.data.bikes && response.data.bikes[0] && response.data.bikes[0].icNumber != null && response.data.bikes[0].plateNumber != null
        && response.data.bikes[0].engineNumber != null && response.data.bikes[0].chassisNumber != null && response.data.bikes[0].status === 'Verified') {
        setHasBikeAllDetail(false)
        setHasBikeInitialDetail(true)
      }

      else if (response.data && response.data.bikes && response.data.bikes[0] && response.data.bikes[0].icNumber != null && response.data.bikes[0].plateNumber != null
        && response.data.bikes[0].engineNumber == null && response.data.bikes[0].chassisNumber == null) {
        setHasBikeInitialDetail(true)
        setHasBikeAllDetail(false)
      }
      if (response && response.data) {
        let selectedGender = genderList && genderList.filter(aGender => aGender.value === response.data.gender)
        setData({
          ...data,
          ["mobileNumber"]: response.data.mobile && response.data.mobile.mobileNumber,
          ["name"]: response.data.name,
          ["gender"]: selectedGender && selectedGender[0] && selectedGender[0].value,
          ["email"]: response.data.email,
          ["dateOfBirth"]: response.data.dateOfBirth,
        })
      }
      if (response.data.addresses && response.data.addresses[0]) {
        setData({
          ...data,
          ["addressLine1"]: response.data.addresses[0].addressLine1,
          ["addressLine2"]: response.data.addresses[0].addressLine2,
          ["addressType"]: response.data.addresses[0].addressType,
          ["postCode"]: response.data.addresses[0].postCode,
          ["city"]: response.data.addresses[0].city,
          ["locationState"]: response.data.addresses[0].state
        })
      }
      if (response.data && response.data.cards && response.data.cards.length === 0 && response.data && response.data.bikes && response.data.bikes[0]
        && response.data.bikes[0].status !== 'Verified') {
          console.log("one");
        setDetailEditable(true)
      }
    
      else if (response.data && response.data.cards && response.data.cards.length === 0 && response.data && response.data.bikes && response.data.bikes[0]
        && response.data.bikes[0].status === 'Verified') { 
          setDetailEditable(false)
          console.log("two");
      }
      else if (response.data && response.data.cards && response.data.cards.length === 0 && response.data && response.data.bikes && response.data.cards.length === 0) {    
        setDetailEditable(true)
        console.log("three");
        }
      else {
        setDetailEditable(false)
        console.log("four");
      }
    }).catch(error => {
      setError(error && error.response && error.response.data && error.response.data.message)
    }).finally(e => {
      //(false)
    })
  }

  const handleMemberProfile = () => {
  //  setIsLoading(true)
    let userId = sessionStorage.getItem("userId")
    memberService.handleMemberProfile(userId).then(response => {
      sessionStorage.setItem("userDetails", JSON.stringify(response.data))
      localStorage.setItem("userDetails",JSON.stringify(response.data))
      // if((sessionStorage.getItem("redirecturl")!== null || sessionStorage.getItem("redirecturl")!== undefined ||sessionStorage.getItem("redirecturl")!== "") &&
      // sessionStorage.getItem("client_id")!== null || sessionStorage.getItem("client_id")!== undefined ||sessionStorage.getItem("client_id")!== "")
      // {
      //     var redirect_uri = sessionStorage.getItem("redirecturl") ;
      //     var client_id = sessionStorage.getItem("client_id");

      //     let request ={
      //       "client_id": redirect_uri,
      //         "redirect_uri": client_id,
      //        "credentialType": "Username",
      //        "credentials": {
      //         "username": data.userName,
      //        "hashedPassword": sha512(data.password)
      //  }
      //     }
      //     memberService.redirectGOS(request).then(response=> {
      //       console.log(response);
      //     })
      //     return;
      // }
      sessionStorage.setItem("profileSetupStatus", "Completed")
      window.location.reload()
     
    }).catch(error => {
      setError(error && error.response && error.response.data && error.response.data.message)
    }).finally(e => {
   //  setIsLoading(false)
    })
  }
  const sendOtp = () => {
     setIsLoading(true)
    memberService.handleSendOtp("+60" + data.mobileNumber).then(response => {
      setRedirectToOTPScreen(true)
    }).catch(error => {
      setAPIError(error && error.response && error.response.data && error.response.data.message)
    }).finally(e => {
       setIsLoading(false)
    })
  }


  const handleError = () => {
    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (data.name === undefined || data.name === "") {
      setError({
        ...error,
        ["name"]: "Please enter your name",
      });
      return false;
    } else if (data.gender === undefined || data.gender === "") {
      setError({
        ...error,
        ["gender"]: "Please enter your gender",
      });
      return false;
    }

    // else if (data.email === undefined || data.email === "" || data.email === null){
    //   setError({
    //     ...error,
    //     ["email"]: "Please enter your email",
    //   });
    //   return false;
    // } 
    
    else if (data.email != "" && data.email != undefined && !emailRegex.test(data.email)) {
      setError({
        ...error,
        ["email"]: "Please enter a valid email address",
      });
      return false;
    }
    
    // else if ((userDetails.mobile && userDetails.mobile.mobileNumber === null) || data.mobileNumber === undefined) {
    //   setError({
    //     ...error,
    //     ["mobileNumber"]: "Please enter mobile number",
    //   });
    //   return false
    // }
    else if (userDetails.mobile && userDetails.mobile.mobileNumber == null && data.mobileNumber === undefined) {
      setError({
        ...error,
        ["mobileNumber"]: "Please enter mobile number",
      });
      return false
    // }
    // else if (data.addressLine1 === undefined) {
    //   setError({
    //     ...error,
    //     ["addressLine1"]: "Please enter address line 1",
    //   });
    //   return false;
    } else if (data.postCode === undefined) {
      setError({
        ...error,
        ["postCode"]: "Please enter post code",
      });
      return false;
    } else if (data.city === undefined) {
      setError({
        ...error,
        ["city"]: "Please enter a valid post code to get city",
      });
      return false;
    } else if (data.locationState === undefined) {
      setError({
        ...error,
        ["locationState"]: "Please enter a valid post code to get state",
      });
      return false;
    }
    else if (data.addressType === undefined) {
      setError({
        ...error,
        ["addressType"]: "Please select your address type",
      });
      return false;
    }
    return true
  }
  return (
    <>
      {isLogin && <Redirect to={{
        pathname: '/auth/initialDetails',
        state: { mobile: data.mobileNumber, isLoginAsFan: isLoginAsFan, signupType: signupType }
      }}></Redirect>}
      {redirectToOTPScreen && <Redirect to={{
        pathname: '/auth/sendOTP',
        state: { mobile: data.mobileNumber, isLoginAsFan: isLoginAsFan, signupType: signupType }
      }}
      />}
      <Col lg="12" md="12" className="mt-8">
        <span className="login_header">{t("Personal Details")}</span>
        <Card className="bg-secondary shadow module-border-wrap">
          <CardBody className="px-lg-5 py-lg-5">
            <Row >
              <Col xs="6">
                <FormGroup className="mb-3 text-center">
                  {/* {!showProfilePicture && <Button className="ma_btn mt-3 mb-3 profile_img_btn" color="primary" type="button" >
                    <i style={{ fontSize: '20px', margin: '2px' }} class="fas fa-user-plus"></i>

                    <input type="file" accept=".jpg,.jpeg,.png" onChange={(event) => onFileChange(event)} />
                  </Button>} */}
                  {
                    <div>
                      {userDetails.profileImageName != undefined && <img src={assetBaseUrl + userDetails && userDetails.profileImageName} className="profileImage" style={{ marginLeft: '24px' }} />}
                      {userDetails.profileImageName != undefined && <Button className="camera_btn1  " color="primary" type="button" >
                        <i className="fas fa-camera  " style={{ marginLeft: '-5px', marginTop: '-2px' }}></i>
                        <input type="file" accept=".jpg,.jpeg,.png" onChange={(event) => onFileChange(event)} />
                      </Button>}
                      {userDetails.profileImageName == undefined && <Button className="ma_btn mt-3 mb-3 profile_img_btn" color="primary" type="button" >
                        <i style={{ fontSize: '20px', margin: '2px' }} class="fas fa-user-plus"></i>
                        <input type="file" accept=".jpg,.jpeg,.png" onChange={(event) => onFileChange(event)} />
                      </Button>}
                    </div>
                  }
                </FormGroup>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      style={{ paddingLeft: '10px' }}
                      value={data.name}
                      name="name"
                      placeholder={t("Full name") + "*"} 
                      type="text"
                      autoComplete="new-name"
                      onChange={handleChange}
                      disabled={userDetails && userDetails.name != null && !isDetailEditable}
                      required={true}

                    />
                  </InputGroup>
                  { error.name && <small style={{ color: 'red' }}>{t(error.name)} </small>}
                </FormGroup>
                <FormGroup className="mb-3">

                  <Autocomplete
                  disabled={userDetails && userDetails.gender != null && !isDetailEditable}
                    defaultValue={{ value: data.gender }}
                    id="gender"
                    options={genderList}
                    getOptionLabel={(option) => t(option.value)}
                    debug
                    onChange={handleGenderChange}
                    name="gender"
                    required
                    disableClearable
                    renderInput={(params) => <TextField {...params} label={t("Gender")}
                      disabled={userDetails && userDetails.gender != null && !isDetailEditable}
                      required={true}

                    />}
                  />
                {error.gender && <small style={{ color: 'red' }}>{t(error.gender)}</small>}
                </FormGroup>
                <Card style={{ height: '40px', marginBottom: '20px', boxShadow: '0 10px 20px rgba(0,0,0,0.02), 0 6px 6px rgba(0,0,0,0.00)' }}>
                  <FormGroup className="mb-3 mt-2">
                    <div style={{ display: 'flex', }}>
                      <i style={{ marginLeft: '10px', marginTop: '3px', marginRight: '12px', color: '#adb5bd' }} class="fas fa-calendar-day"></i>
                      <DatePicker
                        maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 16))}
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        disabled={userDetails && userDetails.dateOfBirth != null && !isDetailEditable}
                        required={true}

                      />
                    </div>
                  </FormGroup>
                </Card>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-at"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      value={data.email}

                      style={{ paddingLeft: '10px' }}
                      name="email"
                      placeholder={t("Email address")}
                      type="text"
                      autoComplete="new-email"
                      onChange={handleChange}

                    />
                  </InputGroup>
                  { error.email && <small style={{ color: 'red' }}>{t(error.email)}</small>}
                </FormGroup>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-mobile"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            
                                            <span style={{ marginLeft: '10px' }}> +60</span>
                                        </InputGroupText>
                                    </InputGroupAddon>
                    <Input
                      style={{ paddingLeft: '10px' }}
                      name="mobileNumber"
                      placeholder={t("Mobile number") + "*"} 
                      type="number"
                      value={data.mobileNumber}
                      autoComplete="new-mobileNumber"
                      onChange={handleChange}
                      onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                      disabled={user.mobileVerifiedStatus == "Verified" }
                    
                    />
                  </InputGroup>
                  {error.mobileNumber && <small style={{ color: 'red' }}>{t(error.mobileNumber)}</small>}
                </FormGroup>

              </Col>
              <Col xs="6">
                <FormGroup className="mt-5 mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-address-card"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      value={data.addressLine1}
                      style={{ paddingLeft: '10px' }}
                      name="addressLine1"
                      placeholder={t("Address line 1")}
                      type="text"
                      autoComplete="new-addressLine1"
                      onChange={handleChange}
                      required={true}

                    />
                  </InputGroup>
                  {/* <small style={{ color: 'red' }}>{error.addressLine1}</small> */}
                </FormGroup>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-address-card"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      value={data.addressLine2}

                      style={{ paddingLeft: '10px' }}
                      name="addressLine2"
                      placeholder={t("Address line 2")}
                      type="text"
                      autoComplete="new-addressLine2"
                      onChange={handleChange}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-address-card"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      value={data.postCode}
                      style={{ paddingLeft: '10px' }}
                      name="postCode"
                      placeholder={t("Postcode") + "*"}
                      type="text"
                      autoComplete="new-postCode"
                      onChange={handleChange}
                      onBlur={handlePostalCode}
                    />
                  </InputGroup>
                  {error.postCode && <small style={{ color: 'red' }}>{t(error.postCode)}</small>}
                </FormGroup>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-city"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      style={{ paddingLeft: '10px' }}
                      value={data.city}
                      name="city"
                      placeholder={t("City")}
                      type="text"
                      autoComplete="new-city"
                      onChange={handleChange}
                      disabled
                      required={true}

                    />
                  </InputGroup>
                  { error.city && <small style={{ color: 'red' }}>{t(error.city)}</small>}
                </FormGroup>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-city"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      style={{ paddingLeft: '10px' }}
                      value={data.locationState}
                      name="locationState"
                      placeholder={t("State")}
                      type="text"
                      autoComplete="new-state"
                      onChange={handleChange}
                      disabled
                      required={true}

                    />
                  </InputGroup>
                  { error.locationState && <small style={{ color: 'red' }}>{t(error.locationState)}</small>}
                </FormGroup>
                <Row>
                  <Col xs="4">
                    <FormGroup className="mb-3">
                      <Autocomplete
                        value={{ value: data.addressType }}
                        id="addressType"
                        options={addressTypeList}
                        getOptionLabel={(option) => t(option.value)}
                        debug
                        onChange={handleAddressType}
                        name="addressType"
                        required
                        disableClearable
                        renderInput={(params) => <TextField {...params} label={t("Save as")}
                        required={true}
                        />}
                      />
                      { error.addressType && <small style={{ color: 'red' }}>{error.addressType}</small> }

                    </FormGroup>
                  </Col>
                  <Col xs="8">
                    {showOthersField && <FormGroup className="mt-1">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-map-pin"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          style={{ paddingLeft: '10px' }}
                          name="saveAddressAs"
                          placeholder="Save Address As"
                          type="text"
                          autoComplete="new-saveAddressAs"
                          onChange={handleChange}

                        />
                      </InputGroup>
                    </FormGroup>}
                  </Col>
                </Row>
              </Col>
              {/* </Form> */}
            </Row>
            <Row><Col xs="12">                  <small style={{ color: 'red' }}>{apiError}</small>
            </Col></Row>

            <Row>
              <Col xs="12" className="text-center">
                <div className="text-center">
                  {/* {!hasBikeInitialDetail && !hasBikeAllDetail && isLogin &&  <Link to={{ pathname: '/auth/initialDetails', state: props && props.location && props.location.state }}> </Link>} */}
                  {!hasBikeAllDetail && !hasBikeInitialDetail && <Link to={{ pathname: '/auth/initialDetails', state: { signupType: signupType } }} >
                    <Button onClick={handleBack} className="mt-3" color="primary" type="button" style={{ marginRight: '15px' }}>
                      {t("Back")}
                    </Button></Link>}
                  {hasBikeInitialDetail && <Link to={{ pathname: '/auth/initialDetails', state: { signupType: signupType } }} >
                    <Button onClick={handleBack} className="mt-3" color="primary" type="button" style={{ marginRight: '15px' }}>
                      {t("Back")}
                    </Button></Link>}
                  {hasBikeAllDetail && <Link to={{ pathname: '/auth/initialDetails', state: { signupType: signupType } }} >
                    <Button  onClick={handleBack} className="mt-3" color="primary" type="button" style={{ marginRight: '15px' }}>
                      {t("Back")}
                    </Button></Link>}
                  {!isLoading ? <Button className="mt-3" color="primary" type="button" onClick={handleSaveProfile}>
                    {t("Activate account")}
                  </Button> : <Spinner style={{ marginTop: '10px' }} color="primary" />}
                 
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default MemberProfile;
