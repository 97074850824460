/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Spinner
} from "reactstrap";
import "../../views/LoginComponents/login.css"
import { Link, Redirect } from "react-router-dom";
import React, { useEffect } from 'react';
import memberService from "../service/APIService"
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import firebase from "utils/firebase";
import { useTranslation } from "react-i18next";

const MobileLogin = (props) => {
    const [mobileError, setMobileError] = React.useState("");
    const [privacyError, setPrivacyError] = React.useState("");
    const [nextScreen, setNextScreen] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)
    const [isSignup, setIsSignup] = React.useState(props.location && props.location.state && props.location.state.isSignup)
    const [error, setError] = React.useState("");
    const [isForgetPassword, setIsForgetPassword] = React.useState(props && props.location && props.location.state && props.location.state.forgotPassword)


    useEffect(()=>{
        firebase.logEventForAnalytics("Forgot Password")
    })

    const handleSendOTP = () => {
        if (handleError()) {
            sendOtp()
        } else {
            return
        }
    }
    console.log("environment",process.env.REACT_APP_ENV);

    const termsAndConditionUrl =  process.env.REACT_APP_ENV === "PROD" ? process.env.REACT_APP_TERMS_AND_CONDITION_URL_PROD :  process.env.REACT_APP_ENV === "DEV" ? process.env.REACT_APP_TERMS_AND_CONDITION_URL :  process.env.REACT_APP_TERMS_AND_CONDITION_URL_STAGE;
    console.log("termsurl",termsAndConditionUrl);
   const privacyPolicyUrl =  process.env.REACT_APP_ENV === "PROD" ? process.env.REACT_APP_PRIVACY_URL_PROD :  process.env.REACT_APP_ENV === "DEV" ? process.env.REACT_APP_PRIVACY_URL :  process.env.REACT_APP_PRIVACY_URL_STAGE;


    const sendOtp = () => {
        setIsLoading(true)
        memberService.handleSendOtp("+60" + data.mobileNumber).then(response => {
            setNextScreen(true)
        }).catch(error => {
            setError(error && error.response && error.response.data && error.response.data.message)
            
        }).finally(e => {
            setIsLoading(false)
            setNextScreen(true)
        })
    }

    const handleError = () => {
            if (data.mobileNumber ===undefined || data.mobileNumber === "") {
                setMobileError("Please enter valid mobile number.")
                return false
            }
        //     else if (data.mobileNumber && (data.mobileNumber.length < 5 || data.mobileNumber.length > 16)) {
        //         setMobileError("Please enter valid mobile number.")
        //         return false

        //     }
        //    else
        if (!data.privacyTerms) {
            setPrivacyError("Please agree to terms and conditions.")
            return false

        }
        return true

    }
    const [data, setData] = React.useState({});
    const handleChange = (e) => {
        let phoneRegex = /^(\+?6?01)[0|1|2|3|4|6|7|8|9]\-*[0-9]{7,9}$/
        if (e.target.name === "mobileNumber") {
            if (!phoneRegex.test("+60" + e.target.value)) {
                setMobileError("Please enter valid mobile number.")
            }
            else {
                setMobileError("")
            }
        }
        // setMobileError("")
        setPrivacyError("")
        if (e.target.name !== 'privacyTerms') {
            setData({
                ...data,
                [e.target.name]: e.target.value,
                [e.target.name + "_error"]: null,
            });
        }
        else {
            setData({
                ...data,
                [e.target.name]: e.target.checked,

            });
        }
    };

    const { t } = useTranslation();

    return (
        <>
            {nextScreen && <Redirect to={{
                pathname: "/auth/sendOTP",
                state: { mobileNumber: data.mobileNumber, mobile: data.mobileNumber ,  forgotPassword: props.location.state.forgotPassword, signupType: "Mobile", isSignup: isSignup }
            }}></Redirect>}
            {/* {nextScreen && <Redirect  to={{
            pathname: "/auth/sendOTP",
            state: { mobileNumber: data.mobileNumber,isForgetPassword:isForgetPassword }
          }}></Redirect>} */}
            <Col lg="5" md="7" className="mt-8" style={{ height: '70vh' }}>
                {props.location && props.location.state && props.location.state.forgotPassword && <span className="login_header">Reset password</span>}
                {/* {props.location && props.location.state && !props.location.state.forgotPassword && <span className="login_header">bile</span>} */}
                {props.location && props.location.state && !props.location.state.forgotPassword && props.location.state.isSignup && <span className="login_header">{t("Signup Via Mobile")}</span>}
                {props.location && props.location.state && !props.location.state.forgotPassword && !props.location.state.isSignup && <span className="login_header">{t("Login Via Mobile")}</span>}

                <Card className="bg-secondary shadow module-border-wrap mt-2" >
                    <CardBody >
                        <Form role="form">

                            <FormGroup>
                                <InputGroup className="input-group-alternative mb-3">
                                <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fas fa-mobile-alt"></i>
                                            <span style={{ marginLeft: '10px' }}> +60</span>
                                        </InputGroupText>
                                    </InputGroupAddon>

                                    <Input style={{ paddingLeft: '10px' }} name="mobileNumber" placeholder={t("Mobile number")} type="number" onChange={handleChange}
                                        onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                                    />
                                </InputGroup>
                            </FormGroup>

                            <Row>
                                <Col xs="12" style={{ textAlign: 'center' }}>
                                    <small style={{ color: 'red' }}>{t(mobileError)}</small>
                                    <small style={{ color: 'red' }}>{t(privacyError)}</small>
                                </Col>

                            </Row>
                            <Row className="mt-4" >
                                <Col xs="12">
                                    <div style={{ display: 'flex' }}>
                                        <input style={{ margin: '10px' }} name="privacyTerms"
                                            type="checkbox" onChange={(e) => handleChange(e)}
                                        />
                                        <label>
                                            <span className="text-muted" style={{ fontSize: '11px' }}>
                                                {t("I have read, understood and accepted the")}
                                                {" "}
                                                <a href={termsAndConditionUrl} target="_blank">
                                                    {t("Terms and conditions")}
                                                </a> {t("and")}
                                                {" "}
                                                <a href={privacyPolicyUrl} target="_blank">
                                                    {t("Privacy Policy")}
                                                </a>
                                            </span>
                                        </label>
                                    </div>
                                </Col>
                            </Row>

                            <div className="text-center">
                                <Link to={{ pathname: '/auth/login', state: props && props.location && props.location.state }} style={{ marginRight: '15px' }}>
                                    <Button className="mt-3" color="primary" type="button" >
                                        {t("Cancel")}
                                    </Button>
                                </Link>
                                {!isLoading && <Button className="mt-3" color="primary" type="button" onClick={handleSendOTP}
                                disabled={mobileError!=""}>
                                    {t("Proceed")}
                                </Button>}
                                {isLoading && <Spinner style={{ marginTop: '10px' }} color="primary" />}
                            </div>
                        </Form>
                    </CardBody>
                </Card>
            </Col>
        </>
    );
};

export default MobileLogin;
