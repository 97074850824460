import { Button, Container, Row } from "reactstrap";
import React, { useState, useEffect } from 'react';
import "layouts/layouts.css"
import serviceBanner from "assets/img/yamahaIcons/serviceBanner.jpg"


const ServiceHeader = (props) => {
  const [nextId, setNextId] = React.useState(1)


  return (
    <>
      <div
        className="header pb-4 pt-2 pt-lg-5 d-flex align-items-center bg-white"

      >
        {/* Mask */}
        <span />
        {/* Header container */}
        <Container className="pt-2 d-flex align-items-center bg-white" fluid   >

          <Row style={{ width: '100%' }}>
            <div className="wrapper" style={{cursor:'pointer'}} >
              <img src={serviceBanner} alt="" height="400" width="1100" />

          
            </div>

          </Row>
        </Container>
      </div>
    </>
  );
};

export default ServiceHeader;
