import { useEffect } from "react"
import firebase from "utils/firebase";


const TermsnConditions = () => {
    const termsAndConditionUrl =  process.env.REACT_APP_ENV === "PROD" ? process.env.REACT_APP_TERMS_AND_CONDITION_URL_PROD :  process.env.REACT_APP_ENV === "DEV" ? process.env.REACT_APP_TERMS_AND_CONDITION_URL :  process.env.REACT_APP_TERMS_AND_CONDITION_URL_STAGE;
 
    useEffect(()=>{
        firebase.logButtonClicked("Terms and Conditions")
        console.log("environment3",process.env.REACT_APP_ENV);

  
console.log("termsurls",termsAndConditionUrl);

        })
    return (<div>
        <iframe src={termsAndConditionUrl} style={{ height: "1200px", width: '100%', marginTop: "-50px"}} title="Iframe Example"></iframe>
    </div>)
}
export default TermsnConditions