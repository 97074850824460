// import React from 'react'
// import Iframe from 'react-iframe'

import React, { useState,useEffect } from 'react';
import {useRef} from 'react';
import { Row, Col,Spinner, Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupAddon, InputGroupText, Input, Form } from 'reactstrap';
import "../../layouts/layouts.css"
import Select from 'react-select';
import memberService from "../service/APIService"
import HWMessage from 'HWLibrary/HWMessage';
import { Snackbar } from '@material-ui/core';
import { style } from '@mui/system';
import { defaultFormat } from 'moment';
import { getAnalytics, logEvent } from "firebase/analytics";
import firebase from "utils/firebase.js"
import { useTranslation } from 'react-i18next';


// const RaiseTicket = () => {
    
   
    
//     return (
       
//         <Iframe url="https://yls.freshdesk.com/support/tickets/new/"
//         width="100%"
//         height="1000px"
//         id="myId"
//         className="myClassname"
//         display="initial"
//         position="relative"/>
        
        
    
//     )
// }

// export default RaiseTicket


//https://hummingwavetest2.freshdesk.com/support/tickets/new/

const RaiseTicket=(props)=>{
    const {
        buttonLabel,
        className
    } = props;

    const [fileName, setFileName] = useState()
    const [isUploading, setIsUploading] = useState(false)
    const [supportImageList, setSupportImageList] = React.useState([])
    const [userId, setUserId] = useState(sessionStorage.getItem('userId'))
    const [userDetails, setUserDetails] = useState(JSON.parse(sessionStorage.getItem('userDetails')))
    const [data, setData] = React.useState({subject: null,description: null,category:null});
    const [isLoading, setIsLoading] = useState(false)
    const [isSubmitting,setIsSubmitting] = useState(false)
    const [error, setError] = React.useState("");
    const [subjectError, setSubjectError] = React.useState('');
    const [descriptionError, setDescriptionError] = React.useState('');
    const [url, setUrl]=useState()
    const [attachmentExists, setAttachmentExists] = useState(false)
    const [clearInput,setClearInput] = useState(false);
    const [subjectEmpty,setSubjectEmpty] = useState(false);
    const [categoryEmpty,setCategoryEmpty] = useState(false);
    const [descriptionEmpty,setDescriptionEmpty] = useState(false);
    const [options, setOptions] = useState([{ name: 'User Account' }, { name: 'Motorcycle Information' }, { name: 'Campaigns and Events' }, { name: 'Points and Transactions' }, { name: 'Membership and Subscription' }, { name: 'E-Service Coupon and Vouchers' }, { name: 'User Manager' }, { name: 'Profile' }, { name: 'Redemptions' }, { name: 'Errors/Bug Reporting' }, { name: 'General Inquiry' }]);
    const [submitAttempt,setSubmitAttempt] = useState(false)   
    const [defaultValue,setDefaultValue] = useState(null);
    const colourStyles = {
        control: styles => ({ ...styles, overflow: 'hidden', color: 'blue',backgroundColor: this.state.selectedOption.value || '#32CD32', fontSize: 23,  paddingLeft: 'center', height:46}),
        singleValue: styles => ({ ...styles, color: 'blue' }),
      }       
      const inputFieldStyle = `
           .inputField::-webkit-input-placeholder{
                   color: red;
           }`
   
    const { t } = useTranslation();
    // useEffect(()=>{
    //     console.log("1")
    //         HWMessage.show ("Ticket Raised Successfully" );
    //         console.log("2")
    // },[clearInput])
    useEffect(() => {
        // const analytics = getAnalytics(app);
         firebase.logEventForAnalytics('Raise a Ticket');
     }, [])

    useEffect(() => {
        setOptions([])
        refreshOptions()
        console.log("refresh")
        //fetchTicketDetails()
 }, [clearInput])

    const refreshOptions = () => {
        setOptions([{ name: 'User Account' }, { name: 'Motorcycle Information' }, { name: 'Campaigns and Events' }, { name: 'Points and Transactions' }, { name: 'Membership and Subscription' }, { name: 'E-Service Coupon and Vouchers' }, { name: 'User Manager' }, { name: 'Profile' }, { name: 'Redemptions' }, { name: 'Errors/Bug Reporting' }, { name: 'General Inquiry' }])
        
    }
    const submitTicket = "submitTicket"


    const onFileChange = (event) => {
        setIsUploading(true)
        setIsLoading(true)
        if (event.target.files && event.target.files.length) {
            let fileList = event.target.files;
            let filepush = []
            filepush.push(fileList[0])
            console.log(event)
            if(fileName!=='')
            uploadFile(event.target.files)
        }


    }
    const uploadFile = (files) => {
        setIsLoading(true)
        setAttachmentExists(true)
        let fileName = files[0].name.split(".")
        let actualFileName = sessionStorage.getItem("userId") + fileName[0]
        let extension = fileName[1]
        setFileName(actualFileName)
        console.log("uploading")
        //        setUserDetails({...userDetails,
        //     ["profileImageName"]:actualFileName
        // })
        memberService.fetchs3PresignedURlForSupport(actualFileName).then(res => {
            if (res) {
                setUrl({
                    ...url,
                    res
                })
                
                console.log(url)
                memberService.uploadToS3(res, files[0]).then(res => {
                    // updateMemberProfileDetails(actualFileName)
                    let fileList = files;
                    let filepush = []
                    filepush.push(fileList[0])
                    let fileName = fileList[0].name
                    fileName = fileName.substring(0, fileName.lastIndexOf(".") )
                    supportImageList.push({ name: fileName, type: fileList[0].type, index: supportImageList.length + 1,fileName:fileName })
                    setSupportImageList([...supportImageList])
                    setIsLoading(false)
                })
            }
        })
        
        }

        const handleChange = (e) => {
            setClearInput(false)
            if (e.name !== undefined) {
                setCategoryEmpty(false)
                console.log("category",e.name)
                setData({
                    ...data,
                    ['category']: e.name
                })
                setDefaultValue({name:e.name});
            }

            if(e.target){
                console.log("sd",e)
            setError("")
            if (e.target.name === 'subject')
                setSubjectError("")
            if (e.target.name === 'description')
                setDescriptionError("")
            if (e.target.name === 'select')
                setDescriptionError("")
            
            // if (vocImageList.length === 4) {
            //     setVOCUploadError("")
            // }
            setData({
                ...data,
                [e.target.name]: e.target.value,
                [e.target.name + "_error"]: null,
            });
            if(data.description){
                if((data.description.length)>65534){
                    HWMessage.show("maximum characters limit(65,535) reached","error")
                    
                }
            }
            if(data.subject){
                if((data.subject.length)>255){
                    HWMessage.show("maximum characters limit(255) reached","error")
                    
                }
            }
            
        }
        
        }

        
    
        const handleSaveTicket = () => {

            setSubmitAttempt(true)
            console.log(data)
         
            let userDetails = JSON.parse(sessionStorage.getItem('userDetails'))
            {
                let supportImageListToUpload = []
                supportImageList && supportImageList.map(aDoc => {
                    supportImageListToUpload.push(aDoc.name)
                })
                
                console.log(url)
                
                
                
                //let imgurl=(url.res.data.presignedUrl.substring(0, url.res.data.presignedUrl.lastIndexOf("?X-") ))
            
            if ((data.subject!==(null) && data.subject.trim()!==''&& data.subject!==undefined)
                    && (data.category!==(null) && data.category!==''&& data.category!==undefined)
                    &&(data.description!==(null) && data.description.trim()!=='' && data.description!==undefined)){
                console.log(data.category!==(null) && data.category!==''&& data.category!==undefined)
                setIsSubmitting(true)
                firebase.logButtonClicked(submitTicket)
                let request = url!==''?{
                    "subject": data.subject,
                    "description": data.description,
                    "category":data.category,
                    "memberId": userId,
                    "membershipNumber": userDetails.membershipNumber,
                    "name":userDetails.name,
                    "attachmentUrl": (attachmentExists)?url.res.data.presignedUrl.substring(0, url.res.data.presignedUrl.lastIndexOf("?X-") ):null
                }:{
                    "subject": data.subject,
                    "description": data.description,
                    "category":data.category,
                    "memberId": userId,
                    "membershipNumber": userDetails.membershipNumber,
                    "name":userDetails.name,
                }
                console.log(request);
                setAttachmentExists(false)
                memberService.updateSupportTicket(request)
                .then((response) => {
                       
                    response.data.responseStatus=="success"?HWMessage.show("Your ticket has been raised successfully", "success" ):HWMessage.show("Failed to raise your ticket. Try again", "error" );
                    
                    
                })
                .catch(error => {
                    //HWMessage.show("Failed to raise ticket. Try again", "error" ) 
                    
                    setError(error && error.response && error.response.data && error.response.data.message)
                    
                    console.log("bvjhdfv")
                }).finally(e => {
                    setIsSubmitting(false)
                })
                setData({
                    ...data,
                    subject:'',
                    description:'',
                    category:''
                });
                setDefaultValue(null)
                setFileName('');
            setUrl('');
            setClearInput(!clearInput)
            setSubmitAttempt(false)
            setSubjectEmpty(false)
            setCategoryEmpty(false)
            setDescriptionEmpty(false) 
            document.getElementById("attachment").value='';
            }   
            else {
                
                if ((data.subject && data.subject.trim()=='')
                    || data.description==''
                    || data.subject==null
                    || data.subject==undefined){
                    //HWMessage.show("Enter Subject")
                    setData({
                        ...data,
                        subject:''
                    });
                    setSubjectEmpty(true)
                    console.log("subject is empty")
                    
                }
                if (data.category==''
                || data.category==null
                || data.category==undefined){
                    //HWMessage.show("Choose Category")
                    setData({
                        ...data,
                        category:''
                    });
                    setCategoryEmpty(true)
                    console.log("category is empty")
                }
                if ((data.description && data.description.trim()=='')
                || data.description==''
                || data.description==null
                || data.description==undefined){
                    //HWMessage.show("Enter Description")
                    setData({
                        ...data,
                        description:''
                    });
                    setDescriptionEmpty(true)
                    console.log("description is empty")
                }
                
            }
            
            
            
         
        }
    }
         const handleClearTicket=()=>{
            // document.getElementById("subject"). ;
            // document.getElementById("description").reset();
            document.getElementById("attachment").value='';
            //document.getElementById("category")
            
            
            setData({
                ...data,
                subject:'',
                description:'',
                category:''
            });
            setDefaultValue(null)
            setFileName('');
            setUrl('');
            console.log("clear")   
            setClearInput(true)
            setSubmitAttempt(false)  
            setSubjectEmpty(false)
            setCategoryEmpty(false)
            setDescriptionEmpty(false)       
            refreshOptions()
         }

         
        
        

        


    return(
        
        
        <div>
      <HWMessage/>
            <Col className="mt-3">
                
                 <Row className="mt-3">
                    {t("Category")}<text style={{color:"red"}}> *</text>
                </Row>
                 
                            <Row className="mt-1" >
                            
                                <InputGroup>
                                <Col xs="6" style={{marginLeft: '-15px'}}> 
                                 {console.log("defaultValue",defaultValue)}
                                  <Select isSearchable={false}
                                    placeholder= {t("Select a Category")} 
                                    name="category"
                                    id="category"
                                    style={{ paddingLeft: '10px' }} 
                                    value={defaultValue}
                                    options={options}
                                    getOptionLabel={(options) => t(options['name'])}
                                    getOptionValue={(options) => options['name']}
                                    
                                    onChange={handleChange}
                                     />
                                     {/* <select value={data.category} onChange={handleChange}> 
                                        <option name="male"> Male</option>
                                        <option name="female">Female</option>
                                        </select> */}

                                     
                                 
                                 
                                {categoryEmpty &&  <small style={{color:"red"}}>{t("Please select the Category to proceed")}</small>}
                                
                                </Col>
                                </InputGroup>
                                
                                

                            </Row> 
                

                <Row className="mt-3">
                    {t("Subject")} <text style={{color:"red"}}> *</text>
                </Row>
                            <Row className="mt-1" >
                                <InputGroup>
                                    <textarea placeholder={t("Please enter the subject line" )}
                                            
                                            name="subject" 
                                            id='subject'
                                            //style={{ paddingLeft: '10px' }}
                                            
                                            value={data.subject}
                                            maxLength="255"
                                            style={{
                                                borderStyle: "inset",
                                                borderWidth: "1px",
                                                paddingLeft: '10px',
                                                
                                                
                                            }}
                                            rows="1" cols="125"                                     
                                            onChange={handleChange}></textarea>
                                </InputGroup>
                                
                                {subjectEmpty && <small style={{color:"red"}}>{t("Please provide the subject related to your issue.")}</small>}
                                {data.subject && data.subject.length==255 && <small style={{color:'red'}}>Maximum characters limit reached(255/255)</small>}
                            </Row>
                            
                            <Row className="mt-3">
                                Description <text style={{color:"red"}}> *</text>
                            </Row>

                            <Row className="mt-1">
                                <InputGroup>
                                
                                <textarea 
                                placeholder={t('Please provide the description')}
                                
                                id="description" 
                                name="description" 
                                value={data.description} 
                                maxLength="65535"
                                style={{
                                    borderStyle: "inset",
                                    borderWidth: "1px",
                                    paddingLeft: '10px',
                                    
                                    
                                }}
                                
                                rows="6" cols="125" 
                                onChange={handleChange}>

                                </textarea>
                               
                                    
                                    {/* <Input placeholder="Description" 
                                            name="description" 
                                            id='description'
                                            style={{ paddingLeft: '10px' }}
                                            value={data.description}
                                            onChange={handleChange}></Input>
                                         { onChange={handleChange}  } */}
                                </InputGroup>
                                {/* <small style={{ color: 'red' }}>{mobileError}</small> */}
                            {descriptionEmpty && <small style={{color:"red"}}>{t("Please provide the description for your issue")}</small>}
                            {data.description && data.description.length==65535 && <small style={{color:'red'}}>Maximum characters limit reached(65535/65535)</small>}
                            </Row>

                            <Row className="mt-3">
                                {t("Attachments")} 
                            </Row>
                            
                            <Row >
                            <Button  color="primary" type="button" >
                                            
                                            {/* <input type="file" name="file" /> */}
                                            { <input type="file" accept=".jpg,.jpeg,.png"
                                                        id="attachment"
                                                        placeholder='Add supporting image'
                                                        name="attachment"
                                                        onChange={(event) => onFileChange(event)} 
                                                        onAbort={(event) => onFileChange(event)} />  }
                                        </Button>
                                        {isLoading && <Spinner style={{ marginTop: '10px' }} color="primary" />}
                            </Row>
                            <Row>    
                                <small style={{color:"grey"}}>{t("Attach an image/screenshot of the issue(Optional)")}</small>
                            </Row>
                            <Row className='mt-3'>
                                <Col xs='2'>
                                <Button type="reset" onClick={handleClearTicket}>
                                    {t("Clear")}
                                </Button>
                                </Col>
                                <Col xs='2'>
                                <Button color="primary" type="button" 
                                value={fileName}
                                disabled={isLoading}
                                onClick={handleSaveTicket}>
                                    {t("Submit")}
                                </Button>
                                </Col>
                                <Col xs='8'>
                                {isSubmitting && <Spinner style={{ marginTop: '10px' }} color="primary" />}
                                </Col>
                                
                            </Row>
                            
                        </Col>
        </div>
    )
}   
export default RaiseTicket