/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
} from "reactstrap";
// core components
import React, { useState, useEffect } from 'react';
import memberService from "../service/APIService";
import ticket from "assets/img/yamahaIcons/ticketBlue.svg"
import {  Link } from "react-router-dom";
import ShopHeader from "./ShopHeader";
import firebase from "utils/firebase";
import { useTranslation } from "react-i18next";



const Shop = () => {
    const [userId, setUserId] = useState(sessionStorage.getItem('userId'))
    const url=`https://shoponline.yamaha-motor.com.my?memberId=${userId}`
    const { t } = useTranslation();

      const navigateToShop =()=>{
        firebase.logButtonClicked("Shop Explore Now")
        window.open(
            `https://shoponline.yamaha-motor.com.my?memberId=${userId}`,
            '_blank' // <- This is what makes it open in a new window.
          );
      }
      useEffect(() => {
        // const analytics = getAnalytics(app);
         firebase.logEventForAnalytics('Shop');
     }, [])  

    return (
        <>
        <Card style={{backgroundColor:'white',height:'730px'}}>
            <ShopHeader />
            {/* Page content */}
            < >
               <Row >
                   <Col xs="12" className="d-flex justify-content-center">
                     
               <Button color="primary" type="button" onClick={navigateToShop}>{t("Explore now")}</Button>
       
               </Col>
               </Row>
            </>
            </Card>
        </>
    );
};

export default Shop;
