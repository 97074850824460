import React, { useState, useEffect } from 'react';
import { Row, Col, Spinner, Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, FormGroup, InputGroupText, Input } from 'reactstrap';
import "../../../layouts/layouts.css"
import Select from 'react-select';
import memberService from "../../service/APIService"
import moment from "moment"
import bikeImage from "assets/img/yamahaIcons/bike2.imageset/Rectangle654@2x.png"
import NewBikeOtherDetails from './NewBikeOtherDetails';
import ProvideMoreDetails from './ProvideMoreDetails';
import { ToastsStore } from "react-toasts";
import firebase from 'utils/firebase';

const NewBikeJPJDetails = (props) => {
    const [data, setData] = useState({})
    const [userDetails, setUserDetails] = useState(JSON.parse(sessionStorage.getItem('userDetails')))
    const [plateNumberError, setPlateNumberError] = React.useState('');
    const [icNumberError, setICNumberError] = React.useState('');
    const [error, setError] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false)
    const [nextScreen, setNextScreen] = React.useState(false)
    const [addNewBike, setAddNewBike] = React.useState(false)
    const [addMoreDetails,setAddMoreDetails] = React.useState(false)
    const [plateNumberIsShort,setPlateNumberIsShort] = useState(false)

    const [bikeDetails, setBikeDetails] = React.useState(null)
    const {
        buttonLabel,
        className
    } = props;

    const [modal, setModal] = useState(false);


    const toggle = () => setModal(!modal);

    useEffect(()=>{
        firebase.logEventForAnalytics('Bike More Details')
    },[addMoreDetails])

    const getFormattedRegDate = (regDate) => {
        let newRegDate = moment(regDate).format('DD MMM YYYY')
        return newRegDate;
    }

    const handleChange = (e) => {
        setError("")
        if (e.target.name === 'plateNumber')
            setPlateNumberError("")
        if (e.target.name === 'icNumber')
            setICNumberError("")
        setData({
            ...data,
            [e.target.name]: e.target.value.toUpperCase(),
            [e.target.name + "_error"]: null,
        });
    }

    const handleSaveVehicleJPJInfo = () => {
       
        //  setAddNewBike(true)
        // let response ={
        //     "chassisNumber": "PMYUG0520M0219887",
        //     "engineNumber": "G3D3E-463887",
        //     "icNumber": "880331055419",
        //     "id": "04133664-45e1-4aa8-b5b9-30b176f2824f",
        //     "modelNumber": "Y15ZR",
        //     "plateNumber": "PPR4496",
        //     "registrationDate": "2021-04-09",
        //     "status": "Verified",
        //     "vocVerificationMode": "Jpj"
        // }
        // props.parentCallback(response)

        
        if (handleError()) {
            setIsLoading(true)
            let request = {
                "icNumber": data.icNumber || userDetails.icNumber,
                "plateNumber": data.plateNumber
            }
            console.log("request in proceed",request)
            if (bikeDetails === null) {
                memberService.handleVehicleJPJInfo(request).then((response) => {
                    if (response.data.status === 'Verified') {
                        setAddNewBike(false)
                        setBikeDetails(response.data)
                        props.parentCallback(response.data)
                        ToastsStore.success("Bike verified successfully")
                        console.log('Verified---', response.data)
                        sessionStorage.setItem("BikeId",response.data.id)
                    }
                    else {
                        // props.closePopup()
                        setBikeDetails(response.data)
                        setAddMoreDetails(true)
                        setAddNewBike(false)
                        console.log('else---', response.data)
                    }
                }).catch(error => {
                    setError(error && error.response && error.response.data && error.response.data.message)
                }).finally(e => {
                    setIsLoading(false)
                })
            }
            else {
                setAddNewBike(false)
                setIsLoading(false)
                setAddMoreDetails(true)
            }
        }
        else {
            return
        }
    

    }

    const handleError = () => {
        let alphaNumericRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/;
        let alphaNumericRegexForICNumber = /^[A-Za-z0-9-]+$/;
        if (!alphaNumericRegex.test(data.plateNumber)) {
            setPlateNumberError("Plate number can contain upto 16 alphanumeric characters. Special characters and space are not allowed.")
            return false
        }
        
        // if (data.plateNumber && (data.plateNumber.length < 8)) {
        //     setPlateNumberError("Plate number should be between 8 to 16 alphanumeric characters")
        //     return false
        // }
        // if (data.icNumber === undefined) {
        //     setICNumberError("Plate number should be between 6 to 8 characters")
        //     return false
        // }
        if (!alphaNumericRegexForICNumber.test(data.icNumber)
            && userDetails.icNumber == null 
            && data.icNumber 
            && ( data.icNumber.length > 35)) {
            setICNumberError("IC number cannot be more than 35 characters")
            return false
        }
        if(userDetails.icNumber == null && data.icNumber===""){
            setICNumberError("Please enter IC number.")
            return false
        }
        if(userDetails.icNumber == null && data.icNumber==null){
            setICNumberError("Please enter valid IC number.")
            return false
        }
        return true
    }
    useEffect(() => {

    }, [])

    const handleSave = (response) => {
        setAddNewBike(false)
        props.parentCallback(response)
    }

    const handleDetailScreen = ()=>{
        setAddMoreDetails(false)
        setAddNewBike(true)
    }

    return (
        <div>
            {/* <Button color="danger" onClick={toggle}>{buttonLabel}</Button> */}
            {addNewBike && <NewBikeOtherDetails plateNumber={data.plateNumber}  icNumber ={data.icNumber || userDetails.icNumber} bikeDetails={bikeDetails} openPopup={addNewBike} closePopup={() => { setAddNewBike(false) }}
                parentCallback={handleSave}></NewBikeOtherDetails>}
                 {addMoreDetails && <ProvideMoreDetails icNumber ={data.icNumber || userDetails.icNumber} plateNumber={data.plateNumber} bikeDetails={bikeDetails} openPopup={addMoreDetails} closePopup={() => { setAddMoreDetails(false) }}
                parentCallback={handleDetailScreen}></ProvideMoreDetails>}
            <Modal size="sm" isOpen={props.openPopup} className={className}>
                <ModalHeader toggle={props.closePopup}>Bike Details</ModalHeader>
                <ModalBody>
                    <Row><Col xs="12" className="d-flex justify-content-center">
                    </Col>
                    </Row>
                    <Row className="mt-3">
                        {/* <FormGroup className="mb-3"> */}
                        <InputGroup className="input-group-alternative">
                            <Input
                                name="plateNumber"
                                placeholder="Plate Number"
                                value={data && data.plateNumber}
                                autoComplete="new-plateNumber"
                                onChange={handleChange}
                                style={{ textTransform: "uppercase" }}
                                maxLength='16'
                                
                            />
                        </InputGroup>
                          {/* {plateNumberIsShort && <small style={{ color: 'red' }}>*Plate number has to be minimum 8 characters</small>}  */}
                        <small style={{ color: 'red' }}>{plateNumberError}</small>
                        {/* </FormGroup> */}
                        {userDetails.icNumber == null && <>
                            <InputGroup className="input-group-alternative mt-3">
                                <Input
                                    name="icNumber"
                                    value={data && data.icNumber}
                                    placeholder="IC NUMBER"
                                    autoComplete="new-icNumber"
                                    onChange={handleChange}
                                    maxLength='35'
                                    type="text"
                                />
                            </InputGroup>
                            <small style={{ color: 'red' }}>{icNumberError}</small></>
                        }
                        <small style={{ color: 'red' }}>{error}</small>
                    </Row>

                </ModalBody>
                <ModalFooter className="d-flex justify-content-center">
                    {!isLoading && <Button className="mt-3" color="primary" type="button" onClick={handleSaveVehicleJPJInfo}>
                        Proceed
                    </Button>}
                    {isLoading && <Spinner style={{ marginTop: '10px' }} color="primary" />}
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default NewBikeJPJDetails;