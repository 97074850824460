/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,Spinner
} from "reactstrap";
// core components
import React, { useState, useEffect } from 'react';
import memberService from "../service/APIService";
import moment from "moment"
import "../../views/LoginComponents/login.css"
import sampleItem from "assets/img/yamahaIcons/sampleItem.png"
import firebase from "utils/firebase";
import { t } from 'i18next';
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import { Attachment } from "@material-ui/icons";
import cloudfrontConfig from 'HWLibrary/HWCloudfrontConfig';


const TransactionHistory = () => {
    const [userId, setUserId] = useState(sessionStorage.getItem('userId'))
    const [userDetails, setUserDetails] = useState(JSON.parse(sessionStorage.getItem('userDetails')))
    const [memberPoints, setMemberPoints] = useState()
    const [totalPoints,setTotalPoints] = useState()
    const [transactionList, setTransactionList] = useState({})
    const [activeTransaction, setActiveTransaction] = useState({})
    const [validityOfTransaction, setValidityOfTransaction] = useState({})
    const [activeTransactionText, setActiveTransactionText] = useState()
    const [showOrder, setShowOrder] = useState(false)
    const [showDetailLoader,setShowDetailLoader] = useState(false)
    const [showTransactionLoader,setShowTransactionLoader] = useState(false)
    const [showPointsLoader,setShowPointsLoader] = useState(false)
    const [showLoadingOrder, setLoadingOrder] = useState(false)
    const [orderDetails, setOrderDetails]  = useState({})
    const [defaultCard, setDefaultCard] = useState()
    const [enlargedImage, setEnlargedImage] = useState()
    const [ imageChosen, setImageChosen ] = useState (-1);
    const [ imageName, setImageName ] = useState (null);

    const enlargeImage = (imageLink, name) => {setEnlargedImage (true); setImageChosen(imageLink); setImageName (name)};

    useEffect(() => {
        // const analytics = getAnalytics(app);
         showOrder?firebase.logEventForAnalytics('Order Details'):firebase.logEventForAnalytics('Transactions');
     }, [showOrder])

    useEffect(() => {
        fetchMemberTransactionList()
        getMemberPointsBalance()
        let cardDetails = userDetails && userDetails.cards && userDetails.cards.filter(aCard=>aCard.defaultStatus === "DefaultCard")
        if(cardDetails[0] && cardDetails[0].cardNumber!==null){
            setDefaultCard(cardDetails[0].cardNumber)
        }
        else {setDefaultCard("1234 8793 3233 575")}

    }, [])

    

    //console.log("order details",orderDetails.order.updateTime);

    const fetchMemberTransactionList = () => {
        setShowTransactionLoader(true)
        setShowOrder(false)
        memberService.getTransactionList(userId).then(response => {
            setTransactionList(response.data)
            getSpecificTransactions(userId,response.data && response.data.transactionList && response.data.transactionList[0] && response.data.transactionList[0].id)
            setActiveTransactionText(response.data && response.data.transactionList && response.data.transactionList[0] && response.data.transactionList[0].id)

        }).catch(error => {}).finally(() =>{
            setShowTransactionLoader(false)
        })
    }

    const getMemberPointsBalance = () => {
        setShowPointsLoader(true)
        let request = {
            fetchLimit: 1000,
            paginationBookmark: 0
        }

        memberService.getMemberPointsBalance(userId, request).then(response => {
            setTotalPoints(response.data.totalPoints)
            let sortedPointsData = response.data && response.data.points.sort(function (a, b) {
                return moment(b.expiryDate) - moment(a.expiryDate);
            }).reverse();
            const result = Array.from(new Set(sortedPointsData.map(s => s.expiryDate)))
            .map(expDate => {
                let arrayList = []
                arrayList.push({expiryDate: expDate,
                    balance: response.data && response.data.points.filter(s => s.expiryDate === expDate).map(edition => edition.balance)})
                return {expiryDate: expDate,
                balance: response.data && response.data.points.filter(s => s.expiryDate === expDate).map(edition => edition.balance)}
            })           
        let finalResult = []
        result.forEach(aValue => {
            let sum = 0
            let expiryDate = aValue.expiryDate
            // aValue[0].balance
            aValue.balance.forEach(b => {
                sum = sum + b
            })
            // sum = sum + parseInt(aValue[0].balance)
            finalResult.push({ expiryDate: expiryDate, sum: sum })
        })
            setMemberPoints(finalResult)

        }).catch(error => {}).finally(() =>{
            setShowPointsLoader(false)
        })
    }


    const getFormattedExpiryDate = (expiryDate) => {
        let newExpiryDate = moment(expiryDate).format('DD MMM YYYY')
        return newExpiryDate;
    }

    const getFormattedTransactionDate = (transactionTime) => {
        let newTransactionTime = moment(transactionTime).format('DD MMM')
        return newTransactionTime;
    }

    const handleTransactionDetails = (transaction) => {
        setShowOrder(false)
        getSpecificTransactions(userId,transaction.id)
        setActiveTransactionText(transaction && transaction.id)
        

    }

    const getSpecificTransactions = (userId,transactionId) =>{
        setShowDetailLoader(true)
        memberService.getSpecificTransactions(userId, transactionId).then(response => {
            setActiveTransaction(response && response.data && response.data.transaction)
            setValidityOfTransaction(response && response.data && response.data.points)
        }).catch(error => {}).finally(() =>{
            setShowDetailLoader(false)

        })
    }

    const getTransactionDescription = (transactionType) => {
        switch (transactionType) {
            case "RegularSale":
                return "Regular sale"
            case "CampaignSale":
                return "Campaign sale"
            case "PointReturnPending":
                return
            case "CheckIn":
                return "Campaign check in"
            case "OpsAward":
                return "Points awarded"
            case "OpsDeduct":
                return "Points deducted"
            case "GenBluStore":
                return "Genblu purchase"
            case "WooCommerceSale":
                return "Points redeemed"
            case "PointsExpired":
                return "Points expired "
            case "GenBluRegularPurchase":
                return "Genblu regular purchase"
            case "GenBluCampaignPurchase":
                return "Genblu campaign purchase"
            case "PointPurchasePending":
                    return "Subscription Transaction Failed"
            default:
                return
        }
    }

    const handleOrderSummary = (transaction) => {
        setShowOrder(true)
        setLoadingOrder(true)
        memberService.getOrderDetails(transaction.id).then(response=>{
            setOrderDetails(response && response.data)
        }).catch(error => {}).finally(() =>{
            setLoadingOrder(false)

        })
    }

    const closeOrderSummary = () => {
        setShowOrder(false)
    }

    const viewReceipt =() => {
        window.open(
            orderDetails.order.invoiceUrl,
            '_blank' // <- This is what makes it open in a new window.
          );
    }


    return (
        <>
            < >
                <Row >
                    <Col className="order-xl-1" xl="12">
                        <Card className="bg-white shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className=" mt-3">
                                    <Col xs="6">
                                        <Card className="mt-3">
                                            <CardHeader className="d-flex" style={{ justifyContent: 'space-between', height: '60px' }}>
                                                <p style={{ color: '#373A3C', fontSize: '16px', fontWeight: '600' }}>{t("Points Validity")}</p>
                                                <span className="d-flex" style={{ justifyContent: 'flex-end' }}><p style={{ color: '#373A3C', fontSize: '16px' }}>{t("My reward points")} : </p>
                                                    <span style={{ color: '#373A3C', fontSize: '20px', marginLeft: '5px' }}>{totalPoints && totalPoints.toLocaleString()}</span>
                                                </span>
                                            </CardHeader>
                                            <CardBody className="mt--3" style={{ overflow: 'auto', maxHeight: '200px' }}>
                                            {showPointsLoader && <div className="d-flex" style={{ margin: '10% 40%' }}><Spinner style={{ marginTop: '10px' }} color="primary" />
                                                    </div>}
                                                {!showPointsLoader && memberPoints && memberPoints.map(aPointData => {
                                                    return (
                                                        <Row style={{ width: '100%', borderBottom: '1px solid #dddd' }} >
                                                            <Col xs={5} className=" d-flex"><p style={{ color: '#373A3C', fontSize: '14px', fontWeight: '600', paddingBottom: '5px', paddingTop: '12px' }}>{t("Valid Till")}: </p>
                                                                <p style={{ color: '#373A3C', fontSize: '14px', marginLeft: '5px', paddingBottom: '10px', paddingTop: '12px' }}>    {getFormattedExpiryDate(aPointData.expiryDate)}</p></Col>
                                                            <Col xs={7} style={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: '10px', paddingTop: '12px' }}>
                                                                <Button style={{ backgroundColor: '#ddd', border: 'none', width: '118px', height: '34px', fontSize: '13px', fontWeight: '600', cursor: 'auto', textAlign:'right' }} type="button">{aPointData.sum && aPointData.sum.toLocaleString()} {t("Points")}</Button>
                                                            </Col>
                                                        </Row>

                                                    )
                                                })}
                                                {/* <Row><Col xs="12" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <p style={{ color: '#138de8', fontSize: '12px', marginRight: '30px' }}>Load More....</p>
                                                </Col></Row> */}

                                            </CardBody>
                                        </Card>
                                        <Card className="mt-3">
                                            <CardHeader className="d-flex" style={{ justifyContent: 'space-between', height: '60px' }}>
                                                <p style={{ color: '#373A3C', fontSize: '16px', fontWeight: '600' }}>{t("Transaction history")}</p>

                                            </CardHeader>
                                            {transactionList && transactionList.transactionList &&transactionList.transactionList.length===0 && !showTransactionLoader && <CardBody>
                                                <Row><Col xs="12" className="d-flex justify-content-center">
                                                          <p className="waterMark" >{t("No transactions to show")}</p></Col></Row> 
                                            </CardBody>}
                                            {showTransactionLoader && <div className="d-flex" style={{ margin: '10% 40%' }}><Spinner style={{ marginTop: '10px' }} color="primary" />
                                                    </div>}
                                           {transactionList &&transactionList.transactionList &&transactionList.transactionList.length>0 && !showTransactionLoader && <CardBody className="mt--3" style={{ overflow: 'auto', maxHeight: '400px' }}>
                                                {transactionList && transactionList.transactionList && transactionList.transactionList.map(aTransaction => {
                                                    return (
                                                        <Row style={{
                                                            width: '100%', height: '63px', borderBottom: '1px solid #dddd', paddingBottom: '15px', paddingTop: '5px', cursor: 'pointer',
                                                            backgroundColor: aTransaction && aTransaction.id === activeTransactionText ? '#dddddd33' : ''
                                                        }} onClick={() => handleTransactionDetails(aTransaction)}>
                                                            <Col xs={2} className="d-flex">
                                                                <Button style={{ border: 'none', width: '100px', height: '53px', fontSize: '13px', fontWeight: '600', cursor: 'auto' }} color="primary" type="button">
                                                                    {getFormattedTransactionDate(aTransaction.transactionTime)}
                                                                </Button>
                                                            </Col>
                                                            <Col xs={6}>
                                                                <p className="descriptionText" style={{ fontWeight: aTransaction && aTransaction.id === activeTransactionText ? '600' : '' }}>{aTransaction.transactionType ==="PointPurchasePending" ? "Subscription Transaction Failed"  : aTransaction.description}</p>
                                                            </Col>
                                                            {/* #00C851  #D5F8E4*/}
                                                            <Col xs={4} className="mt-2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                {aTransaction.transactionType !=="PointPurchasePending" && aTransaction.pointAction === 'plus' && <Button style={{ backgroundColor: ' #D5F8E4', color: '#00C851', border: 'none', width: '118px', height: '34px', fontSize: '13px', fontWeight: '600', cursor: 'auto', textAlign:'right'}} type="button"> + {aTransaction.pointsRewarded} {t("Points")}</Button>
                                                                }
                                                                {aTransaction.transactionType !=="PointPurchasePending" && aTransaction.pointAction === 'minus' && <Button style={{ backgroundColor: '#FFEAE8', color: '#FF6558', border: 'none', width: '118px', height: '34px', fontSize: '13px', fontWeight: '600', cursor: 'auto', textAlign:'right'}} type="button"> - {aTransaction.pointsDeducted || aTransaction.pointsSpent}{t("Points")}</Button>
                                                                }
                                                                 {aTransaction.transactionType ==="PointPurchasePending"  && <Button style={{ backgroundColor: '#FFEAE8', color: '#FF6558', border: 'none', width: '118px', height: '34px', fontSize: '13px', fontWeight: '600', cursor: 'auto', textAlign:'right'}} type="button"> - {aTransaction.pointsPurchased}{t("Points")}</Button>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    )
                                                })}
                                                <hr style={{ marginTop: '10px' }} />
                                                <p></p>

                                            </CardBody>
                                        }
                                        </Card>
                                    </Col>
                                    <Col xs="6">
                                        <Card className="mt-3">
                                            <CardHeader className="d-flex" style={{ height: '60px' }}>
                                                {showOrder && <i className="fas fa-arrow-left mt-1" style={{ color: '#138de8', marginRight: '10px' }} onClick={closeOrderSummary}></i>
                                                }                                             <p style={{ color: '#373A3C', fontSize: '16px', fontWeight: '600' }}>{t("Details")}</p>

                                            </CardHeader>
                                            {showDetailLoader && <div className="d-flex" style={{ margin: '10% 40%' }}><Spinner style={{ marginTop: '10px' }} color="primary" />
                                                    </div>}
                                            {activeTransaction && activeTransaction.id != null && !showOrder && !showDetailLoader &&
                                                <CardBody>
                                                    <Row>
                                                        <Col xs={12} className="d-flex align-items-center" style={{ justifyContent: 'center' }}>
                                                        {activeTransaction.transactionType !== "PointPurchasePending" && activeTransaction.pointAction === 'plus' && <p style={{ textTransform: "uppercase", color: '#373A3C', fontSize: '16px' }}>{t("Points accrued")}</p>}
                                                        {activeTransaction.transactionType !== "PointPurchasePending" && activeTransaction.pointAction === 'minus' && activeTransaction.transactionType !== "PointsExpired" && <p style={{ textTransform: "uppercase", color: '#373A3C', fontSize: '16px' }}>{t("Points deducted")}</p>}
                                                        {activeTransaction.transactionType !== "PointPurchasePending" && activeTransaction.pointAction === 'minus' && activeTransaction.transactionType === "PointsExpired" && <p style={{ textTransform: "uppercase", color: '#373A3C', fontSize: '16px' }}>{t("Points expired")}</p>}
                                                        {activeTransaction.transactionType === "PointPurchasePending" &&  <p style={{ textTransform: "uppercase", color: '#373A3C', fontSize: '16px' }}>Subscription Transaction Failed</p>}

                                                        
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={12} className="d-flex align-items-center" style={{ justifyContent: 'center' }}>
                                                            {activeTransaction.transactionType !== "PointPurchasePending" && activeTransaction.pointAction === 'plus' &&
                                                                <Button style={{ backgroundColor: ' #D5F8E4', color: '#00C851', border: 'none', width: '100%', height: '44px', fontSize: '20px', fontWeight: 'normal', cursor:"auto" }} type="button"> + {activeTransaction.pointsRewarded} {t("Points")}</Button>}

                                                            {activeTransaction.transactionType !== "PointPurchasePending" && activeTransaction.pointAction === 'minus' &&
                                                                <Button style={{ backgroundColor: '#FFEAE8', color: '#FF6558', border: 'none', width: '100%', height: '44px', fontSize: '20px', fontWeight: 'normal' , cursor:"auto"}} type="button"> - {activeTransaction.pointsDeducted || activeTransaction.pointsSpent} {t("Points")}</Button>}

                                                            {activeTransaction.transactionType === "PointPurchasePending" &&
                                                                <Button style={{ backgroundColor: '#FFEAE8', color: '#FF6558', border: 'none', width: '100%', height: '44px', fontSize: '20px', fontWeight: 'normal' , cursor:"auto"}} type="button">  {activeTransaction.pointsPurchased } {t("Points")}</Button>}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={12} className="d-flex align-items-center" style={{ justifyContent: 'space-between' }}>
                                                            <div className="d-flex align-items-center"> <i className="far fa-money-bill-alt" style={{ color: '#F0AD4E' }}></i>
                                                                <p style={{ color: '#373A3C', fontSize: '13px', margin: '10px' }}>{t("Transaction type")}</p>
                                                            </div>
                                                            <p style={{ color: '#373A3C', fontSize: '14px', margin: '10px', fontWeight: '600' }}>{getTransactionDescription(activeTransaction.transactionType)}</p>
                                                        </Col>
                                                    </Row>
                                                    <Card>
                                                        <Row >
                                                            <Col xs={12} className="d-flex align-items-center" style={{ justifyContent: 'space-between' }}>
                                                               {activeTransaction.transactionType !== "PointPurchasePending" && activeTransaction.pointAction === 'minus' && activeTransaction.transactionType !== "PointsExpired" && <p style={{ color: '#373A3C', fontSize: '13px', margin: '10px' }}>{t("Used on")}</p>}
                                                               {activeTransaction.transactionType !== "PointPurchasePending"  && activeTransaction.pointAction === 'plus'  && <p style={{ color: '#373A3C', fontSize: '13px', margin: '10px' }}>{t("Awarded on")}</p>}
                                                               {activeTransaction.transactionType !== "PointPurchasePending" && activeTransaction.pointAction === 'minus' && activeTransaction.transactionType === "PointsExpired" && <p style={{ color: '#373A3C', fontSize: '13px', margin: '10px' }}>{t("Expired on")}</p>}
                                                               {activeTransaction.transactionType === "PointPurchasePending" && <p style={{ color: '#373A3C', fontSize: '13px', margin: '10px' }}>Failed on</p>}

                                                               
                                                                <p style={{ color: '#373A3C', fontSize: '14px', margin: '10px', fontWeight: '600' }}>{getFormattedExpiryDate(activeTransaction.transactionTime)}</p>
                                                            </Col>
                                                        </Row>
                                                       {activeTransaction.transactionType !== "PointPurchasePending" && activeTransaction.pointAction === 'plus' && validityOfTransaction &&<><hr style={{ marginTop: '-5px', marginBottom: '-5px' }} />
                                                        <Row>
                                                            <Col xs={12} className="d-flex" style={{ justifyContent: 'space-between' }}>
                                                                <p style={{ color: '#373A3C', fontSize: '13px', margin: '10px' }}>{t("Valid Till")}</p>
                                                                <p  style={{ color: '#373A3C', fontSize: '14px', margin: '10px', fontWeight: '600',textAlign:'right' }}>{getFormattedExpiryDate(validityOfTransaction.expiryDate)}</p>
                                                            </Col>
                                                        </Row></>}
                                                        <hr style={{ marginTop: '-5px', marginBottom: '-5px' }} />
                                                       { activeTransaction.transactionType !== "PointPurchasePending" && <Row>
                                                            <Col xs={12} className="d-flex" style={{ justifyContent: 'space-between' }}>
                                                                <p style={{ color: '#373A3C', fontSize: '13px', margin: '10px' }}>{t("Summary")}</p>
                                                                <p  style={{ color: '#373A3C', fontSize: '14px', margin: '10px', fontWeight: '600',textAlign:'right' }}>{activeTransaction.description}</p>
                                                            </Col>
                                                        </Row>}

                                                        { activeTransaction.transactionType === "PointPurchasePending" && <Row>
                                                            <Col xs={12} className="d-flex" style={{ justifyContent: 'space-between' }}>
                                                                <p style={{ color: '#373A3C', fontSize: '13px', margin: '10px' }}>{t("Summary")}</p>
                                                                <p  style={{ color: '#373A3C', fontSize: '14px', margin: '10px', fontWeight: '600',textAlign:'right' }}>{"Subscription Transaction Failed"}</p>
                                                            </Col>
                                                        </Row>}
                                                        <hr style={{ marginTop: '-5px', marginBottom: '-5px' }} />

                                                        { activeTransaction.merchantRemarks &&<> <Row>
                                                            <Col xs={12} className="d-flex" style={{ justifyContent: 'space-between' }}>
                                                                <p style={{ color: '#373A3C', fontSize: '13px', margin: '10px' }}>{t("Merchant Remarks")}</p>
                                                                <p  style={{ color: '#373A3C', fontSize: '14px', margin: '10px', fontWeight: '600',textAlign:'right' }}>{activeTransaction.merchantRemarks}</p>
                                                            </Col>
                                                        </Row>
                                                        { activeTransaction.merchantRemarksAttachment && <Row>
                                                            <Col xs={12} className="d-flex" style={{ justifyContent: 'end' }}>
                                                                <p  style={{ color: '#1da1f2', fontSize: '14px', margin: '10px', fontWeight: '600',textAlign:'right' }} onClick={()=>{enlargeImage (`${cloudfrontConfig.cloudfrontBaseUrl}/merchantRemarksAttachment-images/${activeTransaction.merchantRemarksAttachment}`,'Attachment') }}><Attachment/>{t("View Attachment")}</p>
                                                            </Col>
                                                        </Row>}
                                                        <hr style={{ marginTop: '-5px', marginBottom: '-5px' }} />
                                                        </>}
                                                       
                                                       {activeTransaction.transactionType !== "PointPurchasePending" && <Row >
                                                            <Col xs={12} className="d-flex align-items-center" style={{ justifyContent: 'space-between' }}>
                                                                <p style={{ color: '#373A3C', fontSize: '13px', margin: '10px' }}>{t("Card used for this transaction")}</p>
                                                                <p style={{ color: '#373A3C', fontSize: '14px', margin: '10px', fontWeight: '600' }}>{activeTransaction.cardNumber || defaultCard}</p>
                                                            </Col>
                                                        </Row>}
                                                    
                                                       {activeTransaction.pointsRequestRefNumber && <Row >
                                                            <Col xs={12} className="d-flex align-items-center" style={{ justifyContent: 'space-between' }}>
                                                                <p style={{ color: '#373A3C', fontSize: '13px', margin: '10px' }}>Reference Number</p>
                                                                <p style={{ color: '#373A3C', fontSize: '14px', margin: '10px', fontWeight: '600' }}>{activeTransaction.pointsRequestRefNumber}</p>
                                                            </Col>
                                                        </Row> }

                                                        {activeTransaction.address && <> <hr />
                                                            <Row>
                                                                <Col xs={12} className="d-flex align-items-center" style={{ justifyContent: 'space-between' }}>
                                                                    <p style={{ color: '#373A3C', fontSize: '13px', margin: '10px' }}>Location</p>
                                                                    <p style={{ color: '#373A3C', fontSize: '14px', margin: '10px', fontWeight: '600' }}>{activeTransaction.address}</p>
                                                                </Col>
                                                            </Row></>}


                                                            {activeTransaction.pointAction === "plus"  && activeTransaction.transactionType !== "PointPurchasePending" && <Row >
                                                            <Col xs={12} className="d-flex align-items-center" style={{ justifyContent: 'space-between' }}>
                                                                <p style={{ color: '#373A3C', fontSize: '13px', margin: '10px' }}>{t("Awarded by")}</p>
                                                                <p style={{ color: '#373A3C', fontSize: '14px', margin: '10px', fontWeight: '600' }}>{activeTransaction.awardedBy}</p>
                                                            </Col>
                                                        </Row>

                                                        }
                                                    {enlargedImage && <Lightbox image={imageChosen} onClose = { () => { setEnlargedImage(false) } }/> }
                                                    </Card>


                                                    {activeTransaction.transactionType === 'WooCommerceSale' &&
                                                        <Row className="mb-3 mt-4">
                                                            <Col xs="12" style={{ justifyContent: 'center', display: 'flex' }}>
                                                                <Button style={{ border: 'none', width: '100%', height: '43px', fontSize: '13px', fontWeight: '600', cursor: 'auto' }}
                                                                    color="primary" type="button" onClick={()=>handleOrderSummary(activeTransaction)}>
                                                                    {t("Order Details")}
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    }
                                                </CardBody>}
                                            {activeTransaction && activeTransaction.id != null && showOrder && !showDetailLoader &&
                                                <CardBody>
                                                    {showLoadingOrder && <div className="d-flex" style={{ margin: '10% 40%' }}><Spinner style={{ marginTop: '10px' }} color="primary" />
                                                    </div>}
                                                   {!showLoadingOrder && orderDetails && <> 
                                                   {orderDetails && orderDetails.order.line_items && orderDetails.order.line_items.map(anItem=>{
                                                       return(<Row  style={{borderBottom: '1px solid #dddd' }}>
                                                           <Col xs={4}>
                                                           <img src={anItem.imageUrl} alt="" style={{ marginTop: '1px 10px', marginLeft: '20px',height:'110px',width:'110px' }} />

                                                        </Col>
                                                        <Col xs={8}>
                                                            <p style={{ color: '#373A3C', fontSize: '13px',  fontWeight: '600' }}>{anItem.name}</p>
                                                            <Row>
                                                                <Col xs={4}> <p className="productLabel">{anItem.size}</p></Col>
                                                                <Col xs={4}> <p className="productLabel">{anItem.color}</p></Col>

                                                                </Row>
                                                            <Row>
                                                                <Col xs={4}>
                                                                <p className="productQuantityLabel">{t("Unit Points")}</p>
                                                                <p className="mt--2 productQuantity" style={{ marginLeft: '10px', marginRight: '10px' }}>{anItem.price}</p>

                                                                </Col>
                                                                <Col xs={4}>
                                                                <p className="productQuantityLabel">{t("Qty")}</p>
                                                                <p className="mt--2 productQuantity" style={{ marginLeft: '10px', marginRight: '10px' }}>{anItem.quantity}</p>

                                                                </Col>
                                                                <Col xs={4}>
                                                                <p className="productQuantityLabel">{t("Total Points")}</p>
                                                                <p className="mt--2 productQuantity" style={{ marginLeft: '10px', marginRight: '10px' }}>{anItem.total}</p>

                                                                </Col>

                                                            </Row>
                                                        </Col>
                                                           </Row>)
                                                   })}
                                                    
                                                   <Row style={{borderBottom: '1px solid #dddd' }}>
                                                        <Col xs={6}>
                                                            <p style={{ color: '#373A3C', fontSize: '13px', margin: '0px 10px', fontWeight: '600' }}>{t("Transaction ID")}</p>
                                                        </Col>
                                                        <Col xs={6}>
                                                        <p style={{ color: '#373A3C', fontSize: '16px' }}>{orderDetails.order.woocommerceOrderId}</p>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{borderBottom: '1px solid #dddd' }}>
                                                        <Col xs={6}>
                                                            <p style={{ color: '#373A3C', fontSize: '13px', margin: '0px 10px', fontWeight: '600' }}>{t("Total Points")}</p>
                                                        </Col>
                                                        <Col xs={6}>
                                                        <p style={{ color: '#373A3C', fontSize: '16px' }}>{orderDetails.order.total}</p>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{borderBottom: '1px solid #dddd' }}>
                                                        <Col xs={6}>
                                                            <p style={{ color: '#373A3C', fontSize: '13px', margin: '0px 10px', fontWeight: '600' }}>{t("Order Placed")} </p>
                                                        </Col>
                                                        <Col xs={6}>
                                                        <p style={{ color: '#373A3C', fontSize: '13px' }}>{orderDetails.order.dateCreated}</p>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{borderBottom: '1px solid #dddd' }}>
                                                        <Col xs={6}>
                                                            <p style={{ color: '#373A3C', fontSize: '13px', margin: '0px 10px', fontWeight: '600',display: 'flex'}}>
                                                            <i className="fas fa-map-marker-alt" style={{margin:'5px'}}></i>{t("Shipping Address")}</p>
                                                        </Col>
                                                        <Col xs={6}>
                                                        <p style={{ color: '#373A3C', fontSize: '13px' }}>{orderDetails.order.shipping && (orderDetails.order.shipping.first_name) }</p>
                                                        <p style={{ color: '#373A3C', fontSize: '13px' }}>{orderDetails.order.shipping && (orderDetails.order.shipping.address_1 + " " + orderDetails.order.shipping.address_2 + " "
                                                        + " " + orderDetails.order.shipping.city + "," + orderDetails.order.shipping.state + "," + orderDetails.order.shipping.postcode ) }</p>

                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={12} className="d-flex mt-2">
                                                        <Button style={{ border: 'none', width: '100%', height: '43px', fontSize: '13px', fontWeight: '600', cursor: 'auto' }}
                                                                    color="primary" type="button" onClick={()=>viewReceipt()} >
                                                                    {t("View Receipt")}
                                                                </Button>
                                                        </Col>
                                                        
                                                    </Row></>}

                                                </CardBody>
                                            }


                                        </Card>
                                    </Col>

                                </Row>

                            </CardHeader>
                            <CardBody>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </>
        </>
    );
};

export default TransactionHistory;
