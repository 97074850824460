
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Row, CardText, Nav,
    Col, TabContent, TabPane, NavItem, NavLink, CardTitle,
    Spinner
} from "reactstrap";
import "../../views/LoginComponents/login.css"
import { Redirect, Link } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import memberService from "../service/APIService"
import classnames from 'classnames';
import yamahaVoucherTitle from "assets/img/yamahaIcons/yamahaVoucherTitle.png"
import ticketRed from "assets/img/yamahaIcons/ticketRed.svg"
import ticketGrey from "assets/img/yamahaIcons/ticketGrey.svg"
import QRCode from "react-qr-code";

import moment from "moment"
import EditProfile from "./EditProfile";
import UserHeader from "components/Headers/UserHeader";
import EventsAttended from "./EventsAttended";
import ChangePassword from "./ChangePassword";
import EditUsername from "./EditUsername";
import SetUsername from "./SetUsername";
import BikeDetails from "./BikeDetails/BikeDetails";
import firebase from "utils/firebase";
import {t} from 'i18next';
function SettingsDashboard(props) {
    const [activeTab, setActiveTab] = useState('1');
    const [userId, setUserId] = useState(sessionStorage.getItem('userId'))
    const [autoOpenAddBike,setAutoOpenAddBike] =useState(false);
    const [userDetails, setUserDetails] = useState(JSON.parse(sessionStorage.getItem('userDetails')))
    const [isFromredirectedBool, setIsFromredirectedBool] = useState(false);

    const toggle = tab => {

        if (activeTab !== tab){ 
            setActiveTab(tab);
            firebase.logEventForAnalytics(screenName(tab))
        }
        
    }

    const screenName = tab =>{
        switch(tab){
            case '1' : return "Edit profile"
            case '2' : return "Change Password"
            case '3' : return "Edit UserName"
            case '5' : return "Bike Info"
            case '6' : return "Events Attended"
        }
    }
    const openDirectFromPopup =()=>{
      
    }

    const saveUserDetails = (response) => {
        sessionStorage.setItem('userDetails', JSON.stringify(response))
        setUserDetails(response)
    }

    // useEffect(() => {
    //     setActiveTab('5');
    //     setAutoOpenAddBike(true)
    // }, [props && props.location && props.location.state === "activeSetting"])

    
    useEffect(() => {
        
        if(props!=null && props.location != null && props.location.state != null && (props.location.state.activeSetting ==true||props.location.state.bikeSetting ==true)){
            toggle('5');
            if(props.location.state.activeSetting ==true)
            setIsFromredirectedBool(true);
            else setIsFromredirectedBool(false);
            console.log("setting redirected")
        }
        else {
            setIsFromredirectedBool(false);
            firebase.logEventForAnalytics("Edit Profile")
        }
        console.log("sadasdas",props.location.state);
        
        
    }, [])

    console.log("bool",isFromredirectedBool);
    return (
        <>
            <Row>
                <Col className="order-xl-1" xl="12">
                    <Card className="bg-white shadow">
                        <CardHeader className="bg-white border-0">
                            <Row>
                                <Col xs="12" className="mt-4">
                                    <Card className="mt-3">
                                        <CardHeader style={{ padding: '0px' }}>
                                            <Nav tabs>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === '1' })}
                                                        onClick={() => { toggle('1'); }}
                                                    >{t("Edit profile")}
                                                    </NavLink>
                                                </NavItem>
                                                {userDetails.username != null && <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === '2' })}
                                                        onClick={() => { toggle('2'); }} >
                                                        {t("Change Password")}
                                                    </NavLink>
                                                </NavItem>}
                                                {userDetails.username != null && <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === '3' })}
                                                        onClick={() => { toggle('3'); }} >
                                                        {t("Edit Username")}
                                                    </NavLink>
                                                </NavItem>}
                                                {userDetails.username == null && <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === '3' })}
                                                        onClick={() => { toggle('3'); }}
                                                    >
                                                        {t("Set Username")}
                                                    </NavLink>
                                                </NavItem>}
                                                {/* <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === '4' })}
                                                        onClick={() => { toggle('4'); }}
                                                    >
                                                        Payment Methods
                                                    </NavLink>
                                                </NavItem> */}
                                                <NavItem>
                                                    {/* <NavLink
                                                        className={classnames({ active: activeTab === '4' })}
                                                        onClick={() => { toggle('4'); }}
                                                    >
                                                        Support
                                                    </NavLink> */}
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === '5' })}
                                                        onClick={() => { toggle('5'); }}
                                                    >
                                                        {t("Bike Details")}
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === '6' })}
                                                        onClick={() => { toggle('6'); }}
                                                    >
                                                        {t("Events Attended")}
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </CardHeader>
                                        <CardBody style={{ overflow: 'auto' }}>
                                            <TabContent activeTab={activeTab}>
                                                <TabPane tabId="1">
                                                    <EditProfile></EditProfile>
                                                </TabPane>
                                                <TabPane tabId="2">
                                                    <ChangePassword></ChangePassword>
                                                </TabPane>
                                                <TabPane tabId="3">
                                                    {userDetails.username == null && <SetUsername parentCallback={saveUserDetails}></SetUsername>}
                                                    {userDetails.username != null && <EditUsername></EditUsername>}

                                                </TabPane>
                                                {/* <TabPane tabId="4">

                                                </TabPane> */}
                                                <TabPane tabId="4">

                                                </TabPane>
                                                <TabPane tabId="5">
                                                    <BikeDetails isFromRedirected={isFromredirectedBool}></BikeDetails>

                                                </TabPane>
                                                <TabPane tabId="6">
                                                    <EventsAttended></EventsAttended>

                                                </TabPane>
                                            </TabContent>

                                        </CardBody>
                                    </Card>
                                </Col>

                            </Row>
                        </CardHeader>
                        <CardBody>

                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </>
    );
};

export default SettingsDashboard;
