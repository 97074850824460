import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Row,
    Col, Form, InputGroup, InputGroupAddon, InputGroupText, Input, Spinner
} from "reactstrap";
// core components
import React, { useState, useEffect } from 'react';
import memberService from "../service/APIService";
import { Link } from "react-router-dom"
import "../../views/LoginComponents/login.css"
import "../../layouts/layouts.css"
import filter from "assets/img/yamahaIcons/filter.svg"
import { ToastsStore } from "react-toasts";
import CreateAddress from "../Address/CreateAddress"
import EditAddress from "./EditAddress";
import NotificationModal from "components/Utilities/NotificationModal";
import firebase from "utils/firebase";
import {t} from 'i18next';

const ManageAddress = () => {
    const [userDetails, setUserDetails] = useState(JSON.parse(sessionStorage.getItem("userDetails")))
    const [openPopup, setOpenPopup] = useState(false)
    const [openEditPopup, setEditPopup] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [defaultAddress, setDefaultAddress] = useState({})
    const [addressDetails, setAddressDetails] = useState({})
    const [otherAddresses, setOtherAddresses] = useState()
    const [selectedAddress, setSelectedAddress] = useState()
    const [addressType, setAddressType] = useState(null)
    const [notificationAlertPopup, setNotificationAlertOpen] = useState(false)
    const [message, setMessage] = useState()
    const [defaultMobile , setDefaultMobile ] = useState();
    useEffect(() => {
        getMemberProfile(null)
        firebase.logEventForAnalytics("Manage Address")
       
     
    }, [])

    const getMemberProfile = (address) => {
        setIsLoading(true)
        let userId = sessionStorage.getItem("userId")
        memberService.handleMemberProfile(userId).then(response => {
            let defaultAddress = response && response.data && response.data.addresses && response.data.addresses.filter(anAddress =>
                anAddress.defaultStatus && anAddress.defaultStatus === "DefaultAddress"
            )
            let otherAddresses = response && response.data && response.data.addresses && response.data.addresses.filter(anAddress =>
                anAddress.defaultStatus && anAddress.defaultStatus === "OtherAddress"
            )
            setDefaultMobile(response.data.mobile.mobileNumber)
            setDefaultAddress(defaultAddress[0])
            setOtherAddresses(otherAddresses)
            setUserDetails(response.data)
        }).catch(error => { }).finally(() => {
            setIsLoading(false)
        })
    }

    const editAddress = (addressDetails, addressType) => {
        console.log("asdasd" ,addressDetails )
        //setAddressDetails({...addressDetails, mobileNumber:defaultMobile})
        setAddressType(addressType)
        setAddressDetails(addressDetails)
        setEditPopup(true)
    }

    const createAddress = () => {
        setOpenPopup(true)
    }

    const closePopup = () => {
        setOpenPopup(false)
        setEditPopup(false)
        getMemberProfile()

    }

    const deleteAddress = (anAddress) => {
        setAddressDetails(anAddress)
        setMessage("Are you sure you want to delete?")
        setNotificationAlertOpen(true)
    }

    console.log("defaultAddress",defaultAddress);

    const deletingAddress = () => {
        if (userDetails && addressDetails) {
            setIsLoading(true)
            setNotificationAlertOpen(false)
            if(userDetails && userDetails.addresses && JSON.stringify(userDetails.addresses).includes(JSON.stringify((addressDetails)))){
   

                 let text=userDetails.addresses.filter(anAddress=>
                    JSON.stringify(anAddress)!==JSON.stringify(addressDetails)
                 )
                 let request = {
                    "name": userDetails.name,
                    "gender": userDetails.gender,
                    "dateOfBirth": userDetails.dateOfBirth,
                    "email": userDetails.email,
                    "profileImageName": userDetails.profileImageName ,
                    "mobile": {
                        "countryCode": userDetails && userDetails.mobile && userDetails.mobile.countryCode || "+60",
                        "mobileNumber": userDetails && userDetails.mobile && userDetails.mobile.mobileNumber
                    },
                    "addresses": text
                }
                 memberService.updateMemberProfileDetails(request, userDetails.id).then(response => {
                    sessionStorage.setItem("userDetails", JSON.stringify(response.data))
                    localStorage.setItem("userDetails",JSON.stringify(response.data))
                    setUserDetails(response.data)
                    let defaultAddress = response.data && response.data.addresses && response.data.addresses.filter(anAddress =>
                        anAddress.defaultStatus === "DefaultAddress"
                    )
                    let otherAddresses = response.data && response.data.addresses && response.data.addresses.filter(anAddress =>
                        anAddress.defaultStatus === "OtherAddress"
                    )
                    if(defaultAddress)
                    setDefaultAddress(defaultAddress[0])
                    setOtherAddresses(otherAddresses)
                }).catch(error => {
                    // setAPIError(error && error.response && error.response.data && error.response.data.message)
                }).finally(e => {
                    setIsLoading(false)
    //  
                })
            }
               
        }
    }

    const handleAddress = () =>{
        setOpenPopup(false) 
        setUserDetails(JSON.parse(sessionStorage.getItem("userDetails")))
        let userDetail = JSON.parse(sessionStorage.getItem("userDetails"))
        let defaultAddress = userDetail && userDetail.addresses && userDetail.addresses.filter(anAddress =>
            anAddress.defaultStatus === "DefaultAddress"
        )
        let otherAddresses = userDetail && userDetail.addresses && userDetail.addresses.filter(anAddress =>
            anAddress.defaultStatus === "OtherAddress"
        )
        if(defaultAddress)
        setDefaultAddress(defaultAddress[0])
        setOtherAddresses(otherAddresses)
        setUserDetails(JSON.parse(sessionStorage.getItem("userDetails")))
    }


console.log(defaultAddress);


    return (
        <>
            < >
                {openPopup && <CreateAddress userDetails={userDetails} openPopup={openPopup} closePopup={() => { setOpenPopup(false) }}
                   parentCallback={handleAddress}></CreateAddress>}
                {openEditPopup && <EditAddress defaultAddress={defaultAddress} addressType={addressType} userDetails={userDetails} addressDetails={addressDetails} openPopup={openEditPopup} 
                parentCallback={closePopup} defaultMobile = {defaultMobile} close={() => { setEditPopup(false) }}></EditAddress>}
                {notificationAlertPopup && <NotificationModal addressDetails={addressDetails} message={message} openPopup={notificationAlertPopup} parentCallback={deletingAddress}
                    closePopup={() => { setNotificationAlertOpen(false) }}></NotificationModal>}

                <Row >
                    <Col className="order-xl-1" xl="12">
                        <Card className="bg-white shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className=" mt-3">
                                    <Col className=" mt-4 d-flex" xs={12} style={{ justifyContent:'flex-end'}}>
                                        <Button color="primary" type="button" onClick={createAddress}>
                                            <i className="fas fa-plus"></i> {t("Add New Address")}
                                        </Button>
                                    </Col>
                                </Row>
                                {!isLoading &&<Row>
                                    <Col xs="12">
                                        <Card className="mt-3">
                                            <CardHeader style={{ height: '30px', marginBottom: '20px' }}>
                                                <p className="mt--3">{t("Default Address")}</p>
                                            </CardHeader>
                                            <Row><Col xs="6">
                                                <Card style={{ margin: '0px 30px 30px 20px' }}>
                                                    <CardBody>
                                                        <Row>
                                                            <Col xs="8">
                                                                <p className="textDescription">{defaultAddress && defaultAddress.fullName || userDetails && userDetails.name}</p>
                                                            </Col>
                                                            <Col xs="3">
                                                                <p className="textDescription" style={{color:"grey"}}>{defaultAddress && defaultAddress.addressType}</p>
                                                            </Col>
                                                            <Col xs="1" className="d-flex" style={{ justifyContent: 'flex-end' }}>
                                                                <i className="far fa-edit" style={{ color: "#138de8", marginRight: '10px', marginTop:"5px" }} onClick={() => editAddress(defaultAddress, "DefaultAddress")}></i>
                                                                {/* <i class="fas fa-trash-alt" style={{ color: "#138de8" }} onClick={()=>deleteAddress()}></i> */}

                                                            </Col>
                                                        </Row>
                                                        {defaultAddress!==undefined && <Row>
                                                            <Col xs="12" className="d-flex">
                                                                <p className="textHeading">{defaultAddress.addressLine1 !== undefined ? defaultAddress.addressLine1 : ''
                                                                    + (defaultAddress.addressLine2 !== undefined ? ", " + defaultAddress.addressLine2 : '')
                                                                    + ", " + defaultAddress.city
                                                                    + ", " + defaultAddress.state
                                                                    + ", "  + defaultAddress.postCode}</p>
                                                            </Col>
                                                        </Row>}
                                                        <Row>
                                                            <Col xs="12">
                                                                <p className="textHeading">{t("Mobile:")}{ defaultMobile && defaultMobile[0]!=="+" ? "+60" +  defaultMobile : defaultMobile } </p>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col></Row>

                                        </Card>
                                        <Card className="mt-3">
                                            <CardHeader style={{ height: '30px', marginBottom: '20px' }}>
                                                <p className="mt--3">{t("Other address")}</p>
                                            </CardHeader>
                                            <Row>
                                                {otherAddresses && otherAddresses.map(anAddress => {
                                                    return (

                                                        <Col xs="6">

                                                            <Card style={{ margin: '0px 30px 30px 20px' }}>
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col xs="8">
                                                                            <p className="textDescription">{anAddress.fullName !== undefined ? anAddress.fullName : userDetails.name}</p>
                                                                        </Col>
                                                                        <Col xs="3">
                                                                            <p className="textDescription" style={{color:"grey"}}>{anAddress.addressType}</p>
                                                                        </Col>
                                                                        <Col xs="1" className="d-flex" style={{ justifyContent: 'flex-end' }}>
                                                                            <i className="far fa-edit" style={{ color: "#138de8", marginRight: '10px', marginTop:"5px" }} onClick={() => editAddress(anAddress, "OtherAddress")}></i>
                                                                            <i className="fas fa-trash-alt" style={{ color: "#138de8", marginTop:"5px" }} onClick={() => deleteAddress(anAddress)}></i>

                                                                        </Col>
                                                                    </Row>
                                                                   {anAddress!== undefined&& <Row>
                                                                        <Col xs="12" className="d-flex">
                                                                            <p className="textHeading">{anAddress.addressLine1 !== undefined ? anAddress.addressLine1 : ''
                                                                                + ( anAddress.addressLine2 !== undefined ? ", " + anAddress.addressLine2 : '')
                                                                                + ", " + anAddress.city
                                                                                + ", " + anAddress.state
                                                                                + ", " + anAddress.postCode}</p>
                                                                        </Col>
                                                                    </Row>}
                                                                    <Row>
                                                                        <Col xs="12">
                                                                            <p className="textHeading">{t("Mobile:")} {anAddress &&  anAddress.mobileNumber ?   anAddress.mobileNumber[0]!=="+" ? "+60" + anAddress.mobileNumber  : anAddress.mobileNumber : ''} </p>
                                                                        </Col>
                                                                    </Row>

                                                                </CardBody>
                                                            </Card>
                                                        </Col>

                                                    )
                                                })}

                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>}
                    {isLoading && <Spinner style={{ margin: '20% 50%' }} color="primary" />}
                            </CardHeader>
                            <CardBody>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </>
        </>
    );
};

export default ManageAddress;
