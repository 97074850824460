import axios from "axios";

function _attachInterceptor() {
  axios.interceptors.request.use(
    config => {
      const token =  sessionStorage.getItem("accessToken")
      let s3Url = config.url.indexOf("s3.amazonaws.com") !== -1;
      if (token && !s3Url && !config.ignoreDefaultAuth) { 
        // config.headers["Authorization"] = token;
      }
      return config;
    },
    error => {
      Promise.reject(error);
    }
  );

  const getBaseUrl = process.env.REACT_APP_ENV === "PROD" ? process.env.REACT_APP_BASE_URL_PROD :  process.env.REACT_APP_ENV === "DEV" ? process.env.REACT_APP_BASE_URL :  process.env.REACT_APP_BASE_URL_STAGE;
  console.log("mybaseurl",getBaseUrl); 
  console.log("authbaseurl",`${getBaseUrl}/auth/refresh-token`);

  axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      const originalRequest = error.config;
      if (error.response && error.response.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true;
        const headers = {
          "Content-Type": "application/json",
          "x-api-key":"4zgXjiVjME3taE3FHIUtH94lzK7e3U1H1HX3Gk45"
        };
        return axios
          .post(`${getBaseUrl}/auth/refresh-token`, {
            refreshToken: sessionStorage.getItem("refreshToken"),
          },{ headers: headers,ignoreDefaultAuth: true })
          .then(res => {
            if (res.data) {
                sessionStorage.setItem("accessToken",res.data.accessToken);
              // originalRequest.headers["Authorization"] = sessionStorage.getItem("accessToken");
              return axios(originalRequest);
            } else {
                // _logout()
              // window.location = "/";
            }
          });
      }

      // return Error object with Promise
      return Promise.reject(error);
    }
  );
}


export const RequestInterceptor = { attachInterceptors: _attachInterceptor}
