import React, { useState,useEffect } from 'react';
import { Row, Col,Spinner, Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import "../../layouts/layouts.css"
import Select from 'react-select';
import memberService from "../service/APIService"
import firebase from 'utils/firebase';
import {t} from 'i18next';

const CreateAddress = (props) => {
    useEffect(()=>{
        firebase.logEventForAnalytics("Add New Address")
    })
    const {
        buttonLabel,
        className
    } = props;

    const [modal, setModal] = useState(false);
    const [data, setData] = useState({})
    const [isAddressTypeOthers, setAddressTypeOthers] = useState(false)
    const [error, setError] = React.useState({})
    const [userDetails,setUserDetails] = useState(props && props.userDetails)
    const [isLoading,setIsLoading]= useState(false)
    const [mobileError,setMobileError] = useState()
 
    const toggle = () => setModal(!modal);
    const options = [{ name: 'Home' }, { name: 'Work' }, { name: 'Others' }]
    const handleChange = (event) => {
        setError({
        })
        let phoneRegex = /^(\+?6?01)[0|1|2|3|4|6|7|8|9]\-*[0-9]{7,9}$/
        if (event && event.target && event.target.name === "mobileNumber") {
            if (!phoneRegex.test("+60" + event.target.value)) {
                setMobileError("Please enter valid mobile number.")
            }
            else {
                setMobileError("")
            }
        }
        
        if (event.name !== null && event.name !== 'Others') {
            setAddressTypeOthers(false)

            setData({
                ...data,
                ["addressType"]: event.name
            })
        }
        else if (event.name !== null && event.name === 'Others') {
            setAddressTypeOthers(true)
           
        }
        if (event && event.target && event.target.value) {           
            if (event.target && event.target.name === "addressType" && event.target.value !== undefined) {
                setAddressTypeOthers(true)

                setData({
                    ...data,
                    ["addressType"]: event.target.value
                })
            }
            else{
                setAddressTypeOthers(false)

                setData({
                    ...data,
                    [event.target.name]: event.target.value
                })
            }
        }
    }

    const saveAddress = () => {
        if (handleError()) {
            setIsLoading(true)
                let newAddress={
                "fullName":data.fullName,
                "mobileNumber":data.mobileNumber,
                "addressLine1": data.addressLine1,
                "addressLine2": data.addressLine2,
                "postCode": data.postCode,
                "city": data.city,
                "state": data.state,
                "addressType": data.addressType,
                "defaultStatus":data.defaultStatus
              }
            //   addresses.push(newAddress)
           
           
            let defaultAddress = userDetails && userDetails.addresses && userDetails.addresses.filter(anAddress =>
                anAddress.defaultStatus === "DefaultAddress"
            )
            let otherAddresses = userDetails && userDetails.addresses && userDetails.addresses.filter(anAddress =>
                anAddress.defaultStatus === "OtherAddress"
            )
            if(data && data.defaultStatus==='DefaultAddress'){
                if(defaultAddress && defaultAddress[0])
                defaultAddress[0].defaultStatus='OtherAddress'
            }
            otherAddresses.push(defaultAddress[0])

            let addresses=otherAddresses
            addresses.push(newAddress)
             let request = {
                "name": userDetails.name,
                "gender": userDetails.gender,
                "dateOfBirth": userDetails.dateOfBirth,
                "email": userDetails.email,
                "profileImageName": userDetails.profileImageName,
                "mobile": {
                  "countryCode": userDetails && userDetails.mobile && userDetails.mobile.countryCode || "+60",
                  "mobileNumber": userDetails && userDetails.mobile && userDetails.mobile.mobileNumber 
                },
                "addresses": addresses
              }
              memberService.updateMemberProfileDetails(request, userDetails.id).then(response => {
                  sessionStorage.setItem("userDetails",JSON.stringify(response.data))
                  localStorage.setItem("userDetails",JSON.stringify(response.data))
                 props.parentCallback(newAddress)
              }).catch(error => {
                // setAPIError(error && error.response && error.response.data && error.response.data.message)
              }).finally(e => {
                setIsLoading(false)
              })
        }
    }

    const handleError = () => {    
        if (data.fullName === undefined) {
          setError({
            ...error,
            ["name"]: "Please enter full name",
          });
          return false;
        } 
        
        // else if (data.mobileNumber === undefined ) {
        //   setError({
        //     ...error,
        //     ["mobileNumber"]: "Please enter mobile number",
        //   });
        //   return false;
        // } 
        else if (data.addressLine1 === undefined) {
          setError({
            ...error,
            ["addressLine1"]: "Please enter address line 1",
          });
          return false;
        } else if (data.postCode === undefined) {
          setError({
            ...error,
            ["postCode"]: "Please enter postcode",
          });
          return false;
        } else if (data.city === undefined) {
          setError({
            ...error,
            ["city"]: "City can not be empty",
          });
          return false;
        } else if (data.state === undefined) {
          setError({
            ...error,
            ["state"]: "State can not be empty",
          });
          return false;
        }
        else if (data.addressType === undefined) {
          setError({
            ...error,
            ["addressType"]: "Please select address type",
          });
          return false;
        }
        return true
      }

    const handlePostalCode = () => {
        getStateAndCity()
    }
    const getStateAndCity = () => {
        memberService.getStateAndCity(data.postCode).then(response => {
            setData({
                ...data,
                ["city"]: response.data.city,
                ["state"]: response.data.state,
                // [e.target.name]: null,
            });
        }).catch(error => {
            //   setError({
            //     ...error,
            //     ["postCode"]: error.response.data.message,
            //   });
        }).finally(e => {
        })
    }

    const handleCheckBox = (event) => {
        if(event  && event.target&&  event.target.checked){
            setData({
                ...data,
                ["defaultStatus"]: "DefaultAddress"
            })
        }
       else if(event  && event.target && !event.target.checked){
            setData({
                ...data,
                ["defaultStatus"]: "OtherAddress"
            })
        }
    }

    useEffect(()=>{
        setData({
            ...data,
            ["defaultStatus"]: "OtherAddress"
        })
    },[])

    return (
        <div>
            {/* <Button color="danger" onClick={toggle}>{buttonLabel}</Button> */}
            <Modal size="lg" isOpen={props.openPopup} className={className}>
                <ModalHeader toggle={props.closePopup}>{t("Create Address")}</ModalHeader>
                <ModalBody>
                    <div>
                        <Row className="mt-3">
                            <Col xs="6">
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText><i class="fas fa-user"></i></InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="Name" name="fullName"style={{ paddingLeft: '10px' }}
                                        onChange={handleChange} />
                                </InputGroup>
                                <small style={{ color: 'red' }}>{error.fullName}</small>

                            </Col>

                            <Col xs="6">
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText><i class="fas fa-mobile-alt"></i></InputGroupText>
                                       
                                      
                                        <InputGroupText> +60 </InputGroupText>
                                   
                                    </InputGroupAddon>
                                    <Input placeholder="Mobile number" name="mobileNumber" type="number"style={{ paddingLeft: '10px' }}
                                        onChange={handleChange} />
                                </InputGroup>
                                <small style={{ color: 'red' }}>{mobileError}</small>

                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col xs="6">
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText> <i className="fas fa-address-card"></i></InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="Address line 1" name="addressLine1"style={{ paddingLeft: '10px' }}
                                        onChange={handleChange} />
                                </InputGroup>
                                <small style={{ color: 'red' }}>{error.addressLine1}</small>

                            </Col>

                            <Col xs="6">
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText> <i className="fas fa-address-card"></i></InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="Address line 2 (Optional)" name="addressLine2"style={{ paddingLeft: '10px' }}
                                        onChange={handleChange} />
                                </InputGroup>
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col xs="6">
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText> <i className="fas fa-address-card"></i></InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="Postcode"style={{ paddingLeft: '10px' }}
                                        name="postCode"
                                        onChange={handleChange}
                                        onBlur={handlePostalCode}
                                    />
                                </InputGroup>
                                <small style={{ color: 'red' }}>{error.postCode}</small>

                            </Col>

                            <Col xs="6">
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText> <i className="fas fa-city"></i></InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="city" style={{ paddingLeft: '10px' }}
                                        value={data.city} disabled />
                                </InputGroup>
                                
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col xs="6">
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText> <i className="fas fa-address-card"></i></InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="state" style={{ paddingLeft: '10px' }}
                                        value={data.state} disabled />
                                </InputGroup>
                            </Col>

                            <Col xs={isAddressTypeOthers ? "3" : "6"}>
                                <Select isSearchable={false}
                                    options={options}
                                    getOptionLabel={(options) => options['name']}
                                    getOptionValue={(options) => options['name']}
                                    onChange={handleChange} />
                            </Col>
                            {isAddressTypeOthers && <Col xs="3">
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText> <i className="fas fa-address-card"></i></InputGroupText>
                                    </InputGroupAddon>
                                    <Input style={{ paddingLeft: '10px' }}
                                        name="addressType"
                                        placeholder="Address type"
                                        type="text"
                                        autoComplete="new-saveAddressAs"
                                        onChange={handleChange} />
                                </InputGroup>
                            </Col>}
                        </Row>
                        <Row>
                        <Col xs="12" style={{margin:'18px'}}>
                             <Input name="defaultStatus" type="checkbox" onChange={handleCheckBox} />{' '}
          {t("Set as default address")}</Col></Row>





                    </div>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-center">
                    <Button color="secondary" onClick={props.closePopup}>Cancel</Button>
                    {!isLoading && <Button color="primary" onClick={saveAddress} disabled={mobileError!=""}>{t("Save Address")}</Button>}
                    {isLoading && <Spinner style={{ marginTop: '10px' }} color="primary" />}

                </ModalFooter>
            </Modal>
        </div>
    );
}

export default CreateAddress;