
import {
    Button,
    Card,
    CardBody, CardHeader,
    CardFooter,
    Col, Row
} from "reactstrap";
import "../../views/LoginComponents/login.css"
import { Link } from "react-router-dom";
import React from 'react';
import bag from "assets/img/yamahaIcons/blueBag.svg"
import {t} from 'i18next';

function OrderConfirmatainScreen(props) {
    return (
        <>
            <Row >
                <Col className="order-xl-1" xl="12" >
                    <Card className="bg-white shadow">
                        <CardHeader className="bg-white border-0">
                            <Row className=" mt-3">
                                <Col xs="12">
                                    <Card className=" mt-4" style={{ height: '660px', overflow: 'auto' }}>
                                        <Row>
                                            <Col xs="12" className="d-flex">
                                                <img src={bag} alt="" style={{ marginTop: '1px 10px', marginLeft: '20px' }} />
                                                <p className="mt-1" style={{ margin: '0px 10px', color: '#138de8' }}>{t("Bag")} </p>

                                                <p className="mt-1" style={{ margin: '0px 5px', color: '#138de8' }}>-----------
                                                    <i className="fas fa-map-marker-alt mt-1" style={{ marginRight: '10px', marginLeft: '10px' }}></i> {t("Address")}</p>

                                                <p className="mt-1" style={{ margin: '0px 5px', color: '#138de8' }}> -----------
                                                    <i class="fas fa-check-square" style={{ marginRight: '10px', marginLeft: '10px' }}></i>
                                                    {t("Order")}</p></Col>
                                        </Row>
                                          <CardBody >
                            <Row >
                                <Col className="order-xl-1" xl="12">
                                    <Card className="bg-white shadow module-border-wrap">
                                        <CardBody className="px-lg-5 py-lg-5 text-center">
                                            <i className="fas fa-check-circle" style={{ color: '#12bf5f', fontSize: '90px' }}></i>
                                            <h4>{t("Congratulations!")}</h4>
                                            <br /><br />
                                            <p>{t("your order has been successfully placed")}</p>
                                            <div className="text-center">
                                                <Link to={{ pathname: '/admin/my-redemptions', }}>
                                                    <Button className="mt-3" color="primary" type="button" >
                                                        {t("Continue shopping")}
                                                    </Button>
                                                </Link>

                                            </div>
                                        </CardBody>

                                    </Card>
                                </Col>
                            </Row>
                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </CardHeader>
                      
                    </Card>
                </Col>
            </Row>

        </>
    );
};

export default OrderConfirmatainScreen;