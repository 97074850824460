import React, { useState, useEffect } from 'react';
import { Row, Col, Spinner, Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import "../../layouts/layouts.css"
import Select from 'react-select';

const NotificationModal = (props) => {
    const {
        buttonLabel,
        className
    } = props;

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);
  

    return (
        <div>
            {/* <Button color="danger" onClick={toggle}>{buttonLabel}</Button> */}
            <Modal size="sm" isOpen={props.openPopup} className={className}>
                <ModalHeader toggle={props.closePopup}>Alert!</ModalHeader>
                <ModalBody>
                    <div>
                     {props.message}
                    </div>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-center">
                    <Button color="secondary" onClick={props.closePopup}>Cancel</Button>
                   {!props.showLoader && <Button color="primary" onClick={props.parentCallback}>Okay</Button>}
                   {props.showLoader && <Spinner style={{ marginTop: '10px' }} color="primary" />}
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default NotificationModal;