import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Row,
    Col, CardFooter, Spinner
} from "reactstrap";
// core components
import React, { useState, useEffect } from 'react';
import memberService from "../service/APIService";
import { Link, Redirect } from "react-router-dom"
import "../../views/LoginComponents/login.css"
import "../../layouts/layouts.css"
import filter from "assets/img/yamahaIcons/filter.svg"
import { ToastsStore } from "react-toasts";
import CreateAddress from "../Address/CreateAddress"
import NotificationModal from "components/Utilities/NotificationModal";
import bag from "assets/img/yamahaIcons/blueBag.svg"
import firebase from "utils/firebase";
import {t} from 'i18next';

const CheckoutAddress = (props) => {
    const [userDetails, setUserDetails] = useState(JSON.parse(sessionStorage.getItem("userDetails")))
    const [openPopup, setOpenPopup] = useState(false)
    const [openEditPopup, setEditPopup] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [defaultAddress, setDefaultAddress] = useState({})
    const [ defaultMobile ,setDefaultMobile] = useState()
    const [addressDetails, setAddressDetails] = useState({})
    const [otherAddresses, setOtherAddresses] = useState([])
    const [selectedAddress, setSelectedAddress] = useState()
    const [showOrderConfirmation, setShowConfirmationScreen] = useState(null)
    const [notificationAlertPopup, setNotificationAlertOpen] = useState(false)
    const [message, setMessage] = useState()
    const [isShippingAddress, setIsShippingAddress] = useState(false)
    const [initialAddress, setInitialAddress] = useState(false)
    const [orderPunching, setOrderPunching] = useState(false)
    const [shippingAddress, setShippingAddress] = useState()
    const [finalListOfProducts, setFinalListOfProducts] = useState(props && props.location && props.location.state && props.location.state.finalListOfProducts)
    const [redemptionPoints, setRedemptionPoints] = useState(props && props.location && props.location.state && props.location.state.redemptionPoints)
    const [remainingProduct, setRemainingProduct] = useState(props && props.location && props.location.state && props.location.state.remainingProduct)


    useEffect(() => {
        getMemberProfile(null)
        firebase.logEventForAnalytics("Check Out Address")

    }, [])

    const getMemberProfile = (address) => {
        setIsLoading(true)
        let userId = sessionStorage.getItem("userId")
        memberService.handleMemberProfile(userId).then(response => {
            let defaultAddress = response && response.data && response.data.addresses && response.data.addresses.filter(anAddress =>
                anAddress.defaultStatus && anAddress.defaultStatus === "DefaultAddress"
            )
            let otherAddresses = response && response.data && response.data.addresses && response.data.addresses.filter(anAddress =>
                anAddress.defaultStatus && anAddress.defaultStatus === "OtherAddress"
            )
            if (address == null) {
                setSelectedAddress(defaultAddress[0])
                setShippingAddress(defaultAddress[0])
            }
            else {
                let lengthOfAddress = response.data.addresses && response.data.addresses.length
                setSelectedAddress(response.data.addresses && response.data.addresses[lengthOfAddress - 1])
                setShippingAddress(response.data.addresses && response.data.addresses[lengthOfAddress - 1])
            }
            setDefaultMobile(response.data.mobile.mobileNumber)
            setDefaultAddress(defaultAddress[0])
            setOtherAddresses(otherAddresses)
            setUserDetails(response.data)
        }).catch(error => { }).finally(() => {
            setIsLoading(false)
        })
    }



    const createAddress = () => {
        setOpenPopup(true)
    }

    const closePopup = () => {
        setOpenPopup(false)
    }

    const deleteAddress = (anAddress) => {
        setAddressDetails(anAddress)
        setMessage("Are you sure you want to delete?")
        setNotificationAlertOpen(true)
    }

    const deletingAddress = () => {
        if (userDetails && addressDetails) {
            setIsLoading(true)
            setNotificationAlertOpen(false)
            if (userDetails.addresses.includes(addressDetails)) {
                let filtered = userDetails.addresses.filter(anAddress => anAddress !== addressDetails)
                let addresses = filtered
                let request = {
                    "name": userDetails.name,
                    "gender": userDetails.gender,
                    "dateOfBirth": userDetails.dateOfBirth,
                    "email": userDetails.email,
                    "profileImageName": userDetails.profileImageName,
                    "mobile": {
                        "countryCode": userDetails && userDetails.mobile && userDetails.mobile.countryCode || "+60",
                        "mobileNumber": userDetails && userDetails.mobile && userDetails.mobile.mobileNumber
                    },
                    "addresses": addresses
                }
                memberService.updateMemberProfileDetails(request, userDetails.id).then(response => {
                    sessionStorage.setItem("userDetails", JSON.stringify(response.data))
                    localStorage.setItem("userDetails",JSON.stringify(response.data))
                    setUserDetails(JSON.stringify(response.data))
                }).catch(error => {
                    // setAPIError(error && error.response && error.response.data && error.response.data.message)
                }).finally(e => {
                    setIsLoading(false)

                })
            }
        }
    }

    const handleShippingAddress = (event, address) => {
        setSelectedAddress(address)
        setShippingAddress(address)
    }

    const handlePlaceOrder = () => {
        // setOrderPunching(true)
        console.log(remainingProduct);
        sessionStorage.setItem("Rem",JSON.stringify(remainingProduct));
        let line_items = []
        
        finalListOfProducts && finalListOfProducts.map(aProduct => {
            if (aProduct.variationId === null) {
                line_items.push({
                    id: aProduct.productId,
                    product_id: aProduct.woocommerceId,
                    quantity: aProduct.productQtyInCart<aProduct.stockQuantity?aProduct.productQtyInCart:aProduct.stockQuantity
                })
            }
            else {
                line_items.push({
                    id: aProduct.productId,
                    product_id: aProduct.woocommerceId,
                    variation_id: aProduct.variationId,
                    quantity: aProduct.variationQtyInCart<aProduct.variationQtyInStock?aProduct.variationQtyInCart:aProduct.variationQtyInStock
                })
            }
        })
        let orderShippingAddress = {
            "address_1": shippingAddress && shippingAddress.addressLine1,
            "address_2": shippingAddress && shippingAddress.addressLine2,
            "city": shippingAddress && shippingAddress.city,
            "country": "MY",
            "first_name": shippingAddress && shippingAddress.fullName != null ? shippingAddress.fullName : userDetails.name,
            "postcode": shippingAddress && shippingAddress.postCode,
            "state": shippingAddress && shippingAddress.state
        }
        let orderBillingAddress = {}
        if (userDetails && userDetails.email) {
            orderBillingAddress = {
                "first_name": userDetails && userDetails.name,
                "address_1": shippingAddress && shippingAddress.addressLine1,
                "address_2": shippingAddress && shippingAddress.addressLine2,
                "city": shippingAddress && shippingAddress.city,
                "state": shippingAddress && shippingAddress.state,
                "postcode": shippingAddress && shippingAddress.postCode,
                "country": "MY",
                "email": userDetails && userDetails.email,
                "phone": shippingAddress && shippingAddress.mobileNumber

            }
        }
        else {
            orderBillingAddress = {
                "first_name": userDetails && userDetails.name,
                "address_1": shippingAddress && shippingAddress.addressLine1,
                "address_2": shippingAddress && shippingAddress.addressLine2,
                "city": shippingAddress && shippingAddress.city,
                "state": shippingAddress && shippingAddress.state,
                "postcode": shippingAddress && shippingAddress.postCode,
                "country": "MY",
                "phone": shippingAddress && shippingAddress.mobileNumber

            }
        }

        let request = {
            "line_items": line_items,
            "paidStatus": "paid",
            "payment_method": "bacs",
            "payment_method_title": "Direct Bank Transfer",
            "set_paid": true,
            "shipping": orderShippingAddress,
            "billing": orderBillingAddress
        }
        setIsLoading(true)
        memberService.addGenbluProducts(request).then(response => {
            setIsLoading(false)
            setShowConfirmationScreen(true)
            firebase.logEventForAnalytics("Order Placed")

        }).catch(err => { }).finally(() => {
            setOrderPunching(false)
        })
    }


    const handleAddress = (newAddress) => {
        setOpenPopup(false)
        setSelectedAddress(newAddress)
        getMemberProfile(newAddress)
    }



    return (
        <>
            < >
                {showOrderConfirmation && <Redirect to="/admin/orderConfirmation" />}
                {openPopup && <CreateAddress userDetails={userDetails} openPopup={openPopup} closePopup={() => { setOpenPopup(false) }}
                    parentCallback={handleAddress}></CreateAddress>}
                {notificationAlertPopup && <NotificationModal addressDetails={addressDetails} message={message} openPopup={notificationAlertPopup} parentCallback={deletingAddress}
                    closePopup={() => { setNotificationAlertOpen(false) }}></NotificationModal>}

                <Row >
                    <Col className="order-xl-1" xl="12">
                        <Card className="bg-white shadow" >
                            <CardHeader className="bg-white border-0">
                                <Row className=" mt-3">
                                    <Col xs="12">
                                        <Card className=" mt-4">
                                            <Row>
                                                <Col xs="12" className="d-flex">
                                                    <img src={bag} alt="" style={{ marginTop: '1px 10px', marginLeft: '20px' }} />
                                                    <p className="mt-1" style={{ margin: '0px 10px', color: '#138de8' }}>{t("Bag")} </p>

                                                    <p className="mt-1" style={{ margin: '0px 5px', color: '#138de8' }}>-----------
                                                        <i className="fas fa-map-marker-alt mt-1" style={{ marginRight: '10px', marginLeft: '10px' }}></i> {t("Address")}</p>

                                                    <p className="mt-1" style={{ margin: '0px 5px' }}> -----------
                                                        <i className="far fa-check-square mt-1" style={{ marginRight: '10px', marginLeft: '10px' }}></i>
                                                        {t("Order")}</p></Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row >
                                    <Col className="order-xl-1" xl="12">
                                        <Card className="bg-white shadow">
                                            <CardHeader className="bg-white border-0">
                                                <Row className=" mt--4">
                                                    <Col className=" mt-4 d-flex" xs={12} style={{ justifyContent: 'space-between' }}>
                                                        <Link to="/admin/bagDetail">
                                                            <i className="fas fa-chevron-left mt-3" style={{ cursor: 'pointer' }}></i>
                                                        </Link>
                                                        <Button color="primary" type="button" onClick={createAddress}>
                                                            <i className="fas fa-plus"></i> {t("Add New Address")}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                {!isLoading && <Row>
                                                    <Col xs="12">
                                                        <Card className="mt-3">
                                                            <CardHeader style={{ height: '30px', marginBottom: '20px' }}>
                                                                <p className="mt--3">{t("Default Address")}</p>
                                                            </CardHeader>
                                                            <Row><Col xs="6">
                                                                <Card style={{ margin: '0px 30px 30px 20px' }}>
                                                                    <CardBody>
                                                                        <Row>
                                                                            <Col xs="1">
                                                                                <input name="shippingAddress" type="radio" value={defaultAddress && defaultAddress.defaultStatus} onChange={(e) => handleShippingAddress(e, defaultAddress)} checked={selectedAddress === defaultAddress} />
                                                                            </Col>
                                                                            <Col xs="6">
                                                                                <p className="textDescription">{defaultAddress && defaultAddress.fullName}</p>
                                                                            </Col>
                                                                            <Col xs="3">
                                                                                <p className="textDescription">{defaultAddress && defaultAddress.addressType}</p>
                                                                            </Col>

                                                                        </Row>
                                                                        {defaultAddress !== undefined && <Row>
                                                                            <Col xs="12" className="d-flex">
                                                                                <p className="textHeading">{defaultAddress.addressLine1
                                                                                    + " ," + (defaultAddress.addressLine2 !== undefined ? defaultAddress.addressLine2 : '')
                                                                                    + " ," + defaultAddress.city
                                                                                    + " ," + defaultAddress.state
                                                                                    + " ," + defaultAddress.postCode}</p>
                                                                            </Col>
                                                                        </Row>}
                                                                        <Row>
                                                                            <Col xs="12">
                                                                                <p className="textHeading">{t("Mobile")}:{ "+60" + defaultMobile } </p>
                                                                            </Col>
                                                                        </Row>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col></Row>

                                                        </Card>
                                                        <Card className="mt-3">
                                                            <CardHeader style={{ height: '30px', marginBottom: '20px' }}>
                                                                <p className="mt--3">{t("Other address")}</p>
                                                            </CardHeader>
                                                            <Row>
                                                                {otherAddresses && otherAddresses.map(anAddress => {
                                                                    return (

                                                                        <Col xs="6">

                                                                            <Card style={{ margin: '0px 30px 30px 20px' }}>
                                                                                <CardBody>
                                                                                    <Row>
                                                                                        <Col xs="1">
                                                                                            <input name="shippingAddress" type="radio" value={anAddress.defaultStatus} onChange={(e) => handleShippingAddress(e, anAddress)} checked={selectedAddress === anAddress} />
                                                                                        </Col>
                                                                                        <Col xs="6">
                                                                                            <p className="textDescription">{anAddress.fullName !== undefined ? anAddress.fullName : userDetails.name}</p>
                                                                                        </Col>
                                                                                        <Col xs="3">
                                                                                            <p className="textDescription">{anAddress.addressType}</p>
                                                                                        </Col>

                                                                                    </Row>
                                                                                    {anAddress !== undefined && <Row>
                                                                                        <Col xs="12" className="d-flex">
                                                                                            <p className="textHeading">{anAddress.addressLine1
                                                                                                + " ," + (anAddress.addressLine2 !== undefined ? anAddress.addressLine2 : '')
                                                                                                + " ," + anAddress.city
                                                                                                + " ," + anAddress.state
                                                                                                + " ," + anAddress.postCode}</p>
                                                                                        </Col>
                                                                                    </Row>}
                                                                                    <Row>
                                                                                        <Col xs="12">
                                                                                            <p className="textHeading">{t("Mobile")}:{anAddress && anAddress.mobileNumber ? "+60" +  anAddress.mobileNumber : ""} </p>
                                                                                        </Col>
                                                                                    </Row>

                                                                                </CardBody>

                                                                            </Card>
                                                                        </Col>

                                                                    )
                                                                })}

                                                            </Row>
                                                     x       <CardFooter >
                                                                <Row>
                                                                    <Col xs="6">
                                                                        <div className="d-flex " style={{ marginRight: '20px' }}>
                                                                            <p className="memberPointsLabel ">{t("Total Amount")} </p>
                                                                            <p className="memberPoints mt--1"> {redemptionPoints && redemptionPoints.toLocaleString()} {t("Points")}</p>
                                                                        </div>

                                                                    </Col>

                                                                    <Col xs={"6"} style={{ display: 'flex', justifyContent: 'flex-end' }}>

                                                                        {!orderPunching && <Button color="primary" type="button" style={{ marginRight: '15px', height: "40px ", width: '200px', justifyContent: 'flex-end' }}
                                                                            onClick={handlePlaceOrder}
                                                                            disabled={isLoading}>
                                                                            {t("Place Order")}
                                                                        </Button>}
                                                                        {orderPunching && <Spinner color="primary" />}

                                                                    </Col>
                                                                </Row>
                                                            </CardFooter>
                                                        </Card>
                                                    </Col>
                                                </Row>}
                                                {isLoading && <Spinner style={{ margin: '20% 50%' }} color="primary" />}
                                            </CardHeader>
                                            <CardBody>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </>
        </>
    );
};

export default CheckoutAddress;