/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Spinner,
  ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";
import { Redirect, Link } from "react-router-dom";
import "../../views/LoginComponents/login.css"
import React, { useState,useEffect } from 'react';
import memberService from "../service/APIService"
import jwt_decode from 'jwt-decode';
import { sha512 } from "js-sha512";
import { useHistory } from 'react-router';
import { useTranslation } from "react-i18next";
import firebase from "utils/firebase";


const Login = () => {
  const [error, setError] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false)
  const [dropdownOpen, setOpen] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [hasBikeInitialDetail, setHasBikeInitialDetail] = useState(false);
  const [hasBikeAllDetail, setHasBikeAllDetail] = useState(false);
  const [initialScreen,setInitialScreen] = useState(false)
  const [otherScreen,setOtherScreen] = useState(false)
  const [profileScreen,setProfileScreen] = useState(false)
  const [showPassword,setShowPassword] = useState(false)
  const [data, setData] = React.useState({});
  const [clientId, setClientId] = useState(null);
  const [redirectURI, setRedirectURI] = useState(null);
  const { t } = useTranslation();
  

  const history = useHistory();


  const toggle = () => setOpen(!dropdownOpen);


  const handleChange = (e) => {
    setError("")
    setData({
      ...data,
      [e.target.name]: e.target.value,
      [e.target.name + "_error"]: null,
    });
  };




  const handleLogin = () => {

   

    if(handleError())
   { setIsLoading(true)
   

    memberService.handleLogin(data,"Username",null).then((response) => {
      var token =response.data && response.data.accessToken
      var decoded = jwt_decode(token);
      sessionStorage.setItem("accessToken", response.data.accessToken)
      sessionStorage.setItem("refreshToken", response.data.refreshToken)
      sessionStorage.setItem("userId", decoded.sub)
      sessionStorage.setItem("userType", decoded.userType)       
      let userDetails = {userName:'',password:''}
      // userDetails.userName =   data.userName
      // userDetails.password = data.password
      // sessionStorage.setItem("userDetails",JSON.stringify(userDetails))
      sessionStorage.setItem("showSubscriptionPopup",true)
      handleMemberProfile()
      if(clientId!= null && redirectURI!=null)
      {
        // setData({
        //   ...data,
        //   "client_id": clientId,
        //   "redirect_uri" : redirectURI,
        //   "credentialType": "Username"
        // });      
     
  
        let request ={
          "client_id": clientId,
            "redirect_uri": redirectURI,
           "credentialType": "Username",
           "credentials": { 
            "username": data.userName,
           "hashedPassword": sha512(data.password)
     }
        }
  
        memberService.redirectGOS(request).then(response=> {
          console.log(response);
        })
        
         window.location.replace(redirectURI);
  
        return;
      }
    }).catch(error => {
      setIsLoading(false)
      setError(error && error.response && error.response.data && error.response.data.message)
     // /auth/register
     //window.location.replace("/auth/register")
     if((sessionStorage.getItem("redirecturl")!== null && sessionStorage.getItem("redirecturl")!== undefined && sessionStorage.getItem("redirecturl")!== "") &&
        sessionStorage.getItem("client_id")!== null && sessionStorage.getItem("client_id")!== undefined && sessionStorage.getItem("client_id")!== "")
        {
      history.push ({pathname : "/auth/register", state: "Username"});
        }
    }).finally(e => {
      setIsLoading(false)
    })}
  };


  const handleError = () => {
    if(data.userName=== undefined || data.userName===""){
      setError("Please enter username to continue")
      return false
    }
    else if(data.password=== undefined || data.password===""){
      setError("Please enter password to continue")
      return false
    }
    return true
  }


    const handleMemberProfile = () => {
      let userId = sessionStorage.getItem("userId")
      memberService.handleMemberProfile(userId).then(response => {
        sessionStorage.setItem("userDetails",JSON.stringify(response.data))
        localStorage.setItem("userDetails",JSON.stringify(response.data))
        if(response.data && response.data.bikes && response.data.bikes.length===0 && response.data.cards && response.data.cards.length>0){
          setInitialScreen(false)
          setOtherScreen(false)
          setProfileScreen(false)
          sessionStorage.setItem("profileSetupStatus","Completed")
          window.location.reload()

        }
        else if(response && response.data && response.data.bikes && response.data.bikes.length===0 && response.data.cards && response.data.cards.length===0 )
        { setInitialScreen(true)}
        else if(response && response.data && response.data.bikes && response.data.bikes.length>0){
          if(response.data.bikes[0].plateNumber!= null && response.data.bikes[0].icNumber!=null && response.data.bikes[0].chassisNumber!=null
            && response.data.bikes[0].engineNumber!=null && response.data.cards && response.data.cards.length===0){
            setInitialScreen(false)
            setOtherScreen(false)
            setProfileScreen(true)
            sessionStorage.setItem("profileSetupStatus","OtherBikeDetails")

          }
          else if(response.data.bikes[0].plateNumber!== null && response.data.bikes[0].icNumber!==null && response.data.bikes[0].chassisNumber!==null
            && response.data.bikes[0].engineNumber!==null && response.data.cards && response.data.cards.length>0){
            setInitialScreen(false)
            setOtherScreen(false)
            setProfileScreen(false)
            sessionStorage.setItem("profileSetupStatus","Completed")
            window.location.reload()
          }
          else if(response.data.bikes[0].plateNumber!== null && response.data.bikes[0].icNumber!==null){
            setInitialScreen(false)
            setOtherScreen(true)
            setProfileScreen(false)
            sessionStorage.setItem("profileSetupStatus","InitialBikeDetails")
          }
          else{ setOtherScreen(false)
            setInitialScreen(true)
            setProfileScreen(false)
          }
        }
        
      }).catch(error => {
        setError(error && error.response && error.response.data && error.response.data.message)
      }).finally(e => {
        setIsLoading(false)
      })
    }


    // useEffect(()=>{
    //   sessionStorage.clear();
    // })

    useEffect(()=>{
      firebase.logEventForAnalytics("Login")
      let paramString = window.location.href.split('?')[1];
      if(paramString!= undefined || paramString!= null)
      {
        var client_id = paramString.split('&')[0].split("=")[1];
        var redirecturl= paramString.split('&')[1].split("=")[1];
        sessionStorage.setItem("redirecturl",redirecturl);
        sessionStorage.setItem("client_id",client_id);

      }


      console.log(client_id);
      console.log(redirecturl);

      setClientId(client_id);
      setRedirectURI(redirecturl);
    })

    // const { t } = useTranslation();

  
  return (
    <>  
     {initialScreen && <Redirect to="/auth/initialDetails"/> }
      {otherScreen && <Redirect to="/auth/otherDetails"/> }
      {profileScreen && <Redirect to="/auth/profile"/>}
      <Col lg="5" md="7" className="pb-8 mt-7">

        <span className="login_header">{t("Login")}</span>
        <Card className="bg-secondary shadow module-border-wrap">

          <CardBody className="px-lg-5 py-lg-5">
            {/* <div className="text-center text-muted mb-4">
              <small>Or sign in with credentials</small>
            </div> */}
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend"> 
                    <InputGroupText>
                      <i className="ni ni-single-02" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                   style={{paddingLeft:'10px'}}
                    name="userName"
                    placeholder={t("Username")}
                    type="userName"
                    autoComplete="new-userName"
                    onChange={handleChange}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                  {!showPassword &&   <InputGroupText>
                   <i className="fas fa-eye-slash" onClick={()=>{setShowPassword(true)}}></i>
                    </InputGroupText>}
                    { showPassword &&  <InputGroupText>
                  <i class="fas fa-eye" onClick={()=>{setShowPassword(false)}}></i>
                    </InputGroupText>}
                  </InputGroupAddon>
                  <Input
                     style={{paddingLeft:'10px'}}
                    name="password"
                    placeholder={t("Password")}
                    type={showPassword?"text":"password"}
                    autoComplete="new-password"
                    onChange={handleChange}
                  />
                </InputGroup>
              </FormGroup>
              <div style={{ textAlign: 'right', margin: '10px' }}>
              <Link  className="text-link" style={{fontSize: '14px'}} to={{ pathname: '/auth/loginViaMobile', state:{type:"Mobile",forgotPassword:true}}}>
              {t("Forgot password")}  
                  </Link> 
              </div>
              <Row className="mt-3">
                                <Col xs="12" className="text-center" >
                                    <small style={{ color: 'red' }}>{t(error)}</small>
                                </Col>
                            </Row>
              <div className="text-center">
                {!isLoading && <Button className="my-4" color="primary" type="button" style={{ width: '100%' }} onClick={handleLogin}>
                  {t("Login")}
                </Button>}
                {isLoading && <Spinner style={{ marginTop: '10px' }} color="primary" />}
              </div>
              <Row>
              <Col xs="12" style={{display:'flex',justifyContent: 'center'}}>
                {t("OR")}
              </Col>
              </Row>
              <Row>
                <Col xs="12" style={{display:'flex',justifyContent: 'center',color:"#138de8"}}>
                <Link to={{ pathname: '/auth/loginViaMobile', state:{type:"Mobile",forgotPassword:false,isSignup:false} }}>
                  {t("Login using")}  <i style={{margin:'5px'}} class="fas fa-mobile-alt"></i> 
                  </Link> 
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs="6">
                  <a
                    className="text-light"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <small>{t("Don't have an account?")}</small>
                  </a>
                </Col>
                <Col className="text-right" xs="6">
                  <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} style={{ border: '1px solid #FF9017', borderRadius: '5px' }}>
                    <DropdownToggle caret>
                      {t("Let's register")}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem  > <Link to={{ pathname: '/auth/loginViaMobile', state:{type:"Mobile",forgotPassword:false,isSignup:true} }}> {t("Mobile number")}</Link></DropdownItem>
                      <DropdownItem><Link to={{ pathname: '/auth/register', state: "Username" }}>{t("Username")}</Link></DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                  

                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
        {/*       
        */}
      </Col>
    </>
  );
};

export default Login;
