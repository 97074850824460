import React, { useState, useEffect } from 'react';
import { Row, Col, Spinner, Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import "../../layouts/layouts.css"
import Select from 'react-select';
import OTPVerification from 'views/Settings/OTPVerification';
import memberService from "../service/APIService"
import { ToastsStore } from "react-toasts";
import AssignedUserConfirmationPopup from './AssignedUserConfirmationPopup';
import {t} from 'i18next';
const MembershipDetail = (props) => {
    const {
        buttonLabel,
        className
    } = props;

    const [modal, setModal] = useState(false);
    const [showOTPScreen, setShowOTPScreen] = useState(false)
    const [bikeDetails, setBikeDetails] = useState(props && props.bikeDetails)
    const [membershipId, setMembershipId] = useState()
    const [mobileNumber, setMobileNumber] = useState()
    const [error, setError] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [mobileError, setMobileError] = useState(false)
    const [confirmRequest, setConfirmRequest] = useState()
    const [showConfirmationScreen, setShowConfirmationScreen] = useState(false)
    const toggle = () => setModal(!modal);

    const handleAssignBike = () => {
        setIsLoading(true)
        let request = {}
        if (mobileNumber != null) {
            request =
            {
                "mobile": {
                    "countryCode": "+60",
                    "mobileNumber": mobileNumber
                },
             //   "bypassCode": "123456",
                // "otp": "123456"

            }
        }
        else {
            request =
            {
                "membershipNumber": membershipId

            }
        }
        console.log(request);
        setConfirmRequest(request)
        memberService.addBikeAssignee(bikeDetails.id, request).then(response => {
            props.parentCallback(request)
            // setShowOTPScreen(true)
        }).catch(error =>
            setError(error && error.response && error.response.data && error.response.data.message)
        ).finally(() => {
            setIsLoading(false)

        })
    }



    const handleChange = (e) => {
        setError('')
        if (e.target.value.includes('-')) {
            setMobileError("")
            e.target.value = e.target.value.substring(0, Math.min(e.target.value.length, 12));
            setMembershipId(e.target.value)
            setMobileNumber(null)
        }
        else {
            let phoneRegex = /^(\+?6?01)[0|1|2|3|4|6|7|8|9]\-*[0-9]{7,9}$/
            // if (e.target.name === "mobileNumber") {
            if (!phoneRegex.test("+60" + e.target.value)) {
                setMobileError("Please enter valid mobile number.")
            }
            else {
                setMobileError("")
                setMobileNumber(e.target.value)
                setMembershipId(null)
            }
            // }
            // e.target.value = e.target.value.substring(0, Math.min(e.target.value.length, 10));

        }

    }

    const handleAssign = () => {
        let request = {}
        setShowConfirmationScreen(false)
        if (mobileNumber != null) {
            request =
            {
                "mobile": {
                    "countryCode": "+60",
                    "mobileNumber": mobileNumber
                },
            //    "bypassCode": "123456",
               // "otp": "123456"

            }
        }
        else {
            request =
            {
                "membershipNumber": membershipId,
             //   "bypassCode": "123456",
               // "otp": "123456"

            }
        }
        memberService.addBikeConfirmAssignee(bikeDetails.id, request).then(response => {
            setShowConfirmationScreen(false)
            props.parentCallback(request)

        }).catch(error =>
            setError(error && error.response && error.response.data && error.response.data.message)
        ).finally(() => {
            props.parentCallback(request)

            ToastsStore.success(t("Congratulations! Bike assigned successfully."))
        })


    }

    const handleShowConfirmation = () => {
        setShowConfirmationScreen(true)
        setShowOTPScreen(false)

    }
console.log(mobileNumber);

    return (
        <div>
            <Modal size="sm" isOpen={props.openPopup} className={className}>
                <ModalBody>
                    <InputGroup className="input-group-alternative">
                        <Input
                            name="membershipNumber"
                            placeholder={t("Membership Number or Registered Mobile Number")}
                            onChange={handleChange}
                        />
                    </InputGroup>
                    <small style={{ color: '#808080' }}>* Please enter membership number in this format 386-4173-270</small>
                    <br />

                    <small style={{ color: 'red' }}>{error}</small>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-center">
                    <Row>
                        <Col xs={12}>
                            <small style={{ color: 'red' }}>{mobileError}</small>
                        </Col>
                    </Row>
                    <Button color="secondary" onClick={props.closePopup}>Cancel</Button>
                    {!isLoading && <Button color="primary" onClick={handleAssignBike} disabled={mobileError != ""}>Proceed</Button>}
                    {isLoading && <Spinner style={{ marginTop: '10px' }} color="primary" />}
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default MembershipDetail;