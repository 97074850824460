import { FormControl, InputLabel, MenuItem ,Select } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Row, Col, Spinner, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input } from 'reactstrap';
import "../../layouts/layouts.css"
import subscriptionService from "../../views/service/APIService"

const BankList = (props) => {
    const {
        buttonLabel,
        className
    } = props;

    const [modal, setModal] = useState(false);
    const [checked, setChecked] = useState(null);
    const [bankList, setBankList] = useState([])
    const [selectedBank, setSelectedBank] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isUpdatingSignature , setIsUpdatingSignature] = useState(false)

    
    useEffect(() => {
         fetchBankList()
         }, [])

    const fetchBankList = () => {
        setIsLoading(true)
        subscriptionService.getBankList().then(response => {
            setBankList(response && response.data && response.data.banks)
            
        }).catch(error => {}).finally(()=>{setIsLoading(false)})
    }

    const handleChange = (event) => {
        setSelectedBank(event.target.value)
    }

    async function postData(url = '', data = {}) {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': '4zgXjiVjME3taE3FHIUtH94lzK7e3U1H1HX3Gk45'
            },
            body: JSON.stringify(data)
        });
        return response.json().catch(error => { console.log(error); });
    }

    async function updateSignature() {
        if(props.amount == 1){

        }
        setIsUpdatingSignature(true)
        var bankCode = selectedBank;
        var sellerExOrderNumber = new Date().getTime() + "";
        var sellerOrderNumber = props.sellerId;
        var amountStringified = props.amount + ".00";
        console.log("session storage: ",sessionStorage);
        console.log("Bike Models",localStorage.getItem("bikeModels"));
       // console.log("Bike Model id",localStorage.getItem("bikeModels")[0].id);
        var parsedUserDetails = JSON.parse(localStorage.getItem("userDetails"));
        console.log("passed userDetails: ", parsedUserDetails.membershipNumber,parsedUserDetails.name,parsedUserDetails.email)
        var userCred = parsedUserDetails.membershipNumber//"Hwtest45";
        console.log("userCred: ",userCred);
        console.log("membershipNumber: ",sessionStorage.getItem("membershipNumber"));
        let buyerName = parsedUserDetails.name;//"HwTest45";
        let buyerEmail = parsedUserDetails.email === null || parsedUserDetails.email === undefined? "shivam.shukla@hummingwave.com" : parsedUserDetails.email  // make this field mandatory
        let userType = "member";

        let data = {
            "fields": {
                "fpx_msgType": "AR",
                "fpx_msgToken": "01",
                "fpx_sellerExId": "EX00011375",
                "fpx_sellerExOrderNo": `${sellerExOrderNumber}`,
                "fpx_sellerTxnTime": "20211012095905",
                "fpx_sellerOrderNo": `${sellerOrderNumber}`,
                "fpx_sellerId": "SE00012887",
                "fpx_sellerBankCode": "01",
                "fpx_txnCurrency": "MYR",
                "fpx_txnAmount": `${amountStringified}`,
                "fpx_buyerEmail": `${buyerEmail}`,
                "fpx_buyerName": `${buyerName}`,
                "fpx_buyerBankId": `${bankCode}`,
                "fpx_buyerBankBranch": "",
                "fpx_buyerAccNo": "",
                "fpx_buyerId": "",
                "fpx_makerName": "",
                "fpx_buyerIban": "",
                "fpx_productDesc": "Yamaha UAT Testing",
                "fpx_version": "7.0",

                "buyerName": `${buyerName}`,
                "buyerEmail": `${buyerEmail}`,
                "userCred": `${userCred}`,
                "userType": `${userType}`
            }
        };
        const getBaseUrl = () => { return process.env.REACT_APP_ENV === "PROD" ? process.env.REACT_APP_BASE_URL_PROD :  process.env.REACT_APP_ENV === "DEV" ? process.env.REACT_APP_BASE_URL :  process.env.REACT_APP_BASE_URL_STAGE;  }
        console.log("baseurl",getBaseUrl())
        console.log("Prepared Data", data);
        await postData(`${getBaseUrl()}/payment/fpx-sign`, data).then(response => {
            document.getElementById("fpx_sellerExOrderNo").value = sellerExOrderNumber;
            document.getElementById("fpx_sellerOrderNo").value = sellerOrderNumber;
            document.getElementById("fpx_txnAmount").value = amountStringified;
            document.getElementById("fpx_buyerBankId").value = bankCode; //"TEST0021";
           // document.getElementById("fpx_buyerBankId").value = bankCode; //"TEST0021";
            document.getElementById("fpx_buyerEmail").value = buyerEmail; //"TEST0021";
            document.getElementById("fpx_buyerName").value = buyerName; //"TEST0021";
            document.getElementById("fpx_sellerTxnTime").value = response.sellerTransactionTime; 
            document.getElementById("fpx_sellerOrderNo").value = response.sellerOrderNumber;
            document.getElementById("fpx_buyerId").value = response.buyerId;
            document.getElementById("fpx_checkSum").value = response.checksum;//response.message;
            console.log("response of fpx-sign api: ",response);
            document.getElementById("payment_form").submit();
            props.closePopup()   ; 
            window.location.reload();
            //console.log("response of fpx-sign api: ",response);
        });
    };


    console.log(bankList);
    console.log("props.amount: ",props.amount, " bool " ,props.amount < 1);
    console.log("disabled bool value: ",(selectedBank===null) ? true : (props.amount > 30000 || props.amount < 1 ? true : false));
    console.log("props.amount bool value: ",props.amount > 30000 ? true : false);

    return (
        <div>
            {/* <Button color="danger" onClick={toggle}>{buttonLabel}</Button> */}
            <Modal size="md" isOpen={props.openPopup} className={className}>
                <ModalHeader >                <center>  <span style = {{ fontWeight : 500, fontSize: 26 }} > Pay with <img height = { 32 } src = { '/payment-fpx.jpeg' } /> ( Current and Savings Account ) </span>  </center>
                <center>  <span style = {{ fontWeight : 500, fontSize: 13 , fontColor: "#D4D0CF"}} > Amount should be between RM 1 and RM 30,000 . </span>  </center>
</ModalHeader>
                <ModalBody className="">

                
                {isLoading && <div style={{display: 'flex', justifyContent:'center'}}> <Spinner style={{ marginTop: '10px' }} color="primary" /></div>}


                                    <div style={{display: 'flex', justifyContent:'space-between'}}>    
                                    <span  style={{ marginTop: 10 , marginRight: 10 }}> Amount </span>

                                    <Input 
                                        type = "text"

                                        disabled = {true}
                                        value = {"RM " + parseFloat(props.amount).toFixed(2)  } 

                                    /> 
                                    </div>
                                    <br/>
                                     <div style={{display: 'flex', justifyContent:'space-between'}}> 
                                     <span  style={{ marginTop: 10 , marginRight: 10 }}>  Select Bank List </span>
                                    <FormControl fullWidth >
                                        <InputLabel id={`label-${props.labelCategory}-category`}  disabled = { props.disabled } > Select Bank List </InputLabel>
                                        <Select
                                            name = { "banks" }
                                            fullWidth
                                            style = {{ width : 320, borderColor : '#138DE8' }}
                                            value = {  selectedBank }
                                            disabled = { false }
                                            onChange =  { (e) => { console.log("Checking", e.target.value); setSelectedBank (e.target.value); } }
                                        >
                                            { bankList.map ((item, idx) => <MenuItem value = {item.bankCode} key = {idx} disabled = { item.bankActiveStatus === "Blocked" } > { item.displayName } { item.bankActiveStatus === "Blocked" ? "(Offline)" : "" } </MenuItem>) }
                                        </Select>
                                    </FormControl>
                                </div>

                    {/* {bankList && bankList.length > 0 && <FormGroup tag="fieldset">
                        {bankList && bankList.map((aBank, key) => {
                            return (
                                <FormGroup check>
                                    <Label check>
                                        <Input type="radio" value={aBank.bankCode} name="radio1" onChange={handleChange} style={{ marginTop: "15px" }} />{' '}
                                        <p className="subscriptionTitle">{aBank.displayName}</p>
                                    </Label>
                                </FormGroup>
                            )
                        }
                        )}

                    </FormGroup>} */}
                </ModalBody>
                <ModalFooter className="d-flex justify-content-center">
                <Button className="mt-3" color="primary" type="button" onClick={e => props.closePopup() } >
                        Back
                    </Button>
                  {!isUpdatingSignature &&  <Button className="mt-3" color="primary" type="button" onClick={e => updateSignature()} disabled={(selectedBank===null) ? true : (props.amount > 30000 || props.amount < 1 ? true : false)}>
                        Pay
                    </Button>}
                    {isUpdatingSignature && <Spinner style={{ marginTop: '10px' }} color="primary" />}
                </ModalFooter>
                <Row>
                <Row >
                <div style = {{display:"flex" }}>
                <span style = {{ fontWeight : 300 , fontSize: 12 , marginLeft: 35 , marginBottom: 3 , width: "60%"}} > <sup>*</sup> By Clicking on &ldquo; Pay &rdquo; Button, you hereby agree with <a style = {{ color : '#138DE8', fontWeight : 300 , fontSize: 12 , marginBottom: 3}} href = "https://www.mepsfpx.com.my/FPXMain/termsAndConditions.jsp" target = "_blank" > FPX Terms &amp; Conditions </a>   </span>
                <span style = {{ fontWeight : 300  }} > Powered by <img height = { 25 } width={68} src = { '/payment-fpx.jpeg' } /> </span>
                </div>
                </Row>
                    <form method="post" target="_blank" id="payment_form" action="https://uat.mepsfpx.com.my/FPXMain/seller2DReceiver.jsp">
                        <input type="hidden" name="fpx_msgType" value="AR" />
                        <input type="hidden" name="fpx_msgToken" value="01" />
                        <input type="hidden" name="fpx_sellerExId" value="EX00011375" />
                        <input type="hidden" id="fpx_sellerExOrderNo" name="fpx_sellerExOrderNo" value="" />
                        <input type="hidden" name="fpx_sellerTxnTime" id="fpx_sellerTxnTime" value="" />
                        <input type="hidden" id="fpx_sellerOrderNo" name="fpx_sellerOrderNo" value="" />
                        <input type="hidden" name="fpx_sellerId" value="SE00012887" />
                        <input type="hidden" name="fpx_sellerBankCode" value="01" />
                        <input type="hidden" name="fpx_txnCurrency" value="MYR" />
                        <input type="hidden" id="fpx_txnAmount" name="fpx_txnAmount" value="150.00" />
                        <input type="hidden" name="fpx_buyerEmail" id="fpx_buyerEmail" value="" />
                        <input id="fpx_checkSum" type="hidden" name="fpx_checkSum" value="" />
                        <input type="hidden" name="fpx_buyerName" id="fpx_buyerName" value="" />
                        <input type="hidden" id="fpx_buyerBankId" name="fpx_buyerBankId" value="TEST0021" />
                        <input type="hidden" name="fpx_buyerBankBranch" value="" />
                        <input type="hidden" name="fpx_buyerAccNo" value="" />
                        <input id="fpx_buyerId" type="hidden" name="fpx_buyerId" value="" />
                        <input type="hidden" name="fpx_makerName" value="" />
                        <input type="hidden" name="fpx_buyerIban" value="" />
                        <input type="hidden" name="fpx_productDesc" value="Yamaha UAT Testing" />
                        <input type="hidden" name="fpx_version" value="7.0" />
                       
                    </form>
                    <script src="http://ajax.googleapis.com/ajax/libs/jquery/1.9.1/jquery.min.js"></script>
                </Row>
            </Modal>
        </div>
    );
}

export default BankList;