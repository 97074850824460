import React, { useState, useEffect } from 'react';
import { Row, Col, Spinner, Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, FormGroup, InputGroupText, Input } from 'reactstrap';
import "../../layouts/layouts.css"

const AssignedUserConfirmationPopup = (props) => {
    const {
        buttonLabel,
        className
    } = props;

    const [modal, setModal] = useState(false);


    const toggle = () => setModal(!modal);

   const handleSave =(response) =>{
        props.parentCallback(response)
    }

    return (
        <div>
            {/* <Button color="danger" onClick={toggle}>{buttonLabel}</Button> */}
            <Modal size="sm" isOpen={props.openPopup} className={className}>
                <ModalHeader toggle={props.closePopup}>Confirmation</ModalHeader>
                <ModalBody className="">
                <i className="fas fa-check-circle"  style={{color:'#12bf5f',fontSize:'60px',display:'block',textAlign:'center'}}></i>
                    {/* <i className="fas fa-thumbs-up" style={{color:'#12bf5f',fontSize:'90px'}}></i> */}
                        <h3 className="mt-3" style={{textAlign:'center'}}>Congratulations!</h3>
                        <p style={{textAlign:'center'}}> User assigned successfully.</p> 
                </ModalBody>
                <ModalFooter className="d-flex justify-content-center">
               <Button className="mt-3" color="primary" type="button" onClick={props.parentCallback}>
                                    Ok
                                </Button>

                </ModalFooter>
            </Modal>
        </div>
    );
}

export default AssignedUserConfirmationPopup;