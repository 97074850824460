import React, { useState, useEffect } from 'react';
import { Row, Col, Spinner, Button, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import "../../layouts/layouts.css"
import Select from 'react-select';
import memberService from "../service/APIService"
import firebase from 'utils/firebase';
import {t} from 'i18next';

const EditAddress = (props) => {
    useEffect(()=>{
        firebase.logEventForAnalytics("Edit Address")
    })
    const {
        buttonLabel,
        className
    } = props;

    const [modal, setModal] = useState(false);
    const [data, setData] = useState(props && props.addressDetails)
    const [isAddressTypeOthers, setAddressTypeOthers] = useState(false)
    const [error, setError] = React.useState({})
    const [userDetails, setUserDetails] = useState(JSON.parse(sessionStorage.getItem("userDetails")))
    const [isLoading, setIsLoading] = useState(false)
    const [existingAddresses, setExistingAddresses] = useState()
    const [selectedAddressType,setSelectedAddressType] = useState("")
    const [selectedAddressDetails,setSelectedAddressDetails] = useState(props && props.addressDetails)
    const [allddressDetails,setAllAddressDetails] = useState(JSON.parse(sessionStorage.getItem("userDetails")).addresses)


    const toggle = () => setModal(!modal);
    const options = [{ name: 'Home' }, { name: 'Work' }, { name: 'Others' }]
    const handleChange = (event) => {
        setError({
        })
        if(event.target && event.target.name!=="addressType")
        setData({...data,[event.target.name]:event.target.value})
        else if(event && event.target && event.target.name === "addressType"){
            setData({...data,["addressType"]:event.target.value})
        }
        else if(event && event.name && (event.name==='Home' || event.name==='Work')){
            setAddressTypeOthers(false)
            setSelectedAddressType(event.name)
            setData({...data,["addressType"]:event.name})   
        }
        else if(event && event.name && (event.name==='Others')){
            setAddressTypeOthers(true)
            setSelectedAddressType("Others")
            setData({...data,["addressType"]:""})   
        }
    }

    const updateAddress = () => {
        if (handleError()) {
            // setIsLoading(true)
            let newAddress = {
                "fullName": data.fullName,
                "mobileNumber": data.mobileNumber,
                "addressLine1": data.addressLine1,
                "addressLine2": data.addressLine2,
                "postCode": data.postCode,
                "city": data.city,
                "state": data.state,
                "addressType": data.addressType,
                "defaultStatus": data.defaultStatus
            }
            // 

            if(newAddress.defaultStatus==="DefaultAddress"){
               existingAddresses && existingAddresses.forEach(anAddress=>{
                   if(anAddress.defaultStatus === 'DefaultAddress'){
                    anAddress.defaultStatus = "OtherAddress"  
                   }
               })
            }
            existingAddresses.push(newAddress)
            

            let request = {
                "name": userDetails.name,
                "gender": userDetails.gender,
                "dateOfBirth": userDetails.dateOfBirth,
                "email": userDetails.email,
                "profileImageName": userDetails.profileImageName,
                "mobile": {
                    "countryCode": userDetails && userDetails.mobile && userDetails.mobile.countryCode || "+60",
                    "mobileNumber": userDetails && userDetails.mobile && userDetails.mobile.mobileNumber
                },
                "addresses": existingAddresses
            }

            memberService.updateMemberProfileDetails(request, userDetails.id).then(response => {
                sessionStorage.setItem("userDetails", JSON.stringify(response.data))
                localStorage.setItem("userDetails",JSON.stringify(response.data))
            }).catch(error => {
                // setAPIError(error && error.response && error.response.data && error.response.data.message)
            }).finally(e => {
                setIsLoading(false)
                props.parentCallback()
 
            })
        }
        else{}
    }

    const handleError = () => {
        if (data.fullName === undefined || data.fullName == "") {
            setError({
                ...error,
                ["fullName"]: "Please enter full name",
            });
            return false;
        }

         if (data.mobileNumber === undefined || data.mobileNumber == "") {
            setError({
                ...error,
                ["mobileNumber"]: "Please enter mobile number",
            });
            return false;
        } 
         if (data.addressLine1 === undefined || data.addressLine1 == "") {
            setError({
                ...error,
                ["addressLine1"]: "Please enter address line 1",
            });
            return false;
        } 
         if (data.postCode === undefined || data.postCode == "") {
            setError({
                ...error,
                ["postCode"]: "Please enter postcode",
            });
            return false;
        } 
         if (data.city === undefined || data.city == "") {
            setError({
                ...error,
                ["city"]: "City can not be empty",
            });
            return false;
        } 
         if (data.state === undefined || data.state == "") {
            setError({
                ...error,
                ["state"]: "State can not be empty",
            });
            return false;
        }
         if (data.addressType === undefined || data.addressType == "") {
            setError({
                ...error,
                ["addressType"]: "Please select address type",
            });
            return false;
        }
        return true
    }

    const handlePostalCode = () => {
        getStateAndCity()
    }
    const getStateAndCity = () => {
        memberService.getStateAndCity(data.postCode).then(response => {
            setData({
                ...data,
                ["city"]: response.data.city,
                ["state"]: response.data.state,
                // [e.target.name]: null,
            });
        }).catch(error => {
            setData({
                ...data,
                ["city"]: "",
                ["state"]: "",
                // [e.target.name]: null,
            });
            //   setError({
            //     ...error,
            //     ["postCode"]: error.response.data.message,
            //   });
        }).finally(e => {
        })
    }

    const handleCheckBox = (event) => {
        if (event && event.target && event.target.checked) {
            setData({
                ...data,
                ["defaultStatus"]: "DefaultAddress"
            })
        }
        else if (event && event.target && !event.target.checked) {
            setData({
                ...data,
                ["defaultStatus"]: "OtherAddress"
            })
        }
    }

    useEffect(() => {
        if (props && props.userDetails && props.addressDetails) {
            setData( props.addressDetails)
            if(props.addressDetails.addressType==="Work" || props.addressDetails.addressType==="Home" ){
                setSelectedAddressType(props.addressDetails.addressType)
            }
            else{
                setSelectedAddressType("Others")
                setAddressTypeOthers(true)
                setData({...data,["addressType"]:props.addressDetails.addressType})
            }
        }
        let filteredAddress=allddressDetails && allddressDetails.filter(anAddress=>JSON.stringify(anAddress)!==JSON.stringify(selectedAddressDetails))
        setExistingAddresses(filteredAddress)
    }, [])

    console.log(data);


    return (
        <div>
            {/* <Button color="danger" onClick={toggle}>{buttonLabel}</Button> */}
            <Modal size="lg" isOpen={props.openPopup} className={className}>
                <ModalHeader toggle={props.closePopup}>{t("Edit Address")}</ModalHeader>
                <ModalBody>
                    <div>
                        <Row className="mt-3">
                            <Col xs="6">
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText><i class="fas fa-user"></i></InputGroupText>
                                    </InputGroupAddon>
                                   <Input placeholder="fullName"
                                        value={data.fullName !==undefined?data.fullName :props.userDetails.name}
                                        name="fullName" style={{ paddingLeft: '10px' }}
                                        onChange={handleChange} />
                                </InputGroup>
                                <small style={{ color: 'red' }}>{error.fullName}</small>

                            </Col>

                            <Col xs="6">
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText><i class="fas fa-mobile-alt"></i></InputGroupText>
                                        <InputGroupText> +60 </InputGroupText>

                                    </InputGroupAddon>
                                    <Input
                                        value={data.mobileNumber}
                                        placeholder="mobileNumber" name="mobileNumber" style={{ paddingLeft: '10px' }}
                                        onChange={handleChange} />
                                </InputGroup>
                                <small style={{ color: 'red' }}>{error.mobileNumber}</small>

                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col xs="6">
                                <InputGroup>
                                   
                                    <Input value={data.addressLine1} placeholder="addressLine1" name="addressLine1" style={{ paddingLeft: '10px' }}
                                        onChange={handleChange} />
                                </InputGroup>
                                <small style={{ color: 'red' }}>{error.addressLine1}</small>

                            </Col>

                            <Col xs="6">
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText> <i className="fas fa-address-card"></i></InputGroupText>
                                    </InputGroupAddon>
                                    <Input value={data.addressLine2} placeholder="addressLine2" name="addressLine2" style={{ paddingLeft: '10px' }}
                                        onChange={handleChange} />
                                </InputGroup>
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col xs="6">
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText> <i className="fas fa-address-card"></i></InputGroupText>
                                    </InputGroupAddon>
                                    <Input value={data.postCode} placeholder="postCode" style={{ paddingLeft: '10px' }}
                                        name="postCode"
                                        onChange={handleChange}
                                        onBlur={handlePostalCode}
                                    />
                                </InputGroup>
                                <small style={{ color: 'red' }}>{error.postCode}</small>

                            </Col>

                            <Col xs="6">
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText> <i className="fas fa-city"></i></InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="city" style={{ paddingLeft: '10px' }}
                                        value={data.city} disabled />
                                </InputGroup>

                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col xs="6">
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText> <i className="fas fa-address-card"></i></InputGroupText>
                                    </InputGroupAddon>
                                    <Input placeholder="state" style={{ paddingLeft: '10px' }}
                                        value={data.state} disabled />
                                </InputGroup>
                            </Col>

                            <Col xs={isAddressTypeOthers ? "3" : "6"}>
                                <Select isSearchable={false}
                                    value={
                                        options.filter(option =>
                                            option.name === selectedAddressType)
                                    }
                                    options={options}
                                    getOptionLabel={(options) => options['name']}
                                    getOptionValue={(options) => options['name']}
                                    onChange={handleChange} />
                            </Col>
                            {isAddressTypeOthers && <Col xs="3">
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText> <i className="fas fa-address-card"></i></InputGroupText>
                                    </InputGroupAddon>
                                    <Input style={{ paddingLeft: '10px' }}
                                    value={data.addressType}
                                        name="addressType"
                                        placeholder="Save Address As"
                                        type="text"
                                        autoComplete="new-saveAddressAs"
                                        onChange={handleChange} />
                                </InputGroup>
                            </Col>}
                        </Row>
                        {props && props.addressType !== 'DefaultAddress' && <Row>
                            <Col xs="12" style={{ margin: '18px' }}>
                                <Input name="defaultStatus" type="checkbox" onChange={handleCheckBox} />{' '}
                                {t("Set as default address")}</Col></Row>}
                    </div>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-center">
                    <Button color="secondary" onClick={props.close}>{t("Cancel")}</Button>
                    {!isLoading && <Button color="primary" onClick={updateAddress}>{t("Update Address")}</Button>}
                    {isLoading && <Spinner style={{ marginTop: '10px' }} color="primary" />}

                </ModalFooter>
            </Modal>
        </div>
    );
}

export default EditAddress;