
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroup,
    Row,
    Col,
    Spinner
} from "reactstrap";
import "../../views/LoginComponents/login.css"
import { Redirect, Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import memberService from "../service/APIService"
import PicturePreview from "../../components/Utilities/PicturePreview"
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import firebase from "utils/firebase";
function VehicleOtherDetails(props) {
    const vocImageBaseUrl = process.env.REACT_APP_ENV === "PROD" ? process.env.REACT_APP_VOC_IMAGE_BASE_URL_PROD :  process.env.REACT_APP_ENV === "DEV" ? process.env.REACT_APP_VOC_IMAGE_BASE_URL :  process.env.REACT_APP_VOC_IMAGE_BASE_STAGE;
    //const vocImageBaseUrl = process.env.REACT_APP_VOC_IMAGE_BASE_URL
    // const sampleVOCURL = "/assets/img/yamahaIcons/sampleVOC.png"
    const [nextScreen, setNextScreen] = useState(false)
    const [data, setData] = React.useState({});
    // const [idTopicCatg, setIdTopicCatg] = useState([])

    const [error, setError] = React.useState("");
    const [engineNumberError, setEngineNumberError] = React.useState('');
    const [chassisNumberError, setChassisNumberError] = React.useState('');
    const [vocUploadError, setVOCUploadError] = React.useState('');
    const [modelNameError, setModelNameError] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false)
    const [vehicleModels, setVehicleModels] = React.useState([])
    const [vocImageList, setVOCImageList] = React.useState([])
    const [showVoc, setShowVoc] = React.useState(false)
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    const [profileScreen,setProfileScreen] = React.useState(false)
    const [vocUploadLoader,setVOCUploadLoader] = React.useState(false)
    const [modelName, setModelName] = React.useState(userDetails && userDetails.bikes && userDetails.bikes[0] && userDetails.bikes[0].modelNumber!=null?userDetails && userDetails.bikes && userDetails.bikes[0] && userDetails.bikes[0].modelNumber:"")
    const [signupType, setSignupType] = React.useState(props && props.location && props.location.state && props.location.state.signupType)

    // const vocImageList =[]
    useEffect(() => {
        fetchVehicleModels()
        setVOCImageList([])
        let userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
      
        let bikeDetails =  userDetails && userDetails.bikes && userDetails.bikes[0]
        if(bikeDetails===undefined){
            handleMemberProfile()
        }
        userDetails && userDetails.bikes && userDetails.bikes[0] &&  userDetails.bikes[0].vocCopyFileNames && userDetails.bikes[0].vocCopyFileNames.map(aVocImage=>{
         let name= aVocImage.split(".")[0]
         let extension= aVocImage.split(".")[1]
  
        vocImageList.push({ name:name ,type:extension})
     })
        setVOCImageList(vocImageList)
        setShowVoc(true)

        setData({
            ...data,
            ["icNumber"]: bikeDetails && bikeDetails.icNumber,
            ["id"]: bikeDetails && bikeDetails.id,
            ["plateNumber"]:bikeDetails &&  bikeDetails.plateNumber,
            ["engineNumber"]: bikeDetails && bikeDetails.engineNumber,
            ["chassisNumber"]:bikeDetails &&  bikeDetails.chassisNumber,
            ["status"]: bikeDetails && bikeDetails.status,
            ["userName"]: userDetails.userName,
            ["vocVerificationMode"]:bikeDetails &&  bikeDetails.vocVerificationMode,
        })
    }, [])

    const fetchVehicleModels = () => {
        memberService.fetchVehicleModels().then(response => {
            let vehicleModels = []
            response.data.bikeModels.map((aModel, key) => {
                vehicleModels.push({ id: aModel.id, bikeModelName: aModel.bikeModelName })
            })
            setVehicleModels(vehicleModels)
        })
    }

    const handleMemberProfile = () => {
        let userId = sessionStorage.getItem("userId")
        memberService.handleMemberProfile(userId).then(response => {
            sessionStorage.setItem("userDetails", JSON.stringify(response.data))
            localStorage.setItem("userDetails",JSON.stringify(response.data))
            // setProfileScreen(true)
        }).catch(error => {
            setError(error && error.response && error.response.data && error.response.data.message)
        }).finally(e => {
           // setIsLoading(false)
        })
    }

    const handleChange = (e) => {
        setError("")
        if (e.target.name === 'engineNumber')
            setEngineNumberError("")
        if (e.target.name === 'chassisNumber')
            setChassisNumberError("")
        if (e.currentTarget && e.currentTarget.textContent) {
            setModelName(e.currentTarget.textContent)
            setModelNameError("")
        }
        if (vocImageList.length === 4) {
            setVOCUploadError("")
        }
        setData({
            ...data,
            [e.target.name]: e.target.value.toUpperCase(),
            [e.target.name + "_error"]: null,
        });
    }

    const handleSaveVehicleJPJInfo = () => {
        
        if (data.engineNumber!=undefined && userDetails && vocImageList && vocImageList.length>0 && modelName!=null && userDetails.bikes  && userDetails.bikes[0] && userDetails.bikes[0].engineNumber !=undefined && userDetails.bikes[0].engineNumber === data.engineNumber 
            && userDetails.bikes[0].chassisNumber === data.chassisNumber) {
            setProfileScreen(true)
        }
        else{
        if (handleError()) {
            let vocImageListToUpload = []
            vocImageList && vocImageList.map(aDoc => {
                vocImageListToUpload.push(aDoc.name)
            })
            setIsLoading(true)
            userDetails.plateNumber = userDetails && userDetails.bikes && userDetails.bikes[0] && userDetails.bikes[0].plateNumber
            userDetails.icNumber = userDetails && userDetails.bikes && userDetails.bikes[0] && userDetails.bikes[0].icNumber
            userDetails.vehicleId = userDetails && userDetails.bikes && userDetails.bikes[0] && userDetails.bikes[0].id

            let request = {
                "engineNumber": data.engineNumber,
                "modelName": modelName,
                "chassisNumber": data.chassisNumber,
                "plateNumber": userDetails.plateNumber,
                "icNumber": userDetails.icNumber,
                "vocCopyfileNames": vocImageListToUpload
            }
            memberService.updateVehicleJPJInfo(request,sessionStorage.getItem("BikeId")).then((response) => {
                
                sessionStorage.setItem("userDetails", JSON.stringify(userDetails))
                localStorage.setItem("userDetails",JSON.stringify(response.data))
                console.log("vehicleDetailsline144",userDetails);
                setNextScreen(true)
                firebase.logButtonClicked("Continue as Owner")

            }).catch(error => {
                setError(error && error.response && error.response.data && error.response.data.message)
            }).finally(e => {
                setIsLoading(false)
            })
        }
        else {
            return
        }}

    }

    const testEngineNumber = (x) => {
        var count = (x.match(/-/g) || []).length;
        console.log(count); 
        if(count > 1){
            return false;
        }
        return true;
    }

    const handleError = () => {
        var temp = data.engineNumber;

        let engineRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+-[a-zA-Z0-9]*$/
        var temp = data.engineNumber;
        if (!testEngineNumber(temp)) {
            setEngineNumberError("Engine number should be between 7 to 20 characters and in alphanumeric combination with maximum one dash '-'")
            return false
        }
        if (data.engineNumber.length < 7 || data.engineNumber.length > 20) {
            setEngineNumberError("Engine number should be between 7 to 20 characters and in alphanumeric combination with maximum one dash '-'")
            return false
        }
        if (data.engineNumber === undefined || data.engineNumber === "") {
            setEngineNumberError("Engine number should be between 7 to 20 characters and in alphanumeric combination with maximum one dash '-'")
            return false
        }

        let alphaNumericRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/;
        if (!alphaNumericRegex.test(data.chassisNumber)) {
            setChassisNumberError("Chassis number should be between 7 to 20 characters and in alphanumeric combination")
            return false
        }
        if (data.chassisNumber === undefined || data.chassisNumber === "") {
            setChassisNumberError("Chassis number should be between 7 to 20 characters and in alphanumeric combination")
            return false
        }
        if (data.chassisNumber && (data.chassisNumber.length < 7 || data.chassisNumber.length > 20)) {
            setChassisNumberError("Chassis number should be between 7 to 20 characters and in alphanumeric combination")
            return false
        }
        if (modelName === "") {
            setModelNameError("Please select model name")
            return false
        }
        if (vocImageList.length === 0 || vocImageList.length > 4) {
            setVOCUploadError("Please upload upto 4 VOC documents")
            return false
        }
        return true
    }

    const deleteFile = (name) => {
        vocImageList.splice(vocImageList.indexOf(name), 1);
        setVOCImageList([...vocImageList])
        setShowVoc(true)

    }
    console.log("vehicleOtherDetails215",userDetails);

    const handleBack = () => {
        console.log("vehicleOtherDetails218",userDetails)
        let data = {
            "icNumber": props.location.state.icNumber,
            "plateNumber": props.location.state.plateNumber
        }
        memberService.deleteVehicle(data).then(response => {
        }).catch(error => {
            setError(error && error.response && error.response.data && error.response.data.message)
        }).finally(e => {
        })
    }
     
    const onFileChange = (event) => {
        setVOCUploadError("")
        if (event.target.files && event.target.files.length) {
            setShowVoc(true)
            setVOCUploadLoader(true)

            uploadFile(event.target.files)
        }

    }

    

    useEffect(() => {
        setVOCImageList(vocImageList)
    }, [vocImageList])

    const vocShow = () => {
        return (
            <>
            {profileScreen && <Redirect to={{ pathname: '/auth/profile', state: { signupType: signupType } }}/>}
          {nextScreen && <Redirect to={{ pathname: '/auth/vehicleConfirmation', state: { signupType: signupType , icNumber : props.location.state.icNumber , plateNumber : props.location.state.plateNumber} }} ></Redirect>}
                {vocImageList && vocImageList.map((anImage) => {
                    if (anImage && anImage.type && anImage.type.includes('pdf')) {
                        return (<div>
                            <Card style={{ marginRight: '10px' }}>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <i style={{ color: "#138de8", marginRight: '-7px', cursor: 'pointer' }} class="fas fa-times-circle" onClick={() => deleteFile(anImage.name)}></i>
                                </div>
                                <CardBody>
                                    <i className="fas fa-file-pdf" style={{ color: 'red', fontSize: '30px' }}></i>
                                </CardBody>
                            </Card>
                        </div>)
                    }
                    else if (anImage && anImage.type && (anImage.type.includes('image') || anImage.type.includes('png'))) {
                        return (<div>

                            <Card style={{ marginRight: '10px' }}>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <i style={{ color: "#138de8", marginRight: '-7px', cursor: 'pointer' }} class="fas  fa-times-circle" onClick={() => deleteFile(anImage.name)}></i>
                                </div>
                                
                                <img src={vocImageBaseUrl + anImage.name}  style={{ height: '70px', width:'70px' }} />
                                {/* <CardBody>
                               
                                    <i className="fas fa-file-image" style={{ color: 'red', fontSize: '30px' }}></i>
                                </CardBody> */}
                            </Card>
                        </div>)
                    }
                })}


            </>)

    }

    const vocUpload = () => {
        return (
            <>
               {!vocUploadLoader && <Button className="ma_btn mt-3 mb-3" color="primary" type="button" >
                    Upload Copy of VOC <i className="fa fa-upload" aria-hidden="true"></i>
                    <input type="file" accept=".jpg,.jpeg,.png" onChange={(event) => onFileChange(event)} />
                </Button>}
                {vocUploadLoader && <div style={{ display: 'flex',justifyContent: 'center'}}><span style={{marginRight:'10px'}}>Uploading</span> <Spinner size="sm"  color="primary"/></div> }
                <PicturePreview>
                </PicturePreview>
            </>)
    }

    const uploadFile = (files) => {
        let fileName = files[0].name
        let actualFileName = sessionStorage.getItem("userId") + " " + fileName
        memberService.fetchs3PresignedURl(actualFileName).then(res => {
            if (res) {
                memberService.uploadToS3(res, files[0]).then(res => {
                    let fileList = files;
                    let filepush = []
                    filepush.push(fileList[0])
                    let actualFileName = sessionStorage.getItem("userId") +" "+ fileList[0].name
                    let fileName = actualFileName.substring(0, actualFileName.lastIndexOf(".") )
                    vocImageList.push({ name: actualFileName, type: fileList[0].type, index: vocImageList.length + 1,fileName:fileName })
                    setVOCImageList([...vocImageList])
                    setShowVoc(true)

                })
            }
        }).catch(error => {
            setError(error && error.response && error.response.data && error.response.data.message)
        }).finally(e => {
            setVOCUploadLoader(false)
            // setNextScreen(true)
        })
    }



    return (
        <>

            <Col lg="5" md="7" className="pb-8 mt-7">
                <span className="login_header">Vehicle Details</span>

                <Card className="bg-secondary shadow module-border-wrap">
                    <CardBody className="px-lg-5 py-lg-5">
                        <Form role="form">
                            <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                    <Input
                                        name="engineNumber"
                                        placeholder="Engine Number"
                                        value={data && data.engineNumber}
                                        autoComplete="new-engineNumber"
                                        onChange={handleChange}
                                        style={{ textTransform: "uppercase" }}
                                    />
                                </InputGroup>
                                <small style={{ color: 'red' }}>{engineNumberError}</small>
                            </FormGroup>
                            <FormGroup>
                                <InputGroup className="input-group-alternative">
                                    <Input
                                        name="chassisNumber"
                                        value={data && data.chassisNumber}
                                        placeholder="CHASSIS NUMBER"
                                        autoComplete="new-chassisNumber"
                                        onChange={handleChange}
                                        maxLength={20}
                                        style={{ textTransform: "uppercase" }}
                                        type="text"
                                    />
                                </InputGroup>
                                <small style={{ color: 'red' }}>{chassisNumberError}</small>
                            </FormGroup>
                            <Row>
                                <Col xs="12">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="input-organizationName">
                                            Select Model Name
                                        </label>
                                        <Autocomplete
                                            id="modelName"
                                            defaultValue= {{bikeModelName:  modelName}}
                                            options={vehicleModels}
                                            getOptionLabel={(option) => option.bikeModelName}
                                            debug
                                            onChange={handleChange}
                                            name="modelName"
                                            required
                                            renderInput={(params) => <TextField {...params} label="Model Name"

                                            />}
                                        />

                                    </FormGroup>

                                </Col>
                                <Col xs="12" style={{ display: 'flex',justifyContent: 'center'}}>
                                    {vocUpload()}
                                </Col>

                            </Row>
                            {showVoc && <Row>{vocShow()}</Row>}
                            <Row className="mt-3">
                                <Col xs="12" className="text-center" >
                                    <small style={{ color: 'red' }}>{modelNameError}</small>
                                    <small style={{ color: 'red' }}>{error}</small>
                                    <small style={{ color: 'red' }}>{vocUploadError}</small>

                                </Col>
                            </Row>
                            <div className="text-center">
                                <Link to={{ pathname: '/auth/initialDetails', state: props && props.location && props.location.state }} style={{ marginRight: '15px' }}>
                                    <Button className="mt-3" color="primary" type="button"  onClick={e=>handleBack()}>
                                        Back
                                    </Button>
                                </Link>
                                {!isLoading && <Button className="mt-3" color="primary" type="button" onClick={handleSaveVehicleJPJInfo}>
                                    Proceed
                                </Button>}
                                {isLoading && <Spinner style={{ marginTop: '10px' }} color="primary" />}

                            </div>
                        </Form>

                    </CardBody>
                </Card>
                {/*       
          */}
            </Col>
        </>
    );
};

export default VehicleOtherDetails;