
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    Col
} from "reactstrap";
import "../../views/LoginComponents/login.css"
import {  Link } from "react-router-dom";
import React from 'react';
function VehicleConfirmation(props) {
    const [signupType,setSignupType]= React.useState(props && props.location && props.location.state && props.location.statesignupType)
    const [plateNumber,setPlateNumber]= React.useState(props && props.location && props.location.state && props.location.plateNumber)
    const [icNumber,setIcNumber]= React.useState(props && props.location && props.location.state && props.location.icNumber)

    return (
        <>

            <Col lg="5" md="7" className="pb-8 mt-7">
                <span className="login_header">Confirmation</span>

                <Card className="bg-white shadow module-border-wrap">
                    <CardBody className="px-lg-5 py-lg-5 text-center">
                    <i className="fas fa-check-circle"  style={{color:'#12bf5f',fontSize:'90px'}}></i>
                        <h4>Vehicle Details Uploaded Successfully</h4>
                        <br/><br/>
                        <p>Bike details uploaded.We will notify you as soon as it is validated by our team.</p>
                    </CardBody>
                    <CardFooter>
                    <div className="text-center">
                                <Link to={{ pathname: '/auth/profile',state: {signupType:signupType ,icNumber:icNumber , plateNumber : plateNumber  }}}>
                                    <Button className="mt-3" color="primary" type="button" >
                                        Proceed
                                    </Button>
                                </Link>

                            </div>
                    </CardFooter>
                </Card>
            </Col>
        </>
    );
};

export default VehicleConfirmation;